import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './db.js';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import UserVerify from "./components/user-verify";
import ForgotPassword from "./components/forgot-pwd";
import LoginDemo from "./components/demo-login";
import Login from "./components/login";
import SignUp from "./components/signup";
import Dashboard from "./components/dashboard";
import Billings from "./components/billings";
import Orders from "./components/orders";
import Payments from "./components/payments";
import Inventory from "./components/inventory";
import Contacts from "./components/contacts";
import Accounting from "./components/accounting";
import AddContact from "./components/add-contact";
import NewBilling from "./components/new-billing";
import ViewBilling from "./components/view-billing";
import ViewPayment from "./components/view-payment";
import EditContact from "./components/edit-contact";
import ViewContact from "./components/view-contact";
import Reports from "./components/reports";
import CreditDebitNote from "./components/credit-debit-note";
import ViewCreditDebitNote from "./components/view-credit-debit";
import Products from "./components/products";
import Inventories from "./components/inventories";
import CreateProduct from "./components/create-product";
import ViewProduct from "./components/view-product";
import ViewVariant from "./components/view-variant";
import EditVariant from "./components/edit-variant";
import NewPayment from "./components/new-payment";
import EditInventory from "./components/edit-inventory";
import ViewInventory from "./components/view-inventory";
import CompanyDetails from "./components/settings/company-details";
import SettingBillings from "./components/settings/setting-billings";
import SettingInventory from "./components/settings/setting-inventory";
import StockCalculator from './components/settings/stock-calculator';
import SettingsBarcode from "./components/settings/setting-barcode";
import SettingContacts from "./components/settings/setting-contacts";
import UserManagement from "./components/settings/user-management";
import SettingExpenses from "./components/settings/setting-expenses";
import SettingsProfile from "./components/settings/setting-profile";
import AdjustUnpaidBills from './components/settings/adjust-unpaid-bills';
import LinkStores from './components/settings/link-stores';
import Expenses from "./components/expenses";
import Transport from "./components/transport";
import NewExpense from "./components/new-expense";
import ViewExpense from "./components/view-expense";
import SettingsNotifications from "./components/settings/setting-notifications";
import notFound from "./components/404";
import CreateQuotation from './components/create-quotation';
//ADMIN CONTROLLERS
import AdminLogin from "./components/admin/login";
import AdminStores from "./components/admin/stores";
import AdminViewStore from "./components/admin/view-store";
import AdminQueryBuilder from "./components/admin/query-builder";
import AdminSettings from "./components/admin/settings";

import encrypt_decrypt from './components/encrypt-decrypt.js';
import Quotation from './components/quotation';
import ViewQuotation from './components/view-quotation';
import PDFDownload from './components/pdf-download';
import ViewAccounts from './components/view-accounts';
import NewOrder from './components/new-order';
import ViewOrder from './components/view-order';
import SettingAccounts from './components/settings/setting-account';
import StockTransfer from './components/stock-transfer';
import Cheques from './components/cheques-list';
import SettingsPrintTemplateDesign from './components/settings/settings-print-template-design.js';
import PurchaseReturnRequest from './components/purchase-return-request.js';
import BillingPOS from './components/billing-pos.js';
import BulkProductImport from './components/bulk-product-import.js';
import BulkProductListing from './components/bulk-product-listing.js';


localStorage.removeItem('screen');
localStorage.removeItem("inventory_data");
localStorage.removeItem("is_create_variation");
localStorage.removeItem("item_type");
localStorage.removeItem("ctarget");
localStorage.removeItem("payment_mode");
localStorage.removeItem("selected_contact");
localStorage.removeItem("item-row-edit");
localStorage.removeItem("credit_days");
localStorage.removeItem("state");
localStorage.removeItem("discount");
localStorage.removeItem("is_auto_generate_barcode");
localStorage.removeItem('is_list_item_edit');
localStorage.removeItem('bpmode');
localStorage.removeItem('p_mode');
let user_info = localStorage.getItem('user');
let admin_info = localStorage.getItem('HFnmKMOHHO');
let is_auth = localStorage.getItem('is_auth');

let auth_decrypt = '';
if(is_auth!==null && is_auth!==''){
  auth_decrypt = JSON.parse(encrypt_decrypt.decrypt(JSON.parse(is_auth)));
}
 
let isAdminLoggedin = false;
let isAdmin = false;
if(admin_info){
  admin_info = JSON.parse(admin_info);
  if(admin_info.response.login_id){
      isAdminLoggedin = true;
  }
}

let isPDFDownload = false;

let isLoggedin = false;
let isDemoLogin = false;
if(user_info){
  user_info = JSON.parse(user_info);
  if(user_info.response.name){
      isLoggedin = true;
  }
}else{
    localStorage.removeItem("item_options");
    localStorage.removeItem("purchase_item_options");
    
    var request = window.indexedDB.deleteDatabase("ERP");
    request.onsuccess = function(e) {  };
    request.onblocked = function(e) {
    };
    request.onerror = function(e) { console.log("error: " + e); };

    var request1 = window.indexedDB.deleteDatabase("BaazarERP");
    request1.onsuccess = function(e) {  };
    request1.onblocked = function(e) {
    };
    request1.onerror = function(e) { console.log("error: " + e); };

    /*if(auth_decrypt!==''){
      if(auth_decrypt.username==='admin' && auth_decrypt.password==='magazine3'){
        isDemoLogin = false;
      }
    }*/
    /*var DBOpenRequest = window.indexedDB.open("ERP"); // opening a database.

    // Create event handlers for both success and failure of
    DBOpenRequest.onerror = function(event) {
      console.log("error");
    };

    DBOpenRequest.onsuccess = function(event) {
       console.log("success");

      // store the result of opening the database in the db variable.
      let db = DBOpenRequest.result;

      // now let"s close the database again!
      db.close();

      var request = window.indexedDB.deleteDatabase("ERP",1);
      request.onsuccess = function(e) { console.log("success"); };
      request.onblocked = function(e) {
        console.log("blocked: " + JSON.stringify(e));
      };
      request.onerror = function(e) { console.log("error: " + e); };
    };*/
}

const current_route = window.location.pathname;
if(current_route.match(/\/pdf/g)){
  isPDFDownload = true;
}

if(current_route.match(/\/admin-area/g)){
  isAdmin = true;
}
if(current_route==='/admin-area/login'){
  localStorage.removeItem('HFnmKMOHHO');
  isAdminLoggedin = false;
}

if(current_route==='/sign-up'){
  localStorage.removeItem('user');
  localStorage.removeItem('IdbVersion');
  localStorage.removeItem('is_auth');
  localStorage.removeItem('item_options');
  localStorage.removeItem('purchase_item_options');
  isLoggedin = false;
  var request = window.indexedDB.deleteDatabase("ERP");
  request.onsuccess = function(e) {  };
  request.onblocked = function(e) {
  };
  request.onerror = function(e) { console.log("error: " + e); };

  var request1 = window.indexedDB.deleteDatabase("BaazarERP");
  request1.onsuccess = function(e) {  };
  request1.onblocked = function(e) {
  };
  request1.onerror = function(e) { console.log("error: " + e); };
}

if(current_route==='/login'){
  let version = localStorage.getItem('IdbVersion');
  localStorage.removeItem('IdbVersion');
  localStorage.removeItem('user');
  localStorage.removeItem('is_auth');
  localStorage.removeItem('item_options');
  localStorage.removeItem('purchase_item_options');
  isLoggedin = false;
  if(version!==null){
    var request = window.indexedDB.deleteDatabase("ERP",version);
    request.onsuccess = function(e) {  };
    request.onblocked = function(e) {
      
      window.location.href = '/';
    };
    request.onerror = function(e) { console.log("error: " + e); };
    
    var request1 = window.indexedDB.deleteDatabase("BaazarERP",version);
  request1.onsuccess = function(e) {  };
  request1.onblocked = function(e) {
  };
  request1.onerror = function(e) { console.log("error: " + e); };
  }else{
    var request = window.indexedDB.deleteDatabase("ERP",1);
    request.onsuccess = function(e) {  };
    request.onblocked = function(e) {
      
      window.location.href = '/';
    };
    request.onerror = function(e) { console.log("error: " + e); };
  }
  var request1 = window.indexedDB.deleteDatabase("BaazarERP");
  request1.onsuccess = function(e) {  };
  request1.onblocked = function(e) {
  };
  request1.onerror = function(e) { console.log("error: " + e); };
}
let isVerify = false;
if(current_route==='/user-verify' || current_route==='/forgot-pwd'){
  isVerify = true;
  isLoggedin = false;
}

if(isPDFDownload===true){
  isVerify = false;
  isLoggedin = false;
  isDemoLogin = false;
}

function App() {
  
  if(isPDFDownload){
    return (
            <Switch>
              <Route path="/pdf/:store_name/:file_name" component={PDFDownload} />
              <Route component={notFound} />
          </Switch>
    );
  }
  if(isAdmin && isAdminLoggedin){
    return (
            <Switch>
              <Route exact path="/admin-area" component={AdminStores} />
              <Route path="/admin-area/stores" component={AdminStores} />
              <Route path="/admin-area/view-store" component={AdminViewStore} />
              <Route path="/admin-area/query-builder" component={AdminQueryBuilder} />
              <Route path="/admin-area/settings" component={AdminSettings} />
              <Route component={notFound} />
          </Switch>
        );
  }
  if(isAdmin && !isAdminLoggedin){
      return (<Router>
    <div className="App">
     
      <div className="auth-wrapper">
        <div className="auth-inner">
          <Switch>
            <Route exact path='/admin-area' component={AdminLogin} />
            <Route path="/admin-area/login" component={AdminLogin} />
          </Switch>
        </div>
      </div>
    </div></Router>
    );
  }



  if(isLoggedin){
    return (
            <Switch>
              <Route exact path="/" component={Billings} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/billings" component={Billings}/>
              <Route path="/orders" component={Orders}/>
              <Route path="/quotation" component={Quotation}/>
              <Route path="/payments/cheques" component={Cheques}/>
              <Route path="/payments/adjust_unpaid_bills" component={AdjustUnpaidBills}/>
              <Route path="/payments" component={Payments}/>
              <Route path="/order/payments" component={Payments}/>
              <Route path="/inventory" component={Inventory}/>
              <Route path="/new-expense" component={NewExpense}/>
              <Route path="/view-expense" component={ViewExpense}/>
              <Route path="/expenses" component={Expenses}/>
              <Route path="/transports" component={Transport}/>
              <Route path="/contacts" component={Contacts}/>
              <Route path="/accounting" component={Accounting}/>
              <Route path="/view-accounts" component={ViewAccounts}/>
              <Route path="/settings/user-management" component={UserManagement}/>
              <Route path="/settings/link-store" component={LinkStores}/>
              <Route path="/settings/stock-transfer" component={StockTransfer}/>
              <Route path="/settings/purchase-return-request" component={PurchaseReturnRequest}/>
              <Route path="/setting/inventory/stock-calculator" component={StockCalculator}/>
              <Route path="/settings/inventory/update-bulk" component={SettingInventory}/>
              <Route path="/settings/inventory" component={SettingInventory}/>
              <Route path="/settings/company-details" component={CompanyDetails}/>
              <Route path="/settings/billings-and-payments" component={SettingBillings}/>
              <Route path="/settings/contacts" component={SettingContacts}/>
              <Route path="/settings/barcode-management" component={SettingsBarcode}/>
              <Route path="/settings/desing-print-template" component={SettingsPrintTemplateDesign}/>
              <Route path="/settings/notifications" component={SettingsNotifications}/>
              <Route path="/settings/expenses" component={SettingExpenses}/>
              
              <Route path="/settings/accounts" component={SettingAccounts}/>
              <Route path="/profile" component={SettingsProfile}/>
              <Route path="/add-contact" component={AddContact}/>
              <Route path="/new-billing" component={NewBilling}/>
              <Route path="/new-pos" component={BillingPOS}/>
              <Route path="/new-order" component={NewOrder}/>
              <Route path="/create-quotation" component={CreateQuotation}/>
              <Route path="/edit-contact" component={EditContact}/>
              <Route path="/view-contact" component={ViewContact}/>
              <Route path="/new-payment/order" component={NewPayment}/>
              <Route path="/new-payment" component={NewPayment}/>
              <Route path="/edit-inventory" component={EditInventory}/>
              <Route path="/view-inventory" component={ViewInventory}/>
              <Route path="/view-billing" component={ViewBilling}/>
              <Route path="/view-order" component={ViewOrder}/>
              <Route path="/view-quotation" component={ViewQuotation}/>
              <Route path="/view-payment" component={ViewPayment}/>
              <Route path="/order/view-payment" component={ViewPayment}/>
              <Route path="/reports" component={Reports}/>
              <Route path="/products/view" component={ViewProduct}/>  
              <Route path="/products/log_old" component={ViewProduct}/>  
              <Route path="/products/log" component={ViewProduct}/>  
              <Route path="/products/consumption" component={ViewProduct}/>
              <Route path="/products/add" component={CreateProduct}/>  
              <Route path="/products/edit" component={CreateProduct}/>
              <Route path="/product-bulk-add" component={BulkProductImport}/>
              <Route path="/product-bulk-list" component={BulkProductListing}/>
              <Route path="/variant/view" component={ViewVariant}/>  
              <Route path="/variant/edit" component={EditVariant}/>  
              <Route path="/products" component={Products}/>  
              <Route path="/inventories" component={Inventories}/>  
              <Route path="/credit-debit-note" component={CreditDebitNote}/>  
              <Route path="/view-credit-debit" component={ViewCreditDebitNote}/>  
              <Route path="/404" component={notFound} />
              <Route component={notFound} />
          </Switch>
    );
  }
  if(isDemoLogin){
    return (<Router>
          <div className="App">
          <div className="auth-wrapper">
        <div className="auth-inner">
            <Switch>
              <Route path="/" component={LoginDemo} />
              
            </Switch>
            </div>
            </div>
            </div>
          </Router>
    );
  }
  if(isVerify){
    return (
            <Switch>
              <Route path="/user-verify" component={UserVerify} />
              <Route path="/forgot-pwd" component={ForgotPassword} />
              <Route component={notFound} />
          </Switch>
    );
  }


  return (<Router>
    <div className="App">
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">
          <a className="navbar-brand" href="https://bazaarerp.com/"><span style={{color:'black',fontWeight:'bold'}}>Bazaar</span><span style={{color:'#3b4fe4',fontWeight:'bold'}}>ERP</span></a>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to={"/login"}>Login</Link>
              </li>
              <li className="nav-item" style={{display:'none'}}>
                <Link className="nav-link" to={"/sign-up"}>Sign up</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="auth-wrapper">
        <div className="auth-inner">
          <Switch>
            <Route exact path='/' component={Login} />
            <Route path="/login" component={Login} />
            <Route path="/sign-up" component={SignUp} />
          </Switch>
        </div>
      </div>
    </div></Router>
  );
}

export default App;