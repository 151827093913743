import React, { Component } from 'react';
import Leftnav from "./leftnav";
import axios from 'axios';
import Link from './link.js';
import utils from '../utils.js';
let { app_api, user_info } = require('../library.js');

class BulkProductListing extends Component {
  constructor(props) {
    super();
    this.state = {
      products: [], // Holds the "imports" from the response
      loading: true,
      error: null,
      total_page: 0,
      current_page: 1,
    };
  }

  componentDidMount() {
    this.fetchProducts();
  }

  // Helper function to format a date string as "13 May 2039"
  formatDate = (dateStr) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  fetchProducts = () => {
    const { current_page } = this.state;
    const payload = {
      store_name: user_info.store_name,
      branch_id: user_info.login_id,
      user_id: user_info.user_id,
      uuid: utils.uuid(),
      role_id: user_info.access_level,
      token: user_info.token,
      _schema: user_info._schema,
      page: current_page
    };

    axios.post(app_api + "/bulk_products/list", payload)
      .then(response => {
         if(response.data && response.data.imports) {
             this.setState({
                products: response.data.imports,
                total_page: response.data.number_of_page,
                loading: false
             });
         } else {
             this.setState({ loading: false });
         }
      })
      .catch(error => {
         this.setState({ error, loading: false });
      });
  };

  prevPage = () => {
    this.setState(prevState => ({
      current_page: prevState.current_page > 1 ? prevState.current_page - 1 : 1,
      loading: true
    }), this.fetchProducts);
  };

  nextPage = () => {
    this.setState(prevState => ({
      current_page: prevState.current_page < prevState.total_page ? prevState.current_page + 1 : prevState.total_page,
      loading: true
    }), this.fetchProducts);
  };

  goToPage = (pageNumber) => {
    this.setState({
      current_page: pageNumber,
      loading: true
    }, this.fetchProducts);
  };

  renderPagination = () => {
    const { current_page, total_page } = this.state;
    if (total_page <= 1) return null;

    // Create an array for page numbers
    const pages = Array.from({ length: total_page }, (_, i) => i + 1);

    return (
      <nav aria-label="Page navigation">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${current_page === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={this.prevPage}>Previous</button>
          </li>
          {pages.map(page => (
            <li key={page} className={`page-item ${current_page === page ? 'active' : ''}`}>
              <button className="page-link" onClick={() => this.goToPage(page)}>
                {page}
              </button>
            </li>
          ))}
          <li className={`page-item ${current_page === total_page ? 'disabled' : ''}`}>
            <button className="page-link" onClick={this.nextPage}>Next</button>
          </li>
        </ul>
      </nav>
    );
  };

  render() {
    const { products, loading, error } = this.state;
    return (
      <div className="d-flex" id="wrapper">
        <Leftnav />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <nav id="page-header" className="navbar border-bottom">
              <div className="row">
                <div className="col-md-4">
                  <div className="mod-title">
                    <b id="breadcrumbs_subtitle">Bulk Products Listing</b>
                  </div>
                </div>
              </div>
            </nav>
            <div className="content-area">
              {loading ? (
                <p>Loading...</p>
              ) : error ? (
                <p>Error loading products.</p>
              ) : (
                <>
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th>PURCHASE #</th>
                        <th>DATE</th>
                        <th>Products Imported</th>
                        <th>Total Items</th>
                        <th>AMOUNT</th>
                        <th>INVOICE DATE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.length === 0 ? (
                        <tr>
                          <td colSpan="5" className="text-center">No products found.</td>
                        </tr>
                      ) : (
                        products.map((product, index) => (
                          <tr key={index}>
                            <td>
                              <Link link={"/view-billing/purchase/" + product.purchase_id} name={product.purchase_number} />
                            </td>
                            <td>{this.formatDate(product.date)}</td>
                            <td>{parseFloat(product.units)}</td>
                            <td>{parseFloat(product.total_products_imported)}</td>
                            <td>{product.amount}</td>
                            <td>{this.formatDate(product.import_date)}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  {this.renderPagination()}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BulkProductListing;
