import React, { Component } from 'react';
import Leftnav from "./leftnav";
import utils from '../utils.js';
import axios from 'axios';
let { enc_key, app_api, user_info } = require('../library.js');

class BulkProductImport extends Component {
  constructor() {
    super();
    this.state = {
      products: [this.getEmptyProduct()],
      searchResults: [],
      showDropdown: false,
      // bulkEntries now holds an array of bulk entry rows with an errors object for size and price.
      bulkEntries: [
        { productName: "", article: "", color: "", size: "", price: "", mrp: "", errors: { size: false, price: false } }
      ]
    };
  }

  // ----------------------------
  // Data Structure Generators
  // ----------------------------
  getEmptyProduct = () => ({
    productName: "",
    article: "",
    variation: [
      {
        variationName: [
          { variationName: "Color", action: "" },
          { variationName: "Size", action: "" }
        ],
        rows: [
          this.getEmptyRow([], 2),
        ],
      },
    ],
    actions: ""
  });

  getEmptyVariationName = () => ({
    variationName: "",
    action: ""
  });

  getEmptyRow = (variationNameArr, numCols) => ({
    variationValue: Array(numCols).fill(""),
    qty: "",
    rate: "",
    mrp: "",
    action: ""
  });

  // ----------------------------
  // Product Search and Field Handlers
  // ----------------------------
  handleProductChange = async (pIndex, field, value) => {
    const products = [...this.state.products];
    products[pIndex][field] = value;
    this.setState({ products });

    if (field === "productName" && value.trim().length >= 3) {
      try {
        const searchPayload = {
          store_name: user_info.store_name,
          branch_id: user_info.login_id,
          user_id: user_info.user_id,
          uuid: utils.uuid(),
          role_id: user_info.access_level,
          token: user_info.token,
          _schema: user_info._schema,
          item_name: value.trim(),
          target: "product"
        };
        let api = app_api + '/inventory/search_item';
        const response = await axios.post(api, searchPayload, { headers: { "Content-Type": "application/json" } });

        if (response.data && Array.isArray(response.data)) {
          this.setState({
            searchResults: response.data,
            showDropdown: true
          });
        } else {
          this.setState({ searchResults: [], showDropdown: false });
        }
      } catch (error) {
        console.error("Product search failed:", error);
        this.setState({ searchResults: [], showDropdown: false });
      }
    } else {
      this.setState({ searchResults: [], showDropdown: false });
    }
  };

  handleSelectProduct = (pIndex, selectedProduct) => {
    console.log("Selected Product:", selectedProduct);
    const products = [...this.state.products];
    products[pIndex].productName = selectedProduct.item_name;
    products[pIndex].product_id = selectedProduct.inventory_id;
    this.setState({
      products,
      searchResults: [],
      showDropdown: false
    });
  };

  removeProduct = (pIndex) => {
    if (this.state.products.length > 1) {
      const products = [...this.state.products];
      products.splice(pIndex, 1);
      this.setState({ products });
    }
  };

  addProduct = () => {
    const products = [...this.state.products, this.getEmptyProduct()];
    this.setState({ products });
  };

  // Variation Block Handlers
  handleVariationNameChange = (pIndex, colIndex, field, value) => {
    const products = [...this.state.products];
    const variationBlock = products[pIndex].variation[0];
    variationBlock.variationName[colIndex][field] = value;
    this.setState({ products });
  };

  removeVariationNameColumn = (pIndex, colIndex) => {
    const products = [...this.state.products];
    const variationBlock = products[pIndex].variation[0];
    if (variationBlock.variationName.length > 1) {
      variationBlock.variationName.splice(colIndex, 1);
      variationBlock.rows.forEach(row => {
        row.variationValue.splice(colIndex, 1);
      });
      this.setState({ products });
    }
  };

  addVariationNameColumn = (pIndex) => {
    const products = [...this.state.products];
    const variationBlock = products[pIndex].variation[0];
    variationBlock.variationName.push(this.getEmptyVariationName());
    variationBlock.rows = variationBlock.rows.map(row => ({
      ...row,
      variationValue: [...row.variationValue, ""]
    }));
    this.setState({ products });
  };

  handleRowChange = (pIndex, rowIndex, field, value) => {
    const products = [...this.state.products];
    const variationBlock = products[pIndex].variation[0];
    variationBlock.rows[rowIndex][field] = value;
    this.setState({ products });
  };

  removeRow = (pIndex, rowIndex) => {
    const products = [...this.state.products];
    const variationBlock = products[pIndex].variation[0];
    if (variationBlock.rows.length > 1) {
      variationBlock.rows.splice(rowIndex, 1);
      this.setState({ products });
    }
  };

  addRow = (pIndex) => {
    const products = [...this.state.products];
    const variationBlock = products[pIndex].variation[0];
    const numCols = variationBlock.variationName.length;
    variationBlock.rows.push(this.getEmptyRow(variationBlock.variationName, numCols));
    this.setState({ products });
  };

  handleVariationValueChange = (pIndex, rowIndex, colIndex, newVal) => {
    const products = [...this.state.products];
    const variationBlock = products[pIndex].variation[0];
    variationBlock.rows[rowIndex].variationValue[colIndex] = newVal;
    this.syncVariationColumns(products[pIndex]);
    this.setState({ products });
  };

  syncVariationColumns(product) {
    const variationBlock = product.variation[0];
    const colCount = variationBlock.variationName.length;
    variationBlock.rows.forEach((row) => {
      while (row.variationValue.length < colCount) {
        row.variationValue.push("");
      }
      while (row.variationValue.length > colCount) {
        row.variationValue.pop();
      }
    });
  }

  // ----------------------------
  // Bulk Excel-like Entry Section (Multiple Rows)
  // ----------------------------
  isBulkEntryRowComplete = (entry) => {
    return (
      entry.productName.trim() !== "" &&
      entry.article.trim() !== "" &&
      entry.color.trim() !== "" &&
      entry.size.trim() !== "" &&
      entry.price.toString().trim() !== "" &&
      entry.mrp.toString().trim() !== ""
    );
  };

  validateSize = (sizeValue) => {
    const pairs = sizeValue.split(',');
    if (pairs.length === 0) return false;
    for (let pair of pairs) {
      const parts = pair.trim().split('/');
      if (parts.length !== 2) return false;
      if (parts[0].trim() === "" || parts[1].trim() === "" || isNaN(Number(parts[1].trim()))) {
        return false;
      }
    }
    return true;
  };

  handleBulkEntryRowChange = (index, field, value) => {
    this.setState(prevState => {
      const bulkEntries = [...prevState.bulkEntries];
      bulkEntries[index][field] = value;

      if (field === "size") {
        bulkEntries[index].errors.size = !this.validateSize(value);
      }
      if (field === "price") {
        const isValid = value.toString().trim() !== "" && !isNaN(Number(value));
        bulkEntries[index].errors.price = !isValid;
      }

      if (index === bulkEntries.length - 1 && this.isBulkEntryRowComplete(bulkEntries[index])) {
        bulkEntries.push({ productName: "", article: "", color: "", size: "", price: "", mrp: "", errors: { size: false, price: false } });
      }
      return { bulkEntries };
    });
  };

  addBulkEntryRow = () => {
    const bulkEntries = [...this.state.bulkEntries, { productName: "", article: "", color: "", size: "", price: "", mrp: "", errors: { size: false, price: false } }];
    this.setState({ bulkEntries });
  };

  removeBulkEntryRow = (index) => {
    const bulkEntries = [...this.state.bulkEntries];
    if (bulkEntries.length > 1) {
      bulkEntries.splice(index, 1);
      this.setState({ bulkEntries });
    }
  };

  handleBulkEntriesAdd = () => {
    const { bulkEntries, products } = this.state;
    let newProducts = [];
  
    // Filter out any bulk entry rows that are empty/incomplete
    const filteredBulkEntries = bulkEntries.filter(entry =>
      entry.productName.trim() !== "" &&
      entry.article.trim() !== "" &&
      entry.color.trim() !== "" &&
      entry.size.trim() !== "" &&
      entry.price.toString().trim() !== "" &&
      entry.mrp.toString().trim() !== ""
    );
  
    // Alert the user if no valid bulk entry row is filled
    if (filteredBulkEntries.length === 0) {
      alert("Please fill out bulk entry rows before saving.");
      return;
    }
  
    for (let entry of filteredBulkEntries) {
      const { productName, article, color, size, price, mrp } = entry;
      const sizePairs = size.split(',');
      if (sizePairs.length === 0 || !this.validateSize(size)) {
        alert("One or more rows have an invalid Size field. Expected format: size/qty, size/qty");
        return;
      }
      
      const rows = [];
      for (let pair of sizePairs) {
        pair = pair.trim();
        if (!pair) continue;
        const parts = pair.split('/');
        const sizeValue = parts[0].trim();
        const qtyValue = parts[1].trim();
        rows.push({
          variationValue: [color, sizeValue],
          qty: qtyValue,
          rate: price,
          mrp: mrp,
          action: ""
        });
      }
  
      // Use trimmed values for consistent merging
      const trimmedProductName = productName.trim();
      const trimmedArticle = article.trim();
  
      // Check if a product with the same productName and article already exists in newProducts
      let existingProduct = newProducts.find(
        prod =>
          prod.productName === trimmedProductName &&
          prod.article === trimmedArticle
      );
      if (existingProduct) {
        // Merge the new variation rows into the existing product's variation block
        existingProduct.variation[0].rows = existingProduct.variation[0].rows.concat(rows);
      } else {
        // Create a new product entry
        const newProduct = {
          productName: trimmedProductName,
          article: trimmedArticle,
          variation: [{
            variationName: [
              { variationName: "Color", action: "" },
              { variationName: "Size", action: "" }
            ],
            rows: rows
          }],
          actions: ""
        };
        newProducts.push(newProduct);
      }
    }
  
    // Remove any default empty product entries from the existing products list.
    // A product is considered empty if both productName and article are blank.
    const nonEmptyProducts = products.filter(
      prod => prod.productName.trim() !== "" || prod.article.trim() !== ""
    );

  
    this.setState({
      products: [...nonEmptyProducts, ...newProducts],
      bulkEntries: [{
        productName: "",
        article: "",
        color: "",
        size: "",
        price: "",
        mrp: "",
        errors: { size: false, price: false }
      }]
    });
  };
  
  // ----------------------------
  // Submit Handler
  // ----------------------------
  handleSubmit = () => {
    const { products } = this.state;
    console.log("Final Data:", products);
  
    for (let pIndex = 0; pIndex < products.length; pIndex++) {
      const product = products[pIndex];
      if (!product.productName?.trim() && !product.article?.trim()) {
        continue;
      }
      if (!product.productName?.trim() || !product.article?.trim()) {
        alert(`Please fill all required fields (Product Name, Article) for product row ${pIndex + 1}.`);
        return;
      }
      const variationBlock = product.variation[0];
      const requiredColCount = variationBlock.variationName.length;
      for (let rIndex = 0; rIndex < variationBlock.rows.length; rIndex++) {
        const row = variationBlock.rows[rIndex];
        const isRowEmpty =
          row.variationValue.every(val => val.trim() === "") &&
          !row.qty &&
          !row.rate &&
          !row.mrp;
        if (isRowEmpty) continue;
        if (row.variationValue.length !== requiredColCount) {
          alert(`Mismatch in variation columns for product row ${pIndex + 1}, variation row ${rIndex + 1}.`);
          return;
        }
        const allVariationValuesFilled = row.variationValue.every(val => val.trim() !== "");
        if (!allVariationValuesFilled) {
          alert(`Please fill all variation values in product row ${pIndex + 1}, variation row ${rIndex + 1}.`);
          return;
        }
        if (!row.qty || !row.rate || !row.mrp) {
          alert(`Please complete Qty, Rate, and MRP in product row ${pIndex + 1}, variation row ${rIndex + 1}.`);
          return;
        }
      }
    }

    let formData = new FormData();
    formData.append("store_name", user_info.store_name);
    formData.append("branch_id", user_info.login_id);
    formData.append("user_id", user_info.user_id);
    formData.append("uuid", utils.uuid());
    formData.append("role_id", user_info.access_level);
    formData.append("token", user_info.token);
    formData.append("_schema", user_info._schema);
    formData.append("product_data", JSON.stringify(products));

    axios.post(app_api + "/import_bulk_products", formData)
      .then((response) => {
        console.log("Bulk product import response:", response.data);
        if (response.data.success === 1) {
          alert(response.data.message);
          this.props.history.push("/product-bulk-list");
        } else {
          alert("Failed to import products. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Bulk product import error:", error);
        alert("Failed to import products. Please try again.");
      });
  };
  
  render() {
    const { products, bulkEntries, searchResults, showDropdown } = this.state;
    return (
      <div className="d-flex" id="wrapper">
        <Leftnav />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <nav id="page-header" className="navbar border-bottom">
              <div className="row">
                <div className="col-md-4">
                  <div className="mod-title">
                    <b id="breadcrumbs_subtitle">Import Bulk Products</b>
                  </div>
                </div>
              </div>
            </nav>
            <div className="content-area">
              <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                {/* Main Products Table */}
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr style={headerStyle}>
                      <th style={thStyle}>S.No</th>
                      <th style={thStyle}>Product Name</th>
                      <th style={thStyle}>Article</th>
                      <th style={thStyle}>Variations</th>
                      <th style={thStyle}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product, pIndex) => {
                      const variationBlock = product.variation[0];
                      const variationNameArr = variationBlock.variationName;
                      const rowsArr = variationBlock.rows;
                      return (
                        <tr key={pIndex}>
                          <td style={tdStyle}>{pIndex + 1}</td>
                          <td style={{ ...tdStyle, position: "relative" }}>
                            <input
                              type="text"
                              value={product.productName}
                              onChange={(e) => this.handleProductChange(pIndex, "productName", e.target.value)}
                              placeholder="Search Product"
                              style={{ width: "100%", padding: "5px" }}
                            />
                            {showDropdown && searchResults.length > 0 && (
                              <ul
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  background: "#fff",
                                  border: "1px solid #ccc",
                                  listStyle: "none",
                                  padding: "5px",
                                  margin: "0",
                                  zIndex: 1000,
                                  maxHeight: "200px",
                                  overflowY: "auto"
                                }}
                              >
                                {searchResults.map((result, index) => (
                                  <li
                                    key={index}
                                    onClick={() => this.handleSelectProduct(pIndex, result)}
                                    style={{
                                      padding: "5px",
                                      cursor: "pointer",
                                      borderBottom: "1px solid #ddd"
                                    }}
                                  >
                                    {result.item_name}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </td>
                          <td style={tdStyle}>
                            <input
                              type="text"
                              style={inputStyle}
                              value={product.article}
                              onChange={(e) =>
                                this.handleProductChange(pIndex, "article", e.target.value)
                              }
                            />
                          </td>
                          <td style={tdStyle}>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                              <thead>
                                <tr style={subHeaderStyle}>
                                  {variationNameArr.map((colObj, colIndex) => (
                                    <th key={colIndex} style={subThStyle}>
                                      {colIndex === 0 ? (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                          <div>
                                            <input
                                              type="text"
                                              style={variationNameInputStyle}
                                              placeholder={`Variation #${colIndex + 1}`}
                                              value={colObj.variationName}
                                              onChange={(e) =>
                                                this.handleVariationNameChange(
                                                  pIndex,
                                                  colIndex,
                                                  "variationName",
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <button
                                              onClick={() => this.addVariationNameColumn(pIndex)}
                                              style={buttonStyle}
                                            >
                                              +
                                            </button>
                                            <button
                                              onClick={() => this.removeVariationNameColumn(pIndex, colIndex)}
                                              disabled={variationNameArr.length === 1}
                                              style={variationNameArr.length === 1 ? disabledButtonStyle : buttonStyle}
                                            >
                                              x
                                            </button>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <input
                                            type="text"
                                            style={variationNameInputStyle}
                                            placeholder={`Variation #${colIndex + 1}`}
                                            value={colObj.variationName}
                                            onChange={(e) =>
                                              this.handleVariationNameChange(
                                                pIndex,
                                                colIndex,
                                                "variationName",
                                                e.target.value
                                              )
                                            }
                                          />
                                          <button
                                            onClick={() => this.removeVariationNameColumn(pIndex, colIndex)}
                                            disabled={variationNameArr.length === 1}
                                            style={variationNameArr.length === 1 ? disabledButtonStyle : buttonStyle}
                                          >
                                            X
                                          </button>
                                        </div>
                                      )}
                                    </th>
                                  ))}
                                  <th style={subThStyle}>Qty</th>
                                  <th style={subThStyle}>Rate</th>
                                  <th style={subThStyle}>MRP</th>
                                  <th style={subThStyle}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {rowsArr.map((rowObj, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {rowObj.variationValue.map((val, colIndex) => (
                                      <td key={colIndex} style={subTdStyle}>
                                        <input
                                          type="text"
                                          style={inputStyle}
                                          placeholder={`Value #${colIndex + 1}`}
                                          value={val}
                                          onChange={(e) =>
                                            this.handleVariationValueChange(
                                              pIndex,
                                              rowIndex,
                                              colIndex,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                    ))}
                                    <td style={subTdStyle}>
                                      <input
                                        type="number"
                                        style={inputStyle}
                                        placeholder="Qty"
                                        value={rowObj.qty}
                                        onChange={(e) =>
                                          this.handleRowChange(pIndex, rowIndex, "qty", e.target.value)
                                        }
                                      />
                                    </td>
                                    <td style={subTdStyle}>
                                      <input
                                        type="number"
                                        style={inputStyle}
                                        placeholder="Rate"
                                        value={rowObj.rate}
                                        onChange={(e) =>
                                          this.handleRowChange(pIndex, rowIndex, "rate", e.target.value)
                                        }
                                      />
                                    </td>
                                    <td style={subTdStyle}>
                                      <input
                                        type="number"
                                        style={inputStyle}
                                        placeholder="MRP"
                                        value={rowObj.mrp}
                                        onChange={(e) =>
                                          this.handleRowChange(pIndex, rowIndex, "mrp", e.target.value)
                                        }
                                      />
                                    </td>
                                    <td style={subTdStyle}>
                                      <button
                                        onClick={() => this.addRow(pIndex)}
                                        style={{ ...buttonStyle, marginLeft: '4px' }}
                                      >
                                        +
                                      </button>
                                      <button
                                        onClick={() => this.removeRow(pIndex, rowIndex)}
                                        disabled={rowsArr.length === 1}
                                        style={rowsArr.length === 1 ? disabledButtonStyle : buttonStyle}
                                      >
                                        x
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                          <td style={tdStyle}>
                            <button onClick={this.addProduct} style={buttonStyle}>+</button>
                            <button
                              onClick={() => this.removeProduct(pIndex)}
                              disabled={products.length === 1}
                              style={products.length === 1 ? disabledButtonStyle : buttonStyle}
                            >
                              x
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <br />
                <button onClick={this.handleSubmit} style={submitButtonStyle}>
                  Submit
                </button>
                {/* ----------------------------
                    Bulk Excel-like Entry Section (Multiple Rows)
                ---------------------------- */}
                <div style={{ marginTop: '20px', border: '1px solid #ccc', padding: '10px' }}>
                  <h4>Bulk Excel-like Entry</h4>
                  <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '10px' }}>
                    <thead>
                      <tr style={headerStyle}>
                        <th style={thStyle}>Product</th>
                        <th style={thStyle}>Article</th>
                        <th style={thStyle}>Color</th>
                        <th style={thStyle}>Size (e.g., 7/1,8/3)</th>
                        <th style={thStyle}>Price</th>
                        <th style={thStyle}>MRP</th>
                        <th style={thStyle}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bulkEntries.map((entry, index) => (
                        <tr key={index}>
                          <td style={tdStyle}>
                            <input
                              type="text"
                              value={entry.productName}
                              onChange={(e) => this.handleBulkEntryRowChange(index, 'productName', e.target.value)}
                              style={inputStyle}
                              placeholder="Product Name"
                            />
                          </td>
                          <td style={tdStyle}>
                            <input
                              type="text"
                              value={entry.article}
                              onChange={(e) => this.handleBulkEntryRowChange(index, 'article', e.target.value)}
                              style={inputStyle}
                              placeholder="Art No"
                            />
                          </td>
                          <td style={tdStyle}>
                            <input
                              type="text"
                              value={entry.color}
                              onChange={(e) => this.handleBulkEntryRowChange(index, 'color', e.target.value)}
                              style={inputStyle}
                              placeholder="Blue."
                            />
                          </td>
                          <td style={tdStyle}>
                            <input
                              type="text"
                              value={entry.size}
                              onChange={(e) => this.handleBulkEntryRowChange(index, 'size', e.target.value)}
                              style={
                                entry.errors.size
                                  ? { ...inputStyle, border: '1px solid red' }
                                  : inputStyle
                              }
                              placeholder="7/1,8/3"
                            />
                          </td>
                          <td style={tdStyle}>
                            <input
                              type="number"
                              value={entry.price}
                              onChange={(e) => this.handleBulkEntryRowChange(index, 'price', e.target.value)}
                              style={
                                entry.errors.price
                                  ? { ...inputStyle, border: '1px solid red' }
                                  : inputStyle
                              }
                              placeholder="1000"
                            />
                          </td>
                          <td style={tdStyle}>
                            <input
                              type="number"
                              value={entry.mrp}
                              onChange={(e) => this.handleBulkEntryRowChange(index, 'mrp', e.target.value)}
                              style={inputStyle}
                              placeholder="2500"
                            />
                          </td>
                          <td style={tdStyle}>
                            <button
                              onClick={() => this.removeBulkEntryRow(index)}
                              disabled={bulkEntries.length === 1}
                              style={bulkEntries.length === 1 ? disabledButtonStyle : buttonStyle}
                            >
                              x
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <button onClick={this.addBulkEntryRow} style={buttonStyle}>
                      Add New Row
                    </button>
                    <button onClick={this.handleBulkEntriesAdd} style={submitButtonStyle}>
                      Save Bulk Products
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// ----------------------------
// STYLES
// ----------------------------
const headerStyle = { backgroundColor: '#f2f2f2' };
const thStyle = {
  border: '1px solid #ddd',
  padding: '8px',
  textAlign: 'center',
  verticalAlign: 'middle'
};
const tdStyle = {
  border: '1px solid #ddd',
  padding: '8px',
  textAlign: 'center',
  verticalAlign: 'top'
};

const subHeaderStyle = { backgroundColor: '#e6e6e6' };
const subThStyle = {
  border: '1px solid #ccc',
  padding: '4px',
  textAlign: 'center'
};
const subTdStyle = {
  border: '1px solid #ccc',
  padding: '4px',
  textAlign: 'center'
};

const inputStyle = {
  width: '100%',
  outline: 'none',
  textAlign: 'center'
};

const variationNameInputStyle = {
  width: '70%',
  outline: 'none',
  textAlign: 'center',
  marginRight: '4px'
};

const buttonStyle = {
  cursor: 'pointer',
  backgroundColor: '#ff4d4d',
  color: '#fff',
  padding: '3px 6px',
  borderRadius: '3px',
  fontSize: '0.8em',
  marginRight: '2px'
};

const disabledButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#ccc',
  cursor: 'not-allowed'
};

const submitButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#28a745',
  marginLeft: '5px'
};

export default BulkProductImport;
