import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import ProductUnits from "./product-units";
import utils from '../utils.js';
import Autocomplete from "./autocomplete";
import BillingItemList from './billing-item-list';
import check_access from './access-level.js';
import ReturnBillingItemList from './return-billing-item-list';
import ProductCategory from "./product-category";
import TagFields from "./tag-field";
import ModalBox from "./modal-box";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import { faTimes,faCheckCircle,faPencilAlt,faTrash,faExclamationTriangle, faPlus  } from '@fortawesome/free-solid-svg-icons';
import DifferentBarcodeInventoryItems from "./different-barcode-inventory-items";
import PurchaseCustomFields from "./purchase-custom-fields";
import TextField from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
let {enc_key,app_api,user_info,gst_options} = require('../library.js');
let _this;
let remove_items = [];
let auto_generate_barcode = '';


export default class AddBillingItem extends Component {
    constructor() {
        super();
        this.state = {
            key : 0,
            is_custom_field : false,
            item_discount_on_mrp : false,
            item_options : [],
            purchase_item_options : [],
            variable_purchase_item_options : [],
            bundle_text : '',
            is_tax_inc : 'false',
            more_rows : '',
            return_more_rows : '',
            is_new_item:0,
            active_tab : '',
            edit_item_selected : 'none',
            item_id_selected : 'none',
            selected_inventory_id : 'none',
            selected_barcode : 'none',
            selected_alias_barcode : 'none',
            edit_item_active_index : 'none',
            item_add_count : 0,
            allow_negative_inventory:false,
            is_item_commission:false,
            item_commission : 0,
            is_checked_lsp :false,
            list_item_type :'',
            product_variation_pop_title :'',
            selected_product :'',
            selected_item_name :'',
            selected_item_desc :'',
            selected_item_hsn :'',
            selected_old_item :'',
            is_variant_checked : true,
            variantOptions : [],
            variantList : [],
            variantPreviewList : [],
            v_opt_counter : 1,
            inventory_item:[],
            variation_list : [],
            options_head:[],
            edit_product_variant : 0,
            is_open_popup : false,
            isMobile : false,
            barcode_name_on_the_bill_view:false,
            item_name_on_the_bill_view:false,
            is_auto_generate_barcode : false,
            on_off_msp : false,
            alias_barcode_while_billing : false,
            total_item_amount : 0,
            empty_var_error : '',
            scrollPos : 60,
            regenerate_barcode : {regenerate_barcode:false},
            regenerate_barcode_opt : {regenerate_barcode:false},
            regen_bc_flag : false,
            p_rand_key : '',
            defaultCategory : '',
            defaultCategoryID : 0,
            sale_field_hide : [],
            auto_options: (localStorage.getItem('auto_desc_opt')!==null)?JSON.parse(localStorage.getItem('auto_desc_opt')):[],
            auto_opt_value :'',
            send_e_invoice :false,
            sales_sp_disc_type : 'per',
            purchase_sp_disc_type : 'per',
            purchase_pp_disc_type : 'per',
            hsn_list:[],
        };
        this.rm_var_li_ids = [];
        this.item_to_remove = [];
        this.variant_data = [];
        this.variantOptions = [];
        this.variantList = [];
        this.variantPreviewList = [];
        this.handleCalculation = this.handleCalculation.bind(this);
        this.handleTaxInclude = this.handleTaxInclude.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleChangeGST = this.handleChangeGST.bind(this);
        this.handleIncludeTaxCalculation = this.handleIncludeTaxCalculation.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.isMobile = utils.isMobile.bind(this);
        this.ADJUST_BILL_SNO = utils.ADJUST_BILL_SNO.bind(this);
        this.CHECK_CREDIT_LIMIT = utils.CHECK_CREDIT_LIMIT.bind(this);
        this.handleCustomItemName = this.handleCustomItemName.bind(this);
        this.handleRemoveRow = this.handleRemoveRow.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this);
        this.calculateReturnTotal = this.calculateReturnTotal.bind(this);
        this.checkLowestSellingPrice = this.checkLowestSellingPrice.bind(this);
        this.handleLSP = this.handleLSP.bind(this);
        this.handleFinalCalculation = this.handleFinalCalculation.bind(this);
        this.handleCreateCategory = this.handleCreateCategory.bind(this);
        
        this.handleSmoothAnimation = this.handleSmoothAnimation.bind(this);
        this.addMoreItem = this.addMoreItem.bind(this);
        this.addMoreReturnItem = this.addMoreReturnItem.bind(this);
        this.handleResetField = this.handleResetField.bind(this);
        this.handleUpdateInventoryQuantity = this.handleUpdateInventoryQuantity.bind(this);
        this.handleUnsetState = this.handleUnsetState.bind(this);
        this.handleChangeItemType = this.handleChangeItemType.bind(this);
        this.handleChangeListTypeOnFly = this.handleChangeListTypeOnFly.bind(this);
        this.updateVariationListState = this.updateVariationListState.bind(this);
        this.updateVariationOptionState = this.updateVariationOptionState.bind(this);
        this.removeVariationList = this.removeVariationList.bind(this);
        this.handleMoreVariantOpt = this.handleMoreVariantOpt.bind(this);
        
        this.handleRemoveItem = this.handleRemoveItem.bind(this);
        this.handleVariationEdit = this.handleVariationEdit.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.handleCreateVariation = this.handleCreateVariation.bind(this);
        this.handleCalculationOnClick = this.handleCalculationOnClick.bind(this);
        this.handleCalculationOnFly = this.handleCalculationOnFly.bind(this);
        this.handleVarIncludeTaxCalculation = this.handleVarIncludeTaxCalculation.bind(this);
        this.handleChangeItemInfo = this.handleChangeItemInfo.bind(this);
        this.handleChangeBarcodeAuto = this.handleChangeBarcodeAuto.bind(this);
        this.updateItemQuantity = this.updateItemQuantity.bind(this);
        this.handleCloseBarcodeAlert = this.handleCloseBarcodeAlert.bind(this);
        this.handleOpenBarcodeAlert = this.handleOpenBarcodeAlert.bind(this);
        this.handleRegenerateBarcode = this.handleRegenerateBarcode.bind(this);
        this.is_access = check_access.is_access.bind(this);
        this.more_rows = [];
        this.return_more_rows = [];
        _this = this;
    }

    handleOpenBarcodeAlert(){
      var element = document.getElementById('re-generate-barcode');
      element.classList.remove("fade");
      element.style.display="block";
      setTimeout(function(){
        document.getElementById("handleRegenerateBarcode").focus();
      },200)
    }

    closeBarcodeAlert = () =>{
      var element = document.getElementById('re-generate-barcode');
      element.classList.add("fade");
      element.style.display="none";
    }

    handleCloseBarcodeAlert(){
      this.setState({regen_bc_flag:null});
      this.closeBarcodeAlert();
      setTimeout(function(){
        document.getElementById("handleRegenerateBarcode").focus();
        document.getElementById("add-item-to-list").click();
      },200)
    }

    handleRegenerateBarcode(e){
      this.setState({regen_bc_flag:true});
      setTimeout(function(){
        document.getElementById("add-item-to-list").click();
      },100)
      this.closeBarcodeAlert();
    }

    handleCreateCategory(e){
      let thisid = e.currentTarget.getAttribute('data_target');
      if(thisid!==null){
        var element = document.getElementById(thisid);
        element.classList.remove("fade");
        element.style.display="block";
         document.getElementById("save-target-type").value = 'billing-category';
      }
    }
    handleChangeBarcodeAuto(e){
      let is_checked = e.currentTarget.checked;
      this.setState({is_auto_generate_barcode:is_checked});
      if(is_checked){
        document.getElementById("iitem-barcode").setAttribute('disabled',true);
        document.getElementById("iitem-barcode").removeAttribute('tabindex');
      }else{
        document.getElementById("iitem-barcode").removeAttribute('disabled');
        document.getElementById("iitem-barcode").setAttribute('tabindex',191);
      }
    }
    handleChangeItemInfo(e){
      let target = e.currentTarget.getAttribute('data-target');
      let thisval = e.currentTarget.value;
      if(target==='item_name'){
        let product_name = 'pk_'+thisval.replace(/\s+/g, "___");
        this.setState({selected_item_name:thisval});
        this.setState({selected_product:product_name});
        let product_variation_pop_title = "VARIATION FOR PRODUCT "+thisval;
        this.setState({product_variation_pop_title:product_variation_pop_title});
      }else if(target==='desc'){
        this.setState({selected_item_desc:thisval});
      }else if(target==='hsn'){
        this.setState({selected_item_hsn:thisval});
      }

    /*   if(e.keyCode===13){
         if(target==='item_name'){
            document.getElementById("selected_item_desc").focus();
            let tidx = document.getElementById("selected_item_desc").getAttribute('tindex');
            console.log(tidx);
         }else if(target==='desc'){
            document.getElementById("selected_item_hsn").focus();
         }else if(target==='hsn'){
          if(document.getElementById("prev-item-price")){
            document.getElementById("prev-item-price").focus();
          }
         }
      } */
    }
    handleCalculationOnClick(e){
        let data_target = localStorage.getItem('ctarget');
        data_target = data_target.replace('prev','var');
        let dt = document.querySelectorAll('[data-target='+data_target+']');
        for(let i=0;i<dt.length;i++){
          let thisval = dt[i].value;
          let data_id = dt[i].getAttribute('data-id');
          let id = dt[i].id;
          this.handleCalculationOnFly(thisval,data_target,data_id,id);
        }
    }


  handleCalculationOnFly(thisval,data_target,data_id,id){
      let contact_stat = localStorage.getItem('state');

      let quantity = 1;
      if(document.getElementById("var-item-quantity-"+data_id)){
        quantity = document.getElementById("var-item-quantity-"+data_id).value;
        quantity = (quantity!==''?parseFloat(quantity):1);
        
      }
     
      let price = 0;
      if(document.getElementById("var-item-price-"+data_id)){
        price = document.getElementById("var-item-price-"+data_id).value;
        price = (price!==''?parseFloat(price):0);
      }
      let hidden_price = 0;
      if(data_target==="var-item-price"){
        document.getElementById("var-item-price-hidden-"+data_id).value = thisval;
      }
      if(document.getElementById("var-item-price-hidden-"+data_id)){
        hidden_price = document.getElementById("var-item-price-hidden-"+data_id).value
      }

    

      let discount = 0;
      if(document.getElementById("var-item-pp-disc-"+data_id)){
        discount = document.getElementById("var-item-pp-disc-"+data_id).value;
        if(discount===""){
          discount = 0;
        }
      }
      
      let pp_tax = 0;
      if(document.getElementById("var-item-pp-tax-"+data_id)){
        pp_tax = document.getElementById("var-item-pp-tax-"+data_id).value.replace('GST','');
        pp_tax = pp_tax.replace('%','');
       
        pp_tax = parseFloat(pp_tax);
      }
     
       let igst = 0;
       let sgst = 0;
       let cgst = 0;
      if(pp_tax>0){
       if(contact_stat===""){
          cgst = pp_tax/2;
          sgst = pp_tax/2;
          igst = 0;
       }else if(contact_stat!==user_info.state){
          cgst = 0;
          sgst = 0;
          igst = pp_tax;
       }else if(contact_stat===user_info.state){
          cgst = pp_tax/2;
          sgst = pp_tax/2;
          igst = 0;
       }
     }else{
        cgst = 0;
        sgst = 0;
        igst = 0;
     }

    if(document.getElementById("var-item-sgst-"+data_id)){
      document.getElementById("var-item-sgst-"+data_id).value = sgst;
      document.getElementById("var-item-sgst-hidden-"+data_id).value = sgst;
    }
    if(document.getElementById("var-item-cgst-"+data_id)){
      document.getElementById("var-item-cgst-"+data_id).value = cgst;
      document.getElementById("var-item-cgst-hidden-"+data_id).value = cgst;
    }
    if(document.getElementById("var-item-igst-"+data_id)){
      document.getElementById("var-item-igst-"+data_id).value = igst;
      document.getElementById("var-item-igst-hidden-"+data_id).value = igst;
    }

      if(document.getElementById("var-item-igst-"+data_id)){
        igst = document.getElementById("var-item-igst-"+data_id).value;
        igst = (igst!=='')?parseInt(igst):0;
      }      

      
      if(document.getElementById("var-item-sgst-"+data_id)){
        sgst = document.getElementById("var-item-sgst-"+data_id).value;
        sgst = (sgst!=='')?parseInt(sgst):0;
      }      

      if(document.getElementById("var-item-cgst-"+data_id)){
        cgst = document.getElementById("var-item-cgst-"+data_id).value;
        cgst = (cgst!=='')?parseInt(cgst):0;
      }      
      let tax = cgst+sgst+igst;
      
      let cal_discount = ((hidden_price*quantity)*discount)/100;
      let item_grand_total = (price*quantity)-cal_discount;
      let cal_tax = (item_grand_total*tax)/100;
      let cgst_tax = (item_grand_total*cgst)/100;
      let sgst_tax = (item_grand_total*sgst)/100;
      let igst_tax = (item_grand_total*igst)/100;
      document.getElementById("var-item-igst-total-"+data_id).value = this.floating_point(igst_tax);
      document.getElementById("var-item-sgst-total-"+data_id).value = this.floating_point(sgst_tax);
      document.getElementById("var-item-cgst-total-"+data_id).value = this.floating_point(cgst_tax);

      if(document.getElementById("var-pp-discount-hidden-"+data_id)){
        document.getElementById("var-pp-discount-hidden-"+data_id).value = this.floating_point(cal_discount);
      }
      
      let final_amount = item_grand_total+cal_tax;
     
      document.getElementById("var-item-total-txt-"+data_id).value = this.floating_point(final_amount);
       
      //if(data_target==='var-item-total-txt' || data_target==='var-item-pp-tax' ||data_target==='var-item-pp-tax-type' || data_target==='var-item-pp-disc' || data_target==='var-item-quantity'){
        this.handleVarIncludeTaxCalculation(data_id);
      //}
    }

    handleVarIncludeTaxCalculation = (id)=>{
        let is_checked = document.getElementById("var-item-pp-tax-type-"+id).value;
        
        let cgst = document.getElementById("var-item-cgst-"+id).value;
        let sgst = document.getElementById("var-item-sgst-"+id).value;
        let igst = document.getElementById("var-item-igst-"+id).value;
        let quantity = document.getElementById("var-item-quantity-"+id).value.replace(/,/g , "");
        if(quantity===""){
          quantity = 1;
        }else{
          quantity = parseInt(quantity);
        }
        
        if(is_checked==='including'){  
        let price = document.getElementById("var-item-price-hidden-"+id).value;
        if(price===""){
          price = document.getElementById("var-item-price-"+id).value;
          document.getElementById("var-item-price-hidden-"+id).value = price;
        }
        
        let discount = 0;
        if(document.getElementById("var-pp-discount-hidden-"+id)){
          discount = document.getElementById("var-pp-discount-hidden-"+id).value;
          if(discount===""){
            discount=0;
          }
          discount = parseFloat(discount);
        }
         
        
        let tax = 0;
        if((cgst!=="" && cgst!=="0")  && (sgst!=="" && sgst!=="0")){
          
          cgst = parseFloat(cgst);
          sgst = parseFloat(sgst);
          let total_cgst = 0;
          let total_sgst =0;
        
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + (sgst+cgst) ) ) );

          GST = price-GST;

          GST = parseFloat(this.floating_point(GST));

          let gross_amount = GST-(discount/quantity);
          
          let TOTAL_GST = price-GST;

          total_cgst = TOTAL_GST/2;
          total_sgst = TOTAL_GST/2;

          total_cgst = (quantity*total_sgst);
          total_sgst = (quantity*total_sgst);
          document.getElementById("var-item-cgst-total-"+id).value = this.floating_point(total_cgst);
          document.getElementById("var-item-sgst-total-"+id).value = this.floating_point(total_sgst);
          document.getElementById("var-item-price-"+id).value = this.floating_point(gross_amount);
          price = (quantity*price)-discount;
          
          document.getElementById("var-item-total-txt-"+id).value =this.floating_point(price);
        }else if(igst!=="" && igst!=="0"){
          
          igst = parseFloat(igst);
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + igst ) ) );
          
          GST = price-GST;
          GST = parseFloat(this.floating_point(GST));
         
          let gross_amount = GST-(discount/quantity);
          let TOTAL_GST = price-GST;
          let total_igst = TOTAL_GST;
          total_igst = (quantity*total_igst);
          document.getElementById("var-item-igst-total-"+id).value = this.floating_point(total_igst);
          document.getElementById("var-item-price-"+id).value = this.floating_point(gross_amount);
          price = (quantity*price)-discount;
          
          document.getElementById("var-item-total-txt-"+id).value =this.floating_point(price);
        } else{
          let price = document.getElementById("var-item-price-hidden-"+id).value;
          if(price===""){
            price = document.getElementById("var-item-price-"+id).value;
            document.getElementById("var-item-price-hidden-"+id).value = price;
          }
          price = price.replace(/,/g , "");
          
          document.getElementById("var-item-price-"+id).value = this.floating_point(price);
          
          price = parseFloat(price);

          let disc = 0;
          let total_discount=0;
          if(document.getElementById("var-item-pp-disc-"+id)){
            disc = document.getElementById("var-item-pp-disc-"+id).value;
            if(disc===""){
              disc = 0;
            }else{
              disc = parseFloat(disc);
            }
            total_discount = (quantity*price)*disc/100;
            //document.getElementById("discount-hidden-"+id).value = total_discount;
          }

          price = (quantity*price)-total_discount;
          let cgst = document.getElementById("var-item-cgst-"+id).value;
          let sgst = document.getElementById("var-item-sgst-"+id).value;
          let igst = document.getElementById("var-item-igst-"+id).value;

          let t_cgst = (price)*cgst/100;
          let t_sgst = (price)*sgst/100;
          let t_igst = (price)*igst/100;

          
          document.getElementById("var-item-cgst-total-"+id).value = t_cgst;
          document.getElementById("var-item-sgst-total-"+id).value = t_sgst;
          document.getElementById("var-item-igst-total-"+id).value = t_igst;

          //price = price+t_cgst+t_sgst+t_igst;

          
          document.getElementById("var-item-total-txt-"+id).value = this.floating_point(price);
        }
      }else{
          
          if(((cgst!=="" && cgst!=="0") && (sgst!=="" && sgst!=="0")) || (igst!=="" && igst!=="0")){
            
          let price = document.getElementById("var-item-price-hidden-"+id).value;
          if(price===""){
            price = document.getElementById("var-item-price-"+id).value;
            document.getElementById("var-item-price-hidden-"+id).value = price;
          }
          price = price.replace(/,/g , "");
          if(price==""){
            price = 0;
          }
          document.getElementById("var-item-price-"+id).value = this.floating_point(price);
          
          price = parseFloat(price);

          let disc = 0;
          let total_discount=0;
          if(document.getElementById("var-item-pp-disc-"+id)){
            disc = document.getElementById("var-item-pp-disc-"+id).value;
            if(disc===""){
              disc = 0;
            }else{
              disc = parseFloat(disc);
            }
            total_discount = (quantity*price)*disc/100;
            //document.getElementById("discount-hidden-"+id).value = total_discount;
          }

          price = (quantity*price)-total_discount;
          
          let cgst = document.getElementById("var-item-cgst-"+id).value;
          let sgst = document.getElementById("var-item-sgst-"+id).value;
          let igst = document.getElementById("var-item-igst-"+id).value;

          let t_cgst = (price)*cgst/100;
          let t_sgst = (price)*sgst/100;
          let t_igst = (price)*igst/100;

          
          document.getElementById("var-item-cgst-total-"+id).value = t_cgst;
          document.getElementById("var-item-sgst-total-"+id).value = t_sgst;
          document.getElementById("var-item-igst-total-"+id).value = t_igst;

          //price = price+t_cgst+t_sgst+t_igst;

          
          document.getElementById("var-item-total-txt-"+id).value = this.floating_point(price);
        }
      }
    }


    handleCreateVariation(e){

      let check_quantity = document.querySelectorAll('input[target=var-item-quantity]');
      let check_price = document.querySelectorAll('input[target=var-item-price]');

      for(let i=0;i<check_price.length;i++){
        let cquan = check_price[i].value;
        if(cquan==="" || cquan==="0"){
          check_price[i].focus();
          return false;
        }
      }

      for(let i=0;i<check_quantity.length;i++){
        let cquan = check_quantity[i].value;
        if(cquan==="" || cquan==="0"){
          check_quantity[i].focus();
          return false;
        }
      }

      document.getElementById("handleCreateVariation").click();
      let iitem_type = document.getElementById("iitem-type").value;
      let parent_item_name = this.state.selected_item_name;
      let product_key = 'pk_'+parent_item_name.replace(/\s+/g, "___");
      let parent_item_desc = this.state.selected_item_desc;
      let parent_item_hsn = this.state.selected_item_hsn;
      let selected_item = this.state.edit_item_selected;
      let selected_old_item = this.state.selected_old_item;
      let p_rand_key = parseInt(this.state.p_rand_key);
      let old_product_key = selected_old_item;
      let pitem_id = '';
      let selected_inventory_id = '';
      if(this.state.selected_inventory_id!=='none'){
        selected_inventory_id = this.state.selected_inventory_id;
      }
      let selected_barcode = auto_generate_barcode;
      let selected_alias_barcode = '';
      if(selected_item!=='none'){
        parent_item_name = this.state.selected_product.replace(/___+/g, " ");
        parent_item_name = parent_item_name.replace('pk_', '');
        product_key = 'pk_'+parent_item_name.replace(/\s+/g, "___");
        old_product_key = 'pk_'+selected_old_item.replace(/\s+/g, "___");
        parent_item_desc = this.state.selected_item_desc;
        parent_item_hsn = this.state.selected_item_hsn;
        pitem_id = this.state.item_id_selected;
        selected_inventory_id = this.state.selected_inventory_id;
        selected_barcode = this.state.selected_barcode;
        selected_alias_barcode = this.state.selected_alias_barcode;
      }else if(this.state.item_id_selected!=='none'){
        pitem_id = this.state.item_id_selected;
        selected_inventory_id = this.state.selected_inventory_id;
      }
      
      if(this.state.selected_barcode!=='none' && this.state.selected_barcode!==""){
        selected_barcode = this.state.selected_barcode;
      }

      let variations = document.querySelectorAll(".variation-item");
      let has_variation = false;
      if(variations.length>0){
        has_variation = true;
      }

      if(has_variation===false){
        this.setState({empty_var_error:"Sorry! You should have at least one variation."});
        setTimeout(function(){
          _this.setState({empty_var_error:""});
        },5000);
        return false;
      }
      let iitem_category= (document.getElementById("tags-id-category"))?document.getElementById("tags-id-category").value:"";
      let iitem_category_name=(document.getElementById("tags-value-category"))?document.getElementById("tags-value-category").value:"";
   
      let variations_data = [];
      let variant = {
              iitem_type : 'variable',
              is_variation : false,
              has_variation : has_variation,
              availability: 0,
              bundle: "",
              bundle_quantity: 0,
              bundle_text: "",
              cgst: 0,
              desc: parent_item_desc,
              discount: 0,
              hidden_cgst: 0,
              hidden_igst: 0,
              hidden_sgst: 0,
              hsn: parent_item_hsn,
              igst: 0,
              is_tax_inc: '-',
              item_id: selected_inventory_id,
              item_name: parent_item_name,
              list_item_id: pitem_id,
              parent_id: 0,
              price: 0,
              price_hidden: 0,
              quantity: 0,
              sgst: 0,
              tax: 0,
              total: 0,
              total_cgst: 0,
              total_discount: 0,
              total_igst: 0,
              total_sgst: 0,
              total_tax: 0,
              unit_id: 1,
              unit_name: "-",
              unitname: "-",
              barcode: selected_barcode,
              alias_barcode: selected_alias_barcode,
              barcode_name_on_bill_view:this.state.barcode_name_on_the_bill_view,
              item_name_on_bill_view:this.state.item_name_on_the_bill_view,
              iitem_category:iitem_category,
              iitem_category_name:iitem_category_name,
              product_key : product_key,
              p_rand_key : p_rand_key,
          };
     
        let birow = document.querySelectorAll(".billing-item-row");
        let next_row = 0;
        let temp_row = -1;
        
        if(selected_item!=='none'){
          this.more_rows[selected_item] = <BillingItemList count={1} type="more" key={selected_item} index={selected_item} billing_type={this.props.billing_type} items={variant} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)}/>;

          for(let i=this.more_rows.length-1;i>0;i--){
            
              let p_product = '';
              p_product = (this.more_rows[i]!==undefined && this.more_rows[i].props!==undefined && this.more_rows[i].props.items.product_key!==undefined)?this.more_rows[i].props.items.product_key:'';

              let is_variation = (this.more_rows[i]!==undefined && this.more_rows[i].props!==undefined && this.more_rows[i].props.items.is_variation!==undefined)?this.more_rows[i].props.items.is_variation:'';

              let i_rand_key = (this.more_rows[i]!==undefined && this.more_rows[i].props!==undefined && this.more_rows[i].props.items.p_rand_key!==undefined)?this.more_rows[i].props.items.p_rand_key:'';

              if(p_product===product_key && (is_variation===true || is_variation==='true') && i_rand_key===p_rand_key){
                this.more_rows.splice(i,1);
              }
          }
        }else{
          birow = document.querySelectorAll(".billing-item-row");
          for(let i=0;i<birow.length;i++){
              let tdid = birow[i].getAttribute('data-id');
              if(parseInt(tdid)>=temp_row){
                temp_row = parseInt(tdid);
              }
          }
          temp_row =  temp_row+1;
          if(localStorage.getItem('return_bill_count')!==null){
            temp_row = localStorage.getItem('return_bill_count');
            localStorage.removeItem('return_bill_count')
          }
          if(this.more_rows[temp_row]===undefined){
            this.more_rows[temp_row] = [];
          }

          this.more_rows[temp_row] = <BillingItemList count={temp_row} type="more" key={temp_row} index={temp_row} billing_type={this.props.billing_type} items={variant} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)}/>;
        }
        let item_key = this.state.selected_product+'_'+p_rand_key;
        if(this.variant_data[item_key]===undefined){
          this.variant_data[item_key] = [];
        }else{
          this.variant_data[item_key] = [];
        }
        if(this.state.selected_item_name!==this.state.selected_old_item){
            let pname = 'pk_'+this.state.selected_old_item.replace(/\s+/g, "___");
            let item_key = pname+'_'+p_rand_key;
            delete this.variant_data[item_key];
        }

      let nexidx = selected_item;
      for(let i=0;i<variations.length;i++){

        let custom_fields = document.querySelectorAll('.var-custom-field-'+i);

        let custom_fields_json = [];
        for (let index = 0; index < custom_fields.length; index++) {
          const cf_element = custom_fields[index];
          const data_id = cf_element.getAttribute('data-id');
          let thisval = cf_element.value;
          let cf_id = document.getElementById('var-cf-id-'+data_id).value;
          let cf_name = document.getElementById('var-cf-name-'+data_id).value;
          let cf_is_sale_active = document.getElementById('var-cf-is-sale-active-'+data_id).value;
          let cf_is_purchase_active = document.getElementById('var-cf-is-purchase-active-'+data_id).value;


          let cf_fields = {'id':cf_id,'is_purchase_active':cf_is_purchase_active,'is_sale_active':cf_is_sale_active,'name':cf_name,'value':thisval};
          custom_fields_json.push(cf_fields);
          this.setState({is_custom_field:true});
        }

          for(let j=0;j<birow.length;j++){
              let tdid = birow[j].getAttribute('data-id');
              if(parseInt(tdid)>=temp_row){
                temp_row = parseInt(tdid);
              }
          }
          temp_row =  temp_row+1;
          if(localStorage.getItem('return_bill_count')!==null){
            temp_row = localStorage.getItem('return_bill_count');
            localStorage.removeItem('return_bill_count')
          }
          if(this.more_rows[temp_row]===undefined){
            this.more_rows[temp_row] = [];
          }
          let did = variations[i].getAttribute('data-id');

          let option1 = document.getElementById("item-option1-"+did);
          let opt1_name = option1.getAttribute('data-id');
          let opt1_val = option1.value;

          let option2 = document.getElementById("item-option2-"+did);
          let opt2_name = option2.getAttribute('data-id');
          let opt2_val = option2.value;

          let option3 = document.getElementById("item-option3-"+did);
          let opt3_name = option3.getAttribute('data-id');
          let opt3_val = option3.value;

          let var_options = {
                          option1:opt1_name,opt1_val:opt1_val,value1:opt1_val,
                          option2:opt2_name,opt2_val:opt2_val,value2:opt2_val,
                          option3:opt3_name,opt3_val:opt3_val,value3:opt3_val,
                        };


          let variant_name = document.getElementById("item-lbl-"+did).innerHTML;
         
          let item_id = document.getElementById("var-item-id-"+did).value;
          let item_inv_id = document.getElementById("var-item-inv-id-"+did).value;
          let purchase_price = document.getElementById("var-item-price-"+did).value;
          let price_hidden = document.getElementById("var-item-price-hidden-"+did).value;
          let pp_discount = document.getElementById("var-item-pp-disc-"+did).value;
          let pp_discount_hidden = document.getElementById("var-pp-discount-hidden-"+did).value;
          
          let pp_tax = document.getElementById("var-item-pp-tax-"+did).value.replace('GST','');
          let pp_tax_type = document.getElementById("var-item-pp-tax-type-"+did).value;

          let pp_item_cgst = document.getElementById("var-item-cgst-"+did).value;
          let pp_item_cgst_hidden = document.getElementById("var-item-cgst-hidden-"+did).value;
          let pp_item_cgst_total = document.getElementById("var-item-cgst-total-"+did).value;


          let pp_item_sgst = document.getElementById("var-item-sgst-"+did).value;
          let pp_item_sgst_hidden = document.getElementById("var-item-sgst-hidden-"+did).value;
          let pp_item_sgst_total = document.getElementById("var-item-sgst-total-"+did).value;


          let pp_item_igst = document.getElementById("var-item-igst-"+did).value;
          let pp_item_igst_hidden = document.getElementById("var-item-igst-hidden-"+did).value;
          let pp_item_igst_total = document.getElementById("var-item-igst-total-"+did).value;

          let selling_price = document.getElementById("var-item-selling-price-"+did).value;
          let sp_discount = document.getElementById("var-item-sp-disc-"+did).value;
          let sp_tax = document.getElementById("var-item-sp-tax-"+did).value.replace('GST','');;
          let sp_tax_type = document.getElementById("var-item-sp-tax-type-"+did).value;
          let quantity = document.getElementById("var-item-quantity-"+did).value;
          let sku = document.getElementById("var-item-sku-"+did).value;
          let barcode = document.getElementById("var-item-barcode-"+did).value;
          let alias_barcode = document.getElementById("var-item-alias-barcode-"+did).value;
          let re_order_quantity = document.getElementById("var-re-order-quantity-"+did).value;

          let item_total_txt = document.getElementById("var-item-total-txt-"+did).value;
          let vd = {
              re_order_quantity:re_order_quantity,
              iitem_type : 'variable',
              is_variation : true,
              product_key :product_key,
              p_rand_key :p_rand_key,
              has_variation : true,
              availability: 0,
              bundle: "",
              bundle_quantity: 0,
              bundle_text: "",
              cgst: pp_item_cgst,
              desc: parent_item_desc,
              discount: pp_discount,
              hidden_cgst: pp_item_cgst_hidden,
              hidden_igst: pp_item_igst_hidden,
              hidden_sgst: pp_item_sgst_hidden,
              hsn: parent_item_hsn,
              igst: pp_item_igst,
              is_tax_inc: pp_tax_type,
              pp_tax_type: pp_tax_type,
              item_id: item_inv_id,
              item_name: variant_name,
              list_item_id: item_id,
              parent_id: 0,
              price: purchase_price,
              price2 : selling_price,
              price_hidden: price_hidden,
              product_name: parent_item_name,
              quantity: quantity,
              sgst: pp_item_sgst,
              tax: pp_tax,
              total: item_total_txt,
              total_cgst: pp_item_cgst_total,
              total_discount: pp_discount_hidden,
              total_igst: pp_item_igst_total,
              total_sgst: pp_item_sgst_total,
              total_tax: 0,
              unit_id: 1,
              unit_name: "Pcs",
              unitname: "pcs",
              sku:sku,
              barcode:barcode,
              alias_barcode:alias_barcode,
              sp_discount : sp_discount,
              sp_total_discount : sp_discount,
              sp_tax : sp_tax,
              sp_tax_type : sp_tax_type,
              var_options:var_options,
              option1:opt1_name,
              option2:opt2_name,
              option3:opt3_name,
              value1:opt1_val,
              value2:opt2_val,
              value3:opt3_val,
              custom_fields_json: custom_fields_json,
              barcode_name_on_bill_view:this.state.barcode_name_on_the_bill_view,
              item_name_on_bill_view:this.state.item_name_on_the_bill_view,
              iitem_category:iitem_category,
              iitem_category_name:iitem_category_name,
          };

         /* for(let key in this.props.list_items ) {
            if(this.props.list_items['parent_id']===selected_item){
              if(key==='item_id' || key==='list_item_id'){
                vd[key] = this.props.list_items[key];
              }
            }
          }*/

         this.variant_data[item_key].push(vd);
         if(selected_item!=='none'){
            nexidx++;
            this.more_rows.splice(nexidx,0,<BillingItemList count={nexidx} type="more" key={nexidx} index={nexidx} billing_type={this.props.billing_type} items={vd} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)}/>);
         }else{
          this.more_rows[temp_row] = <BillingItemList count={temp_row} type="more" key={temp_row} index={temp_row} billing_type={this.props.billing_type} items={vd} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)}/>;
         }
        }
        for(let k=this.more_rows.length-1;k>0;k--){
          if(this.more_rows[k]!==undefined && this.more_rows[k].key===undefined){
            this.more_rows.splice(k,1);
          }
          let p_product = (this.more_rows[k]!==undefined && this.more_rows[k].props!==undefined && this.more_rows[k].props.items.product_key!==undefined)?this.more_rows[k].props.items.product_key:'';

          let product_name = (this.more_rows[k]!==undefined && this.more_rows[k].props!==undefined && this.more_rows[k].props.items.product_name!==undefined)?this.more_rows[k].props.items.product_name:'';

          let iname = (this.more_rows[k]!==undefined && this.more_rows[k].props!==undefined && this.more_rows[k].props.items.item_name!==undefined)?this.more_rows[k].props.items.item_name:'';
          if((this.state.selected_item_name!==this.state.selected_old_item) && product_name===selected_old_item){
            this.more_rows.splice(k,1);
          }
        }
        let mitem_list = [];
        let sno = 0;
        let sno_temp = 0;
        for(let k=0;k<this.more_rows.length;k++){
          if(this.more_rows[k]!==undefined){
            let mr_item = this.more_rows[k].props.items;

            if(mr_item.is_variation === undefined || mr_item.is_variation===false || mr_item.is_variation===""){
              sno++;
              sno_temp = sno;
            }else{
              sno_temp = '';
            }
            let blidata = <BillingItemList serial={sno_temp} count={k} type="more" key={k} index={k} billing_type={this.props.billing_type} items={mr_item} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)}/>;
            mitem_list.push(blidata);
          }
        }
        this.more_rows = mitem_list;

        this.setState({more_rows:this.more_rows});
        this.setState({edit_item_selected:'none'});
        this.setState({product_variation_pop_title:'',selected_product:'',selected_item_desc:'',selected_item_hsn:'',selected_item_name:'',selected_old_item:'',defaultCategory:'',defaultCategoryID:0});
        this.item_to_remove = [];
        setTimeout(function(){
          _this.calculateTotal();
          //_this.calculateReturnTotal();
          let v_list = [];
          _this.updateVariationListState(v_list);
          _this.variantOptions = [];
          _this.variantOptions.push(<VariantOptions variationList={_this.state.variantList} removeVariationList={_this.removeVariationList.bind(_this)} label="Option 1" option="Size" index={1} updateState={_this.updateVariationListState.bind(_this)} updateOptionState={_this.updateVariationOptionState.bind(_this)} tags={[]}/>);
          _this.setState({variantOptions:_this.variantOptions,edit_product_variant:0});

          _this.ADJUST_BILL_SNO('regular');
        },100);
        document.getElementById("closeVariantModal").click();
        let inputs = document.querySelectorAll('#list-item-add-blk input[type=text]');
        for(let i=0;i<inputs.length;i++){
          inputs[i].value = '';
        }
        this.handleSmoothAnimation();
    }



    closeModel(event) {
      let id = event.currentTarget.id;
      var element = document.getElementById('product-variation-add');
      element.classList.add("fade");
      element.style.display="none"; 
      let v_list = [];
      this.updateVariationListState(v_list);
      this.variantOptions = [];
      this.variantOptions.push(<VariantOptions  variationList={this.state.variantList} removeVariationList={this.removeVariationList.bind(this)} label="Option 1" option="Size" index={1} updateState={this.updateVariationListState.bind(this)} updateOptionState={this.updateVariationOptionState.bind(this)} tags={[]}/>);
      this.setState({variantOptions:this.variantOptions,edit_product_variant:0});
      this.setState({edit_item_selected:'none'});

      this.setState({product_variation_pop_title:'',selected_product:'',selected_item_desc:'',selected_item_hsn:'',selected_item_name:'',selected_old_item:'',is_open_popup:false});

      let rm_var_li_ids = this.rm_var_li_ids;
      let del_row = document.getElementById("delete-row").value;
      if(del_row!==""){
          del_row = JSON.parse(del_row);
          for(let i=0;i<del_row.length;i++){
            let id = del_row[i].id;
            if(rm_var_li_ids.indexOf(id)>=0){
                del_row.splice(i,1);
            }
          }
      }
    }
    updateVariationListState(varList){
      this.variantList = varList;
      this.setState({variantList:this.variantList});
    }

    removeVariationList(tag_name,rm_type){
      let variationList = this.state.variantList;
      for(let i=variationList.length-1;i>=0;i--){
        if(variationList[i]!==undefined){
          let label = variationList[i].props.label;
          let tags = label.split("/");
          for(let j=0;j<tags.length;j++){
            let tname = tags[j];
            if(tname===tag_name){
              if(variationList[i].props.item_data.list_item_id!==undefined){
                let liid = variationList[i].props.item_data.list_item_id;
                let liquan = variationList[i].props.item_data.quantity;
                if(liid!==""){
                  let rm_var_li = {id:liid,quantity:liquan};
                  this.rm_var_li_ids.push(liid);
                  remove_items.push(rm_var_li);
                  document.getElementById("delete-row").value = JSON.stringify(remove_items);
                }
              }
              if(rm_type==='remove'){
                variationList.splice(i,1);
              }
            }
          }
        }
      }
      if(rm_type==='remove'){
        this.variantList = [];
        this.setState({variantList:[]});
        this.variantList = variationList;
        let _this = this;
        setTimeout(function(){
          _this.setState({variantList:_this.variantList});
        },5);
      }
    }

    updateVariationOptionState(rm){
      this.variantOptions.splice(rm,1);
      this.setState({v_opt_counter:this.state.v_opt_counter-1,variantOptions:this.variantOptions});
    }
    handleMoreVariantOpt(e){
      let counter = this.state.v_opt_counter+1;
      let lbl = "Option "+counter;

      let opt_name = '';
      if(counter===2){
        opt_name = 'Color';
      }

      this.variantOptions.push(<VariantOptions  variationList={this.state.variantList} removeVariationList={this.removeVariationList.bind(this)} label={lbl} option={opt_name} index={counter} updateState={this.updateVariationListState.bind(this)} updateOptionState={this.updateVariationOptionState.bind(this)}/>);
      this.setState({v_opt_counter:counter,variantOptions:this.variantOptions});
    }

    

    handleRemoveItem(id){
      let item_lbl = document.getElementById("item-lbl-"+id).innerHTML;
      
      let item_to_remove = {item_lbl:item_lbl,selected_product:this.state.selected_product};
      this.item_to_remove.push(item_to_remove);
      for(let i=this.variantList.length-1;i>=0;i--){
        if(this.variantList[i].props.label===item_lbl){
            this.variantList.splice(i,1);
        }
      }
      this.updateVariationListState(this.variantList);

      let item_id = document.getElementById("var-item-id-"+id).value;
      let quantity = document.getElementById("var-item-quantity-"+id).value;
      let item = {id:item_id,quantity:quantity};
      remove_items.push(item);
      document.getElementById("delete-row").value = JSON.stringify(remove_items);

    }

    handleChangeItemType(e){
      let list_item_type = e.currentTarget.value;
      if(list_item_type==='sales_return' || list_item_type==='purchase_return'){
        let birow = document.querySelectorAll(".billing-item-row");
        if(birow.length===0){
          let ret_type = 'purchase';
          if(list_item_type==='sales_return'){
            this.setState({list_item_type:'product'});
            document.getElementById("iitem-type").value = 'product';
             ret_type = 'sale';
          }else{
            this.setState({list_item_type:'single'});
            document.getElementById("iitem-type").value = 'single';
          }
          toast("Sorry! You can not return without the "+ret_type+" items.");
          this.handleToggleFieldVisibility();
          return false;
        }
      }

      if(list_item_type==='variable'){
        this.setState({is_open_popup:true});
      }else{
        this.setState({is_open_popup:false});
      }
      this.setState({list_item_type:list_item_type});
      document.getElementById("iitem-box").focus();
      let inputs = document.querySelectorAll('#list-item-add-blk input[type=text]');
      for(let i=0;i<inputs.length;i++){
        let item_id = inputs[i].id;
        let is_list_item_edit = localStorage.getItem('is_list_item_edit');
        
        if(item_id==='iitem-list-id' && is_list_item_edit==='true'){
        }else{
          inputs[i].value = '';
        }
      }

      this.handleToggleFieldVisibility();
    }

    handleChangeListTypeOnFly(e){
      this.setState({list_item_type:'single'});
    }

    handleUnsetState(e){
      this.setState({is_checked_lsp:false});
    }
    handleResetField(e){
      let selected_item = this.state.edit_item_selected;
      document.getElementById("new-item-form").reset();
      let list_item_type = this.state.list_item_type;
      
      if(this.props.billing_type==="New Purchase"){
        //this.setState({list_item_type:'single'});
        if(list_item_type===''){
          list_item_type = 'single';
        }
      }else{
        //this.setState({list_item_type:'product'});
        if(list_item_type===''){
          list_item_type = 'product';
        }
      }
      if(document.getElementById("iitem-type")){
        document.getElementById("iitem-type").value = list_item_type;
      }
      //if(document.getElementById("item-row-"+selected_item)){
        let sli = document.querySelectorAll(".billing-item-row");
        for(let i=0;i<sli.length;i++){
           sli[i].classList.remove('item-edit-mode');
           localStorage.removeItem('is_list_item_edit');
        }
        let slir = document.querySelectorAll(".return-billing-item-row");
        for(let i=0;i<slir.length;i++){
           slir[i].classList.remove('item-edit-mode');
           localStorage.removeItem('is_list_item_edit');
        }
        this.setState({edit_item_selected:'none'});
      //}
      let item_edit_mode = document.querySelectorAll(".item-mod-options");
      for(let i=0;i<item_edit_mode.length;i++){
        item_edit_mode[i].style.display = 'flex';
      }
      let ret_item_edit_mode = document.querySelectorAll(".ret-item-mod-options");
      for(let i=0;i<ret_item_edit_mode.length;i++){
        ret_item_edit_mode[i].style.display = 'flex';
      }
      let inputs = document.querySelectorAll('#list-item-add-blk input[type=text]');
      for(let i=0;i<inputs.length;i++){
        inputs[i].value = '';
      }
      this.setState({auto_opt_value:''});
     /*  if(document.getElementById("iitem-category")){
        localStorage.setItem('selected_product',1);
        if(document.getElementById("setProductState")){
          document.getElementById("setProductState").click();
        }
      } */
      if(document.querySelector(".bill-ret-type")){
        let cls_ret_type = document.querySelector(".bill-ret-type").getAttribute("data-id");

        if(cls_ret_type==='invoice'){
          let cls = this.props.billing_type.replaceAll(" ",'-');
          if(document.querySelector(".sticky-add-item-panel."+cls)){
            document.querySelector(".sticky-add-item-panel."+cls).style.display = "none";
          }
          if(document.querySelector(".list-item-add-blk."+cls)){
            document.querySelector(".list-item-add-blk."+cls).style.display = "none";
          }
          if(document.querySelector(".add-item-mob."+cls)){
            document.querySelector(".add-item-mob."+cls).style.display = "none";
          }
        }

        //let cls_ret_opt = document.querySelector(".bill-ret-option").getAttribute("data-id");
        
      }
      if(document.getElementById("handleEmptyTagList")){
        document.getElementById("handleEmptyTagList").click();
      }

      if(this.props.billing_type==='New Sales Return' || this.props.billing_type==='New Purchase Return'){
          document.getElementById("iitem-box").removeAttribute('disabled');
          document.getElementById("iitem-box").setAttribute('tabindex',11);
      }
    }

    handleChangeGST(e){
      let sp_gst = document.getElementById("iitem-sp-gst").value.replace('GST','');
      sp_gst = sp_gst.replace('%','');
      document.getElementById("iitem-sp-gst").value = sp_gst.trim();

    }
    handleChangeText(e){
       let thisval = e.currentTarget.value;
       let data_target = e.currentTarget.getAttribute('target');
       if(thisval===""){
          thisval = 0;
       }else{
          thisval = parseFloat(thisval);
       }
       if(this.props.billing_type==="New Purchase"){
        if(document.getElementById("price-hidden") && data_target==='price'){
          document.getElementById("price-hidden").value = thisval;
        }

        if(data_target==='price'){
          let selling_price_margin = document.getElementById("selling_price_margin").value;
          if(selling_price_margin!==""){
            selling_price_margin = parseFloat(selling_price_margin);

            let calculate_sp = thisval/(1-(selling_price_margin/100))
            let new_sp = calculate_sp;
            document.getElementById("iitem-selling-price").value = Math.round(new_sp);
          }
        }
       }

       if(data_target==='price'){
          this.setState({is_checked_lsp:false});
          this.handleCalculation(e);
       }

    }

    updateItemQuantity(item_id){
      let option_available_storage = JSON.parse(localStorage.getItem("item_options"));
      let purchase_option_available_storage = JSON.parse(localStorage.getItem("purchase_item_options"));

      let item_options = this.state.item_options;
      let purchase_item_options = this.state.purchase_item_options;
 
      if(item_options[item_id]!==undefined){
        item_options[item_id] = option_available_storage[item_id];
        this.setState({ 'item_options': item_options});
      }
      if(purchase_item_options[item_id]!==undefined){
        purchase_item_options[item_id] = purchase_option_available_storage[item_id];
        this.setState({ 'purchase_item_options': purchase_item_options});
      }
    }

    handleUpdateInventoryQuantity(type,no_of_item,item_id){
        let option_available = JSON.parse(localStorage.getItem("item_options"));
        let purchase_option_available = JSON.parse(localStorage.getItem("purchase_item_options"));
        if(purchase_option_available!==null && purchase_option_available[item_id]!==undefined && purchase_option_available[item_id]!==null){
          let avl = purchase_option_available[item_id].availability;
          let av = purchase_option_available[item_id].old_available;

          let get_diff_barcode_inv_availability = utils.get_diff_barcode_inv_availability(this.props.billing_type, purchase_option_available[item_id]);
          if(get_diff_barcode_inv_availability>=0){
            avl = get_diff_barcode_inv_availability;
          }

          let availability = 0;
          if(type==='add'){
            availability = avl+no_of_item;
          }else{
            availability = avl-no_of_item;
          }
          if(option_available!==null && option_available[item_id]!==undefined && option_available[item_id]!==null && option_available[item_id].availability!==undefined && option_available[item_id].availability!==null){
            option_available[item_id].availability = availability;
          }
          purchase_option_available[item_id].availability = availability;
          let static_item_name = purchase_option_available[item_id].static_item_name;
          let inventory_status = purchase_option_available[item_id].inventory_status;
          let stat_lbl = '';
          if(inventory_status==='Draft'){
            stat_lbl=' (Inactive)';
          }
          let new_item_name = static_item_name+" - Qty:"+availability+stat_lbl;
          purchase_option_available[item_id].value2 = new_item_name;
          if(option_available[item_id]!==undefined && option_available[item_id]!==null){
            option_available[item_id].value2 = new_item_name;
            localStorage.setItem("item_options",JSON.stringify(option_available));
            //this.setState({ 'item_options': option_available});
          }
          localStorage.setItem("purchase_item_options",JSON.stringify(purchase_option_available));
          //this.setState({ 'purchase_item_options': purchase_option_available}); 

          this.updateItemQuantity(item_id);
        }
    }

    addMoreReturnItem(event){
      let list_item_type = (document.getElementById("iitem-type"))?document.getElementById("iitem-type").value:'';
      let item_name = (document.getElementById("iitem-box"))?document.getElementById("iitem-box").value:'';
      if(item_name===""){
          document.getElementById("iitem-box").focus();
          return false;
      }

      let desc= (document.getElementById("iitem-desc"))?document.getElementById("iitem-desc").value:'';
      let hsn= (document.getElementById("iitem-hsn"))?document.getElementById("iitem-hsn").value:'';
    
      
      if((this.state.is_checked_lsp===false && list_item_type==='sales_return')){
        let is_lowest = this.checkLowestSellingPrice(event);
        if(is_lowest===false){
            localStorage.setItem("save_row",'yes')
            //document.getElementById("target_index").value = 'save-row';
            return false;
        }
      }
      this.setState({is_checked_lsp:false});
      let type = event.currentTarget.id;
      let item_len = this.state.item_add_count;//document.getElementsByClassName("item-name").length;
      let key = item_len+1;
      this.setState({item_add_count:key});
      
      let list_item_id = (document.getElementById("iitem-list-id"))?document.getElementById("iitem-list-id").value:'';
      let item_id= (document.getElementById("iitem-name"))?document.getElementById("iitem-name").value:'';
      item_id = parseInt(item_id);
      let bundle= (document.getElementById("iitem-bundle-quantity"))?document.getElementById("iitem-bundle-quantity").value:'';
      let bundle_text= (document.getElementById("iitem-bundle-text"))?document.getElementById("iitem-bundle-text").value:'';
      let quantity= (document.getElementById("iitem-quantity"))?document.getElementById("iitem-quantity").value:0;
      let availability= (document.getElementById("iitem-availability"))?document.getElementById("iitem-availability").innerHTML:'';
      let bundle_quantity= (document.getElementById("iitem-bundle-quantity"))?document.getElementById("iitem-bundle-quantity").value:'';
      let bundle_desc= (document.getElementById("bundle_quantity"))?document.getElementById("bundle_quantity").innerHTML:'';
     
      let unit_id = (document.getElementById("iitem-unit"))?document.getElementById("iitem-unit").value:1;

      let unit_name = 'Pcs';
      if(document.getElementById("iitem-unit")){
        let iiunit = document.getElementById("iitem-unit");
        if(iiunit.selectedIndex>=0){
          if(iiunit.options[iiunit.selectedIndex]!==undefined && iiunit.options[iiunit.selectedIndex]!==null){
            unit_name = iiunit.options[iiunit.selectedIndex].text;
          }else{
            unit_id = 1;
            unit_name = 'Pcs';
          }
        }else{
          unit_id = 1;
          unit_name = 'Pcs';
        }
      }

      let price= (document.getElementById("price"))?document.getElementById("price").value:'';
      let lowest_selling_price= (document.getElementById("iitem-lowest-selling-price"))?document.getElementById("iitem-lowest-selling-price").value:'';
      let total_avl= availability;
      let discount= (document.getElementById("discount"))?document.getElementById("discount").value:0;
      let total_discount= (document.getElementById("discount-hidden"))?document.getElementById("discount-hidden").value:0;
      let tax= (document.getElementById("iitem-gst"))?document.getElementById("iitem-gst").value:0;
      let cgst= (document.getElementById("iitem-cgst"))?document.getElementById("iitem-cgst").value:0;
      let hidden_cgst= (document.getElementById("iitem-cgst-hidden"))?document.getElementById("iitem-cgst-hidden").value:0;
      let total_cgst= (document.getElementById("iitem-cgst-total"))?document.getElementById("iitem-cgst-total").value:0;
      let sgst= (document.getElementById("iitem-sgst"))?document.getElementById("iitem-sgst").value:0;
      let hidden_sgst= (document.getElementById("iitem-sgst-hidden"))?document.getElementById("iitem-sgst-hidden").value:0;
      let total_sgst= (document.getElementById("iitem-sgst-total"))?document.getElementById("iitem-sgst-total").value:0;
      let igst= (document.getElementById("iitem-igst"))?document.getElementById("iitem-igst").value:0;
      let hidden_igst= (document.getElementById("iitem-igst-hidden"))?document.getElementById("iitem-igst-hidden").value:0;
      let total_igst= (document.getElementById("iitem-igst-total"))?document.getElementById("iitem-igst-total").value:0;
      let pp_tax_type= (document.getElementById("iitem-tax-inc"))?document.getElementById("iitem-tax-inc").value:0;
      let price_hidden= (document.getElementById("price-hidden"))?document.getElementById("price-hidden").value:0;
      let price2= (document.getElementById("iitem-selling-price"))?document.getElementById("iitem-selling-price").value:'';

      let sp_discount= (document.getElementById("sp-discount"))?document.getElementById("sp-discount").value:0;
      let sp_total_discount= (document.getElementById("sp-discount-hidden"))?document.getElementById("sp-discount-hidden").value:0;
      let sp_tax= (document.getElementById("iitem-sp-gst"))?document.getElementById("iitem-sp-gst").value:0;
    
      let sp_tax_type= (document.getElementById("sp-tax-type"))?document.getElementById("sp-tax-type").value:0;
      let iitem_barcode= (document.getElementById("iitem-barcode"))?document.getElementById("iitem-barcode").value:this.state.selected_barcode;
      let iitem_category= (document.getElementById("tags-id-category"))?document.getElementById("tags-id-category").value:"";
      
      let iitem_category_name="";
      if(iitem_category!==""){
        iitem_category_name = document.getElementById("tags-value-category").value;
      }
      let re_order_quantity= (document.getElementById("iitem-re-order-qty"))?document.getElementById("iitem-re-order-qty").value:'';
      let total= (document.getElementById("iitem-total-txt"))?document.getElementById("iitem-total-txt").value:0;
     

      let items = {
          iitem_type : list_item_type,
          list_item_id : list_item_id,
          item_name : item_name,
          item_id : item_id,
          desc : desc,
          hsn : hsn,
          bundle : bundle,
          bundle_text : bundle_text,
          quantity : quantity,
          availability : availability,
          bundle_quantity : bundle_quantity,
          bundle_desc : bundle_desc,
          unit_name : unit_name,
          unit_id : unit_id,
          price : price,
          lowest_selling_price : lowest_selling_price,
          total_avl : total_avl,
          price2 : price2,
          discount : discount,
          total_discount : total_discount,
          tax : tax,
          cgst : cgst,
          hidden_cgst : hidden_cgst,
          total_cgst : total_cgst,
          sgst : sgst,
          hidden_sgst : hidden_sgst,
          total_sgst :  total_sgst,
          igst : igst,
          hidden_igst : hidden_igst,
          total_igst : total_igst,
          pp_tax_type : pp_tax_type,
          is_tax_inc : pp_tax_type,
          price_hidden : price_hidden,

          sp_discount : sp_discount,
          sp_total_discount : sp_total_discount,
          sp_tax : sp_tax,
          sp_tax_type : sp_tax_type,
          barcode : iitem_barcode,
          barcode_name_on_bill_view:this.state.barcode_name_on_the_bill_view,
          item_name_on_bill_view:this.state.item_name_on_the_bill_view,
          iitem_category:iitem_category,
          re_order_quantity:re_order_quantity,
          iitem_category_name:iitem_category_name,
          total : total
      }

      let itotal = parseFloat(total);
      if(itotal<=0){
        if(document.getElementById("price")){
          document.getElementById("price").focus();
        }
        return false;
      }
      quantity = parseInt(quantity);
      let is_valid = true;
      if(item_id>0){
        let option_available = JSON.parse(localStorage.getItem("item_options"));
        let purchase_option_available = JSON.parse(localStorage.getItem("purchase_item_options"));
        if(purchase_option_available!==null && purchase_option_available[item_id]!==undefined && purchase_option_available[item_id]!==null){
          
          let avl = purchase_option_available[item_id].availability;

          let get_diff_barcode_inv_availability = utils.get_diff_barcode_inv_availability(list_item_type, purchase_option_available[item_id]);
          if(get_diff_barcode_inv_availability>=0){
            avl = get_diff_barcode_inv_availability;
          }

          let av = purchase_option_available[item_id].old_available;
          let inventory_status = purchase_option_available[item_id].inventory_status;
          if(list_item_type==='purchase_return'){
            availability = avl-quantity;
          }else if(list_item_type==='sales_return'){
            availability = avl+quantity;
          }
         
          if(inventory_status==='Active'){
            if(option_available!==null && option_available[item_id]!==undefined && option_available[item_id]!==null && option_available[item_id].availability!==undefined && option_available[item_id].availability!==null){
              option_available[item_id].availability = availability;
            }
          }
          purchase_option_available[item_id].availability = availability;
          let static_item_name = purchase_option_available[item_id].static_item_name;
          let stat_lbl = '';
          if(inventory_status==='Draft'){
            stat_lbl=' (Inactive)';
          }

          
          av = parseInt(avl);
         
          if(quantity>av  && this.state.allow_negative_inventory===false){
            
          }else{

            let new_item_name = static_item_name+" - Qty:"+availability+stat_lbl;
            purchase_option_available[item_id].value2 = new_item_name;
            //if(inventory_status==='Active'){
              if(option_available[item_id]!==undefined && option_available[item_id]!==null && option_available[item_id].value2!==undefined && option_available[item_id].value2!==null){
                option_available[item_id].value2 = new_item_name;
                localStorage.setItem("item_options",JSON.stringify(option_available));
                //this.setState({ 'item_options': option_available});
              }
            //}
            localStorage.setItem("purchase_item_options",JSON.stringify(purchase_option_available));
            //this.setState({ 'purchase_item_options': purchase_option_available}); 
            this.updateItemQuantity(item_id);
          }
        }
      }
      
      if(is_valid && quantity>0){
        let selected_item = this.state.edit_item_selected;
        let edit_item_active_index = this.state.edit_item_active_index;
        let active_index = this.state.edit_item_active_index;
        if(selected_item!=='none'){
          this.return_more_rows[selected_item] = <ReturnBillingItemList count={item_len} type="more" key={edit_item_active_index} index={edit_item_active_index} billing_type={this.props.billing_type} items={items} listItemEdit={this.handleReturnListItemEdit.bind(this)} listItemRemove={this.handleReturnListItemRemove.bind(this)}/>;
          //this.setState({more_rows:this.more_rows});
          let sli = document.querySelectorAll(".return-billing-item-row");
          for(let i=0;i<sli.length;i++){
             sli[i].classList.remove('item-edit-mode');
             localStorage.removeItem('is_list_item_edit');
          }
          this.setState({edit_item_selected:'none'});
        }else{

          let birow = document.querySelectorAll(".return-billing-item-row");
          let next_row = 0;
          let temp_row = -1;

          for(let i=0;i<birow.length;i++){
              let tdid = birow[i].getAttribute('data-id');
              if(parseInt(tdid)>=temp_row){
                temp_row = parseInt(tdid);
              }
          }
          temp_row =  temp_row+1;
          if(localStorage.getItem('return_bill_count')!==null){
            temp_row = localStorage.getItem('return_bill_count');
            localStorage.removeItem('return_bill_count')
          }
          if(this.return_more_rows[temp_row]===undefined){
            this.return_more_rows[temp_row] = [];
          }

          //let row_len = this.more_rows.length;
          this.return_more_rows[temp_row] = <ReturnBillingItemList count={temp_row} type="more" key={temp_row} index={temp_row} billing_type={this.props.billing_type} items={items} listItemEdit={this.handleReturnListItemEdit.bind(this)} listItemRemove={this.handleReturnListItemRemove.bind(this)}/>;

          //console.log(this.more_rows);
        }

        this.setState({return_more_rows:this.return_more_rows});
        let rm = document.querySelectorAll(".ret-item-remove");
        for(let i=0;i<rm.length;i++){
          rm[i].style.display = 'block';
        }
        document.getElementById("new-item-form").reset();
        if(this.props.billing_type==='New Sale'){
          this.setState({list_item_type:'product'});
        }
        if(this.props.billing_type==='New Purchase'){
          this.setState({list_item_type:'single'});
        }

        document.getElementById("iitem-box").value = '';
        document.getElementById("iitem-desc-txt").innerHTML = '';
        if(document.getElementById("iitem-barcode")){
          let bcode_name = document.getElementById("iitem-barcode").value;
          if(bcode_name!=="Auto Generate"){
            document.getElementById("iitem-barcode").value = '';
          }
        }


        this.setState({is_new_item:0});
        setTimeout(function(){
          if(document.getElementById("handleEnableReturnInfo")){
            document.getElementById("handleEnableReturnInfo").click();
          }
          document.getElementById("iitem-box").focus();
          document.getElementById("iitem-unit").selectedIndex = "0";
          _this.calculateReturnTotal();
        },100);
      }else{
        document.getElementById("iitem-quantity").focus();
      }
      let item_edit_mode = document.querySelectorAll(".ret-item-mod-options");
      for(let i=0;i<item_edit_mode.length;i++){
        item_edit_mode[i].style.display = 'flex';
      }

      setTimeout(function(){
        document.getElementById("iitem-box").focus();
        document.getElementById("iitem-unit").selectedIndex = "0";
        _this.ADJUST_BILL_SNO('return');
      },100);
    }
    hanldeSetItemDescription = () =>{
      let item_desc = localStorage.getItem("item_desc");
      this.setState({auto_opt_value:item_desc});
      localStorage.removeItem("item_desc");
    }
    addMoreItem(event){
      
      let list_item_type = (document.getElementById("iitem-type"))?document.getElementById("iitem-type").value:'';
      if(list_item_type==='sales_return' || list_item_type==='purchase_return'){
        this.addMoreReturnItem(event);
        return false;
      }
      let item_name = (document.getElementById("iitem-box"))?document.getElementById("iitem-box").value:'';

      if(item_name===""){
          document.getElementById("iitem-box").focus();
          return false;
      }

      let desc= (document.getElementById("iitem-desc"))?document.getElementById("iitem-desc").value:'';
      if(desc!==""){
        desc = desc.trim();
      }
      

      let hsn= (document.getElementById("iitem-hsn"))?document.getElementById("iitem-hsn").value:'';
      if(hsn!==""){
        let is_hsn  = this.handleCheckHSN();
        if(is_hsn===false){
          return false;
        }
      }
      if(list_item_type==='variable'){
        let pid = document.getElementById("iitem-name").value;
        let p_rand_key = pid;
        pid = parseInt(pid);
        let pitem = this.state.variable_purchase_item_options[pid];
        
        if(pid==="" || pid==="0" || pitem===undefined){
          p_rand_key = Math.floor(100000 + Math.random() * 900000)
        }
        
        let product_variation_pop_title = "VARIATION FOR PRODUCT "+item_name;
        let pname = 'pk_'+item_name.replace(/\s+/g, "___");
        this.setState({product_variation_pop_title:'',selected_product:'',selected_item_desc:'',selected_item_hsn:'',selected_item_name:'',selected_old_item:'',p_rand_key:''});
        document.getElementById("selected_item_name").value = item_name;
        document.getElementById("selected_item_desc").value = desc;
        document.getElementById("selected_item_hsn").value = hsn;
        document.getElementById("selected_old_item").value = item_name;
        let selbc= (document.getElementById("iitem-barcode"))?document.getElementById("iitem-barcode").value:'Auto Generate';

        let _this = this;
        setTimeout(function(){
          _this.setState({product_variation_pop_title:product_variation_pop_title,selected_product:pname,selected_item_desc:desc,selected_item_hsn:hsn,selected_item_name:item_name,selected_old_item:item_name,selected_barcode:selbc,p_rand_key:p_rand_key});
        },100)


        let item_key = pname+'_'+p_rand_key;
        
        if(pid!=="" && pid!=="0"){
          this.setState({selected_inventory_id:pid});
          if(pitem!==undefined && pitem.has_variation!==undefined && pitem.has_variation===true){
            
            this.setState({item_id_selected:0});
            let body = {};
            body = {
                  store_name : user_info.store_name,
                  branch_id : user_info.login_id,
                  user_id : user_info.user_id,
                  id : pid,
                  uuid : utils.uuid(),
                  role_id : user_info.access_level,
                  token : user_info.token,
                  _schema : user_info._schema,
               }
            let api = app_api+'/get_product_variations';
            axios.post(api, body)
              .then(function (response) {
                let cursor = response.data;
                this.variant_data[item_key] = [];
                this.variant_data[item_key] = cursor[item_key];
                var element = document.getElementById('product-variation-add');
                element.classList.remove("fade");
                element.style.display="block";
                document.getElementById("tag-box-1").focus();
                this.handleVariationEdit(0,item_key);
                let ps = this.state.purchase_field_hide;
                let _th = this;
                setTimeout(() => {
                  _th.handleHandleItemFields(ps);
                }, 100);
                return false;
            }.bind(this)).catch(function(res){
                if(res.response){
                  if(res.response.data){
                      
                  }
                }
            })
          }
        }

        var element = document.getElementById('product-variation-add');
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("tag-box-1").focus();
        let ps = this.state.purchase_field_hide;
        let _th = this;
        setTimeout(() => {
          _th.handleHandleItemFields(ps);
        }, 100);
        return false;
      }
      let tax= (document.getElementById("iitem-gst"))?document.getElementById("iitem-gst").value:0;
      let sp_tax= (document.getElementById("iitem-sp-gst"))?document.getElementById("iitem-sp-gst").value:0;
      if(this.state.send_e_invoice===true && (user_info.gst_type==='composite' || user_info.gst_type==='regular')){
        if(this.props.billing_type==='New Purchase' && this.props.is_order_screen!==true){
          if(tax==="" || tax==="0" || sp_tax==="" || sp_tax==="0"){
            toast("Please add GST for the item.");
            if(tax==="" || tax==="0"){
              if(document.getElementById("iitem-gst")){
                document.getElementById("iitem-gst").focus();
              }
            }else if(sp_tax==="" || sp_tax==="0"){
              if(document.getElementById("iitem-sp-gst")){
                document.getElementById("iitem-sp-gst").focus();
              }
            }
            return false;
          }
        }else if(this.props.billing_type==='New Sale' && this.props.is_order_screen!==true){
          if(tax==="" || tax==="0"){
            toast("Please add GST for the item.");
            if(tax==="" || tax==="0"){
              if(document.getElementById("iitem-gst")){
                document.getElementById("iitem-gst").focus();
              }
            }
            return false;
          }
        }
      }
      let is_adj_disc = this.handleCheckMSPDiscount(event);
      if(is_adj_disc===false){
        return false;
      }

      if((this.props.billing_type==='New Sale' || this.props.billing_type==='New Purchase' || this.props.billing_type==='New Sales Return') && (this.state.is_checked_lsp===false && list_item_type!=='variable')){
        let is_lowest = this.checkLowestSellingPrice(event);
        if(is_lowest===false){
            localStorage.setItem("save_row",'yes')
            //document.getElementById("target_index").value = 'save-row';
            return false;
        }
      }
      if(document.getElementById("iitem-quantity")){
        document.getElementById("iitem-quantity").focus();
      }
      this.setState({is_checked_lsp:false});
      let type = event.currentTarget.id;
      let item_len = this.state.item_add_count;//document.getElementsByClassName("item-name").length;
      let key = item_len+1;
      this.setState({item_add_count:key});
      

      let custom_fields = document.querySelectorAll('.iitem-custom-field');

      let custom_fields_json = [];
      for (let index = 0; index < custom_fields.length; index++) {
        const cf_element = custom_fields[index];
        const data_id = cf_element.getAttribute('data-id');
        let thisval = cf_element.value;
        let cf_id = document.getElementById('iitem-cf-id-'+data_id).value;
        let cf_name = document.getElementById('iitem-cf-name-'+data_id).value;
        let cf_is_sale_active = document.getElementById('iitem-cf-is-sale-active-'+data_id).value;
        let cf_is_purchase_active = document.getElementById('iitem-cf-is-purchase-active-'+data_id).value;


        let cf_fields = {'id':cf_id,'is_purchase_active':cf_is_purchase_active,'is_sale_active':cf_is_sale_active,'name':cf_name,'value':thisval};
        custom_fields_json.push(cf_fields);
        this.setState({is_custom_field:true});
      }

      
      let list_item_id = (document.getElementById("iitem-list-id"))?document.getElementById("iitem-list-id").value:'';
      let item_id= (document.getElementById("iitem-name"))?document.getElementById("iitem-name").value:'';
      item_id = parseInt(item_id);
      let bundle= (document.getElementById("iitem-bundle-quantity"))?document.getElementById("iitem-bundle-quantity").value:'';
      let bundle_text= (document.getElementById("iitem-bundle-text"))?document.getElementById("iitem-bundle-text").value:'';
      let quantity= (document.getElementById("iitem-quantity"))?document.getElementById("iitem-quantity").value:0;
      let availability= (document.getElementById("iitem-availability"))?document.getElementById("iitem-availability").innerHTML:'';
      let bundle_quantity= (document.getElementById("iitem-bundle-quantity"))?document.getElementById("iitem-bundle-quantity").value:'';
      let bundle_desc= (document.getElementById("bundle_quantity"))?document.getElementById("bundle_quantity").innerHTML:'';
     
      let unit_id = (document.getElementById("iitem-unit"))?document.getElementById("iitem-unit").value:1;

      let unit_name = 'Pcs';
      if(document.getElementById("iitem-unit")){
        let iiunit = document.getElementById("iitem-unit");
        if(iiunit.selectedIndex>=0){
          if(iiunit.options[iiunit.selectedIndex]!==undefined && iiunit.options[iiunit.selectedIndex]!==null){
            unit_name = iiunit.options[iiunit.selectedIndex].text;
          }else{
            unit_id = 1;
            unit_name = 'Pcs';
          }
        }else{
          unit_id = 1;
          unit_name = 'Pcs';
        }
      }

      let price= (document.getElementById("price"))?document.getElementById("price").value:'';
      let lowest_selling_price= (document.getElementById("iitem-lowest-selling-price"))?document.getElementById("iitem-lowest-selling-price").value:'';
      let i_purchase_price= (document.getElementById("iitem-purchase-price"))?document.getElementById("iitem-purchase-price").value:'';
      let total_avl= availability;
      let discount= (document.getElementById("discount"))?document.getElementById("discount").value:0;
      let total_discount= (document.getElementById("discount-hidden"))?document.getElementById("discount-hidden").value:0;
      let sale_discount_type = 'per';
      if(document.getElementById("sales_sp_disc_type")){
        sale_discount_type = document.getElementById("sales_sp_disc_type").value;
      }
      let purchase_sp_discount_type = 'per';
      if(document.getElementById("purchase_sp_disc_type")){
        purchase_sp_discount_type = document.getElementById("purchase_sp_disc_type").value;
      }
      let purchase_pp_discount_type = 'per';
      if(document.getElementById("purchase_pp_disc_type")){
        purchase_pp_discount_type = document.getElementById("purchase_pp_disc_type").value;
      }
      
      let cgst= (document.getElementById("iitem-cgst"))?document.getElementById("iitem-cgst").value:0;
      let hidden_cgst= (document.getElementById("iitem-cgst-hidden"))?document.getElementById("iitem-cgst-hidden").value:0;
      let total_cgst= (document.getElementById("iitem-cgst-total"))?document.getElementById("iitem-cgst-total").value:0;
      let sgst= (document.getElementById("iitem-sgst"))?document.getElementById("iitem-sgst").value:0;
      let hidden_sgst= (document.getElementById("iitem-sgst-hidden"))?document.getElementById("iitem-sgst-hidden").value:0;
      let total_sgst= (document.getElementById("iitem-sgst-total"))?document.getElementById("iitem-sgst-total").value:0;
      let igst= (document.getElementById("iitem-igst"))?document.getElementById("iitem-igst").value:0;
      let hidden_igst= (document.getElementById("iitem-igst-hidden"))?document.getElementById("iitem-igst-hidden").value:0;
      let total_igst= (document.getElementById("iitem-igst-total"))?document.getElementById("iitem-igst-total").value:0;
      let pp_tax_type= (document.getElementById("iitem-tax-inc"))?document.getElementById("iitem-tax-inc").value:0;
      let price_hidden= (document.getElementById("price-hidden"))?document.getElementById("price-hidden").value:0;
      let price2= (document.getElementById("iitem-selling-price"))?document.getElementById("iitem-selling-price").value:'';

      let sp_discount= (document.getElementById("sp-discount"))?document.getElementById("sp-discount").value:0;
      let sp_total_discount= (document.getElementById("sp-discount-hidden"))?document.getElementById("sp-discount-hidden").value:0;
      
    
      let sp_tax_type= (document.getElementById("sp-tax-type"))?document.getElementById("sp-tax-type").value:0;
      let iitem_barcode= (document.getElementById("iitem-barcode"))?document.getElementById("iitem-barcode").value:this.state.selected_barcode;

      let alias_barcode= (document.getElementById("iitem-alias-barcode"))?document.getElementById("iitem-alias-barcode").value:this.state.selected_alias_barcode;
      let re_order_quantity= (document.getElementById("iitem-re-order-qty"))?document.getElementById("iitem-re-order-qty").value:'';

      let iitem_category= (document.getElementById("tags-id-category"))?document.getElementById("tags-id-category").value:"";
      let iitem_category_name="";
      if(iitem_category!==""){
        iitem_category_name = document.getElementById("tags-value-category").value;
      }
      
      let total= (document.getElementById("iitem-total-txt"))?document.getElementById("iitem-total-txt").value:0;
      let item_commission= (document.getElementById("iitem-total-commission"))?document.getElementById("iitem-total-commission").value:0;

    
      if(this.props.billing_type==='New Sale' || this.props.billing_type==='New Purchase'){
          let selected_item = this.state.edit_item_selected;
          let is_credit_limit = this.CHECK_CREDIT_LIMIT('item',selected_item);
          if(is_credit_limit===false){
            return false;
          }
      }
      if(this.state.regen_bc_flag===true){
        iitem_barcode = 'Re Generate';
      }
      
      let items = {
          iitem_type : list_item_type,
          list_item_id : list_item_id,
          item_name : item_name,
          item_id : item_id,
          desc : desc,
          hsn : hsn,
          bundle : bundle,
          bundle_text : bundle_text,
          quantity : quantity,
          availability : availability,
          bundle_quantity : bundle_quantity,
          bundle_desc : bundle_desc,
          unit_name : unit_name,
          unit_id : unit_id,
          price : price,
          purchase_price : i_purchase_price,
          lowest_selling_price : lowest_selling_price,
          total_avl : total_avl,
          price2 : price2,
          discount : discount,
          total_discount : total_discount,
          tax : tax,
          cgst : cgst,
          hidden_cgst : hidden_cgst,
          total_cgst : total_cgst,
          sgst : sgst,
          hidden_sgst : hidden_sgst,
          total_sgst :  total_sgst,
          igst : igst,
          hidden_igst : hidden_igst,
          total_igst : total_igst,
          pp_tax_type : pp_tax_type,
          is_tax_inc : pp_tax_type,
          price_hidden : price_hidden,

          sp_discount : sp_discount,
          sp_total_discount : sp_total_discount,
          sp_tax : sp_tax,
          sp_tax_type : sp_tax_type,
          barcode : iitem_barcode,
          alias_barcode : alias_barcode,
          barcode_name_on_bill_view:this.state.barcode_name_on_the_bill_view,
          item_name_on_bill_view:this.state.item_name_on_the_bill_view,
          iitem_category:iitem_category,
          iitem_category_name:iitem_category_name,
          custom_fields_json : custom_fields_json,
          re_order_quantity : re_order_quantity,
          sale_discount_type : sale_discount_type,
          purchase_sp_discount_type : purchase_sp_discount_type,
          purchase_pp_discount_type : purchase_pp_discount_type,
          total : total,
          item_commission : item_commission,
      }

      quantity = parseFloat(quantity);
      if(quantity<=0 && list_item_type!=='variable' && list_item_type!=='general'){
        if(document.getElementById("iitem-quantity")){
          document.getElementById("iitem-quantity").focus();
        }
        var element = document.getElementById('notice_box');
        let msg = "Oops! Entered quantity should not be 0, please enter quantiy 1 or more.";
        document.getElementById("notice_message").innerHTML = msg;
        element.classList.remove("fade");
        element.style.display="block";
        setTimeout(() => {
          document.getElementById("close-notice").focus();
        }, 500);
        return false;
      }

      let itotal = parseFloat(total);
      if(itotal<=0 && list_item_type!=='variable' && list_item_type!=='general'){
        if(document.getElementById("price")){
          document.getElementById("price").focus();
        }
        return false;
      }
      let is_valid = true;
      if(item_id>0){
        let option_available = JSON.parse(localStorage.getItem("item_options"));
        let purchase_option_available = JSON.parse(localStorage.getItem("purchase_item_options"));
        if(purchase_option_available!==null && purchase_option_available[item_id]!==undefined && purchase_option_available[item_id]!==null){
          
          let avl = purchase_option_available[item_id].availability;

          let get_diff_barcode_inv_availability = utils.get_diff_barcode_inv_availability(this.props.billing_type, purchase_option_available[item_id]);
          
          if(get_diff_barcode_inv_availability>=0){
            avl = get_diff_barcode_inv_availability;
            let iitem_id = item_id;
            item_id = utils.get_diff_barcode_latest_id(this.props.billing_type, purchase_option_available[item_id]);
            if(purchase_option_available[item_id]===undefined){
              item_id = iitem_id;
            }
          }
          
          let av = 0;
          if(purchase_option_available[item_id]!==undefined && purchase_option_available[item_id]!==null){
            av = purchase_option_available[item_id].availability;
          }
          if(purchase_option_available[item_id]!==undefined && purchase_option_available[item_id].old_available!==undefined){
            av = purchase_option_available[item_id].old_available;
          }
          let inventory_status = purchase_option_available[item_id].inventory_status;
          if(this.props.billing_type==='New Sale' || this.props.billing_type==='New Purchase Return'){
            availability = avl-quantity;
          }else if(this.props.billing_type==='New Purchase' || this.props.billing_type==='New Sales Return'){
            availability = avl+quantity;
          }
          
          if(inventory_status==='Active'){
            if(option_available!==null && option_available[item_id]!==undefined && option_available[item_id]!==null && option_available[item_id].availability!==undefined && option_available[item_id].availability!==null){
              option_available[item_id].availability = availability;
            }
          }
          purchase_option_available[item_id].availability = availability;
          let static_item_name = purchase_option_available[item_id].static_item_name;
          let stat_lbl = '';
          if(inventory_status==='Draft'){
            stat_lbl=' (Inactive)';
          }


          if(this.props.billing_type==='New Purchase'){
            let regenerate_barcode_opt = this.state.regenerate_barcode_opt;
            if(regenerate_barcode_opt.regenerate_barcode===true && this.state.regen_bc_flag===false){

              let p_price = purchase_option_available[item_id].purchase_price;
              let s_price = purchase_option_available[item_id].selling_price;
              let p_ptax = purchase_option_available[item_id].tax;
              let p_supplier = purchase_option_available[item_id].supplier;
              let p_age = purchase_option_available[item_id].age;


              let item_purchase_price = price;
              let item_selling_price = price2;
              let item_supplier = document.getElementById("customer-dropdown").value;

              let setting_change_pp = regenerate_barcode_opt.change_pp;
              let setting_change_sp = regenerate_barcode_opt.change_sp;
              let setting_change_tax = regenerate_barcode_opt.change_tax;
              let setting_change_supplier = regenerate_barcode_opt.change_supplier;
              let setting_age = regenerate_barcode_opt.on_stock_age;

              let is_show_reg_pop = false;

              let pop_msg = 'The current barcode will be overwritten to new barcode because system detected changes in ';

              if(setting_change_pp === true && p_price!=item_purchase_price){
                pop_msg +='purchase price, ';
                is_show_reg_pop = true;
              }
              if(setting_change_sp === true && s_price!=item_selling_price){
                pop_msg +='selling price, ';
                is_show_reg_pop = true;
              }
              if(setting_change_tax === true && p_ptax!=tax){
                pop_msg +='tax, ';
                is_show_reg_pop = true;
              }
              if(setting_change_supplier === true && p_supplier!=item_supplier){
                pop_msg +='supplier, ';
                is_show_reg_pop = true;
              }

              if(setting_age !== "" && parseInt(p_age)>parseInt(setting_age)){
                pop_msg +='stock age ';
                is_show_reg_pop = true;
              }
              pop_msg = pop_msg.replace(/,\s*$/, "");

              pop_msg +=' as per your settings. Are you sure you want to change the barcode sequence to new one?';

              if(is_show_reg_pop===true){
                document.getElementById("re-gen-bc-msg").innerHTML = pop_msg;
                this.handleOpenBarcodeAlert();
                return false;
              }else{
                pop_msg = '';
              }
            }
            
            if(this.state.regen_bc_flag===true || this.state.regen_bc_flag===null){
              this.setState({regen_bc_flag:false});
            }
          }
          av = parseFloat(avl);
         
          if(quantity>av  && this.state.allow_negative_inventory===false){
            if(this.props.billing_type==='New Sale' && list_item_type!=='sales_return'){
              var element = document.getElementById('notice_box');
              let msg = "You are entering quantity more than availability, total availability is "+av;
              document.getElementById("notice_message").innerHTML = msg;
              element.classList.remove("fade");
              element.style.display="block";
              document.getElementById("item_availabe_hidden").value = av;
              document.getElementById("item_index_hidden").value = 0;
              document.getElementById("close-notice").focus();
              is_valid = false;
            }
          }else{

            let new_item_name = static_item_name+" - Qty:"+availability+stat_lbl;
            purchase_option_available[item_id].value2 = new_item_name;
            //if(inventory_status==='Active'){
              if(option_available[item_id]!==undefined && option_available[item_id]!==null && option_available[item_id].value2!==undefined && option_available[item_id].value2!==null){
                option_available[item_id].value2 = new_item_name;
                localStorage.setItem("item_options",JSON.stringify(option_available));
                //this.setState({ 'item_options': option_available});
              }
            //}
            localStorage.setItem("purchase_item_options",JSON.stringify(purchase_option_available));
            //this.setState({ 'purchase_item_options': purchase_option_available}); 
            this.updateItemQuantity(item_id);
          }
        }
      }
      
      if((is_valid && quantity>0) || list_item_type==='variable'){
        let selected_item = this.state.edit_item_selected;
        let edit_item_active_index = this.state.edit_item_active_index;
        let active_index = this.state.edit_item_active_index;
        if(selected_item!=='none'){
          this.more_rows[selected_item] = <BillingItemList count={item_len} type="more" key={edit_item_active_index} index={edit_item_active_index} billing_type={this.props.billing_type} items={items} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)}/>;
          //this.setState({more_rows:this.more_rows});
          let sli = document.querySelectorAll(".billing-item-row");
          for(let i=0;i<sli.length;i++){
             sli[i].classList.remove('item-edit-mode');
             localStorage.removeItem('is_list_item_edit');
          }
          this.setState({edit_item_selected:'none'});
        }else{

          let birow = document.querySelectorAll(".billing-item-row");
          let next_row = 0;
          let temp_row = -1;

          for(let i=0;i<birow.length;i++){
              let tdid = birow[i].getAttribute('data-id');
              if(parseInt(tdid)>=temp_row){
                temp_row = parseInt(tdid);
              }
          }
          temp_row =  temp_row+1;
          if(localStorage.getItem('return_bill_count')!==null){
            temp_row = localStorage.getItem('return_bill_count');
            localStorage.removeItem('return_bill_count')
          }
          if(this.more_rows[temp_row]===undefined){
            this.more_rows[temp_row] = [];
          }

          //let row_len = this.more_rows.length;
          this.more_rows[temp_row] = <BillingItemList count={temp_row} type="more" key={temp_row} index={temp_row} billing_type={this.props.billing_type} items={items} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)}/>;

          //console.log(this.more_rows);
        }
        
        this.setState({more_rows:this.more_rows});
        let rm = document.querySelectorAll(".item-remove");
        for(let i=0;i<rm.length;i++){
          rm[i].style.display = 'block';
        }
        document.getElementById("new-item-form").reset();
        if(this.props.billing_type==='New Sale'){
          this.setState({list_item_type:'product'});
          this.handleToggleFieldVisibility();
        }
        if(this.props.billing_type==='New Purchase'){
          this.setState({list_item_type:'single'});
          this.handleToggleFieldVisibility();
        }

        document.getElementById("iitem-box").value = '';
        document.getElementById("iitem-desc-txt").innerHTML = '';
        document.getElementById("iitem-desc").value = '';
        if(document.getElementById("iitem-barcode")){
          let bcode_name = document.getElementById("iitem-barcode").value;
          if(bcode_name!=="Auto Generate"){
            document.getElementById("iitem-barcode").value = '';
          }
        }
        /* if(document.getElementById("iitem-category")){
          localStorage.setItem('selected_product',1);
          if(document.getElementById("setProductState")){
            document.getElementById("setProductState").click();
          }
        } */

        this.setState({is_new_item:0});
        setTimeout(function(){
          document.getElementById("iitem-box").focus();
          document.getElementById("iitem-unit").selectedIndex = "0";
          _this.calculateTotal();
          //_this.calculateReturnTotal();
        },300);
      }else{
        document.getElementById("iitem-quantity").focus();
      }
      let item_edit_mode = document.querySelectorAll(".item-mod-options");
      for(let i=0;i<item_edit_mode.length;i++){
        item_edit_mode[i].style.display = 'flex';
      }

      setTimeout(function(){
        document.getElementById("iitem-box").focus();
        document.getElementById("iitem-unit").selectedIndex = "0";
        _this.ADJUST_BILL_SNO('regular');
      },100);

      this.handleSmoothAnimation();

      if(document.querySelector(".bill-ret-type")){
        let cls_ret_type = document.querySelector(".bill-ret-type").getAttribute("data-id");

        if(cls_ret_type==='invoice'){
          let cls = this.props.billing_type.replaceAll(" ",'-');
          if(document.querySelector(".sticky-add-item-panel."+cls)){
            document.querySelector(".sticky-add-item-panel."+cls).style.display = "none";
          }
          if(document.querySelector(".list-item-add-blk."+cls)){
            document.querySelector(".list-item-add-blk."+cls).style.display = "none";
          }
          if(document.querySelector(".add-item-mob."+cls)){
            document.querySelector(".add-item-mob."+cls).style.display = "none";
          }
        }

        setTimeout(() => {
          let is_clicked_option = localStorage.getItem("return_option");
          if(is_clicked_option!=="" && is_clicked_option!==null && is_clicked_option!=='null'){
            localStorage.removeItem("return_option");
            if(document.getElementById(is_clicked_option)){
              document.getElementById(is_clicked_option).click();
            }
          }
        }, 200);
      }
      if(document.getElementById("handleEmptyTagList")){
        document.getElementById("handleEmptyTagList").click();
      }
      let target = this.props.billing_type.replaceAll(' ','');
      localStorage.setItem('unsaved_list_item_'+target,JSON.stringify(this.more_rows));
      if(this.props.billing_type==='New Purchase'){
        let auto_options = this.state.auto_options;
        if(auto_options.length===0){
          auto_options.push(desc);
          this.setState({auto_options:auto_options});
          this.setState({auto_opt_value:''});
          localStorage.setItem('auto_desc_opt',JSON.stringify(auto_options));
        }else{
          if(!auto_options.includes(desc)){
            auto_options.push(desc);
            this.setState({auto_options:auto_options});
            localStorage.setItem('auto_desc_opt',JSON.stringify(auto_options));
          }
        }
        this.setState({auto_opt_value:''});
      }

      if(this.props.billing_type==='New Sales Return' || this.props.billing_type==='New Purchase Return'){
          document.getElementById("iitem-box").removeAttribute('disabled');
          document.getElementById("iitem-box").setAttribute('tabindex',11);
      }
    }

    handleSmoothAnimation(){
      if(document.getElementById("list-item-table")){
        document.getElementById("list-item-table").classList.remove('animate__animated');
        document.getElementById("list-item-table").classList.remove('animate__fadeIn');
      }
      setTimeout(function(){
        let qs = document.querySelectorAll(".billing-item-row");
        for(let i=qs.length-1;i>0;i--){
          let did = qs[i].getAttribute('data-id');
          document.getElementById("item-row-"+did).classList.add('animate__animated');
          document.getElementById("item-row-"+did).classList.add('animate__fadeIn');
          return;
        }
      },8);
      let _this = this;
      setTimeout(function(){
        let bill_row = document.querySelectorAll(".billing-item-row");
        let cls_len = bill_row.length;
        if(cls_len>5){
          let scrollPos = _this.state.scrollPos;
          window.scrollTo({top: scrollPos, behavior: 'smooth'});
          scrollPos = scrollPos+60;
          _this.setState({scrollPos:scrollPos});
        }
      },100);
    }
    
    handleVariationEdit(index,selected_product){
        let variations  = this.variant_data[selected_product];
        
        let options = [];
        let options_key = [];
        for(let i=0;i<variations.length;i++){
            if(variations[i].var_options.option1!==''){
              if(options[variations[i].var_options.option1]===undefined){
                options[variations[i].var_options.option1] = [];
              }
              options[variations[i].var_options.option1].push(variations[i].var_options.opt1_val);
              if(options_key.indexOf(variations[i].var_options.option1)<0){
                options_key.push(variations[i].var_options.option1);
              }
            }
            if(variations[i].var_options.option2!==''){
              if(options[variations[i].var_options.option2]===undefined){
                options[variations[i].var_options.option2] = [];
              }
              options[variations[i].var_options.option2].push(variations[i].var_options.opt2_val);
              if(options_key.indexOf(variations[i].var_options.option2)<0){
                options_key.push(variations[i].var_options.option2);
              }
            }
            if(variations[i].var_options.option3!==''){
              if(options[variations[i].var_options.option3]===undefined){
                options[variations[i].var_options.option3] = [];
              }
              options[variations[i].var_options.option3].push(variations[i].var_options.opt3_val);
              if(options_key.indexOf(variations[i].var_options.option3)<0){
                options_key.push(variations[i].var_options.option3);
              }
            }

            if(variations[i].var_options!==undefined){
              if(variations[i].var_options.option1){
                variations[i].option1 = variations[i].var_options.option1;
                variations[i].value1 = variations[i].var_options.value1;
              }
              if(variations[i].var_options.option2){
                variations[i].option2 = variations[i].var_options.option2;
                variations[i].value2 = variations[i].var_options.value2;
              }
              if(variations[i].var_options.option3){
                variations[i].option3 = variations[i].var_options.option3;
                variations[i].value3 = variations[i].var_options.value3;
              }
            }
            let lbl = variations[i].item_name;
            this.variantList.push(<VariationList item_data={variations[i]} option_data={variations[i].var_options} label={lbl} index={i} removeItem={this.handleRemoveItem.bind(this)}/>);
        }
        this.setState({variantList:this.variantList});
        if(options_key.length>0){
          this.variantOptions = [];
          for(let i=0;i<options_key.length;i++){
              let option = options_key[i];
              let tags = options[option];
              let opt_tags = [];
              for(let j=0;j<tags.length;j++){
                if(opt_tags.indexOf(tags[j])<0){
                  opt_tags.push(tags[j]);
                }
              }
              let key = i+1;
              this.variantOptions.push(<VariantOptions  variationList={this.variantList} removeVariationList={this.removeVariationList.bind(this)} label={"Option "+key} option={option} index={key} updateState={this.updateVariationListState.bind(this)} updateOptionState={this.updateVariationOptionState.bind(this)} tags={opt_tags}/>);
          }
          this.setState({variantOptions:this.variantOptions});
        }
    }

    handleReturnListItemEdit(e){
      let index = e.currentTarget.getAttribute('data-id');
      index = parseInt(index);
      let has_variation = false;
      let item_name = (document.getElementById("ret-item-box-"+index))?document.getElementById("ret-item-box-"+index).value:'';
      let sitem_id = (document.getElementById("ret-item-list-id-"+index))?document.getElementById("ret-item-list-id-"+index).value:'';
      let selected_inventory_id = (document.getElementById("ret-item-name-"+index))?document.getElementById("ret-item-name-"+index).value:'';
      let selected_barcode = (document.getElementById("vitem-barcode-name-"+index))?document.getElementById("vitem-barcode-name-"+index).value:'';
       let desc= (document.getElementById("ret-iitem-desc-txt-"+index))?document.getElementById("ret-iitem-desc-txt-"+index).innerHTML:'';
      let hsn= (document.getElementById("ret-item-hsn-"+index))?document.getElementById("ret-item-hsn-"+index).value:'';


      let ikey = 0;
      
      for(let k=0;k<this.return_more_rows.length;k++){
        if(this.return_more_rows[k]!==undefined){
          if(this.return_more_rows[k].props!==undefined){
            let ik = this.return_more_rows[k].props.index;
            if(ik===index){
              ikey = k;
            }
          }
        }
      }
      this.setState({edit_item_selected:ikey,edit_item_active_index:index,item_id_selected:sitem_id,selected_inventory_id:selected_inventory_id,selected_barcode:selected_barcode});
    
      let list_item_type = '';
      if(document.getElementById("ret-item-type-"+index)){
        list_item_type = document.getElementById("ret-item-type-"+index).value;
      }
      this.setState({list_item_type:list_item_type});
      if(document.getElementById("iitem-type")){
        document.getElementById("iitem-type").value = list_item_type;
      }
      


      let item_edit_mode = document.querySelectorAll(".ret-item-mod-options");

      for(let i=0;i<item_edit_mode.length;i++){
        item_edit_mode[i].style.display = 'none';
      }

      document.getElementById("return-item-row-"+index).classList.add('item-edit-mode');
      localStorage.setItem('is_list_item_edit',true);
      let list_item_id = (document.getElementById("ret-item-list-id-"+index))?document.getElementById("ret-item-list-id-"+index).value:'';
      let item_id= (document.getElementById("ret-item-name-"+index))?document.getElementById("ret-item-name-"+index).value:'';
    

      let quantity= (document.getElementById("ret-item-quantity-"+index))?document.getElementById("ret-item-quantity-"+index).value:0;

      let availability= '';
      let bundle_quantity= '';
      let unit_name= (document.getElementById("ret-item-unit-name-"+index))?document.getElementById("ret-item-unit-name-"+index).value:'';
      let unit_id = (document.getElementById("ret-item-unit-"+index))?document.getElementById("ret-item-unit-"+index).value:'';
      let price= (document.getElementById("ret-price-"+index))?document.getElementById("ret-price-"+index).value:'';
      let lowest_selling_price= (document.getElementById("ret-item-selling-price-"+index))?document.getElementById("ret-item-selling-price-"+index).value:'';
      let total_avl= availability;
      let price2= (document.getElementById("ret-item-selling-price-"+index))?document.getElementById("ret-item-selling-price-"+index).value:'';
      let discount= (document.getElementById("ret-item-discount-"+index))?document.getElementById("ret-item-discount-"+index).value:0;
     
      let total_discount= (document.getElementById("ret-discount-hidden-"+index))?document.getElementById("ret-discount-hidden-"+index).value:0;
      let tax= (document.getElementById("ret-item-gst-"+index))?document.getElementById("ret-item-gst-"+index).value:0;
      let cgst= (document.getElementById("ret-item-cgst-"+index))?document.getElementById("ret-item-cgst-"+index).value:0;
      let hidden_cgst= (document.getElementById("ret-item-cgst-hidden-"+index))?document.getElementById("ret-item-cgst-hidden-"+index).value:0;
      let total_cgst= (document.getElementById("ret-item-cgst-total-"+index))?document.getElementById("ret-item-cgst-total-"+index).value:0;
      let sgst= (document.getElementById("ret-item-sgst-"+index))?document.getElementById("ret-item-sgst-"+index).value:0;
      let hidden_sgst= (document.getElementById("ret-item-sgst-hidden-"+index))?document.getElementById("ret-item-sgst-hidden-"+index).value:0;
      let total_sgst= (document.getElementById("ret-item-sgst-total-"+index))?document.getElementById("ret-item-sgst-total-"+index).value:0;
      let igst= (document.getElementById("ret-item-igst-"+index))?document.getElementById("ret-item-igst-"+index).value:0;
      let hidden_igst= (document.getElementById("ret-item-igst-hidden-"+index))?document.getElementById("ret-item-igst-hidden-"+index).value:0;
      let total_igst= (document.getElementById("ret-item-igst-total-"+index))?document.getElementById("ret-item-igst-total-"+index).value:0;
      let pp_tax_type= (document.getElementById("ret-item-tax-inc-"+index))?document.getElementById("item-tax-inc-"+index).value:0;
      let price_hidden= 0;
      let total= (document.getElementById("ret-item-total-txt-"+index))?document.getElementById("ret-item-total-txt-"+index).value:0;
      setTimeout(function(){
      if(document.getElementById("iitem-list-id")){document.getElementById("iitem-list-id").value = list_item_id};
      if(document.getElementById("iitem-name")){document.getElementById("iitem-name").value = item_id};
     
      if(document.getElementById("iitem-desc")){document.getElementById("iitem-desc").value = desc};
      if(document.getElementById("iitem-desc-txt")){document.getElementById("iitem-desc-txt").innerHTML = desc};
      if(document.getElementById("iitem-hsn")){document.getElementById("iitem-hsn").value= hsn};

      if(document.getElementById("iitem-quantity")){document.getElementById("iitem-quantity").value=quantity};
      if(document.getElementById("iitem-availability")){document.getElementById("iitem-availability").innerHTML = availability};
      if(document.getElementById("iitem-bundle-quantity")){document.getElementById("iitem-bundle-quantity").value = bundle_quantity};
      if(document.getElementById("iitem-unit-name")){document.getElementById("iitem-unit-name").value = unit_name};
      
        if(document.getElementById("iitem-unit")){document.getElementById("iitem-unit").value = unit_id};
        if(document.getElementById("iitem-box")){document.getElementById("iitem-box").value = item_name};
         document.getElementById("iitem-box").focus();
      
      if(document.getElementById("price")){document.getElementById("price").value = price};
      if(document.getElementById("iitem-selling-price")){document.getElementById("iitem-selling-price").value = price2};

      if(document.getElementById("price-hidden")){document.getElementById("price-hidden").value  = price};
     
      if(document.getElementById("discount")){document.getElementById("discount").value = discount};
      if(document.getElementById("discount-hidden")){document.getElementById("discount-hidden").value = total_discount};
      if(document.getElementById("iitem-gst")){document.getElementById("iitem-gst").value = tax};
      if(document.getElementById("iitem-cgst")){document.getElementById("iitem-cgst").value = cgst};
      if(document.getElementById("iitem-cgst-hidden")){document.getElementById("iitem-cgst-hidden").value = hidden_cgst};
      if(document.getElementById("iitem-cgst-total")){document.getElementById("iitem-cgst-total").value = total_cgst};
      if(document.getElementById("iitem-sgst")){document.getElementById("iitem-sgst").value =sgst};
      if(document.getElementById("iitem-sgst-hidden")){document.getElementById("iitem-sgst-hidden").value = hidden_sgst};
      if(document.getElementById("iitem-sgst-total")){document.getElementById("iitem-sgst-total").value = total_sgst};
      if(document.getElementById("iitem-igst")){document.getElementById("iitem-igst").value = igst};
      if(document.getElementById("iitem-igst-hidden")){document.getElementById("iitem-igst-hidden").value = hidden_igst};
      if(document.getElementById("iitem-igst-total")){document.getElementById("iitem-igst-total").value =  total_igst};
     
      if(document.getElementById("iitem-total-txt")){document.getElementById("iitem-total-txt").value = total};
      },100);
      quantity = parseInt(quantity);
      this.handleUpdateInventoryQuantity('add',quantity,item_id);
    }
    handleListItemEdit(e){

      let index = e.currentTarget.getAttribute('data-id');
      index = parseInt(index);
      let has_variation = false;
      if(document.getElementById("item-has-variant-"+index)){
        has_variation = document.getElementById("item-has-variant-"+index).value;
      }
      let item_name = (document.getElementById("item-box-"+index))?document.getElementById("item-box-"+index).value:'';
      let sitem_id = (document.getElementById("item-list-id-"+index))?document.getElementById("item-list-id-"+index).value:'';
      let selected_inventory_id = (document.getElementById("item-name-"+index))?document.getElementById("item-name-"+index).value:'';
      let selected_barcode = (document.getElementById("vitem-barcode-name-"+index))?document.getElementById("vitem-barcode-name-"+index).value:'';
  
      let item_key = (document.getElementById("vitem-item-key-"+index))?document.getElementById("vitem-item-key-"+index).value:'';
  
      let selected_alias_barcode = (document.getElementById("vitem-alias-barcode-name-"+index))?document.getElementById("vitem-alias-barcode-name-"+index).value:'';
       let desc= (document.getElementById("iitem-desc-txt-"+index))?document.getElementById("iitem-desc-txt-"+index).innerHTML:'';
      let hsn= (document.getElementById("item-hsn-"+index))?document.getElementById("item-hsn-"+index).value:'';
  
      let item_category= (document.getElementById("item-category-"+index))?document.getElementById("item-category-"+index).value:1; 

      let re_order_quantity= (document.getElementById("item-re-order-qty-"+index))?document.getElementById("item-re-order-qty-"+index).value:''; 
  
      let item_category_name= (document.getElementById("item-category-name-"+index))?document.getElementById("item-category-name-"+index).innerHTML:''; 
  
      this.setState({defaultCategory:item_category_name});
      this.setState({defaultCategoryID:item_category});
      localStorage.setItem('selected_product',item_category);
      if(document.getElementById("setProductState")){
        //document.getElementById("setProductState").click();
      }
  
      let ikey = 0;
      
      for(let k=0;k<this.more_rows.length;k++){
        if(this.more_rows[k]!==undefined){
          if(this.more_rows[k].props!==undefined){
            let ik = this.more_rows[k].props.index;
            if(ik===index){
              ikey = k;
            }
          }
        }
      }
      this.setState({edit_item_selected:ikey,edit_item_active_index:index,item_id_selected:sitem_id,selected_inventory_id:selected_inventory_id,selected_barcode:selected_barcode,selected_alias_barcode:selected_alias_barcode,p_rand_key:item_key});
      
  
  
      if(has_variation===true || has_variation==="true"){
  
        document.getElementById("selected_item_name").value = item_name;
        document.getElementById("selected_item_desc").value = desc;
        document.getElementById("selected_item_hsn").value = hsn;
        document.getElementById("selected_old_item").value = item_name;
  
        let product_variation_pop_title = "VARIATION FOR PRODUCT "+item_name;
        let pname = 'pk_'+item_name.replace(/\s+/g, "___");
        let sitme_key = pname+'_'+item_key;
        this.setState({product_variation_pop_title:product_variation_pop_title,selected_product:pname,selected_item_desc:desc,selected_item_hsn:hsn,selected_item_name:item_name,selected_old_item:item_name,is_open_popup:true,p_rand_key:item_key});
  
        this.handleVariationEdit(index,sitme_key);
        var element = document.getElementById('product-variation-add');
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("tag-box-1").focus();
        let ps = this.state.purchase_field_hide;
        let _th = this;
        setTimeout(() => {
          _th.handleHandleItemFields(ps);
        }, 100);
        setTimeout(function(){
          if(document.getElementById("setProductState")){
            document.getElementById("setProductState").click();
          }
        },100);
        return false;
      }else{
        let list_item_type = '';
        if(document.getElementById("item-type-"+index)){
          list_item_type = document.getElementById("item-type-"+index).value;
        }
        this.setState({list_item_type:list_item_type});
        if(document.getElementById("iitem-type")){
          document.getElementById("iitem-type").value = list_item_type;
        }
      }
  
  
      let item_edit_mode = document.querySelectorAll(".item-mod-options");
  
      for(let i=0;i<item_edit_mode.length;i++){
        item_edit_mode[i].style.display = 'none';
      }
  
      document.getElementById("item-row-"+index).classList.add('item-edit-mode');
      localStorage.setItem('is_list_item_edit',true);
     
      
      let list_item_id = (document.getElementById("item-list-id-"+index))?document.getElementById("item-list-id-"+index).value:'';
      let item_id= (document.getElementById("item-name-"+index))?document.getElementById("item-name-"+index).value:'';
     
      let bundle= (document.getElementById("item-bundle-quantity-"+index))?document.getElementById("item-bundle-quantity-"+index).value:'';
      let bundle_text= (document.getElementById("item-bundle-text-"+index))?document.getElementById("item-bundle-text-"+index).value:'';
      let quantity= (document.getElementById("item-quantity-"+index))?document.getElementById("item-quantity-"+index).value:0;
  
  
  
      let availability= (document.getElementById("item-availability-"+index))?document.getElementById("item-availability-"+index).innerHTML:'';
      let bundle_quantity= (document.getElementById("item-bundle-quantity-"+index))?document.getElementById("item-bundle-quantity-"+index).value:'';
      let unit_name= (document.getElementById("item-unit-name-"+index))?document.getElementById("item-unit-name-"+index).value:'';
      let unit_id = (document.getElementById("item-unit-"+index))?document.getElementById("item-unit-"+index).value:'';
      let price= (document.getElementById("price-"+index))?document.getElementById("price-"+index).value:'';
      let lowest_selling_price= (document.getElementById("item-lowest-selling-price-"+index))?document.getElementById("item-lowest-selling-price-"+index).value:'';
      let total_avl= availability;
      let price2= (document.getElementById("item-selling-price-"+index))?document.getElementById("item-selling-price-"+index).value:'';
      let discount= (document.getElementById("discount-"+index))?document.getElementById("discount-"+index).value:0;
      let sales_sp_disc_type= (document.getElementById("sale-sp-discount-type-"+index))?document.getElementById("sale-sp-discount-type-"+index).value:'per';
      let purchase_sp_disc_type= (document.getElementById("purchase-sp-discount-type-"+index))?document.getElementById("purchase-sp-discount-type-"+index).value:'per';
      let purchase_pp_disc_type= (document.getElementById("purchase-pp-discount-type-"+index))?document.getElementById("purchase-pp-discount-type-"+index).value:'per';
      
      if(document.getElementById("sales_sp_disc_type")){document.getElementById("sales_sp_disc_type").value = sales_sp_disc_type};
      if(document.getElementById("purchase_sp_disc_type")){document.getElementById("purchase_sp_disc_type").value = purchase_sp_disc_type};
      if(document.getElementById("purchase_pp_disc_type")){document.getElementById("purchase_pp_disc_type").value = purchase_pp_disc_type};

      let total_discount= (document.getElementById("discount-hidden-"+index))?document.getElementById("discount-hidden-"+index).value:0;
      let tax= (document.getElementById("item-gst-"+index))?document.getElementById("item-gst-"+index).value:0;
      let cgst= (document.getElementById("item-cgst-"+index))?document.getElementById("item-cgst-"+index).value:0;
      let hidden_cgst= (document.getElementById("item-cgst-hidden-"+index))?document.getElementById("item-cgst-hidden-"+index).value:0;
      let total_cgst= (document.getElementById("item-cgst-total-"+index))?document.getElementById("item-cgst-total-"+index).value:0;
      let sgst= (document.getElementById("item-sgst-"+index))?document.getElementById("item-sgst-"+index).value:0;
      let hidden_sgst= (document.getElementById("item-sgst-hidden-"+index))?document.getElementById("item-sgst-hidden-"+index).value:0;
      let total_sgst= (document.getElementById("item-sgst-total-"+index))?document.getElementById("item-sgst-total-"+index).value:0;
      let igst= (document.getElementById("item-igst-"+index))?document.getElementById("item-igst-"+index).value:0;
      let hidden_igst= (document.getElementById("item-igst-hidden-"+index))?document.getElementById("item-igst-hidden-"+index).value:0;
      let total_igst= (document.getElementById("item-igst-total-"+index))?document.getElementById("item-igst-total-"+index).value:0;
      let pp_tax_type= (document.getElementById("item-tax-inc-"+index))?document.getElementById("item-tax-inc-"+index).value:0;
      let price_hidden= (document.getElementById("price-hidden-"+index))?document.getElementById("price-hidden-"+index).value:0;
      let item_commission= (document.getElementById("item-total-commission-"+index))?document.getElementById("item-total-commission-"+index).value:0;
      let total= (document.getElementById("item-total-txt-"+index))?document.getElementById("item-total-txt-"+index).value:0;
  
       let sp_discount= (document.getElementById("sp-discount-"+index))?document.getElementById("sp-discount-"+index).value:0;
      let sp_discount_hidden= (document.getElementById("sp-discount-hidden-"+index))?document.getElementById("sp-discount-hidden-"+index).value:0;
      let sp_gst= (document.getElementById("item-sp-gst-"+index))?document.getElementById("item-sp-gst-"+index).value:0;
  
      let sp_tax_type= (document.getElementById("sp-item-tax-inc-"+index))?document.getElementById("sp-item-tax-inc-"+index).value:0; 
  
      
      let item_barcode= (document.getElementById("vitem-barcode-name-"+index))?document.getElementById("vitem-barcode-name-"+index).value:1; 
      let alias_barcode= (document.getElementById("vitem-alias-barcode-name-"+index))?document.getElementById("vitem-alias-barcode-name-"+index).value:1; 
  
      let cf_json= (document.getElementById("item-cf-"+index))?JSON.parse(document.getElementById("item-cf-"+index).value):[]; 
  
      
  
      setTimeout(function(){
  
        cf_json.forEach(element => {
            let cf_id = element.id;
            let cf_value = element.value;
            let cf_name = element.name;
            let cf_is_sale_active = element.is_sale_active;
            let cf_is_purchase_active = element.is_purchase_active;
  
            let cf_idx = -1;
  
            if(document.getElementById("iitem-custom-field-"+cf_id)){
              document.getElementById("iitem-custom-field-"+cf_id).value = cf_value;
              cf_idx = document.getElementById("iitem-custom-field-"+cf_id).getAttribute('data-id');
            }
            if(cf_idx>=0){
              if(document.getElementById("iitem-cf-name-"+cf_idx)){
                document.getElementById("iitem-cf-name-"+cf_idx).value = cf_name;
              }
              if(document.getElementById("iitem-cf-id-"+cf_idx)){
                document.getElementById("iitem-cf-id-"+cf_idx).value=cf_id;
              }
              if(document.getElementById("iitem-cf-is-sale-active-"+cf_idx)){
                document.getElementById("iitem-cf-is-sale-active-"+cf_idx).value = cf_is_sale_active;
              }
              if(document.getElementById("iitem-cf-is-purchase-active-"+cf_idx)){
                document.getElementById("iitem-cf-is-purchase-active-"+cf_idx).value = cf_is_purchase_active;
              }
            }
        });
  
      if(document.getElementById("iitem-list-id")){document.getElementById("iitem-list-id").value = list_item_id};
      if(document.getElementById("iitem-name")){document.getElementById("iitem-name").value = item_id};
     
      if(document.getElementById("iitem-desc")){document.getElementById("iitem-desc").value = desc};
      if(document.getElementById("iitem-desc-txt")){document.getElementById("iitem-desc-txt").innerHTML = desc};
      if(document.getElementById("iitem-hsn")){document.getElementById("iitem-hsn").value= hsn};
      if(document.getElementById("iitem-bundle-quantity")){document.getElementById("iitem-bundle-quantity").value = bundle};
      if(document.getElementById("iitem-bundle-text")){document.getElementById("iitem-bundle-text").value = bundle_text};
      if(document.getElementById("iitem-quantity")){document.getElementById("iitem-quantity").value=quantity};
      if(document.getElementById("iitem-availability")){document.getElementById("iitem-availability").innerHTML = availability};
      if(document.getElementById("iitem-bundle-quantity")){document.getElementById("iitem-bundle-quantity").value = bundle_quantity};
      if(document.getElementById("iitem-unit-name")){document.getElementById("iitem-unit-name").value = unit_name};
     
        if(document.getElementById("iitem-unit")){document.getElementById("iitem-unit").value = unit_id};
        if(document.getElementById("iitem-box")){document.getElementById("iitem-box").value = item_name};
         document.getElementById("iitem-box").focus();
      
      if(document.getElementById("price")){document.getElementById("price").value = price};
      if(document.getElementById("iitem-selling-price")){document.getElementById("iitem-selling-price").value = price2};
  //      let total_avl= availability;
     
      if(document.getElementById("discount")){document.getElementById("discount").value = discount};
      if(document.getElementById("discount-hidden")){document.getElementById("discount-hidden").value = total_discount};
      if(document.getElementById("iitem-gst")){document.getElementById("iitem-gst").value = tax};
      if(document.getElementById("iitem-cgst")){document.getElementById("iitem-cgst").value = cgst};
      if(document.getElementById("iitem-cgst-hidden")){document.getElementById("iitem-cgst-hidden").value = hidden_cgst};
      if(document.getElementById("iitem-cgst-total")){document.getElementById("iitem-cgst-total").value = total_cgst};
      if(document.getElementById("iitem-sgst")){document.getElementById("iitem-sgst").value =sgst};
      if(document.getElementById("iitem-sgst-hidden")){document.getElementById("iitem-sgst-hidden").value = hidden_sgst};
      if(document.getElementById("iitem-sgst-total")){document.getElementById("iitem-sgst-total").value = total_sgst};
      if(document.getElementById("iitem-igst")){document.getElementById("iitem-igst").value = igst};
      if(document.getElementById("iitem-igst-hidden")){document.getElementById("iitem-igst-hidden").value = hidden_igst};
      if(document.getElementById("iitem-igst-total")){document.getElementById("iitem-igst-total").value =  total_igst};
      if(document.getElementById("iitem-tax-inc")){document.getElementById("iitem-tax-inc").value = pp_tax_type};
      if(document.getElementById("price-hidden")){document.getElementById("price-hidden").value  = price_hidden};
      if(document.getElementById("sp-discount")){document.getElementById("sp-discount").value = sp_discount};
      if(document.getElementById("sp-discount-hidden")){document.getElementById("sp-discount-hidden").value = sp_discount_hidden};
      if(document.getElementById("iitem-sp-gst")){document.getElementById("iitem-sp-gst").value = sp_gst};
      if(document.getElementById("sp-tax-type")){document.getElementById("sp-tax-type").value = sp_tax_type};
      if(document.getElementById("iitem-total-txt")){document.getElementById("iitem-total-txt").value = total};
      if(document.getElementById("iitem-total-commission")){document.getElementById("iitem-total-commission").value = item_commission};
      if(document.getElementById("iitem-re-order-qty")){document.getElementById("iitem-re-order-qty").value = re_order_quantity};
      //if(document.getElementById("iitem-category")){document.getElementById("iitem-category").value = item_category};
      if(document.getElementById("iitem-barcode")){document.getElementById("iitem-barcode").value = item_barcode};
      if(document.getElementById("iitem-alias-barcode")){document.getElementById("iitem-alias-barcode").value = alias_barcode};
      if(document.getElementById("iitem-lowest-selling-price")){document.getElementById("iitem-lowest-selling-price").value = lowest_selling_price};
        
      
      },100);
      quantity = parseFloat(quantity);
      this.handleUpdateInventoryQuantity('add',quantity,item_id);
  
      if(document.querySelector(".bill-ret-type")){
        let cls_ret_type = document.querySelector(".bill-ret-type").getAttribute("data-id");
  
        //if(cls_ret_type==='invoice'){
          let cls = this.props.billing_type.replaceAll(" ",'-');
          if(document.querySelector(".sticky-add-item-panel."+cls)){
            document.querySelector(".sticky-add-item-panel."+cls).style.display = "block";
          }
          if(document.querySelector(".list-item-add-blk."+cls)){
            document.querySelector(".list-item-add-blk."+cls).style.display = "block";
          }
          if(document.querySelector(".add-item-mob."+cls)){
            document.querySelector(".add-item-mob."+cls).style.display = "block";
          }
          document.getElementById("iitem-box").setAttribute('disabled',true);
          document.getElementById("iitem-box").removeAttribute('tabindex');
          document.getElementById("iitem-quantity").focus();
        //}
  
        //let cls_ret_opt = document.querySelector(".bill-ret-option").getAttribute("data-id");
        
      }
  
    }

    handleListItemRemove(e){
      let index = e.currentTarget.getAttribute('data-id');
      
      let total_out_avl = 0;
      if(this.props.billing_type==='New Purchase'){
        total_out_avl = parseInt(document.getElementById("total-avl-out-hidden-"+index).value);

        if(total_out_avl>0){
            var element = document.getElementById('notice_box');
            let msg = "Oops! You can't delete this item";
            document.getElementById("notice_message").innerHTML = msg;
          
            element.classList.remove("fade");
            element.style.display="block";
          
        }
      }
      if(total_out_avl===0){
        let item_id = document.getElementById("item-name-"+index).value;
        let remove_item_id = document.getElementById("item-list-id-"+index).value;
        let remove_item_key = document.getElementById("vitem-item-key-"+index).value;
        let item_name = document.getElementById("item-box-"+index).value;
        let product_key = 'pk_'+item_name.replace(/\s+/g, "___");
        let pro_key = product_key+'_'+remove_item_key;
        if(item_id===""){
          item_id = 0;
        }
        if(remove_item_id===""){
          remove_item_id = 0;
        }
        let quantity = 0;
       
        let item_type = document.getElementById('item-type-'+index).value;
        if(item_type==='variable'){
          let qs = document.querySelectorAll("."+pro_key);
          for(let i=0;i<qs.length;i++){
            let did = qs[i].getAttribute('data-id');
            quantity = document.getElementById("item-quantity-"+did).value;
            remove_item_id = document.getElementById("item-list-id-"+did).value;
            if(remove_item_id!=="" && remove_item_id!=="0"){
              let item = {id:remove_item_id,quantity:quantity};
              remove_items.push(item);
            }
          }
        }else{
          quantity = document.getElementById("item-quantity-"+index).value;
          if(remove_item_id!=="" && remove_item_id!=="0"){
            let item = {id:remove_item_id,quantity:quantity};
            remove_items.push(item);
          }
        }

        document.getElementById("delete-row").value = JSON.stringify(remove_items);
        index = parseInt(index);
        let ikey = 0;
        for(let k=this.more_rows.length-1;k>=0;k--){
          if(this.more_rows[k]!==undefined){
            let ik = parseInt(this.more_rows[k].key);
            if(ik===index){
              ikey = ik;
            }
          }
        }



        if(item_type==='variable'){
          for(let k=this.more_rows.length-1;k>=0;k--){
            if(this.more_rows[k]!==undefined){
              let p_product = (this.more_rows[k]!==undefined && this.more_rows[k].props!==undefined && this.more_rows[k].props.items.product_key!==undefined)?this.more_rows[k].props.items.product_key:'';

              let p_rand_key = (this.more_rows[k]!==undefined && this.more_rows[k].props!==undefined && this.more_rows[k].props.items.p_rand_key!==undefined)?this.more_rows[k].props.items.p_rand_key:'';
              
              if(p_product===product_key && parseInt(remove_item_key)===parseInt(p_rand_key)){
                this.more_rows[k] = [];
                //this.more_rows.splice(k,1);
              }
            }
          }
        }else{
          this.more_rows[ikey] = [];
          //this.more_rows.splice(ikey,1);
        }


        this.setState({more_rows:this.more_rows});
        setTimeout(function(){
          _this.calculateTotal();
          //_this.calculateReturnTotal();
          let rm = document.querySelectorAll(".item-remove");
          if(rm.length===1){
            for(let i=0;i<rm.length;i++){
              //rm[i].style.display = 'none';
            }
          }else{
            for(let i=0;i<rm.length;i++){
              rm[i].style.display = 'block';
            }
          }
        },100);
        quantity = parseInt(quantity);
        this.handleUpdateInventoryQuantity('add',quantity,item_id);


        setTimeout(function(){
          _this.ADJUST_BILL_SNO('regular');
        },150);
      }
    }

    handleReturnListItemRemove(e){
      let index = e.currentTarget.getAttribute('data-id');
      
      let total_out_avl = 0;
      if(total_out_avl===0){
        let item_id = document.getElementById("ret-item-list-id-"+index).value;
        let item_name = document.getElementById("ret-item-box-"+index).value;
        let product_key = 'pk_'+item_name.replace(/\s+/g, "___");
        if(item_id===""){
          item_id = 0;
        }
        let quantity = document.getElementById("ret-item-quantity-"+index).value;
        let item = {id:item_id,quantity:quantity};
        remove_items.push(item);
        document.getElementById("delete-return-row").value = JSON.stringify(remove_items);
        index = parseInt(index);
        let ikey = 0;
        for(let k=this.return_more_rows.length-1;k>0;k--){
          if(this.return_more_rows[k]!==undefined){
            let ik = this.return_more_rows[k].props.index;
            if(ik===index){
              ikey = k;
            }
          }
          let p_product = '';
          p_product = (this.return_more_rows[k]!==undefined && this.return_more_rows[k].props!==undefined && this.return_more_rows[k].props.items.product_key!==undefined)?this.return_more_rows[k].props.items.product_key:'';
          let iname = (this.return_more_rows[k]!==undefined && this.return_more_rows[k].props!==undefined && this.return_more_rows[k].props.items.item_name!==undefined)?this.return_more_rows[k].props.items.item_name:'';
          if(p_product===product_key){
            //this.return_more_rows.splice(k,1);
            this.return_more_rows[k] = [];
          }
        }
        this.return_more_rows[ikey] = [];
        //this.return_more_rows.splice(ikey,1);

        if(document.getElementById("handleEnableReturnInfo")){
          document.getElementById("handleEnableReturnInfo").click();
        }
        this.setState({return_more_rows:this.return_more_rows});
        setTimeout(function(){
          _this.calculateReturnTotal();
          let rm = document.querySelectorAll(".ret-item-remove");
          if(rm.length===1){
            for(let i=0;i<rm.length;i++){
              //rm[i].style.display = 'none';
            }
          }else{
            for(let i=0;i<rm.length;i++){
              rm[i].style.display = 'block';
            }
          }
        },100);
        quantity = parseInt(quantity);
        this.handleUpdateInventoryQuantity('add',quantity,item_id);
      }
    }

    handleFinalCalculation(e){
        let id = e.currentTarget.getAttribute('data-id');
        let is_checked = 'excluding';
        if(document.getElementById("iitem-tax-inc")){
          is_checked = document.getElementById("iitem-tax-inc").value;
        }

        let cgst = document.getElementById("iitem-cgst").value;
        let sgst = document.getElementById("iitem-sgst").value;
        let igst = document.getElementById("iitem-igst").value;
        let quantity = document.getElementById("iitem-quantity").value.replace(/,/g , "");
        if(quantity===""){
          quantity = 1;
        }else{
          quantity = parseInt(quantity);
        }
        
        let price = e.currentTarget.value;
        if(price===""){
          price = 0;
        }else{
          price = parseFloat(price);
          price = price/quantity;
        }
        if(is_checked==='including'){  
        //let price = document.getElementById("price-hidden").value;

       /* if(price===""){
          price = document.getElementById("price").value;
          document.getElementById("price-hidden").value = price;
        }*/
        let discount = 0;
        if(document.getElementById("discount-hidden")){
          discount = document.getElementById("discount-hidden").value;
          if(discount===""){
            discount=0;
          }
          discount = parseFloat(discount);
        }

        
        let tax = 0;
        if((cgst!=="" && cgst!=="0")  && (sgst!=="" && sgst!=="0")){
          
          cgst = parseFloat(cgst);
          sgst = parseFloat(sgst);
          let total_cgst = 0;
          let total_sgst =0;
        
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + (sgst+cgst) ) ) );

          GST = price-GST;

          GST = parseFloat(this.floating_point(GST));

          let gross_amount = GST-(discount/quantity);
          
          let TOTAL_GST = price-GST;

          total_cgst = TOTAL_GST/2;
          total_sgst = TOTAL_GST/2;

          total_cgst = (quantity*total_sgst);
          total_sgst = (quantity*total_sgst);
          document.getElementById("iitem-cgst-total").value = this.floating_point(total_cgst);
          document.getElementById("iitem-sgst-total").value = this.floating_point(total_sgst);
          document.getElementById("price").value = this.floating_point(gross_amount);
          //price = (quantity*price)-discount;
          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          //document.getElementById("iitem-total-txt").value =this.floating_point(price);
        }else if(igst!=="" && igst!=="0"){
          
          igst = parseFloat(igst);
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + igst ) ) );
          
          GST = price-GST;
          GST = parseFloat(this.floating_point(GST));
        
          let gross_amount = GST-(discount/quantity);
         

          let TOTAL_GST = price-GST;
          let total_igst = TOTAL_GST;
          total_igst = (quantity*total_igst);
          document.getElementById("iitem-igst-total").value = this.floating_point(total_igst);
          document.getElementById("price").value = this.floating_point(gross_amount);
          //price = (quantity*price)-discount;
          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          //document.getElementById("iitem-total-txt").value =this.floating_point(price);
        } 
      }else{
          
         /* if(((cgst!=="" && cgst!=="0") && (sgst!=="" && sgst!=="0")) || (igst!=="" && igst!=="0")){
            
          document.getElementById("price").value = this.floating_point(price);
          
          price = parseFloat(price);

          let disc = 0;
          let total_discount=0;
          if(document.getElementById("discount-hidden")){
            disc = parseFloat(document.getElementById("discount").value);
            total_discount = (quantity*price)*disc/100;
            document.getElementById("discount-hidden").value = total_discount;
          }

          price = (quantity*price)-total_discount;
          let cgst = document.getElementById("iitem-cgst").value;
          let sgst = document.getElementById("iitem-sgst").value;
          let igst = document.getElementById("iitem-igst").value;

          let t_cgst = (price)*cgst/100;
          let t_sgst = (price)*sgst/100;
          let t_igst = (price)*igst/100;

          document.getElementById("iitem-cgst-total").value = t_cgst;
          document.getElementById("iitem-sgst-total").value = t_sgst;
          document.getElementById("iitem-igst-total").value = t_igst;
          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
        }*/
      }

      if(this.props.billing_type==="New Purchase" && id!=="price"){
        this.handleLSP(e);
      }
      //this.calculateTotal();
    }
   
    handleCalculationAfterMSPSelect = () =>{
      this.handleIncludeTaxCalculation(null,0);
    }

    handleIncludeTaxCalculation = (e,id)=>{
        let is_checked = document.getElementById("iitem-tax-inc").value;

        let cgst = document.getElementById("iitem-cgst").value;
        let sgst = document.getElementById("iitem-sgst").value;
        let igst = document.getElementById("iitem-igst").value;
        let quantity = document.getElementById("iitem-quantity").value.replace(/,/g , "");
        if(quantity===""){
          quantity = 1;
        }else{
          quantity = parseFloat(quantity);
        }
        
        if(is_checked==='including'){  
        let price = document.getElementById("price-hidden").value;
        if(price===""){
          price = document.getElementById("price").value;
          document.getElementById("price-hidden").value = price;
        }
        if(this.state.edit_item_selected!=='none'){
          price = document.getElementById("price-hidden").value;
          price = (price!==''?parseFloat(price):0);
        }
        let discount = 0;
        if(this.state.item_discount_on_mrp===false){
          if(document.getElementById("discount-hidden")){
            discount = document.getElementById("discount-hidden").value;
            if(discount===""){
              discount=0;
            }
            discount = parseFloat(discount);
          }
        }
         
        
        let tax = 0;
        if((cgst!=="" && cgst!=="0")  && (sgst!=="" && sgst!=="0")){
          
          cgst = parseFloat(cgst);
          sgst = parseFloat(sgst);
          let total_cgst = 0;
          let total_sgst =0;
        
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + (sgst+cgst) ) ) );

          GST = price-GST;

          GST = parseFloat(this.floating_point(GST));

          let gross_amount = GST-(discount/quantity);
          
          let TOTAL_GST = price-GST;

          total_cgst = TOTAL_GST/2;
          total_sgst = TOTAL_GST/2;

          total_cgst = (quantity*total_sgst);
          total_sgst = (quantity*total_sgst);

          total_cgst = (gross_amount*cgst/100)*quantity;
          total_sgst = (gross_amount*sgst/100)*quantity;

          document.getElementById("iitem-cgst-total").value = this.floating_point(total_cgst);
          document.getElementById("iitem-sgst-total").value = this.floating_point(total_sgst);
          document.getElementById("price").value = this.floating_point(gross_amount);
          price = (quantity*price)-discount;
          
          price = (quantity*gross_amount)+(total_cgst+total_sgst);
          if(this.state.item_discount_on_mrp===true){
            if(document.getElementById("discount-hidden")){
              discount = document.getElementById("discount-hidden").value;
              if(discount===""){
                discount=0;
              }
              discount = parseFloat(discount);
            }
            price = price - discount;
          }
          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          document.getElementById("iitem-total-txt").value =this.floating_point(price);
        }else if(igst!=="" && igst!=="0"){
          
          igst = parseFloat(igst);
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + igst ) ) );
          
          GST = price-GST;
          GST = parseFloat(this.floating_point(GST));
         
          let gross_amount = GST-(discount/quantity);
          let TOTAL_GST = price-GST;
          let total_igst = TOTAL_GST;
          total_igst = (quantity*total_igst);

          total_igst = (gross_amount*igst/100)*quantity;

          document.getElementById("iitem-igst-total").value = this.floating_point(total_igst);
          document.getElementById("price").value = this.floating_point(gross_amount);
          price = (quantity*gross_amount)+total_igst;
          if(this.state.item_discount_on_mrp===true){
            if(document.getElementById("discount-hidden")){
              discount = document.getElementById("discount-hidden").value;
              if(discount===""){
                discount=0;
              }
              discount = parseFloat(discount);
            }
            price = price - discount;
          }
          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          document.getElementById("iitem-total-txt").value =this.floating_point(price);
        }else{
          let price = document.getElementById("price-hidden").value;
          if(price===""){
            price = document.getElementById("price").value;
            document.getElementById("price-hidden").value = price;
          }
          price = price.replace(/,/g , "");
          
          document.getElementById("price").value = this.floating_point(price);
          
          price = parseFloat(price);

          let disc = 0;
          let total_discount=0;
          if(document.getElementById("discount-hidden")){
            disc = document.getElementById("discount").value;
            if(disc===""){
              disc = 0;
            }else{
              disc = parseFloat(disc);
            }
            total_discount = (quantity*price)*disc/100;
            //document.getElementById("discount-hidden").value = total_discount;
          }
          if(document.getElementById("sales_sp_disc_type")){
            let disc_type = document.getElementById("sales_sp_disc_type").value;
            if(disc_type==='rs'){
              total_discount = disc * quantity;
            } 
          }else if(document.getElementById("purchase_sp_disc_type")){
            let disc_type = document.getElementById("purchase_sp_disc_type").value;
            if(disc_type==='rs'){
              total_discount = disc * quantity;
            } 
          }
          price = (quantity*price)-total_discount;
          let cgst = document.getElementById("iitem-cgst").value;
          let sgst = document.getElementById("iitem-sgst").value;
          let igst = document.getElementById("iitem-igst").value;

          let t_cgst = (price)*cgst/100;
          let t_sgst = (price)*sgst/100;
          let t_igst = (price)*igst/100;

          
          document.getElementById("iitem-cgst-total").value = t_cgst;
          document.getElementById("iitem-sgst-total").value = t_sgst;
          document.getElementById("iitem-igst-total").value = t_igst;

          //price = price+t_cgst+t_sgst+t_igst;

          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          document.getElementById("iitem-total-txt").value = this.floating_point(price);
        } 
      }else{
          
        if(((cgst!=="" && cgst!=="0") && (sgst!=="" && sgst!=="0")) || (igst!=="" && igst!=="0")){
            
          let price = document.getElementById("price-hidden").value;
          if(price===""){
            price = document.getElementById("price").value;
            document.getElementById("price-hidden").value = price;
          }
          price = price.replace(/,/g , "");
          
          document.getElementById("price").value = this.floating_point(price);
          
          price = parseFloat(price);

          let disc = 0;
          let total_discount=0;
          if(document.getElementById("discount-hidden")){
            disc = document.getElementById("discount").value;
            if(disc===""){
              disc = 0;
            }else{
              disc = parseFloat(disc);
            }
            total_discount = (quantity*price)*disc/100;
            //document.getElementById("discount-hidden").value = total_discount;
          }
          if(document.getElementById("sales_sp_disc_type")){
            let disc_type = document.getElementById("sales_sp_disc_type").value;
            if(disc_type==='rs'){
              total_discount = disc * quantity;
            } 
          }else if(document.getElementById("purchase_sp_disc_type")){
            let disc_type = document.getElementById("purchase_sp_disc_type").value;
            if(disc_type==='rs'){
              total_discount = disc * quantity;
            } 
          }
          price = (quantity*price)-total_discount;
          let cgst = document.getElementById("iitem-cgst").value;
          let sgst = document.getElementById("iitem-sgst").value;
          let igst = document.getElementById("iitem-igst").value;

          let t_cgst = (price)*cgst/100;
          let t_sgst = (price)*sgst/100;
          let t_igst = (price)*igst/100;

          
          document.getElementById("iitem-cgst-total").value = t_cgst;
          document.getElementById("iitem-sgst-total").value = t_sgst;
          document.getElementById("iitem-igst-total").value = t_igst;

          //price = price+t_cgst+t_sgst+t_igst;

          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          document.getElementById("iitem-total-txt").value = this.floating_point(price);
        }
      }
    }

    handleTaxInclude(e){
      let id = e.currentTarget.getAttribute('data-id');
      let is_checked = e.currentTarget.value;
      this.handleIncludeTaxCalculation(e,id);
    }

    handleLSP(e){
      
      let losp = document.getElementById("iitem-lowest-selling-price").value;
     
      let item_name = document.getElementById("iitem-name").value;
      
      if(item_name==="" || item_name==="0"){
        let lospercent = parseInt(document.getElementById("lsp-percent").value);
        if(lospercent!==""){
          let pp = parseFloat(document.getElementById("price").value);
          losp = pp*lospercent/100;
          losp = pp+losp;
          document.getElementById("iitem-lowest-selling-price").value = this.floating_point(losp);
        }
      }
    }

    checkLowestSellingPrice(e){
      let id='';
      let thisid ='';
      let thisval='';
      if(this.state.on_off_msp===true){
        if(this.props.billing_type==='New Purchase'){
          thisval = document.getElementById("iitem-selling-price").value;
          thisid = 'iitem-selling-price';
          id = document.getElementById("iitem-selling-price").getAttribute('data-id');
        }else if(this.props.billing_type==='New Sale' || this.props.billing_type==='New Sales Return'){
          thisval = document.getElementById("price").value;
          thisid = 'price';
          id = document.getElementById("price").getAttribute('data-id');
        }

        if(document.getElementById("iitem-tax-inc")){

          let tax_type = document.getElementById("iitem-tax-inc").value;
          let quantity = document.getElementById("iitem-quantity").value;
          let total_amt = document.getElementById("iitem-total-txt").value;
          
          if(tax_type==='including' && quantity!=="" && total_amt!==""){
              thisval = parseFloat(total_amt)/parseInt(quantity);
          }

          if(thisval!==""){
            thisval = parseFloat(thisval);
            let lsp = document.getElementById("iitem-lowest-selling-price").value;
            
            if(lsp===""){
              if(document.getElementById("iitem-name")){
                let item_id = document.getElementById("iitem-name").value;
                if(item_id!==""){
                  item_id = parseInt(item_id);
                  let i_opt = JSON.parse(localStorage.getItem('item_options'));
                  lsp = (i_opt!==null && i_opt[item_id]!==null && i_opt[item_id]!==undefined && i_opt[item_id].lowest_selling_price!==undefined)?i_opt[item_id].lowest_selling_price:'';
                }
              }
            }
            
            lsp = parseFloat(lsp);
            
            if(thisval<lsp && this.state.is_checked_lsp===false){
              this.setState({is_checked_lsp:true});
              var element = document.getElementById('ok_cancel_poup');
              let msg = "You are entering selling price lesser than Lowest selling price. The Lowest selling price is "+lsp;
              document.getElementById("message_body").innerHTML = msg;
              
              localStorage.setItem("ok_cancel_txt",lsp);
              localStorage.setItem("target_id",thisid);
              localStorage.setItem("target_index",id);
              localStorage.setItem("target_referer",'check_lsp');
              
              document.getElementById("ok_cancel_txt").value = lsp;
              document.getElementById("target_id").value = thisid;
              document.getElementById("target_index").value = id;
              document.getElementById("target_referer").value = 'check_lsp';
              element.classList.remove("fade");
              element.style.display="block";
              document.getElementById("keep-button").innerHTML = "Keep "+lsp;
              document.getElementById("close-popup").innerHTML = "Keep "+thisval;
              setTimeout(function(){
                document.getElementById("close-popup").focus();
              },100);
              return false;
              //e.currentTarget.value = lsp;
            }else{
              return true;
            }
          }else{
            return true;
          }
        }else{
          return true;
        }
      }else{
        return true;
      }
      //this.handleCalculation(e);
    }
    handleRemoveRow(e){
      let id=e.currentTarget.id;
      let total_out_avl = 0;
      if(this.props.billing_type==='New Purchase'){
        total_out_avl = parseInt(document.getElementById("total-avl-out-hidden-"+id).value);

        if(total_out_avl>0){
            var element = document.getElementById('notice_box');
            let msg = "Oops! You can't delete this item";
            document.getElementById("notice_message").innerHTML = msg;
          
            element.classList.remove("fade");
            element.style.display="block";
          
        }
      }
      if(total_out_avl===0){
        
        let item_id = document.getElementById("iitem-name-"+id).value;
        let quantity = document.getElementById("iitem-quantity-"+id).value;
        let item = {id:item_id,quantity:quantity};
        remove_items.push(item);
        document.getElementById("delete-row").value = JSON.stringify(remove_items);
        document.getElementById("iitem-row-"+id).remove();
        let row = document.querySelectorAll(".items-row");
        if(row.length==1){
            let rm = document.querySelectorAll(".item-remove");
            for(let i=0;i<rm.length;i++){
              rm[i].style.display = 'none';
            }
        }
        this.calculateTotal();
        //this.calculateReturnTotal();
      }
    }
    handleCustomItemName(e){
      let idx = e.currentTarget.getAttribute('data_id');
      let thisval = e.currentTarget.value;
      document.getElementById("iitem-box").value = thisval;
    }
    componentWillReceiveProps = (nextProps) =>{

      if(this.state.active_tab!==nextProps.billing_type){
          this.setState({active_tab:nextProps.billing_type});
          this.more_rows = [];
          this.setState({more_rows:this.more_rows});
          let rm = document.querySelectorAll(".item-remove");
          for(let i=0;i<rm.length;i++){
            rm[i].style.display = 'block';
          }
          this.setState({is_new_item:0});
          setTimeout(function(){
            _this.calculateTotal();
            //_this.calculateReturnTotal();
          },100);
      }
    }

    handleHandleItemFields = (ps) =>{
     
      let sfield_hide = [];
      if(this.props.billing_type==='New Sale'){
        sfield_hide = document.querySelectorAll(".hide-opt-check");
      }else if(this.props.billing_type==='New Purchase'){
        sfield_hide = document.querySelectorAll(".hide-pur-opt-check");
      }
      for(let i=0;i<sfield_hide.length;i++){
          let this_element = sfield_hide[i];
          let check = this_element.getAttribute('data-id');
          if(ps!==undefined && check!==undefined){
            if(ps.indexOf(check)>=0){
                this_element.classList.add('hide');
                var inputNodes = this_element.getElementsByTagName('INPUT');
                for(var j = 0; j < inputNodes.length; ++j){
                      var inputNode = inputNodes[j];
                      var tabindex = inputNode.getAttribute('tabindex');
                      if(inputNode.classList.contains('hide') || inputNode.type==='hidden' || tabindex===null){}else{
                        inputNode.removeAttribute('tabindex');
                        inputNode.setAttribute('tindex',tabindex);
                      }
                }
                var inputNodes = this_element.getElementsByTagName('SELECT');
                for(var j = 0; j < inputNodes.length; ++j){
                      var inputNode = inputNodes[j];
                      var tabindex = inputNode.getAttribute('tabindex');
                      if(inputNode.classList.contains('hide') || tabindex===null){}else{
                        inputNode.removeAttribute('tabindex');
                        inputNode.setAttribute('tindex',tabindex);
                      }
                }
            }
          }
      }
    }

    handleToggleFieldVisibility = () =>{
      
      if(this.props.billing_type==='New Sale'){
        
        let ps = this.state.sale_field_hide;
        let _this = this;
        setTimeout(() => {
          let list_item_type = _this.state.list_item_type;
          
          if(list_item_type === 'product'){
            this.handleHandleItemFields(ps);
            /* let lifield_hide = document.querySelectorAll(".hide-item-list");
            for(let i=0;i<lifield_hide.length;i++){
                let this_element = lifield_hide[i];
                let check = this_element.getAttribute('data-id');
              
                if(ps.indexOf(check)>=0){
                    this_element.classList.add('hide');
                }
            } */
          }else{

            let sfield_hide = document.querySelectorAll(".hide-opt-check");
            for(let i=0;i<sfield_hide.length;i++){
                let this_element = sfield_hide[i];
                let check = this_element.getAttribute('data-id');
              
                if(ps.indexOf(check)>=0){
                    this_element.classList.remove('hide');
                    var inputNodes = this_element.getElementsByTagName('INPUT');
                    for(var j = 0; j < inputNodes.length; ++j){
                          var inputNode = inputNodes[j];
                          if(inputNode.classList.contains('hide') || inputNode.type==='hidden'){}else{
                            var tabindex = inputNode.getAttribute('tindex');
                            inputNode.setAttribute('tabindex',tabindex);
                          }
                    }
                    var inputNodes = this_element.getElementsByTagName('SELECT');
                    for(var j = 0; j < inputNodes.length; ++j){
                          var inputNode = inputNodes[j];
                          if(inputNode.classList.contains('hide')){}else{
                            var tabindex = inputNode.getAttribute('tindex');
                            inputNode.setAttribute('tabindex',tabindex);
                          }
                    }
                }
            }

           /*  let lifield_hide = document.querySelectorAll(".hide-item-list");
            
            for(let i=0;i<lifield_hide.length;i++){
                let this_element = lifield_hide[i];
                let check = this_element.getAttribute('data-id');
                
                if(ps.indexOf(check)>=0){
                    this_element.classList.remove('hide');
                }
            } */
          }
        }, 50);
      }else if(this.props.billing_type==='New Purchase'){
        
        let ps = this.state.purchase_field_hide;
        let _this = this;
        setTimeout(() => {
          let list_item_type = _this.state.list_item_type;
          
          if(list_item_type === 'single' || list_item_type==='variable'){
            this.handleHandleItemFields(ps);
          }else{

            let sfield_hide = document.querySelectorAll(".hide-pur-opt-check");
            for(let i=0;i<sfield_hide.length;i++){
                let this_element = sfield_hide[i];
                let check = this_element.getAttribute('data-id');
              
                if(ps.indexOf(check)>=0){
                    this_element.classList.remove('hide');
                    var inputNodes = this_element.getElementsByTagName('INPUT');
                    for(var j = 0; j < inputNodes.length; ++j){
                          var inputNode = inputNodes[j];
                          if(inputNode.classList.contains('hide') || inputNode.type==='hidden'){}else{
                            var tabindex = inputNode.getAttribute('tindex');
                            inputNode.setAttribute('tabindex',tabindex);
                          }
                    }
                    var inputNodes = this_element.getElementsByTagName('SELECT');
                    for(var j = 0; j < inputNodes.length; ++j){
                          var inputNode = inputNodes[j];
                          if(inputNode.classList.contains('hide')){}else{
                            var tabindex = inputNode.getAttribute('tindex');
                            inputNode.setAttribute('tabindex',tabindex);
                          }
                    }
                }
            }
          }
        }, 50);
      }
    }
    
    hanldeContinueWithExistingListItems = ()=>{
      let target = this.props.billing_type.replaceAll(' ','');
      let cached_billing = localStorage.getItem('unsaved_list_item_'+target);
      if(cached_billing){
        this.more_rows = [];
        let iitems = JSON.parse(cached_billing);
        let item_len = iitems.length;
        for(let i=0;i<item_len;i++){
          let list_item = iitems[i];
          let key = 'item-key-'+i;
          let props = list_item.props;
          let type = '';
          if(props.type!==undefined){
            type = props.type;
          }
          if(list_item.key!==undefined){
            key = list_item.key;
          }
          let index = props.index;
          let billing_type = props.billing_type;
          let items = props.items;
          this.more_rows.push(<BillingItemList count={item_len} type={type} key={key} index={index} billing_type={billing_type} items={items} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)}/>);
          if(items.custom_fields_json!==undefined && items.custom_fields_json.length>0){
            this.setState({is_custom_field:true});
          }
        }
        this.setState({more_rows:this.more_rows});
        let _this = this;
        setTimeout(function(){
          _this.calculateTotal();
          _this.ADJUST_BILL_SNO('regular');
          _this.ADJUST_BILL_SNO('return');
          document.querySelector('input[name="global_discount"]:checked').click();
        },100);
      }
    }

    componentDidMount(){
      var isMobile = this.isMobile();
      if(isMobile){
        this.setState({isMobile:true});
      }
        
        setTimeout(function(){
          if(localStorage.getItem('is_auto_generate_barcode')=="true"){
            auto_generate_barcode = 'Auto Generate';
            _this.setState({is_auto_generate_barcode:true})
              if(document.getElementById("iitem-barcode")){
                document.getElementById("iitem-barcode").setAttribute('disabled',true);
                document.getElementById("iitem-barcode").removeAttribute('tabindex');
              }
          } 
        },1500);
        if(this.props.variation_data){
         
          this.variant_data = this.props.variation_data;
        }
        this.variantOptions.push(<VariantOptions  variationList={this.state.variantList} removeVariationList={this.removeVariationList.bind(this)} label="Option 1" option="Size" index={1} updateState={this.updateVariationListState.bind(this)} updateOptionState={this.updateVariationOptionState.bind(this)}/>);
        this.variantPreviewList.push(<VariationPreviewList billing_type={this.props.billing_type}/>);

        this.setState({variantOptions:this.variantOptions,variantPreviewList:this.variantPreviewList});

       let more_row_len = document.getElementsByClassName("items-row").length;
       if(more_row_len==1){
          this.setState({key:1});
       }else{
          let next = more_row_len+1;
          this.setState({key:next});
       }
        let i_opt = localStorage.getItem('item_options');
        let purchase_i_opt = localStorage.getItem('purchase_item_options');
        
        //if(i_opt===null){
        /* let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              active_tab :this.props.billing_type,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/inventory/get_inventory_items';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            let item_options = [];
            let purchase_item_options = [];
            let store_item_options = [];
            let store_purchase_item_options = [];
            let variable_purchase_item_options = [];
            cursor.map( (value, key) => {
                let id = value.inventory_id;
                let stat_lbl = '';
                if(value.inventory_status==='Draft'){
                  stat_lbl=' (Inactive)';
                }
                let item_name = value.item_name+" - Qty:"+value.availability+stat_lbl;
                let static_item_name = value.item_name;

                let sku   = value.sku; 
                let total = value.total;
                let bundle_text= value.bundle_text;
                let barcode_name= value.barcode_name;
                let alias_barcode= value.alias_barcode;
                let btext = this.state.bundle_text;
                if(btext===''){
                  this.setState({bundle_text:bundle_text});
                }
                if(sku===null){
                  sku = "none";
                }
                if(barcode_name===null){
                  barcode_name = "none";
                }

                let custom_data = value.custom_fields_json;

                if(value.inventory_status==='Active'){
                  store_item_options[id] = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,has_variation:value.has_variation,is_variation:value.is_variation,age:value.age,supplier:value.supplier,is_more_barcode:value.is_more_barcode,parent_inventory_id:value.parent_inventory_id,wholesale_price:value.wholesale_price,custom_data:custom_data};
                }
                store_purchase_item_options[id] = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,has_variation:value.has_variation,is_variation:value.is_variation,age:value.age,supplier:value.supplier,is_more_barcode:value.is_more_barcode,parent_inventory_id:value.parent_inventory_id,wholesale_price:value.wholesale_price,custom_data:custom_data};

                if(value.has_variation===false){
                  if(value.inventory_status==='Active'){
                    item_options[id] = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,has_variation:value.has_variation,is_variation:value.is_variation,age:value.age,supplier:value.supplier,is_more_barcode:value.is_more_barcode,parent_inventory_id:value.parent_inventory_id,wholesale_price:value.wholesale_price,custom_data:custom_data};
                  }
                  purchase_item_options[id] = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,has_variation:value.has_variation,is_variation:value.is_variation,age:value.age,supplier:value.supplier,is_more_barcode:value.is_more_barcode,parent_inventory_id:value.parent_inventory_id,wholesale_price:value.wholesale_price,custom_data:custom_data};
                }

                if(value.is_variation===false){
                  variable_purchase_item_options[id] = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,has_variation:value.has_variation,is_variation:value.is_variation,age:value.age,supplier:value.supplier,is_more_barcode:value.is_more_barcode,parent_inventory_id:value.parent_inventory_id,wholesale_price:value.wholesale_price,custom_data:custom_data};
                }
            });
            localStorage.setItem('item_options',JSON.stringify(store_item_options));
            localStorage.setItem('purchase_item_options',JSON.stringify(store_purchase_item_options));
            this.setState({ 'item_options': item_options}); 
            this.setState({ 'purchase_item_options': purchase_item_options}); 
            this.setState({ 'variable_purchase_item_options': variable_purchase_item_options}); 
        }.bind(this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        }) */
      /* }else{  
          this.setState({ 'item_options': JSON.parse(i_opt) }); 
          this.setState({ 'purchase_item_options': JSON.parse(purchase_i_opt) }); 
       }*/
        if(this.props.list_items){
          this.more_rows = [];
          let iitems = this.props.list_items;
          let item_len = iitems.length;
          for(let i=0;i<item_len;i++){
            let items = iitems[i];

            let item_name = items.item_name;
            let product_name = items.product_name;
            let iiname = item_name;
            if(items.parent_id===0 || items.parent_id===null || items.parent_id===""){
              iiname = item_name;
            }
            items.item_name= iiname;
            items.pp_tax_type = items.is_tax_inc;
            let key = i;
            this.more_rows.push(<BillingItemList count={item_len} type="more" key={key} index={key} billing_type={this.props.billing_type} items={items} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)}/>);
            if(items.custom_fields_json!==undefined && items.custom_fields_json.length>0){
              this.setState({is_custom_field:true});
            }
          }
          this.setState({more_rows:this.more_rows});
        }

        if(this.props.return_list_items){
          this.return_more_rows = [];
          let iitems = this.props.return_list_items;
          let item_len = iitems.length;
          for(let i=0;i<item_len;i++){
            let items = iitems[i];

            let item_name = items.item_name;
            let product_name = items.product_name;
            let iiname = item_name;
            if(items.parent_id===0 || items.parent_id===null || items.parent_id===""){
              iiname = item_name;
            }
            items.item_name= iiname;
            items.pp_tax_type = items.is_tax_inc;
            let key = i+1;
            this.return_more_rows.push(<ReturnBillingItemList count={item_len} type="more" key={key} index={key} billing_type={this.props.billing_type} items={items} listItemEdit={this.handleReturnListItemEdit.bind(this)} listItemRemove={this.handleReturnListItemRemove.bind(this)}/>);
          }
          this.setState({return_more_rows:this.return_more_rows});
        }
        
        let ssend_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            access_level : user_info.access_level,
            uuid : utils.uuid(),
						role_id : user_info.access_level,
						token : user_info.token,
            _schema : user_info._schema,
            keys:"'allow_negative_inventory','barcode_name_on_the_bill_view','item_name_on_the_bill_view','regenerate_barcode_opt','sale_field_hide','on_off_msp','alias_barcode_while_billing','purchase_field_hide','send_e_invoice','sales_sp_disc_type','purchase_sp_disc_type','purchase_pp_disc_type','hsn_list','is_item_commission','item_commission','item_discount_on_mrp'",
        }
        let sapi = app_api+'/get_settings';
        axios.post(sapi, ssend_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.is_item_commission!==undefined){
              let is_item_commission = resp.is_item_commission;
              this.setState({is_item_commission:is_item_commission});
            }
            if(resp.item_discount_on_mrp!==undefined){
              let item_discount_on_mrp = resp.item_discount_on_mrp;
              this.setState({item_discount_on_mrp:item_discount_on_mrp});
            }
            if(resp.item_commission!==undefined){
              let item_commission = resp.item_commission;
              this.setState({item_commission:item_commission});
            }
            if(resp.allow_negative_inventory!==undefined){
              let ani = resp.allow_negative_inventory;
              this.setState({allow_negative_inventory:ani});
            }
            if(resp.send_e_invoice!==undefined){
              let si = resp.send_e_invoice;
              this.setState({send_e_invoice:si});
            }
            if(resp.hsn_list!==undefined){
              let hl = resp.hsn_list;
              let hsn_list_tmp = [];
              for (let index = 0; index < hl.length; index++) {
                const element = hl[index];
                let hsn = element.hsn;
                let tax_rate = element.tax_rate;
                /* if(hsn_list_tmp[hsn]===undefined){
                  hsn_list_tmp[hsn]=[];
                } */
                hsn_list_tmp[hsn]=tax_rate;
              }
              this.setState({hsn_list:hsn_list_tmp});
            }
            if(resp.on_off_msp!==undefined){
              let on_off_msp = resp.on_off_msp;
              this.setState({on_off_msp:on_off_msp});
            }
            if(resp.alias_barcode_while_billing!==undefined){
              let alias_barcode_while_billing = resp.alias_barcode_while_billing;
              this.setState({alias_barcode_while_billing:alias_barcode_while_billing});
            }
            if(resp.barcode_name_on_the_bill_view){
              let ps = resp.barcode_name_on_the_bill_view;
              this.setState({barcode_name_on_the_bill_view:ps});
            }
            if(resp.item_name_on_the_bill_view){
              let ps = resp.item_name_on_the_bill_view;
              this.setState({item_name_on_the_bill_view:ps});
            } 
            if(resp.regenerate_barcode_opt){
              let ps = resp.regenerate_barcode_opt;
              this.setState({regenerate_barcode_opt:ps});
            }
            if(this.props.billing_type==='New Sale'){
              if(resp.sale_field_hide){
                let ps = resp.sale_field_hide;
                this.setState({sale_field_hide:ps});
                this.handleHandleItemFields(ps);
              }
            }
            if(this.props.billing_type==='New Purchase'){
              if(resp.purchase_field_hide){
                let ps = resp.purchase_field_hide;
                this.setState({purchase_field_hide:ps});
                localStorage.setItem('purchase_field_hide',JSON.stringify(ps));
                this.handleHandleItemFields(ps);
              }
            }
            if(resp.sales_sp_disc_type){
              let ps = resp.sales_sp_disc_type;
              this.setState({sales_sp_disc_type:ps});
            }
            if(resp.purchase_sp_disc_type){
              let ps = resp.purchase_sp_disc_type;
              this.setState({purchase_sp_disc_type:ps});
            }
            if(resp.purchase_pp_disc_type){
              let ps = resp.purchase_pp_disc_type;
              this.setState({purchase_pp_disc_type:ps});
            }
            document.getElementById("new-item-form").classList.remove('hide');
        }.bind(this)).catch(function(res){
            
        })
    }
    calculateTotal(){
      var item_sub_total = document.querySelectorAll('.item-sub-total'); 
      var item_quantity = document.querySelectorAll('.item-quantity'); 

      let sub_total = 0;
      for (var i = 0; i < item_sub_total.length; i++) { 
          if(item_sub_total[i].value!=='' && item_sub_total[i].value!=="0"){
            let quan = 0;
            if(item_quantity[i].value!==''){
              quan = parseInt(item_quantity[i].value);
            }
            let i_s_total = (item_sub_total[i].value!=''?(item_sub_total[i].value):0);
            sub_total = sub_total + (quan*parseFloat(i_s_total));
          }
      } 
      
      let quantity = parseInt(document.getElementById("total-quantity").innerHTML.replace(/,/g , ""));
      document.getElementById("sub-total").innerHTML = this.currency_format(sub_total); 

      let item_tax = 0;
      let cgst_total =0;
      let sgst_total =0;
      let igst_total =0;
      var item_total_tax = document.querySelectorAll('.item-cgst-total'); 
      for (var i = 0; i < item_total_tax.length; i++) { 
           let did = item_total_tax[i].getAttribute('data-id');
           let cgst = item_total_tax[i].value;
           let sgst = document.getElementById("item-sgst-total-"+did).value;
           let igst = document.getElementById("item-igst-total-"+did).value;
          
           cgst = parseFloat((cgst!=''?(cgst):0));
           sgst = parseFloat((sgst!=''?(sgst):0));
           igst = parseFloat((igst!=''?(igst):0));
           let iigst = document.getElementById("item-igst-"+did).value;
           let isgst = document.getElementById("item-sgst-"+did).value;
           let icgst = document.getElementById("item-cgst-"+did).value;

           if(iigst==="" || iigst===0 || iigst==='0'){
            igst = 0;
           }
           if(isgst==="" || isgst===0 || isgst==='0'){
            sgst = 0;
           }
           if(icgst==="" || icgst===0 || icgst==='0'){
            cgst = 0;
           }
           cgst_total = cgst_total+cgst;
           sgst_total = sgst_total+sgst;
           igst_total = igst_total+igst;

           item_tax = item_tax + (cgst+sgst+igst);
      } 
      /* document.getElementById("total-cgst").innerHTML = this.currency_format(cgst_total);
      document.getElementById("total-sgst").innerHTML = this.currency_format(sgst_total);
      document.getElementById("total-igst").innerHTML = this.currency_format(igst_total); */
      document.getElementById("total-tax").innerHTML = this.currency_format(item_tax);
      document.getElementById("total-after-tax").innerHTML = this.currency_format(item_tax);

      var item_final_total = document.querySelectorAll('.item-total-txt'); 
      let item_total = 0;
      let total_unit = 0;
      for (var i = 0; i < item_final_total.length; i++) { 
            if(item_final_total[i].value!=='' && item_final_total[i].value!=='0'){
              total_unit++;
              item_total = item_total +item_final_total[i].value;
            }
      } 
      //document.getElementById("s-total").innerHTML = this.currency_format(item_total);
      
       let cont_disc = localStorage.getItem('discount');
       if(cont_disc==="" || cont_disc===null || cont_disc==='null'){
         cont_disc = 0;
       }else{
         cont_disc = parseInt(cont_disc);
       }


      var item_total_discount = document.querySelectorAll('.item-discount-hidden'); 
      let item_discount = 0;
      let i_discount = 0;
      for (var i = 0; i < item_total_discount.length; i++) { 
           let did = item_total_discount[i].getAttribute('data-id');
        
           let cgst = document.getElementById("item-cgst-total-"+did).value;
           let sgst = document.getElementById("item-sgst-total-"+did).value;
           let igst = document.getElementById("item-igst-total-"+did).value;
          
           cgst = parseFloat((cgst!=''?(cgst):0));
           sgst = parseFloat((sgst!=''?(sgst):0));
           igst = parseFloat((igst!=''?(igst):0));
           let iigst = document.getElementById("item-igst-"+did).value;
           let isgst = document.getElementById("item-sgst-"+did).value;
           let icgst = document.getElementById("item-cgst-"+did).value;

           if(iigst==="" || iigst===0 || iigst==='0'){
            igst = 0;
           }
           if(isgst==="" || isgst===0 || isgst==='0'){
            sgst = 0;
           }
           if(icgst==="" || icgst===0 || icgst==='0'){
            cgst = 0;
           }
        
          let titax = (cgst+sgst+igst);

          item_discount = item_discount + parseFloat((item_total_discount[i].value!==''?(item_total_discount[i].value):0));
          if(titax===0){
            i_discount = i_discount + parseFloat((item_total_discount[i].value!==''?(item_total_discount[i].value):0));
          }
           
      } 
      if(document.getElementById("total-discount")){
        document.getElementById("total-discount").innerHTML = this.currency_format(item_discount);
       
        if((this.props.billing_type==='New Purchase Return' || this.props.billing_type==='New Sales Return') && item_discount>0){
            document.getElementById("nsr-npr-discount").style.display = '';
        }
      }

       let discount_opt = document.querySelector('input[name="global_discount"]:checked').value;
       
       let discount_on_bill = (sub_total)-i_discount;
       
        let gdisc =0;
       if(discount_opt==="rs"){
           gdisc = (parseFloat(discount_on_bill)*cont_disc)/100;
          document.getElementById("global_discount").value = this.floating_point(gdisc);
          document.getElementById("global_discount_lbl").innerHTML = this.floating_point(gdisc);
          document.getElementById("disc_on_bill").innerHTML = '';
          document.getElementById("disc_on_bill_lbl").innerHTML = '';
         // item_total = parseFloat(discount_on_bill)-gdisc;
       }else if(discount_opt==="per"){
           gdisc = (parseFloat(discount_on_bill)*cont_disc)/100;
          document.getElementById("disc_on_bill").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+this.currency_format(gdisc)+'</span>';
          document.getElementById("disc_on_bill_lbl").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+this.currency_format(gdisc)+'</span>';
          document.getElementById("global_discount").value = this.floating_point(cont_disc);
          document.getElementById("global_discount_lbl").innerHTML = this.floating_point(cont_disc);
       }
      
       let total_before_tax = discount_on_bill-gdisc;
        if(this.props.billing_type==='New Purchase'){
          //total_before_tax = total_before_tax-item_discount;
        }
       document.getElementById("total-before-tax").innerHTML = this.currency_format(total_before_tax);

       let total_after_tax = total_before_tax+item_tax;
      document.getElementById("s-total").innerHTML = this.currency_format(total_after_tax);

      let after_round = Math.round(total_after_tax)-total_after_tax;
      
      if(after_round>=0){
        after_round = Math.ceil(total_after_tax)-total_after_tax;
        after_round = this.currency_format(after_round);
        document.getElementById("plus").click();
      }else{
        after_round = total_after_tax-Math.floor(total_after_tax);
        after_round = this.currency_format(after_round);
        document.getElementById("minus").click();
      }
      document.getElementById("round_off").value = after_round;
      document.getElementById("round_off_lbl").innerHTML = after_round;

      let round_off = document.getElementById("round_off").value;
      let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;

      
      if(round_off!==""){
          if(round_off_opt==='-'){
              item_total = parseFloat(total_after_tax)-parseFloat(round_off);
          }else if(round_off_opt==='+'){
              item_total = parseFloat(total_after_tax)+parseFloat(round_off);
          }
      }

      
      if( document.getElementById("before-redeem-total")){
        document.getElementById("before-redeem-total").innerHTML = this.currency_format(item_total);
      }

      if(document.getElementById("redeem_original_amount")){
        let redeem_original_amount = document.getElementById("redeem_original_amount").value;
        if(redeem_original_amount!=="" && redeem_original_amount!=="0"){
          redeem_original_amount = parseFloat(redeem_original_amount)
          if(redeem_original_amount>item_total){
            redeem_original_amount = item_total;
          }
          if(document.getElementById("redeem-total")){
            document.getElementById("redeem-total").innerHTML = this.currency_format(redeem_original_amount);
          }
        }
      }

      let redeem_total = 0;
      if(document.getElementById("redeem-total")){
        redeem_total = document.getElementById("redeem-total").innerHTML;
        redeem_total = redeem_total.replace(/,/g , "");
        redeem_total = parseFloat(redeem_total);
        if(this.props.billing_type==="New Sale"){
          item_total = item_total - redeem_total;
        }else if(this.props.billing_type==="New Purchase"){
          item_total = item_total - redeem_total;
        }
      }

      

      document.getElementById("top-final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total-text").value = this.floating_point(item_total);
      document.getElementById("total-unit").innerHTML = this.currency_format(total_unit);
     /* if(document.getElementById("total-cash-amount")){
        document.getElementById("total-cash-amount").value = item_total;
      }*/

      let tba = document.querySelectorAll('.total-bill-amount');
      for(let i=0;i<tba.length;i++){
        tba[i].value = '';
      }

      if(document.getElementById("total-bill-amount")){
        document.getElementById("total-bill-amount").innerHTML = this.currency_format(item_total);
      }
      if(document.getElementById("bill-pay-pop-title")){
        document.getElementById("bill-pay-pop-title").innerHTML = this.currency_format(item_total);
      }

      if(document.getElementById("total-paid-amount")){
        document.getElementById("total-paid-amount").innerHTML = 0;
      }
      if(document.getElementById("total-return-amount")){
        document.getElementById("total-return-amount").innerHTML = 0;
      }
      
      if(document.getElementById("handleChangePayBtnStatus")){
        document.getElementById("handleChangePayBtnStatus").click();
      }
       let quant_field = document.querySelectorAll(".item-quantity");
      let total_quantity = 0;
      
      for(let i=0;i<quant_field.length;i++){
          if(quant_field[i].value!==''){
            total_quantity = total_quantity+parseFloat(quant_field[i].value);
          }
      }
      
      document.getElementById("total-quantity").innerHTML = this.currency_format(total_quantity);
      document.getElementById("top-total-quantity").innerHTML = this.currency_format(total_quantity);
        
      document.querySelector('input[name="global_discount"]:checked').click();
    }
    calculateReturnTotal(){
      var item_sub_total = document.querySelectorAll('.ret-item-sub-total'); 
      var item_quantity = document.querySelectorAll('.ret-item-quantity'); 
      if(item_sub_total.length>0){
        let sub_total = 0;
        for (var i = 0; i < item_sub_total.length; i++) { 
            if(item_sub_total[i].value!==''){
              let quan = 0;
              if(item_quantity[i].value!==''){
                quan = parseFloat(item_quantity[i].value);
              }
              let i_s_total = (item_sub_total[i].value!=''?(item_sub_total[i].value):0);
              sub_total = sub_total + (quan*parseFloat(i_s_total));
            }
        } 
        document.getElementById("return-subtotal").innerHTML = this.currency_format(sub_total); 

        let item_tax = 0;
        let cgst_total =0;
        let sgst_total =0;
        let igst_total =0;
        var item_total_tax = document.querySelectorAll('.ret-item-cgst-total'); 
        for (var i = 0; i < item_total_tax.length; i++) { 
             let did = item_total_tax[i].getAttribute('data-id');
             let cgst = item_total_tax[i].value;
             let sgst = document.getElementById("ret-item-sgst-total-"+did).value;
             let igst = document.getElementById("ret-item-igst-total-"+did).value;
            
             cgst = parseFloat((cgst!=''?(cgst):0));
             sgst = parseFloat((sgst!=''?(sgst):0));
             igst = parseFloat((igst!=''?(igst):0));
             let iigst = document.getElementById("ret-item-igst-"+did).value;
             let isgst = document.getElementById("ret-item-sgst-"+did).value;
             let icgst = document.getElementById("ret-item-cgst-"+did).value;

             if(iigst==="" || iigst===0 || iigst==='0'){
              igst = 0;
             }
             if(isgst==="" || isgst===0 || isgst==='0'){
              sgst = 0;
             }
             if(icgst==="" || icgst===0 || icgst==='0'){
              cgst = 0;
             }
             cgst_total = cgst_total+cgst;
             sgst_total = sgst_total+sgst;
             igst_total = igst_total+igst;

             item_tax = item_tax + (cgst+sgst+igst);
        } 

        var item_final_total = document.querySelectorAll('.ret-item-total-txt'); 
        let item_total = 0;
        let total_unit = 0;
        for (var i = 0; i < item_final_total.length; i++) { 
              if(item_final_total[i].value!=='' && item_final_total[i].value!=='0'){
                total_unit++;
                item_total = item_total+parseFloat(item_final_total[i].value);
              }
        } 
    
        var item_total_discount = document.querySelectorAll('.ret-item-discount-hidden'); 
        let item_discount = 0;
        let i_discount = 0;
        for (var i = 0; i < item_total_discount.length; i++) { 
             let did = item_total_discount[i].getAttribute('data-id');
          
             let cgst = document.getElementById("ret-item-cgst-total-"+did).value;
             let sgst = document.getElementById("ret-item-sgst-total-"+did).value;
             let igst = document.getElementById("ret-item-igst-total-"+did).value;
            
             cgst = parseFloat((cgst!=''?(cgst):0));
             sgst = parseFloat((sgst!=''?(sgst):0));
             igst = parseFloat((igst!=''?(igst):0));
             let iigst = document.getElementById("ret-item-igst-"+did).value;
             let isgst = document.getElementById("ret-item-sgst-"+did).value;
             let icgst = document.getElementById("ret-item-cgst-"+did).value;

             if(iigst==="" || iigst===0 || iigst==='0'){
              igst = 0;
             }
             if(isgst==="" || isgst===0 || isgst==='0'){
              sgst = 0;
             }
             if(icgst==="" || icgst===0 || icgst==='0'){
              cgst = 0;
             }
          
            let titax = (cgst+sgst+igst);
        } 

       
        let grand_total = document.getElementById("final-total-text").value;
        let final_total = document.getElementById("total-before-return-txt").value;
        let birow = document.querySelectorAll(".billing-item-row");
        if(birow.length>0 && (final_total==="" || final_total==="0")){
          document.getElementById("total-before-return-txt").value = this.floating_point(grand_total);
          document.getElementById("total-before-return").innerHTML = this.currency_format(grand_total);
          final_total = grand_total;
        }

        if(final_total!=="" && final_total!=="0"){
          final_total = parseFloat(final_total)-item_total;
        }else{
          final_total = item_total;
          document.getElementById("total-before-return-txt").value = 0;
        }

        document.getElementById("return-total").innerHTML = this.currency_format(item_total);
        document.getElementById("final-return-total-text").value = this.floating_point(item_total);

        document.getElementById("top-final-total").innerHTML = this.currency_format(final_total);
        document.getElementById("final-total").innerHTML = this.currency_format(final_total);
        document.getElementById("final-total-text").value = this.floating_point(final_total);
        if(document.getElementById("total-bill-amount")){
          document.getElementById("total-bill-amount").innerHTML = this.currency_format(final_total);
        }
        if(document.getElementById("bill-pay-pop-title")){
          document.getElementById("bill-pay-pop-title").innerHTML = this.currency_format(final_total);
        }
        document.getElementById("return-total-unit").innerHTML = this.currency_format(total_unit);
    

        let quant_field = document.querySelectorAll(".ret-item-quantity");
        let total_quantity = 0;
        
        for(let i=0;i<quant_field.length;i++){
            if(quant_field[i].value!==''){
              total_quantity = total_quantity+parseFloat(quant_field[i].value);
            }
        }
        document.getElementById("return-total-quantity").innerHTML = this.currency_format(total_quantity);
      }else{
        if(document.getElementById("handleEnableReturnInfo")){
          document.getElementById("handleEnableReturnInfo").click();
        }
        document.querySelector('input[name="global_discount"]:checked').click();
      }
    }
    handleCalculation(e){
      
      if(document.getElementById("iitem-type")){
        let list_item_type = document.getElementById("iitem-type").value;
        if(list_item_type==='variable'){
          return false;
        }
      }
      let thisval = 0;
      let id = e.currentTarget.id;
      let id_temp = id;
      let data_target = e.currentTarget.getAttribute("data-target");
      let data_id = e.currentTarget.getAttribute('data-id');
      if(id==='handleCalculationOnTrigger'){
        if(document.getElementById("iitem-quantity")){
          thisval = document.getElementById("iitem-quantity").value;
          data_target="iitem-quantity";
          data_id = 0;
          id = "iitem-quantity";
        }
      }else{
        thisval = e.currentTarget.value;
      }
      if(data_target==='iitem-quantity'){

          let unit_name = 'Pcs';
          if(document.getElementById("iitem-unit")){
            let iiunit = document.getElementById("iitem-unit");
            if(iiunit.selectedIndex>=0){
              if(iiunit.options[iiunit.selectedIndex]!==undefined && iiunit.options[iiunit.selectedIndex]!==null){
                unit_name = iiunit.options[iiunit.selectedIndex].text;
              }else{
             
                unit_name = 'Pcs';
              }
            }else{
              unit_name = 'Pcs';
            }
          }
          if(unit_name.toLocaleLowerCase()==='pcs'){
            thisval = thisval.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
            if(id_temp!=='handleCalculationOnTrigger'){
              e.currentTarget.value = '';
              e.currentTarget.value = thisval;
              let item_select_on_fly = localStorage.getItem('item_select_on_fly');
              
              if(item_select_on_fly==='true' || e.keyCode===13 || e.keyCode===27){
                e.currentTarget.select();
                if(item_select_on_fly){
                  localStorage.removeItem('item_select_on_fly')
                }
              }
            }
          }
      }

      
      let contact_stat = localStorage.getItem('state');

      let item_id = document.getElementById("iitem-name").value;
      let avl =0;
      let av =0;
      let i_total_quan =parseFloat(thisval);

      let option_available = JSON.parse(localStorage.getItem("item_options"));
      let purchase_option_available = JSON.parse(localStorage.getItem("purchase_item_options"));
      
      if(data_target==='iitem-quantity' && (item_id!=="" && item_id!=="0")){
          item_id = parseInt(item_id);
          
          if(purchase_option_available!==null && purchase_option_available[item_id]!==undefined && purchase_option_available[item_id]!==null){
             avl = purchase_option_available[item_id].availability;
             av = purchase_option_available[item_id].old_available;

             let get_diff_barcode_inv_availability = utils.get_diff_barcode_inv_availability(this.props.billing_type, purchase_option_available[item_id]);
            if(get_diff_barcode_inv_availability>=0){
              avl = get_diff_barcode_inv_availability;
            }

            let old_available = av;
            let li_item_ids = [];
            let item_ids = document.querySelectorAll(".billing-item-row .iitem-ids");
            if(li_item_ids[item_id]===undefined){
              li_item_ids[item_id] = [];
              li_item_ids[item_id].push(parseInt(thisval));
            }
            for(let i=0;i<item_ids.length;i++){
                let iid = item_ids[i].value;
                let did = item_ids[i].getAttribute('data-id');
                
                let i_quantity = document.getElementById("item-quantity-"+did).value;

                if(li_item_ids[iid]===undefined){
                  li_item_ids[iid] = [];
                }
                if(i_quantity!==""){
                  i_quantity = parseFloat(i_quantity);
                  li_item_ids[iid].push(i_quantity);
                }
            }
            

            let item_available = li_item_ids[item_id];
            
            av = parseInt(av);
            
            document.getElementById("current-available").value = av;
            thisval = parseInt(thisval);
            if(item_available!==undefined){
              for(let i=0;i<item_available.length;i++){
                //i_total_quan = i_total_quan+item_available[i];
              }
             /* if(this.state.is_new_item!==thisval){
                this.setState({is_new_item:thisval});
                let availability = old_available-i_total_quan;
               
                if(option_available!==null && option_available[item_id]!==undefined){
                  option_available[item_id].availability = availability;
                }
                purchase_option_available[item_id].availability = availability;
                let static_item_name = purchase_option_available[item_id].static_item_name;
                let inventory_status = purchase_option_available[item_id].inventory_status;
                let stat_lbl = '';
                if(inventory_status==='Draft'){
                  stat_lbl=' (Inactive)';
                }
                let new_item_name = static_item_name+" - Qty:"+availability+stat_lbl;
                purchase_option_available[item_id].value2 = new_item_name;
                if(option_available[item_id]!==undefined){
                  option_available[item_id].value2 = new_item_name;
                  localStorage.setItem("item_options",JSON.stringify(option_available));
                  this.setState({ 'item_options': option_available});
                }
                localStorage.setItem("purchase_item_options",JSON.stringify(purchase_option_available));
                this.setState({ 'purchase_item_options': purchase_option_available}); 
              }*/
            }
          }
          av = document.getElementById("current-available").value;
          av = parseFloat(avl);
          let item_type = '';
          if(document.getElementById("iitem-type")){
            item_type = document.getElementById("iitem-type").value;
          }
          if(purchase_option_available!==null && i_total_quan>av && this.state.allow_negative_inventory===false){
            if(this.props.billing_type==='New Sale' && item_type!=='sales_return' && this.state.edit_item_active_index==='none'){
              var element = document.getElementById('notice_box');
              let msg = "You are entering quantity more than availability, total availability is "+avl;
              document.getElementById("notice_message").innerHTML = msg;
              //document.getElementById("iitem-quantity").value = 0;
              element.classList.remove("fade");
              element.style.display="block";
              document.getElementById("item_availabe_hidden").value = avl;
              document.getElementById("item_index_hidden").value = data_id;
              document.getElementById("close-notice").focus();
            }
          }
      }


      let quantity = 1;
      if(document.getElementById("iitem-quantity")){
        quantity = document.getElementById("iitem-quantity").value;
        quantity = (quantity!==''?parseFloat(quantity):1);
        
      }
      
      let bundle = 0;
      if(document.getElementById("iitem-bundle-quantity")){
        bundle = document.getElementById("iitem-bundle-quantity").value;
      }

      let quan = quantity;
      let unitname = '';
      if(document.getElementById("iitem-unit-name")){
        unitname = document.getElementById("iitem-unit-name").value;
        unitname = unitname.toLowerCase();
      }
      let unit_elem = '';
      let uval = '';
      if(document.getElementById("iitem-unit")){
        unit_elem = document.getElementById("iitem-unit")
        uval = unit_elem.value;
      }
      let un = '';
      if(uval!==""){
          let unit_name = unit_elem.options[unit_elem.selectedIndex].text;
          un = unit_name.toLowerCase();
      }else{
          un = 'pcs';
      }
      if(unitname===""){
        unitname = 'dzn';
      }

      if(unitname==='pcs' && un==='dzn'){
        quan = quan*12;
      }

      if(unitname==='dzn' && un==='pcs'){
        if(bundle>0){
           bundle = bundle*12;
        }
      }

      let bundle_quantity = 0;
      
      if(bundle<=quan && bundle>0){
          bundle_quantity = quan/bundle;
          if(!Number.isInteger(bundle_quantity)){
              bundle_quantity = 0;
          }
      }

      let bundle_text = '';
      if(document.getElementById("iitem-bundle-text")){
        bundle_text = document.getElementById("iitem-bundle-text").value;
      }

      if(bundle_quantity>0){
        if(document.getElementById('bundle_quantity')){
          document.getElementById('bundle_quantity').innerHTML = bundle_quantity+' '+bundle_text;
        }
      }else{
        if(document.getElementById('bundle_quantity')){
          document.getElementById('bundle_quantity').innerHTML = '';
        }
      }
      let price = 0;
      if(document.getElementById("price")){
        price = document.getElementById("price").value;
        price = (price!==''?parseFloat(price):0);
      }

      if(id==="price"){
        document.getElementById("price-hidden").value = e.currentTarget.value;
      }

      if(this.state.edit_item_selected!=='none'){
        if(document.getElementById("price-hidden")){
          price = document.getElementById("price-hidden").value;
          price = (price!==''?parseFloat(price):0);
        }
      }
      let hidden_price = 0;
      if(document.getElementById("price-hidden")){

        hidden_price = document.getElementById("price-hidden").value;
      }
      let discount = 0;
      if(document.getElementById("discount")){
        discount = document.getElementById("discount").value;
        if(discount===""){
          discount = 0;
        }
      }
      
      let pp_tax = 0;
      if(document.getElementById("iitem-gst")){
        pp_tax = document.getElementById("iitem-gst").value.replace('GST','');
        pp_tax = pp_tax.replace('%','');
        document.getElementById("iitem-gst").value = pp_tax.trim();
        pp_tax = parseFloat(pp_tax);
      }
      
       let igst = 0;
       let sgst = 0;
       let cgst = 0;
      if(pp_tax>0){
       if(contact_stat===""){
          cgst = pp_tax/2;
          sgst = pp_tax/2;
          igst = 0;
       }else if(contact_stat!==user_info.state){
          cgst = 0;
          sgst = 0;
          igst = pp_tax;
       }else if(contact_stat===user_info.state){
          cgst = pp_tax/2;
          sgst = pp_tax/2;
          igst = 0;
       }
     }else{
        cgst = 0;
        sgst = 0;
        igst = 0;
     }
    if(document.getElementById("iitem-sgst")){
      document.getElementById("iitem-sgst").value = sgst;
      document.getElementById("iitem-sgst-hidden").value = sgst;
    }
    if(document.getElementById("iitem-cgst")){
      document.getElementById("iitem-cgst").value = cgst;
      document.getElementById("iitem-cgst-hidden").value = cgst;
    }
    if(document.getElementById("iitem-igst")){
      document.getElementById("iitem-igst").value = igst;
      document.getElementById("iitem-igst-hidden").value = igst;
    }

      if(document.getElementById("iitem-igst")){
        igst = document.getElementById("iitem-igst").value;
        igst = (igst!=='')?parseInt(igst):0;
      }      

      
      if(document.getElementById("iitem-sgst")){
        sgst = document.getElementById("iitem-sgst").value;
        sgst = (sgst!=='')?parseInt(sgst):0;
      }      

      if(document.getElementById("iitem-cgst")){
        cgst = document.getElementById("iitem-cgst").value;
        cgst = (cgst!=='')?parseInt(cgst):0;
      }      
      let tax = cgst+sgst+igst;
      
      let cal_discount = ((hidden_price*quantity)*discount)/100;
      
      if(document.getElementById("sales_sp_disc_type")){
        let disc_type = document.getElementById("sales_sp_disc_type").value;
        if(disc_type==='rs'){
          cal_discount = discount * quantity;
        } 
      }else if(document.getElementById("purchase_sp_disc_type")){
        let disc_type = document.getElementById("purchase_sp_disc_type").value;
        if(disc_type==='rs'){
          cal_discount = discount * quantity;
        } 
      }
      
      
      
      let item_grand_total = (price*quantity)-cal_discount;
      let cal_tax = (item_grand_total*tax)/100;
      let cgst_tax = (item_grand_total*cgst)/100;
      let sgst_tax = (item_grand_total*sgst)/100;
      let igst_tax = (item_grand_total*igst)/100;
      document.getElementById("iitem-igst-total").value = this.floating_point(igst_tax);
      document.getElementById("iitem-sgst-total").value = this.floating_point(sgst_tax);
      document.getElementById("iitem-cgst-total").value = this.floating_point(cgst_tax);

      if(document.getElementById("discount")){
        document.getElementById("discount-hidden").value = this.floating_point(cal_discount);
      }
      
      let final_amount = item_grand_total+cal_tax;
     
      if(this.state.is_item_commission===true){
        let item_commission = this.state.item_commission;
        if(item_commission>0){
          let cal_commission = (final_amount*item_commission)/100;
          if(document.getElementById("iitem-total-commission")){
            let icomission = document.getElementById("iitem-total-commission").value;
            if(icomission===""){
              document.getElementById("iitem-total-commission").value = this.floating_point(cal_commission);
            }else{
              let commission_type = localStorage.getItem('commission_type');
              if(commission_type==='per'){
                localStorage.removeItem('commission_type');
                icomission = parseFloat(icomission);
                let cal_commission = (final_amount*icomission)/100;
                document.getElementById("iitem-total-commission").value = this.floating_point(cal_commission);
              }
            }
          }
        }
      }

      document.getElementById("iitem-total").innerHTML = this.currency_format(final_amount);
      document.getElementById("iitem-total-txt").value = this.floating_point(final_amount);

      if(data_target==='discount'){
        let lsp = document.getElementById("iitem-lowest-selling-price").value;
      }

      let item_name = document.getElementById("iitem-name").value;
      if((id==='iitem-total-txt' || id==='iitem-gst' || id==='discount' || id==='iitem-quantity') && (this.props.billing_type==="New Purchase" || this.props.billing_type==="New Sale" || this.props.billing_type==="New Sales Return" || this.props.billing_type==="New Purchase Return")){
        //if(this.state.list_item_type!=='sales_return' && this.state.list_item_type!=='purchase_return'){
          this.handleIncludeTaxCalculation(e,data_id);
        //}
      }

      if(this.props.billing_type==="New Purchase" && id!=="price"){
        this.handleLSP(e);
      }
    }
    handleCount(e){
      var item_rows = document.querySelectorAll('.item-name-field'); 
        let number_of_item = 0;
        for (var i = 0; i < item_rows.length; i++) { 
            let i_val = item_rows[i].value;
            if(i_val!=''){
              number_of_item++;
            }
        } 
        document.getElementById("total-unit").innerHTML = number_of_item;

        let quant_field = document.querySelectorAll(".item-quantity");
        let total_quantity = 0;
        for(let i=0;i<quant_field.length;i++){
            if(quant_field[i].value!==''){
              total_quantity = total_quantity+parseInt(quant_field[i].value);
            }
        }

        document.getElementById("total-quantity").innerHTML = this.currency_format(total_quantity);
        document.getElementById("top-total-quantity").innerHTML = this.currency_format(total_quantity);
    }

    handleBarcodeFocus = (e) =>{
      e.currentTarget.select();
    }

    handleSetDefaultCategory = e =>{
      let item_category_name = localStorage.getItem('item_category_name');
      let item_category = localStorage.getItem('item_category');
      this.setState({defaultCategory:item_category_name});
      this.setState({defaultCategoryID:item_category});
      localStorage.removeItem('item_category_name');
      localStorage.removeItem('item_category');
    }

    handleCheckMSPDiscount = (e) =>{
      
      if(document.getElementById("iitem-type")){
        let list_item_type = document.getElementById("iitem-type").value;
        if(this.state.on_off_msp===true){
          if(this.props.billing_type==='New Sale' && list_item_type==='product'){
            let quantity = document.getElementById("iitem-quantity").value;
            let item_total = document.getElementById("iitem-total-txt").value;
            let msp = document.getElementById("iitem-lowest-selling-price").value;
            if(item_total!=="" && msp!=="" && quantity!==""){
              item_total = Math.ceil(parseFloat(item_total));
              msp = parseFloat(msp);
              quantity = parseFloat(quantity);
              let item_value = item_total/quantity;
        
              if(item_value<msp){
                var element = document.getElementById('notice_box');
                let msg = "Oops ! Selling price is lesser than Lowest selling price. The Lowest selling price is "+msp+". Please try adjusting discount for this item";
                document.getElementById("notice_message").innerHTML = msg;
                document.getElementById("item_target").value = 'check_discount';
                element.classList.remove("fade");
                element.style.display="block";
                setTimeout(() => {
                  document.getElementById("close-notice").focus();
                }, 500);
                return false;
              }else{
                return true;
              }
            }else{
              return true;
            }
          }else{
            return true;
          }
        }else{
          return true;
        }
      }else{
        return true;
      }
    }

    handleAutoCompleteOptions = (part) => {
     this.setState({auto_opt_value:part});
    }
    handleResetAutoDescValue = (part) => {
      this.setState({auto_opt_value:part});
    }
    handleClearItemList = ()=>{
      this.more_rows = [];
      this.setState({more_rows:this.more_rows});
    }
    handleCheckHSN = () =>{
      let is_hsn = true;
      if(this.props.billing_type==='New Purchase'){
        if(document.getElementById("iitem-hsn")){
          let thisval = document.getElementById("iitem-hsn").value;
          if(thisval!="" && thisval.length<6){
            is_hsn = false;
            toast("Oops! HSN must be 6 character or more.");
            setTimeout(() => {
              document.getElementById("iitem-hsn").focus();
            }, 100);
          }
        }
      }
      return is_hsn;
    }

    handleChangeHSN = (e) =>{
      let hsn_list = this.state.hsn_list;
      let hsn = e.currentTarget.value;
      let tax_rate = '';
      if(hsn_list[hsn]!==undefined){
        tax_rate = hsn_list[hsn];
      }
     
      let iitem_gst = '';
      if(document.getElementById("iitem-gst")){
        iitem_gst = document.getElementById("iitem-gst").value;
        if(iitem_gst==='' && tax_rate!==''){
          document.getElementById("iitem-gst").value = tax_rate;
        }
      }
      if(document.getElementById("iitem-sp-gst")){
        iitem_gst = document.getElementById("iitem-sp-gst").value;
        if(iitem_gst==='' && tax_rate!==''){
          document.getElementById("iitem-sp-gst").value = tax_rate;
        }
      }
    }
    render() {
        let isSticky = '';
        let isActionButton = '';
        let abfs = '';
        if(this.state.isMobile===false){
            isSticky = 'sticky-add-item-panel '+this.props.billing_type.replaceAll(" ",'-')+" "+this.state.list_item_type;
            isActionButton = 'action-button';
            abfs = 'abfs';
        }
        if(localStorage.getItem('is_auto_generate_barcode')=="true"){
          auto_generate_barcode = 'Auto Generate';
        }
        let is_change_price = true;
        if(this.is_access('billing-stop-editing-price')){
          is_change_price = false;
        }

        return (
          <>
          <input className="hide" type="text" id="deleted_var_items" />
          <label onClick={this.addMoreItem} id="add-item-to-list" className="hide"></label>
          <label onClick={this.hanldeSetItemDescription} id="hanldeSetItemDescription" className="hide"></label>
          <label onClick={this.handleClearItemList} id="handleClearItemList" className="hide"></label>
          <label onClick={this.handleSetDefaultCategory} id="handleSetDefaultCategory" className="hide"></label>
          <label onClick={this.hanldeContinueWithExistingListItems} id="hanldeContinueWithExistingListItems" className="hide"></label>
          <label onClick={this.handleCalculation} id="handleCalculationOnTrigger" data-target="trig-quantity"  target="trig-quantity"  className="hide"></label>
          <form id="new-item-form" className="hide">
          <div className={"main-content border-bottom "+isSticky}>
            <div className={"inner-content list-item-add-blk "+this.props.billing_type.replaceAll(" ",'-')} id="list-item-add-blk">
              <div className="row">
               
                {(this.props.billing_type==='New Purchase' || this.props.billing_type==='New Sale') &&
                  <div className={"col-md-2 "+abfs}>
                    <span className="fe-title">ITEM TYPE</span>
                    <select className="form-control" id={"iitem-type"} onChange={this.handleChangeItemType} defaultValue={this.state.list_item_type}>
                      {(this.props.billing_type==='New Purchase') &&
                      <>
                        <option value="single">Single Product</option>
                        <option value="variable">Variable Product</option>
                        {(this.state.send_e_invoice===false) &&
                          <option value="purchase_return">Purchase Return</option>
                        }
                      </>
                      }
                      {(this.props.billing_type==='New Sale') &&
                      <>
                        <option value="product">Inventory Item</option>
                        <option value="general">General</option>
                        {(this.state.send_e_invoice===false) &&
                          <option value="sales_return">Exchange/Sales Return</option>
                        }
                      </>
                      }
                    </select>
                  </div>
                }



                  <div className={"col-md-2 "+abfs}>
                    <span className="fe-title">ITEM NAME</span>
                    {(this.state.list_item_type==='general')?
                      <div className="search">
                        <input type="text" className="search-box form-control" placeholder="" data_id="1" id="iitem-box" autocomplete="off" target="iitem-box" tabindex="11" />
                          <input type="text" id="iitem-name" className="hide item-ids" data-id="1" value="0" />
                          <input type="text" id="old-iitem-name" className="hide" value="0" />
                      </div>
                      :
                      <>
                    <Autocomplete
                      options={((this.props.billing_type==='New Purchase' && this.state.list_item_type!=='variable') || this.props.billing_type==='New Purchase Return')?this.state.purchase_item_options:(this.props.billing_type==='New Purchase' && this.state.list_item_type==='variable')?this.state.variable_purchase_item_options:this.state.item_options}
                     
                      data_target="billing"
                      title="Start typing SKU or name..."  
                      id={"iitem-name"}
                      className="auto-comp-opt"
                      data_id={this.props.index}
                      active_tab={this.props.billing_type}
                      input_id={"iitem-box"}
                      item_info={this.props.items}
                      target="iitem-box"
                      tabindex={'1'+this.props.index}
                      position='top'
                    />
                    <p className="error" id="no-item-found-error"></p>
                    </>
                  }
                    <i className="iitem-desc-txt hide" id={"iitem-desc-txt"}></i>
                    {(this.state.list_item_type==='variable') &&
                      <input type="hidden" className="form-control iitem-barcode " id={"iitem-barcode"} data-id={this.props.index}  data-target="iitem-barcode"/>
                    }

                  </div>
                  {(this.state.list_item_type!=='sales_return' && this.state.list_item_type!=='purchase_return') &&
                  <>
                  {(this.props.billing_type==='New Purchase') &&
                  <div className="col-md-1 w-50 hide-pur-opt-check mrbtm-10 abfs" data-id="sdesc">
                    <span className="fe-title">DESCRIPTION</span>
                    {(this.props.billing_type==='New Purchase') &&
                    <>
                      <TextField Component="input" type="text" trigger={['']} onRequestOptions={this.handleAutoCompleteOptions} options={this.state.auto_options} className="form-control" placeholder="" data-id={this.props.index} id={"iitem-desc"}  target="iitem-desc" tabindex={'4'+this.props.index}  name="iitem-desc" value={this.state.auto_opt_value} onChange={this.handleResetAutoDescValue} />
                      </>
                    }
                    {(this.props.billing_type!=='New Purchase') &&
                      <input type="text" className="form-control" placeholder="" data-id={this.props.index} id={"iitem-desc"}  target="iitem-desc"/>
                    }
                    <input type="text" className="hide" data-id={this.props.index} id={"iitem-list-id"}  target="iitem-list-id"/>
                  
                  </div> 
                  }
                  {(this.props.billing_type==='New Sale') &&
                  <div className="col-md-1 w-50 abfs hide-opt-check" data-id="sdesc">
                    <span className="fe-title">DESCRIPTION</span>
                    
                    <input type="text" className="form-control" placeholder="" data-id={this.props.index} id={"iitem-desc"}  target="iitem-desc" tabindex={'4'+this.props.index}  name="iitem-desc"/>
                     
                    <input type="text" className="hide" data-id={this.props.index} id={"iitem-list-id"}  target="iitem-list-id"/>
                  
                  </div> 
                  }
                  {(this.props.billing_type!=='New Purchase' && this.props.billing_type!=='New Sale') &&
                  <div className="mrbtm-10 hide"  data-id="sdesc">
                    <span className="fe-title">DESCRIPTION</span>
                    <input type="text" className="form-control" placeholder="" data-id={this.props.index} id={"iitem-desc"}  target="iitem-desc"/>
                    <input type="text" className="hide" data-id={this.props.index} id={"iitem-list-id"}  target="iitem-list-id"/>
                  </div> 
                  }
                  {(this.state.list_item_type!=='variable') &&
                  <>
                  <div className={(this.props.billing_type==='New Purchase')?"col-md-1 hide-pur-opt-check w-50 mrbtm-10 "+abfs:(this.props.billing_type==='New Sale')?"col-md-1 w-50 mrbtm-10 hide-opt-check "+abfs:"col-md-1 w-50 mrbtm-10 abfs"} data-id="shsn">
                    <span className="fe-title">HSN/SAC</span>
                    {((this.props.billing_type==='New Purchase' || this.props.billing_type==='New Sale')) &&
                     /*  <input type="text" className="form-control" placeholder="" id={"iitem-hsn"} data-id={this.props.index}  target="iitem-hsn" tabindex={'5'+this.props.index} onBlur={this.handleCheckHSN}/> */
                      <>
                      <input list="hsn_lists" name="hsn_list" id="iitem-hsn"  className="form-control" placeholder="" data-id={this.props.index}  target="iitem-hsn" tabindex={'5'+this.props.index} onBlur={this.handleCheckHSN}  onChange={this.handleChangeHSN}/>
                      <datalist id="hsn_lists">
                        {(this.state.hsn_list.length>0) &&
                          <>
                            {(this.state.hsn_list.map((value,index)=>{
                                return (<option value={index} />)
                            }))}
                          </>
                        }
                      </datalist>
                      </>

                    }
                    {(this.props.billing_type!=='New Purchase' && this.props.billing_type!=='New Sale') &&
                     /*  <input type="text" className="form-control" placeholder="" id={"iitem-hsn"} data-id={this.props.index}  target="iitem-hsn"/> */
                      <>
                        <input list="hsn_lists" name="hsn_list" id="iitem-hsn"  className="form-control" placeholder="" data-id={this.props.index}  target="iitem-hsn" tabindex={'5'+this.props.index} onBlur={this.handleCheckHSN}  onChange={this.handleChangeHSN}/>
                        <datalist id="hsn_lists">
                          {(this.state.hsn_list.length>0) &&
                            <>
                              {(this.state.hsn_list.map((value,index)=>{
                                  return (<option value={index} />)
                              }))}
                            </>
                          }
                        </datalist>
                        </>
                    }
                  </div> 
                  <div className={"col-md-1 w-50 "+abfs}>
                    <span className="fe-title">QTY.</span>
                    <input type="text" className="hide" data-id={this.props.index} id={"iitem-bundle-quantity"} data-target="iitem-bundle-quantity" target="iitem-bundle-quantity"/>

                    <input type="text" className="hide form-control iitem-purchase-price" placeholder=""  id={"iitem-purchase-price"} data-target="iitem-purchase-price"/>

                    <input type="text" className="hide form-control" id={"price-hidden"} data-id={this.props.index} data-target="iprice-hidden"target="price-hidden"/>
                    
                    <input type="text" className="hide" data-id={this.props.index} id={"iitem-bundle-text"} data-target="iitem-bundle-text" target="iitem-bundle-text" />

                    <input type="text" className="form-control iitem-quantity " id={"iitem-quantity"} placeholder="" onKeyUp={this.handleCalculation} onFocus={this.handleCalculation}  data-id={this.props.index} data-target="iitem-quantity"  target="iitem-quantity" tabindex={'6'+this.props.index}/>
                    <input type="text" className="hide" id={"current-available"}/>
                    {(this.props.items && this.props.items.bundle_quantity>0)?
                      <p className="bundle_quantity" id={"bundle_quantity"} >{this.props.items.bundle_quantity} {this.state.bundle_text}</p>
                    :<p className="bundle_quantity" id={"bundle_quantity"}></p>
                    }
                  </div>
                  {(this.state.list_item_type !=='general') && 
                  <div className={"col-md-1 w-50 hide-opt-check hide-pur-opt-check "+abfs}  data-id="sunit">
                    <span className="fe-title">UNIT</span>
                    <input type="text" className="hide form-control iitem-unit-name" placeholder=""  id={"iitem-unit-name"} data-id={this.props.index} data-target="iitem-unit-name" />
                    <ProductUnits show="dropdown" data_id={this.props.index} id={'iitem-unit'} default={(this.props.items && this.props.items.unit_id)?this.props.items.unit_id:''} target="iitem-unit" tabindex={'7'+this.props.index}/ >
                  </div>
                  }
                  {this.props.billing_type!=='New Purchase' &&
                  <>
                     <input type="text" className="hide form-control iitem-alias-barcode " id={"iitem-alias-barcode"} data-id={this.props.index}  data-target="iitem-alias-barcode"/>
                    <input type="hidden" className="form-control iitem-alias-barcode " id={"iitem-barcode"} data-id={this.props.index}  data-target="iitem-barcode"/>
                  </>
                  }
                  {this.props.billing_type==='New Sale' &&
                  <div className={"col-md-1 w-50 hide-opt-check "+abfs}  data-id="sprice">
                    <span className="fe-title">PRICE</span>
                    <span id="handleCalculationAfterMSPSelect" onClick={this.handleCalculationAfterMSPSelect}></span>
                    <div style={{display:'none'}}>
                      <span className="iitem-availability"  id={"iitem-availability"} data-id={this.props.index} data-target="iitem-availability">{(this.props.items && this.props.items.availability)?this.props.items.availability:'---'}</span>
                    </div> 
                    {(is_change_price===true) &&
                      <input type="number" className="form-control iitem-sub-total " placeholder=""  id={"price"} data-id={this.props.index} data-target="price" target="price" tabindex={'8'+this.props.index} onBlur={this.checkLowestSellingPrice} onChange={this.handleChangeText}/>
                    }
                    {(is_change_price===false) &&
                      <input type="number" className="form-control iitem-sub-total " placeholder=""  id={"price"} data-id={this.props.index} data-target="price" target="price" onBlur={this.checkLowestSellingPrice} onChange={this.handleChangeText} disabled/>
                    }
                    <input type="text" className="hide form-control iitem-lowest-selling-price" placeholder=""  id={"iitem-lowest-selling-price"} data-target="iitem-lowest-selling-price"/>
                  </div>
                  }
                  {this.props.billing_type==='New Purchase' &&
                  <>
                    <div className={"col-md-1 w-50 "+abfs}>
                      <span className="fe-title">P. PRICE</span>
                        <input type="number" className="hide total-avl-out-hidden" id={"total-avl-out-hidden"}/>
                        <input type="number" className="form-control  iitem-sub-total " placeholder=""  onChange={this.handleChangeText} id={"price"} data-id={this.props.index} data-target="price" target="price" tabindex={'9'+this.props.index} onBlur={this.handleLSP}/>
                    </div>
                   </>
                  }
                  {this.props.billing_type==='New Sales Return' &&
                    <>
                      <div className={"col-md-1 w-50 "+abfs}>
                          <span className="fe-title">S. PRICE</span>
                          <input type="number" className="form-control iitem-sub-total " placeholder="" onChange={this.handleChangeText} id={"price"} data-id={this.props.index} data-target="price" onBlur={this.checkLowestSellingPrice} target="price" tabindex={'10'+this.props.index}/>

                          <input type="text" className="hide form-control iitem-lowest-selling-price" placeholder=""  id={"iitem-lowest-selling-price"} data-id={this.props.index} data-target="iitem-lowest-selling-price"/>
                      </div>
                    </>
                  }
                  {this.props.billing_type==='New Purchase Return' &&
                    <>
                      <div className={"col-md-1 w-50 "+abfs}>
                          <span className="fe-title">P. PRICE</span>
                          <input type="text" className="hide total-avl-out-hidden" id={"total-avl-out-hidden"}/>
                          <input type="number" className="form-control iitem-sub-total " placeholder="" onChange={this.handleChangeText} id={"price"} data-id={this.props.index} data-target="price" target="price" tabindex={'11'+this.props.index}/>
                      </div>
                    </>
                  }
                  {(this.props.billing_type==='New Purchase') && 
                  <>
                    <div className={"col-md-1 w-50 hide-opt-check hide-pur-opt-check "+abfs}  data-id={(this.props.billing_type==='New Purchase')?"ppdisc":"sdisc"}>
                      <input type="hidden" value={this.state.purchase_pp_disc_type} id="purchase_pp_disc_type"/>
                     
                        <span className="fe-title">DISC {(this.state.purchase_pp_disc_type==='rs')?'₹':'%'}</span>
                        <input type="number" className="form-control iitem-discount" placeholder={(this.state.purchase_pp_disc_type==='rs')?'₹':'%'} onKeyUp={this.handleCalculation} id={"discount"} data-id={this.props.index} data-target="idiscount" target="idiscount" tabindex={'12'+this.props.index} onBlur={this.handleCheckMSPDiscount}/>
                      
                      
                      <input type="text" className="hide iitem-discount-hidden" id={"discount-hidden"}/>
                    </div>
                  </>
                  }
                  {(this.props.billing_type==='New Sale') && 
                  <>
                    <div className={"col-md-1 w-50 hide-opt-check hide-pur-opt-check "+abfs}  data-id={(this.props.billing_type==='New Purchase')?"ppdisc":"sdisc"}>
                      <input type="hidden" value={this.state.sales_sp_disc_type} id="sales_sp_disc_type"/>
                      <span className="fe-title">DISC {(this.state.sales_sp_disc_type==='rs')?'₹':'%'}</span>
                      <input type="number" className="form-control iitem-discount" placeholder={(this.state.sales_sp_disc_type==='rs')?'₹':'%'} onKeyUp={this.handleCalculation} id={"discount"} data-id={this.props.index} data-target="idiscount" target="idiscount" tabindex={'12'+this.props.index} onBlur={this.handleCheckMSPDiscount}/>
                      <input type="text" className="hide iitem-discount-hidden" id={"discount-hidden"}/>
                    </div>
                  </>
                  }
                  {(this.props.billing_type==='New Sales Return') && 
                  <>
                    <div className={"col-md-1 w-50 hide-opt-check hide-pur-opt-check "+abfs}  data-id={(this.props.billing_type==='New Purchase')?"ppdisc":"sdisc"}>
                      <span className="fe-title">DISC%</span>
                      <input type="number" className="form-control iitem-discount" placeholder="%" onKeyUp={this.handleCalculation} id={"discount"} data-id={this.props.index} data-target="idiscount" target="idiscount" tabindex={'12'+this.props.index} onBlur={this.handleCheckMSPDiscount}/>
                     
                      <input type="text" className="hide iitem-discount-hidden" id={"discount-hidden"}/>
                    </div>
                  </>
                  }
                  {(this.props.billing_type==='New Purchase Return') && 
                  <>
                   
                      <input type="text" className="hide form-control iitem-discount" placeholder="%" id={"discount"} data-id={this.props.index} data-target="idiscount" target="idiscount"/>
                     
                      <input type="text" className="hide iitem-discount-hidden" id={"discount-hidden"}/>
                    
                  </>
                  }
                  <div className={"col-md-1 w-50 hide-opt-check hide-pur-opt-check "+abfs}  data-id={(this.props.billing_type==='New Purchase')?"ppgst":"sgst"}>
                      <span className="fe-title">GST %</span>
                      <input list={"pp_tax_"+this.props.index}  className="form-control iitem-gst  " placeholder="%" id={"iitem-gst"} data-id={this.props.index}  data-target="iitem-gst" onChange={this.handleCalculation} target="igst" tabindex={'13'+this.props.index}/>
                    <datalist id={"pp_tax_"+this.props.index}>
                      {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                         return (
                          <option key={index}>{value.value}</option>
                        )
                       }) : '' }
                    </datalist>
                    <input type="text" className="hide form-control iitem-cgst" placeholder="%" id={"iitem-cgst"} data-id={this.props.index}  data-target="iitem-cgst" onKeyUp={this.handleCalculation} target="icgst" />
                    <input type="text" id={"iitem-cgst-hidden"} data-id={this.props.index} className="iitem-cgst-hidden hide" data-target="iitem-cgst-hidden"/>

                    <input type="text" id={"iitem-cgst-total"} data-id={this.props.index} className="iitem-cgst-total hide" data-target="iitem-cgst-total"/>
                 
                    <input type="text" className="hide form-control iitem-sgst" placeholder="%" id={"iitem-sgst"} data-id={this.props.index}  data-target="iitem-sgst" onKeyUp={this.handleCalculation} target="isgst" />
                    <input type="text" className="hide iitem-sgst-hidden" id={"iitem-sgst-hidden"} data-id={this.props.index}  data-target="iitem-sgst-hidden"/>

                    <input type="text" className="hide iitem-sgst-total" id={"iitem-sgst-total"} data-id={this.props.index}  data-target="iitem-sgst-total"/>
                
                    <input type="text" className="hide form-control iitem-igst" placeholder="%" id={"iitem-igst"} data-id={this.props.index}  data-target="iitem-igst"  onKeyUp={this.handleCalculation} target="iigst" />
                    <input type="text" className="hide iitem-igst-hidden" id={"iitem-igst-hidden"} data-id={this.props.index}  data-target="iitem-igst-hidden"/>

                    <input type="text" className="hide iitem-igst-total" id={"iitem-igst-total"} data-id={this.props.index}  data-target="iitem-igst-total"/>
                  </div>
                  {(this.props.billing_type==='New Purchase' || this.props.billing_type==='New Sale') && 
                    <>
                      <div className={"col-md-1 w-50 hide-opt-check hide-pur-opt-check "+abfs}  data-id={(this.props.billing_type==='New Purchase')?"pptaxtype":"staxtype"}>
                        <span className="fe-title">TAX TYPE</span>
                        
                        <select className="form-control" data-target="iitem-tax-inc" data-id={this.props.index} id={"iitem-tax-inc"} onChange={this.handleTaxInclude} tabindex={'14'+this.props.index} target="itax-inc">
                        {(this.props.billing_type==='New Purchase' || this.props.billing_type==='New Purchase Return') &&
                        <>
                          <option value="excluding">Exc GST</option>
                          <option value="including">Inc GST</option>
                        </>
                        }
                        {(this.props.billing_type==='New Sale' || this.props.billing_type==='New Sales Return') &&
                        <>
                          <option value="including">Inc GST</option>
                          <option value="excluding">Exc GST</option>
                        </>
                        }
                        </select>
                      </div>
                    </>
                  }

                  {(this.props.billing_type==='New Sales Return' || this.props.billing_type==='New Purchase Return') && 
                    <>
                      <div className={"col-md-1 w-50 hide-opt-check hide-pur-opt-check "+abfs}>
                      <span className="fe-title">TAX TYPE</span>
                        <select className="form-control" data-target="iitem-tax-inc" data-id={this.props.index} id={"iitem-tax-inc"} onChange={this.handleTaxInclude} tabindex="141">
                        {(this.props.billing_type==='New Purchase Return') &&
                        <>
                          <option value="excluding">Exc GST</option>
                          <option value="including">Inc GST</option>
                        </>
                        }
                        {(this.props.billing_type==='New Sales Return') &&
                        <>
                          <option value="excluding">Exc GST</option>
                          <option value="including">Inc GST</option>
                        </>
                        }
                        </select>
                      </div>
                    </>
                  }
                  {(this.props.billing_type==='New Purchase') && 
                  <>
                       <div className={"col-md-1 w-50 "+abfs}>
                        <span className="fe-title">S. PRICE</span>
                         <input type="number" className="form-control iitem-selling-price " placeholder=""  id="iitem-selling-price" data-id={this.props.index} data-target="iitem-selling-price" onBlur={this.checkLowestSellingPrice} target="iitem-selling-price" tabindex={'15'+this.props.index} onChange={this.handleUnsetState}/>
                        <input type="text" className="hide form-control iitem-lowest-selling-price" placeholder=""  id={"iitem-lowest-selling-price"} data-id={this.props.index} data-target="iitem-lowest-selling-price"/>
                      </div>
                      <div className={"col-md-1 hide-pur-opt-check w-50 "+abfs} data-id="sdisc">
                        <span className="fe-title">DISC {(this.state.purchase_sp_disc_type==='rs')?'₹':'%'}</span>
                        <input type="number" className="form-control iitem-sp-discount" placeholder={(this.state.purchase_sp_disc_type==='rs')?'₹':'%'} id={"sp-discount"} data-id={this.props.index} data-target="sp-discount" target="sp-discount" tabindex={'16'+this.props.index}/>
                        <input type="text" className="hide iitem-sp-discount-hidden" id={"sp-discount-hidden"}/>
                      </div>
                      <div className={"col-md-1 hide-pur-opt-check w-50 "+abfs}  data-id="sgst">
                        <span className="fe-title">GST %</span>
                        <input list={"sp_tax_list"}  className="form-control iitem-sp-gst  " placeholder="%" id={"iitem-sp-gst"} data-id={this.props.index}  data-target="iitem-sp-gst"  target="sp-gst" tabindex={'17'+this.props.index} onChange={this.handleChangeGST}/>
                      <datalist id={"sp_tax_list"}>
                      
                        {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                           return (
                            <option>{value.value}</option>
                          )
                         }) : '' }

                      </datalist>
                    </div>
                    <div className={"col-md-1 hide-pur-opt-check w-50 "+abfs}  data-id="staxtype">
                      <span className="fe-title">TAX TYPE</span>
                      <select className="form-control" data-target="sp-tax-type" data-id={this.props.index} id={"sp-tax-type"} tabindex={'18'+this.props.index} target="sp-tax-type">
                        <option value="excluding">Exc GST</option>
                        <option value="including">Inc GST</option>
                      </select>
                    </div>
                    <div className={"col-md-1 hide-pur-opt-check w-50 "+abfs} data-id="pbarcode">
                      <span className="fe-title">BARCODE</span>
                      <input type="text" className="form-control iitem-barcode " id={"iitem-barcode"} data-id={this.props.index}  data-target="iitem-barcode" tabindex={'19'+this.props.index} defaultValue={(this.state.is_auto_generate_barcode===true)?'Auto Generate':''} onFocus={this.handleBarcodeFocus}/>
                       <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="iitem-barcode-check" value="New Sale" checked={this.state.is_auto_generate_barcode} onChange={this.handleChangeBarcodeAuto}/>
                        <label className="form-check-label" htmlFor="iitem-barcode-check" style={{color:'white'}}>Auto</label>
                      </div>
                    </div>
                  
                    <div className={"col-md-2 hide-pur-opt-check w-50 "+abfs} data-id="paliasbarcode">
                      <span className="fe-title">ALIAS BARCODE</span>
                      <input type="text" className="form-control iitem-alias-barcode " id={"iitem-alias-barcode"} data-id={this.props.index}  data-target="iitem-alias-barcode" tabindex={'20'+this.props.index}/>
                    </div>
                    
                    
                    <PurchaseCustomFields prefix="iitem" tabindex={'21'+this.props.index} target={this.props.billing_type} cls={abfs}/>
                    
                    <div className={"col-md-3 hide-pur-opt-check w-50 "+abfs} data-id="pcategory">
                      <span className="fe-title">CATEGORY</span>
                      <TagFields target="category" name="product-category" data_id="iitem-category" tabindex={'1000'+this.props.index} default={this.state.defaultCategory} default_id={this.state.defaultCategoryID}/>
                      
                    </div>
                    <div className={"col-md-1 hide-pur-opt-check w-50 "+abfs} data-id="preorderquantity">
                      <span className="fe-title">RE-ORDER QTY.</span>
                      <input type="text" className="form-control iitem-re-order-qty " id={"iitem-re-order-qty"} data-id={this.props.index}  data-target="iitem-re-order-qty" tabindex={'1001'+this.props.index}/>
                    </div>
                    </>
                  }
                  {(this.props.billing_type==='New Sale') && 
                    <PurchaseCustomFields prefix="iitem" target={this.props.billing_type} cls={abfs} tabindex={'21'+this.props.index}  />
                  }
                   {(this.props.billing_type==='New Sale' && this.state.is_item_commission===true) && 
                  <div className={"col-md-1 "+abfs}>
                    <span className="fe-title">COMMISSION</span>
                      <input type="number" className="form-control iitem-total-commission " id={"iitem-total-commission"} data-id={this.props.index}  data-target="iitem-total-commission" tabindex={'22'+this.props.index}/>
                  </div>
                  }
                  <div className={"col-md-1 hide-opt-check hide-pur-opt-check "+abfs}  data-id="stotal">
                    <span className="fe-title">TOTAL</span>
                    {(this.props.billing_type!=='New Sale') && 
                      <>
                        <span className="iitem-total hide" id={"iitem-total"} data-id={this.props.index}  data-target="iitem-total"></span>
                        <input type="number" className="form-control iitem-total-txt " id={"iitem-total-txt"} data-id={this.props.index}  data-target="iitem-total-txt" tabindex={'10000'+this.props.index}  onChange={this.handleFinalCalculation}/>
                      </>
                    }
                    {(this.props.billing_type==='New Sale') && 
                    <>
                      <span className="iitem-total hide" id={"iitem-total"} data-id={this.props.index}  data-target="iitem-total"></span>
                      <input type="number" className="form-control iitem-total-txt " id={"iitem-total-txt"} data-id={this.props.index}  data-target="iitem-total-txt" disabled/>
                    </>
                    }
                  </div>
                 
                  </>
                  }
                  <div className={"col-md-1 add-item-action-but "+abfs+" "+isActionButton} style={{marginTop:'9px'}}>
                    <button type="button" className="btn btn-primary more-item-btn" onClick={this.addMoreItem}>
                      <FontAwesomeIcon icon={faCheckCircle} style={{color:'white'}}/>
                    </button>
                    <button type="button" className="btn btn-danger" id="handleResetField" onClick={this.handleResetField}>
                      <FontAwesomeIcon icon={faTimes} style={{color:'white',margin:'0px'}}/>
                    </button>
                    <span id="handleChangeListTypeOnFly" onClick={this.handleChangeListTypeOnFly}></span>
                  </div>
                  {(this.props.billing_type==='New Purchase Return' || this.props.billing_type==='New Sales Return') && 
                  <div className="col-md-2"></div>
                  }
                </>
              }



              {(this.state.list_item_type==='sales_return' || this.state.list_item_type==='purchase_return') &&
                <>
                <div className=" mrbtm-10 hide">
                  <span className="fe-title">HSN/SAC</span>
                  <input type="text" className="form-control" placeholder="" id={"iitem-hsn"} data-id={this.props.index}  target="iitem-hsn"/>
                </div> 
                <div className={"col-md-1 w-50 "+abfs}>
                  <span className="fe-title">QTY.</span>
                  <input type="text" className="hide" data-id={this.props.index} id={"iitem-bundle-quantity"} data-target="iitem-bundle-quantity" target="iitem-bundle-quantity"/>
                  <input type="text" className="form-control hide" id={"price-hidden"} data-id={this.props.index} data-target="iprice-hidden" target="price-hidden"/>
                  
                  <input type="text" className="hide" data-id={this.props.index} id={"iitem-bundle-text"} data-target="iitem-bundle-text" target="iitem-bundle-text" />

                  <input type="number" className="form-control iitem-quantity " id={"iitem-quantity"} placeholder="" onKeyUp={this.handleCalculation}  data-id={this.props.index} data-target="iitem-quantity"  target="iitem-quantity" tabindex={'6'+this.props.index}/>
                  <input type="text" className="hide" id={"current-available"}/>
                  {(this.props.items && this.props.items.bundle_quantity>0)?
                    <p className="bundle_quantity" id={"bundle_quantity"} >{this.props.items.bundle_quantity} {this.state.bundle_text}</p>
                  :<p className="bundle_quantity" id={"bundle_quantity"}></p>
                  }
                </div>
                
                <div className={"col-md-1 w-50 "+abfs}>
                  <span className="fe-title">UNIT</span>
                  <input type="text" className="hide form-control iitem-unit-name" placeholder=""  id={"iitem-unit-name"} data-id={this.props.index} data-target="iitem-unit-name" />
                  <ProductUnits show="dropdown" data_id={this.props.index} id={'iitem-unit'} default={(this.props.items && this.props.items.unit_id)?this.props.items.unit_id:''} target="iitem-unit" tabindex={'7'+this.props.index}/ >
                </div>
                <input type="hidden" className="form-control iitem-barcode " id={"iitem-barcode"} data-id={this.props.index}  data-target="iitem-barcode"/>
                {this.state.list_item_type==='sales_return' &&
                <>
                    <div className={"col-md-1 w-50 "+abfs}>
                        <span className="fe-title">S. PRICE</span>
                        {(is_change_price===true) &&
                          <input type="number" className="form-control iitem-sub-total " placeholder="" onChange={this.handleChangeText} id={"price"} data-id={this.props.index} data-target="price" onBlur={this.checkLowestSellingPrice} target="price" tabindex={'10'+this.props.index}/>
                        }
                        {(is_change_price===false) &&
                          <input type="number" className="form-control iitem-sub-total " placeholder="" onChange={this.handleChangeText} id={"price"} data-id={this.props.index} data-target="price" onBlur={this.checkLowestSellingPrice} target="price" disabled/>
                        }
                        <input type="text" className="hide form-control iitem-lowest-selling-price" placeholder=""  id={"iitem-lowest-selling-price"} data-id={this.props.index} data-target="iitem-lowest-selling-price"/>
                    </div>
                    <div className={"col-md-1 w-50 "+abfs}>
                      <span className="fe-title">DISC%</span>
                      <input type="number" className="form-control iitem-discount" placeholder="%" onKeyUp={this.handleCalculation} id={"discount"} data-id={this.props.index} data-target="idiscount" target="idiscount" tabindex={'11'+this.props.index}/>
                     
                      <input type="text" className="hide iitem-discount-hidden" id={"discount-hidden"}/>
                    </div>
                  </>
                }
                 
                      <div className="hide">
                        <select className="form-control" data-target="iitem-tax-inc" data-id={this.props.index} id={"iitem-tax-inc"} onChange={this.handleTaxInclude}>
                        {(this.props.billing_type==='New Purchase' || this.props.billing_type==='New Purchase Return') &&
                        <>
                          <option value="excluding">Exc GST</option>
                          <option value="including">Inc GST</option>
                        </>
                        }
                        {(this.props.billing_type==='New Sale' || this.props.billing_type==='New Sales Return') &&
                        <>
                          <option value="including">Inc GST</option>
                          <option value="excluding">Exc GST</option>
                        </>
                        }
                        </select>
                      </div>
                    
                {this.state.list_item_type==='purchase_return' &&
                  <>
                    <div className={"col-md-1 w-50 "+abfs}>
                        <span className="fe-title">P. PRICE</span>
                        <input type="text" className="hide total-avl-out-hidden" id={"total-avl-out-hidden"}/>
                        <input type="number" className="form-control iitem-sub-total " placeholder="" onChange={this.handleChangeText} id={"price"} data-id={this.props.index} data-target="price" target="price" tabindex={'12'+this.props.index}/>
                    </div>

                  </>
                }
                <div className={"col-md-1 w-50 "+abfs}>
                    <span className="fe-title">GST %</span>
                    <input list={"pp_tax_"+this.props.index}  className="form-control iitem-gst  " placeholder="%" id={"iitem-gst"} data-id={this.props.index}  data-target="iitem-gst" onChange={this.handleCalculation} target="igst" tabindex={'13'+this.props.index}/>
                  <datalist id={"pp_tax_"+this.props.index}>
                    {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                       return (
                        <option key={index}>{value.value}</option>
                      )
                     }) : '' }
                  </datalist>
                  <input type="text" className="hide form-control iitem-cgst" placeholder="%" id={"iitem-cgst"} data-id={this.props.index}  data-target="iitem-cgst" onKeyUp={this.handleCalculation} target="icgst" />
                  <input type="text" id={"iitem-cgst-hidden"} data-id={this.props.index} className="iitem-cgst-hidden hide" data-target="iitem-cgst-hidden"/>

                  <input type="text" id={"iitem-cgst-total"} data-id={this.props.index} className="iitem-cgst-total hide" data-target="iitem-cgst-total"/>
               
                  <input type="text" className="hide form-control iitem-sgst" placeholder="%" id={"iitem-sgst"} data-id={this.props.index}  data-target="iitem-sgst" onKeyUp={this.handleCalculation} target="isgst" />
                  <input type="text" className="hide iitem-sgst-hidden" id={"iitem-sgst-hidden"} data-id={this.props.index}  data-target="iitem-sgst-hidden"/>

                  <input type="text" className="hide iitem-sgst-total" id={"iitem-sgst-total"} data-id={this.props.index}  data-target="iitem-sgst-total"/>
              
                  <input type="text" className="hide form-control iitem-igst" placeholder="%" id={"iitem-igst"} data-id={this.props.index}  data-target="iitem-igst"  onKeyUp={this.handleCalculation} target="iigst" />
                  <input type="text" className="hide iitem-igst-hidden" id={"iitem-igst-hidden"} data-id={this.props.index}  data-target="iitem-igst-hidden"/>

                  <input type="text" className="hide iitem-igst-total" id={"iitem-igst-total"} data-id={this.props.index}  data-target="iitem-igst-total"/>
                </div>
                <div className={"col-md-1  "+abfs}>
                  <span className="fe-title">TOTAL</span>
                  {(this.state.list_item_type==='purchase_return') &&
                    <>
                      <span className="iitem-total hide" id={"iitem-total"} data-id={this.props.index}  data-target="iitem-total"></span>
                      <input type="number" className="form-control iitem-total-txt " id={"iitem-total-txt"} data-id={this.props.index}  data-target="iitem-total-txt" tabindex={'21'+this.props.index}  onChange={this.handleFinalCalculation}/>
                    </>
                  }
                  {(this.state.list_item_type==='sales_return') &&
                    <>
                      <span className="iitem-total hide" id={"iitem-total"} data-id={this.props.index}  data-target="iitem-total"></span>
                      <input type="number" className="form-control iitem-total-txt " id={"iitem-total-txt"} data-id={this.props.index}  data-target="iitem-total-txt" onChange={this.handleFinalCalculation} disabled/>
                    </>
                  }
                </div>
                <div className={"col-md-1 add-item-action-but "+abfs+" "+isActionButton} style={{marginTop:'9px'}}>
                  <button type="button" className="btn btn-primary more-item-btn" onClick={this.addMoreItem}>
                    <FontAwesomeIcon icon={faCheckCircle} style={{color:'white'}}/>
                  </button>
                  <button type="button" className="btn btn-danger" onClick={this.handleResetField}>
                    <FontAwesomeIcon icon={faTimes} style={{color:'white',margin:'0px'}}/>
                  </button>
                  <span id="handleChangeListTypeOnFly" onClick={this.handleChangeListTypeOnFly}></span>
                </div>
                <div className="col-md-1"></div>
                </>
              }
              </div>
            </div>
          </div>
          {(this.more_rows.length>0) &&
          <div className="table-responsive">
             <table id="list-item-table" className="table item-table border-bottom bilist pen-in-li">
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="item-serial">S.NO.</th>
                    <th scope="col">ITEM NAME</th>
                    <th scope="col" className="hide">DESC</th>
                    {(this.props.billing_type==='New Purchase' || this.props.billing_type==='New Sale'  || this.props.billing_type==='New Sales Return') &&
                      <th scope="col" className="text-center">HSN/SAC</th>
                    }
                    {(this.props.billing_type==='Purchase Return') &&
                      <th scope="col" className="text-center hide">HSN/SAC</th>
                    }
                    <th scope="col" className="text-center">QTY</th>
                    <th scope="col" className="text-center hide-item-list" data-id="sunit">UNIT</th>
                    {this.props.billing_type==='New Sale' &&
                    <>
                      <th scope="col" style={{display:'none'}}>AVAIL.</th>
                      <th scope="col" className="text-center hide-item-list" data-id="sprice">PRICE</th>
                    </>
                    }
                    {this.props.billing_type==='New Purchase' &&
                    <>
                      <th scope="col" className="text-center">P. PRICE</th>
                     </>
                    }
                    {this.props.billing_type==='New Sales Return' &&
                      <>
                        <th scope="col" className="text-center">S. PRICE</th>
                      </>
                    }
                    {this.props.billing_type==='New Purchase Return' &&
                      <>
                        <th scope="col" className="text-center">P. PRICE</th>
                      </>
                    }
                    {(this.props.billing_type==='New Purchase'||this.props.billing_type==='New Sale' || this.props.billing_type==='New Sales Return' ) && 
                    <>
                      <th scope="col" className="text-center  hide-item-list"  data-id="sdisc">DISC</th>
                    </>
                    }
                    <th scope="col" className="text-center hide-item-list"  data-id="sgst">GST%</th>
                    {(this.props.billing_type==='New Purchase' || this.props.billing_type==='New Sale') && 
                      <>
                        <th scope="col" className="text-center hide-item-list"  data-id="staxtype">TAX TYPE</th>
                      </>
                    }
                    {(this.props.billing_type==='New Purchase Return' || this.props.billing_type==='New Sales Return') && 
                      <>
                        <th scope="col" className="text-center">TAX TYPE</th>
                      </>
                    }
                    
                    {this.props.billing_type==='New Purchase' &&
                    <>
                      <th scope="col" className="text-center">S. PRICE</th>
                      <th scope="col" className="text-center">DISC</th>
                      <th scope="col" className="text-center">GST%</th>
                      <th scope="col" className="text-center">TAX TYPE</th>
                      <th scope="col" className="text-center">CATEGORY</th>
                      <th scope="col" className="text-center">RE-ORDER QTY</th>
                     </>
                    }
                    {(this.props.billing_type==='New Purchase'||this.props.billing_type==='New Sale' ) && 
                      <>
                        {(this.state.is_custom_field===true) &&
                          <th scope="col" className="text-center">CUSTOM DATA</th>
                        }
                      </>
                    }
                    {(this.props.billing_type==='New Sale' && this.state.is_item_commission===true) && 
                      <th scope="col">COMMISSION</th>
                    }
                    <th scope="col" className="hide-item-list"  data-id="stotal">TOTAL</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody id="items-body">
                    {this.more_rows}
                </tbody>
              </table>
             </div>
           }
           

          {(this.return_more_rows.length>0) &&
            <div>
              <div className="ret-item-head">
                <h4>Return Items</h4>
              </div>
              <div className="table-responsive">
               <table className="table item-table border-bottom bilist">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="item-serial">S.NO.</th>
                      <th scope="col">ITEM NAME</th>
                      <th scope="col" className="hide">DESC</th>
                      <th scope="col" className="text-center hide">HSN/SAC</th>
                      <th scope="col" className="text-center">QTY</th>
                      <th scope="col" className="text-center">UNIT</th>
                      {this.props.billing_type==='New Sale' &&
                        <>
                          <th scope="col" className="text-center">S. PRICE</th>
                          <th scope="col" className="text-center">DISC%</th>
                        </>
                      }
                      {this.props.billing_type==='New Purchase' &&
                        <>
                          <th scope="col" className="text-center">P. PRICE</th>
                        </>
                      }
                      <th scope="col" className="text-center">GST%</th>
                      <th scope="col">TOTAL</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody id="items-body">
                      {this.return_more_rows}
                  </tbody>
                </table>
               </div>
            </div>
           }


           <div id="re-generate-barcode" className="modal fade">
              <div className="modal-dialog modal-confirm" style={{width:'unset'}}>
                <div className="modal-content">
                  <div className="modal-header flex-column">
                    <div className="icon-box icon-print">
                      <span className="material-icons"><FontAwesomeIcon icon={faExclamationTriangle}/></span>
                    </div>
                    <h5 className="modal-title w-100" id="re-gen-bc-msg" style={{fontSize:'16px'}}></h5>  
                  </div>
                  <div className="modal-footer justify-content-center" style={{padding:'0px'}}>
                    <div className="row">
                      <div className="col-md-6 mrtop-10">
                        <button type="button" className="btn btn-secondary" onClick={this.handleCloseBarcodeAlert}>No</button>
                      </div>
                    
                      <div className="col-md-6 mrtop-10">
                        <button type="button" onClick={this.handleRegenerateBarcode} className="btn btn-primary" id="handleRegenerateBarcode">Yes</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DifferentBarcodeInventoryItems active_tab={this.props.billing_type}/>
            <ModalBox id="add_category" title="ADD CATEGORY"  data_id="Category"/>
              <div id='product-variation-add' className="modal fade">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header flex-column">
                      <lable className="modal-title" style={{textTransform:'uppercase'}}>{this.state.product_variation_pop_title}</lable>
                      <input type="text" className="hide" defaultValue={this.state.selected_product} id="selected_product" />  
                    </div>
                    <div className="modal-body" style={{paddingTop:'0px'}}>
              
                      <div className="row">
                        {(this.state.is_variant_checked) &&
                          <>
                          {(this.variantOptions.length>0) &&
                            <>
                           <div className="col-md-6" style={{padding:'5px 25px',borderRight:'1px solid #d7d7d7'}}>
                              <div className="row">
                                <div className="col-md-12">
                                    <label style={{fontWeight:'bold'}}>OPTIONS</label>
                                </div>
                              </div>
                              <div>
                                {this.state.variantOptions}
                              </div>
                              {(this.variantOptions.length<3) &&
                              <div className="row mrtop-10 mrbtm-10">
                                  <div className="col-md-12">
                                    <button type="button" className="btn btn-light" id="handleMoreVariantOpt" style={{border:'1px solid #d7d7d7'}} onClick={this.handleMoreVariantOpt}><FontAwesomeIcon icon={faPlus} style={{color:'#898989'}}></FontAwesomeIcon> Add another option</button>
                                  </div>
                              </div>
                              }
                           </div>
                           <div className="col-md-4" style={{padding:'5px 25px'}}>
                            <div className="row">
                              <div className="col-md-12">
                                  <lable>ITEM NAME</lable>
                                  <input type="text" className="form-control" defaultValue={this.state.selected_item_name} onKeyUp={this.handleChangeItemInfo} data-target="item_name" id="selected_item_name" tabindex={107}/>
                                  <input type="text" className="hide form-control" defaultValue={this.state.selected_old_item} id="selected_old_item"  />
                              </div>
                              <div className="col-md-12 mrtop-5 hide-pur-opt-check" data-id="sdesc">
                                  <lable>DESCRIPTION</lable>
                                  <input type="text" className="form-control" defaultValue={this.state.selected_item_desc} onKeyUp={this.handleChangeItemInfo} data-target="desc"  id="selected_item_desc" tabindex={108}/>
                              </div>
                              <div className="col-md-12 mrtop-5 hide-pur-opt-check" data-id="shsn">
                                  <lable>HSN/SAC</lable>
                                  <input type="text" className="form-control" defaultValue={this.state.selected_item_hsn} onKeyUp={this.handleChangeItemInfo} data-target="hsn"  id="selected_item_hsn"  tabindex={109}/>
                              </div>
                              <div className="col-md-12 mrtop-5 hide-pur-opt-check" data-id="pcategory">
                                  <lable>CATEGORY</lable>
                                  <TagFields target="category" name="product-category" data_id="iitem-category" default={this.state.defaultCategory} default_id={this.state.defaultCategoryID} tabindex={110}/>
                              </div>
                            </div>
                           </div>
                           <div className="col-md-2" style={{padding:'5px 25px'}}></div>
                          </>
                         }
                          
                        {(this.state.variantList.length>0) &&
                          <>
                          <span id="handleCalculationOnClick" onClick={this.handleCalculationOnClick}></span>
                           <div className="main-content" style={{borderTop:'1px solid #d7d7d7',padding:'15px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                    <label style={{fontWeight:'bold'}}>
                                      BULK ACTION
                                    </label>
                                </div>
                              </div>
                              
                              <div className="row" style={{borderBottom: '1px solid #d7d7d7',paddingTop:'5px',paddingBottom: '15px'}}>
                                <div className="col-md-12">
                                  <table className="table" style={{display: 'block',overflowX: 'auto',whiteSpace:'nowrap'}}>
                                    <thead>
                                      <tr>  
                                        <th>Pr. Price</th>
                                        <th>Increment</th>
                                        <th className="hide-pur-opt-check" data-id="ppdisc">Disc.%</th>
                                        <th className="hide-pur-opt-check" data-id="ppgst">Tax</th>
                                        <th className="hide-pur-opt-check" data-id="pptaxtype">Tax Type</th>
                                        <th>S. Price</th>
                                        <th>Increment</th>
                                        <th className="hide-pur-opt-check" data-id="sdisc">Disc.%</th>
                                        <th className="hide-pur-opt-check" data-id="sgst">Tax</th>
                                        <th className="hide-pur-opt-check" data-id="staxtype">Tax Type</th>
                                        <th>Quantity</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.variantPreviewList}
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12 mrtop-10">
                                    <label style={{fontWeight:'bold'}}>
                                      PREVIEW
                                    </label>
                                </div>
                              </div>
                              
                              <div className="row variation-options" style={{paddingTop:'5px',paddingBottom: '15px'}}>
                                <div className="col-md-12">
                                  <table className="table" style={{display: 'block',overflowX: 'auto',whiteSpace:'nowrap'}}>
                                    <thead>
                                      <tr>  
                                       
                                        {(this.state.options_head && this.state.options_head.length===0) &&
                                          <th>Variant</th>
                                        }
                                        
                                        {(this.state.options_head && this.state.options_head.length > 0) ?this.state.options_head.map( (value, index) => {
                                           return (
                                            <>
                                            {(value!=="") &&
                                              <th>{value}</th>
                                            }
                                            </>
                                          )
                                         }) : '' }
                                      
                                        <th>Pr. Price</th>
                                        <th className="hide-pur-opt-check" data-id="ppdisc">Disc.%</th>
                                        <th className="hide-pur-opt-check" data-id="ppgst">Tax</th>
                                        <th className="hide-pur-opt-check" data-id="pptaxtype">Tax Type</th>
                                        <th>S. Price</th>
                                        <th className="hide-pur-opt-check" data-id="sdisc">Disc.%</th>
                                        <th className="hide-pur-opt-check" data-id="sgst">Tax</th>
                                        <th className="hide-pur-opt-check" data-id="staxtype">Tax Type</th>
                                        <th>Quantity</th>
                                        {/* <th>SKU</th> */}
                                        <th className="hide-pur-opt-check" data-id="pbarcode">Barcode</th>
                                        <th className="hide-pur-opt-check" data-id="paliasbarcode">Alias Barcode</th>
                                        <th className="hide-pur-opt-check" data-id="preorderquantity">RE-ORDER QTY</th>
                                        <PurchaseCustomFields prefix="var" target='cf-th-headers' cls={''}/>
                                        <th className="hide-pur-opt-check" data-id="stotal">Total</th>
                                        <th></th>
                                        {(this.state.options_head && this.state.options_head.length > 0) &&
                                        <th></th>
                                        }
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.variantList}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                           </div>
                           </>
                         }
                          </>
                         }
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" id="closeVariantModal" onClick={this.closeModel}>Close</button>
                      <button type="button" className="btn btn-primary pull-right" id="create-variation-btn" onClick={this.handleCreateVariation}>Create <u><b>V</b></u>ariation</button>
                      <span className="error pull-right">{this.state.empty_var_error}</span>
                    </div>
                  </div>
                </div>
              </div>  
             </form>
          </>
        );
    }
}


class VariantOptions extends Component {
  constructor(props) {
      super(props)
      this.state = {
        tags: (props.tags)?props.tags:[],
        default_image : 'https://app.business-booster.net/app/uploads/no-image.png',
        tag_box_kp_count : 0,
        tag_error:'',
        variantList : [],
      }
      this.variantList=[];
      this.handleVariantTag = this.handleVariantTag.bind(this);
      this.removeTag = this.removeTag.bind(this);
      this.removeTagList = this.removeTagList.bind(this);
      this.handleVariantList = this.handleVariantList.bind(this);
      this.handleAddRemoveRow = this.handleAddRemoveRow.bind(this);
      this.autoGenerate = this.autoGenerate.bind(this);
      this.handleTextBoxFocus = this.handleTextBoxFocus.bind(this);
  }
  handleTextBoxFocus(e){
    let data_id = e.currentTarget.getAttribute('data-id');
    document.getElementById("tag-box-"+data_id).focus();
  }
  componentDidMount(){
    setTimeout(function(){
      let rm_items = document.querySelectorAll(".variation-opt-row");
      let rm_cls = document.querySelectorAll(".remove-var-opt");
      if(rm_items.length>1){
        for(let i=0;i<rm_cls.length;i++){
            rm_cls[i].classList.remove('hide');
        }
      }
    },100);
  }
  removeTagList(e){
    let id = e.currentTarget.id;
    this.setState({tags:[]});
    let rm = parseInt(id)-1;
    this.props.updateOptionState(rm);
    let this_ = this;
    setTimeout(function(){
      this_.handleAddRemoveRow('remove-tag-list');
      let rm_items = document.querySelectorAll(".variation-opt-row");
      let rm_cls = document.querySelectorAll(".remove-var-opt");
      if(rm_items.length===1){
        for(let i=0;i<rm_cls.length;i++){
            rm_cls[i].classList.add('hide');
        }
      }
    },50);
  }
  removeTag(e){
    let id = e.currentTarget.id;
    let data_value = e.currentTarget.getAttribute('data-id');
    let tags = this.state.tags;
    tags.splice(id, 1);
    this.setState({tags:tags});
    let this_ = this;
    this.props.removeVariationList(data_value,'remove');
    /*setTimeout(function(){
      this_.handleAddRemoveRow('remove-tag');
    },50);*/
  }
  handleVariantTag(e){
    let data_id = e.currentTarget.getAttribute('data-id');
    let value = e.currentTarget.value;
    

    //this.props.removeVariationList(data_value);
    if(e.keyCode===8){
     
      if(value===""){
        let tags = this.state.tags;
        let l = tags.length-1;
        if(l>=0){
          let tname = tags[l];
          this.props.removeVariationList(tname,'back');
          tags.splice(l, 1);
          this.setState({tags:tags});
        }
      }
    }else{
      let thisvalue = e.currentTarget.value.replace('/,/g','');
     
      if((e.keyCode===13 || e.keyCode===9 || e.keyCode===188) && thisvalue!==''){
          let tags = this.state.tags;
          let t_arr =thisvalue;

          if(tags.indexOf(thisvalue)<0){
            tags.push(t_arr);
            this.setState({tags:tags});
            e.currentTarget.value = '';
            e.currentTarget.placeholder = '';
            document.getElementById("tag-box-"+data_id).value = '';
            document.getElementById("tag-box-"+data_id).focus();
            this.setState({tag_error:''});
            let th_ = this;
            setTimeout(() => {
              th_.handleHidePurchaseField();
            }, 200);
          }else{
            let error = thisvalue+" variant already exists";
            this.setState({tag_error:error});
          }
      }
      if((e.keyCode===13 || e.keyCode===9) && thisvalue===''){
        setTimeout(function(){
          if(document.getElementById("selected_item_name")){
            document.getElementById("selected_item_name").focus();
          }
        },100);
      }

    }
  }

  handleHidePurchaseField = () =>{
    if(localStorage.getItem('purchase_field_hide')){
      let ps = JSON.parse(localStorage.getItem('purchase_field_hide'));
      
      let sfield_hide = document.querySelectorAll(".hide-pur-opt-check");
      for(let i=0;i<sfield_hide.length;i++){
        let this_element = sfield_hide[i];
        let check = this_element.getAttribute('data-id');
      
        if(ps.indexOf(check)>=0){
            this_element.classList.add('hide');
            var inputNodes = this_element.getElementsByTagName('INPUT');
            for(var j = 0; j < inputNodes.length; ++j){
                  var inputNode = inputNodes[j];
                  if(inputNode.classList.contains('hide') || inputNode.type==='hidden'){}else{
                    var tabindex = inputNode.getAttribute('tabindex');
                    inputNode.removeAttribute('tabindex');
                    inputNode.setAttribute('tindex',tabindex);
                  }
            }
            var inputNodes = this_element.getElementsByTagName('SELECT');
            for(var j = 0; j < inputNodes.length; ++j){
                  var inputNode = inputNodes[j];
                  if(inputNode.classList.contains('hide')){}else{
                    var tabindex = inputNode.getAttribute('tabindex');
                    inputNode.removeAttribute('tabindex');
                    inputNode.setAttribute('tindex',tabindex);
                  }
            }
        }
    }
      }
  }

  autoGenerate(str,length) {
       var result           = '';
       var characters       = str;
       characters = characters.replace(/[^a-zA-Z0-9]/g, "");
       var charactersLength = characters.length;
       for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result.toUpperCase();
    }
  handleAddRemoveRow(opt_type){

    this.variantList = [];
    let opt_row = document.querySelectorAll(".variation-opt-row");
    let total_tag = 1;
    for(let r = 0; r < opt_row.length; r++){
      let idx = opt_row[r].getAttribute('data-id');
      
      if(document.getElementById("variation-value-"+idx)){
        let var_tags = document.getElementById("variation-value-"+idx).value;
        if(var_tags!==""){
          let v_tags = var_tags.split(',');
          let tag_len = v_tags.length;
          total_tag = total_tag*tag_len;
        }
      }
    }
    
    let lbls = [];
    let first_opt = document.querySelectorAll(".var-tags-hidden");
    let option_names = document.querySelectorAll(".var-option-names");
    
    if(opt_row.length===1 || ((first_opt[1]!==undefined && first_opt[1].value==="") && (first_opt[2]!==undefined && first_opt[2].value===""))){
      let v_tags = first_opt[0].value;
      let option1 = option_names[0].value;
      if(v_tags!==""){
          v_tags = v_tags.split(',');
          let t_len = v_tags.length;
          for(let k=0;k<t_len;k++){
            let opt = {option1:option1,value1:v_tags[k],label:v_tags[k]};
            lbls.push(opt);
          }
      }
    }else if(opt_row.length===2 && ((first_opt[1]!==undefined && first_opt[1].value===""))){
      let v_tags = first_opt[0].value;
      let option1 = option_names[0].value;
      if(v_tags!==""){
          v_tags = v_tags.split(',');
          let t_len = v_tags.length;
          for(let k=0;k<t_len;k++){
            let opt = {option1:option1,value1:v_tags[k],label:v_tags[k]};
            lbls.push(opt);
            
          }
      }
    }else if(opt_row.length===2 || (first_opt[2]!==undefined && first_opt[2].value==="")){
      let v_tags = first_opt[0].value;
      let option1 = option_names[0].value;
      if(v_tags!==""){
        v_tags = v_tags.split(',');
        let t_len = v_tags.length;
        for(let k=0;k<t_len;k++){
          let tn = v_tags[k];
          let sencond_tag = first_opt[1].value;
          if(option_names[1]!==undefined){
            let option2 = option_names[1].value;
            if(sencond_tag!==""){
              sencond_tag = sencond_tag.split(",");
              for(let n=0;n<sencond_tag.length;n++){
                let c_tag = sencond_tag[n];
                let lb = tn+"/"+c_tag;

                let opt = {option1:option1,value1:tn,option2:option2,value2:c_tag,label:lb};
                lbls.push(opt);
                
              }
            }
          }
        }
      }
    }else if(opt_row.length===3){
      let v_tags = first_opt[0].value;
      let option1 = option_names[0].value;
      if(v_tags!==""){
        v_tags = v_tags.split(',');
        let t_len = v_tags.length;
        for(let k=0;k<t_len;k++){
          let tn = v_tags[k];
          let sencond_tag = first_opt[1].value;
          let option2 = option_names[1].value;
          if(sencond_tag!==""){
            sencond_tag = sencond_tag.split(",");
            for(let n=0;n<sencond_tag.length;n++){
              let c_tag = sencond_tag[n];
              let lb = tn+"/"+c_tag;
              let third_tag = first_opt[2].value;
              let option3 = option_names[2].value;
              if(third_tag!==""){
                third_tag = third_tag.split(",");
                for(let m=0;m<third_tag.length;m++){
                  let t_tag = third_tag[m];
                  let ttag = lb+"/"+t_tag;

                  let opt = {option1:option1,value1:tn,option2:option2,value2:c_tag,option3:option3,value3:t_tag,label:ttag};
                  lbls.push(opt);
                }
              }
            }
          }
        }
      }
    }

    let purchase_price = 0;
    let selling_price = 0;
    let quantity = 0;
    let item_data = {};

    this.variantList = [];
    for(let k = 0; k<total_tag; k++){
      let option_data = lbls[k];
      let lbl = '';
      
      if(lbls[k]!==undefined && lbls[k].label!==undefined){
        lbl = lbls[k].label;
      }
      let sku = this.autoGenerate(lbl,6);
      let barcode = this.autoGenerate(lbl,10);
      if(k>0){
        item_data = {image:this.state.default_image,inventory_id:0,purchase_price:purchase_price,selling_price:selling_price,availability:0,barcode_name:barcode,sku:sku};
        if(option_data!==undefined){
          if(option_data.option1){
            item_data.option1 = option_data.option1;
            item_data.value1 = option_data.value1;
          }
          if(option_data.option2){
            item_data.option2 = option_data.option2;
            item_data.value2 = option_data.value2;
          }
          if(option_data.option3){
            item_data.option3 = option_data.option3;
            item_data.value3 = option_data.value3;
          }
        }
      }else{
        item_data = {image:this.state.default_image,inventory_id:0,purchase_price:purchase_price,selling_price:selling_price,availability:quantity,barcode_name:barcode,sku:sku};
        if(option_data!==undefined){
          if(option_data.option1){
            item_data.option1 = option_data.option1;
            item_data.value1 = option_data.value1;
          }
          if(option_data.option2){
            item_data.option2 = option_data.option2;
            item_data.value2 = option_data.value2;
          }
          if(option_data.option3){
            item_data.option3 = option_data.option3;
            item_data.value3 = option_data.value3;
          }
        }
      }
      if(this.props.variationList[k]!==undefined && this.props.variationList[k].props.item_data !==undefined && this.props.variationList[k].props.item_data.item_name===lbl){
        item_data = this.props.variationList[k].props.item_data;
      }
      this.variantList.push(<VariationList item_data={item_data} option_data={option_data} label={lbl} index={k} removeItem={this.handleRemoveItem.bind(this)}/>);
    }

    let hidden_tags = document.querySelectorAll(".var-tags-hidden");
    let is_empty = true;
    for(let ht=0;ht<hidden_tags.length;ht++){
       if(hidden_tags[ht].value!==""){
          is_empty = false;
          break;
       }
    }
    if(is_empty){
      this.variantList=[];
    }
    this.props.updateState(this.variantList);
      
  }

  handleRemoveItem(id){
    
    /*let deleted_var_items = document.getElementById('deleted_var_items').value;
    let d_items = [];
    let item_id = document.getElementById("item-id-"+id).value;
    if(deleted_var_items!==""){
      let ids = deleted_var_items.split(",");
      for(let i=0;i<ids.length;i++){
        if(d_items.indexOf(ids[i])<0){
          d_items.push(ids[i]);
        }
      }
    }
    d_items.push(item_id);
    document.getElementById('deleted_var_items').value = d_items; */  
    //console.log(id,this.variantList);

    let item_lbl = document.getElementById("item-lbl-"+id).innerHTML;
    for(let i=this.variantList.length-1;i>=0;i--){
      if(this.variantList[i].props.label===item_lbl){
          this.variantList.splice(i,1);
      }
    }

    this.props.updateState(this.variantList);
  }

  handleVariantList(e){
    let target = e.currentTarget.getAttribute('data-id');
    let value = e.currentTarget.value;
    if(value===','){
      e.currentTarget.value = '';
    } 
    if(e.keyCode===13 || e.keyCode===188 || e.keyCode===9 || e.keyCode===8){
        this.handleAddRemoveRow('add');
    }
  }

  componentWillReceiveProps = (nextProps) =>{
    if(nextProps.tags){
        this.setState({tags:nextProps.tags});
    }
  }

  render() {
  return <div className="row variation-options v-opt-row variation-opt-row" style={{borderBottom: '1px solid #d7d7d7',paddingTop:'5px',paddingBottom: '15px'}} id={"v-opt-row-"+this.props.index} data-id={this.props.index}>
            <div className="col-md-4">
              <label style={{fontWeight:'bold'}}>{this.props.label}</label>
              <input type="text" id={"option-name-"+this.props.index} className="form-control var-option-names" defaultValue={this.props.option}/>
            </div>
            <div className="col-md-8">

              <div>
              <label style={{fontWeight:'bold'}}>
                Variation Name
              </label>
              <span style={{cursor:'pointer',float: 'right',color: 'cornflowerblue'}} id={this.props.index} onClick={this.removeTagList} className="remove-var-opt hide">Remove</span>
              </div>
        
                <div className="set-tax-box" data-id={this.props.index}  onClick={this.handleTextBoxFocus}>
             
                 {(this.state.tags.length > 0) ? this.state.tags.map( (value, index) => {
                     return (
                     <>
                        <span key={"t-"+index} className="v-tag" id={"v-tag-"+index}>
                          {value}
                          <span key={"tc-"+index} className="t-close" id={index} data-id={value} onClick={this.removeTag}><FontAwesomeIcon icon={faTimes} /></span>
                        </span>
                      </>
                      )
                    }):''}
                
                  
                  <input type="text" id={"tag-box-"+this.props.index} data-id={this.props.index} className="form-control tag-box" onKeyDown={this.handleVariantTag}  onKeyUp={this.handleVariantList}/>
                  <input type="hidden" className="var-tags-hidden" id={"variation-value-"+this.props.index} data-id={this.props.index} defaultValue={this.state.tags}/>
                </div>
            
              <div><span className="error">{this.state.tag_error}</span></div>
            </div>
        </div>;
  }
}
class VariationList extends Component {
  constructor(props) {
      super(props)
      this.state = {
          
      }
      this.handleRemoveItem = this.handleRemoveItem.bind(this);
      this.handleVariationCalculation = this.handleVariationCalculation.bind(this);
      this.handleVariationIncludeTaxCalculation = this.handleVariationIncludeTaxCalculation.bind(this);
      this.handleVariationFinalCalculation = this.handleVariationFinalCalculation.bind(this);
     
      this.currency_format = utils.currency_format.bind(this);
      this.floating_point = utils.floating_point.bind(this);
  }

  handleRemoveItem(e){
    let id=e.currentTarget.id;
   /* let deleted_var_items = document.getElementById('deleted_var_items').value;
    let d_items = [];
    let item_id = document.getElementById("item-id-"+id).value;
    if(deleted_var_items!==""){
      let ids = deleted_var_items.split(",");
      for(let i=0;i<ids.length;i++){
        if(d_items.indexOf(ids[i])<0){
          d_items.push(ids[i]);
        }
      }
    }
    d_items.push(item_id);
    document.getElementById('deleted_var_items').value = d_items;*/
    this.props.removeItem(id);
  }

    handleVariationCalculation(e){
      
      let thisval = e.currentTarget.value;
      let data_target = e.currentTarget.getAttribute("data-target");
      let data_id = e.currentTarget.getAttribute('data-id');
      let id = e.currentTarget.id;
      let contact_stat = localStorage.getItem('state');

      let quantity = 1;
      if(document.getElementById("var-item-quantity-"+data_id)){
        quantity = document.getElementById("var-item-quantity-"+data_id).value;
        quantity = (quantity!==''?parseFloat(quantity):1);
        
      }
     
      let price = 0;
      if(document.getElementById("var-item-price-"+data_id)){
        price = document.getElementById("var-item-price-"+data_id).value;
        price = (price!==''?parseFloat(price):0);
      }

      let prev_item_price = '';
      if(document.getElementById("prev-item-price")){
        prev_item_price = document.getElementById("prev-item-price");
      }

      if(data_target==="var-item-price" && (prev_item_price==='' || prev_item_price===0)){
        document.getElementById("var-item-price-hidden-"+data_id).value = e.currentTarget.value;
      }

    
      let hidden_price = document.getElementById("var-item-price-hidden-"+data_id).value

      let discount = 0;
      if(document.getElementById("var-item-pp-disc-"+data_id)){
        discount = document.getElementById("var-item-pp-disc-"+data_id).value;
        if(discount===""){
          discount = 0;
        }
      }
      
      let pp_tax = 0;
      if(document.getElementById("var-item-pp-tax-"+data_id)){
        pp_tax = document.getElementById("var-item-pp-tax-"+data_id).value.replace('GST','');
        pp_tax = pp_tax.replace('%','');
       
        pp_tax = parseFloat(pp_tax);
      }
     
       let igst = 0;
       let sgst = 0;
       let cgst = 0;
      if(pp_tax>0){
       if(contact_stat===""){
          cgst = pp_tax/2;
          sgst = pp_tax/2;
          igst = 0;
       }else if(contact_stat!==user_info.state){
          cgst = 0;
          sgst = 0;
          igst = pp_tax;
       }else if(contact_stat===user_info.state){
          cgst = pp_tax/2;
          sgst = pp_tax/2;
          igst = 0;
       }
     }else{
        cgst = 0;
        sgst = 0;
        igst = 0;
     }

    if(document.getElementById("var-item-sgst-"+data_id)){
      document.getElementById("var-item-sgst-"+data_id).value = sgst;
      document.getElementById("var-item-sgst-hidden-"+data_id).value = sgst;
    }
    if(document.getElementById("var-item-cgst-"+data_id)){
      document.getElementById("var-item-cgst-"+data_id).value = cgst;
      document.getElementById("var-item-cgst-hidden-"+data_id).value = cgst;
    }
    if(document.getElementById("var-item-igst-"+data_id)){
      document.getElementById("var-item-igst-"+data_id).value = igst;
      document.getElementById("var-item-igst-hidden-"+data_id).value = igst;
    }

      if(document.getElementById("var-item-igst-"+data_id)){
        igst = document.getElementById("var-item-igst-"+data_id).value;
        igst = (igst!=='')?parseInt(igst):0;
      }      

      
      if(document.getElementById("var-item-sgst-"+data_id)){
        sgst = document.getElementById("var-item-sgst-"+data_id).value;
        sgst = (sgst!=='')?parseInt(sgst):0;
      }      

      if(document.getElementById("var-item-cgst-"+data_id)){
        cgst = document.getElementById("var-item-cgst-"+data_id).value;
        cgst = (cgst!=='')?parseInt(cgst):0;
      }      
      let tax = cgst+sgst+igst;
      
      let cal_discount = ((hidden_price*quantity)*discount)/100;
      let item_grand_total = (price*quantity)-cal_discount;
      let cal_tax = (item_grand_total*tax)/100;
      let cgst_tax = (item_grand_total*cgst)/100;
      let sgst_tax = (item_grand_total*sgst)/100;
      let igst_tax = (item_grand_total*igst)/100;
      document.getElementById("var-item-igst-total-"+data_id).value = this.floating_point(igst_tax);
      document.getElementById("var-item-sgst-total-"+data_id).value = this.floating_point(sgst_tax);
      document.getElementById("var-item-cgst-total-"+data_id).value = this.floating_point(cgst_tax);

      if(document.getElementById("var-pp-discount-hidden-"+data_id)){
        document.getElementById("var-pp-discount-hidden-"+data_id).value = this.floating_point(cal_discount);
      }
      
      let final_amount = item_grand_total+cal_tax;
     
      document.getElementById("var-item-total-txt-"+data_id).value = this.floating_point(final_amount);
       

      //if(data_target==='var-item-price' || data_target==='var-item-total-txt' || data_target==='var-item-pp-tax' ||data_target==='var-item-pp-tax-type' || data_target==='var-item-pp-disc' || data_target==='var-item-quantity'){
        this.handleVariationIncludeTaxCalculation(data_id);
      //}
    }
    handleVariationIncludeTaxCalculation = (id)=>{
        let is_checked = document.getElementById("var-item-pp-tax-type-"+id).value;

        let cgst = document.getElementById("var-item-cgst-"+id).value;
        let sgst = document.getElementById("var-item-sgst-"+id).value;
        let igst = document.getElementById("var-item-igst-"+id).value;
        let quantity = document.getElementById("var-item-quantity-"+id).value.replace(/,/g , "");
        if(quantity===""){
          quantity = 1;
        }else{
          quantity = parseInt(quantity);
        }
        
        if(is_checked==='including'){  
        let price = document.getElementById("var-item-price-hidden-"+id).value;
        if(price===""){
          price = document.getElementById("var-item-price-"+id).value;
          document.getElementById("var-item-price-hidden-"+id).value = price;
        }
        
        let discount = 0;
        if(document.getElementById("var-pp-discount-hidden-"+id)){
          discount = document.getElementById("var-pp-discount-hidden-"+id).value;
          if(discount===""){
            discount=0;
          }
          discount = parseFloat(discount);
        }
         
        
        let tax = 0;
        if((cgst!=="" && cgst!=="0")  && (sgst!=="" && sgst!=="0")){
          
          cgst = parseFloat(cgst);
          sgst = parseFloat(sgst);
          let total_cgst = 0;
          let total_sgst =0;
        
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + (sgst+cgst) ) ) );

          GST = price-GST;

          GST = parseFloat(this.floating_point(GST));

          let gross_amount = GST-(discount/quantity);
          
          let TOTAL_GST = price-GST;

          total_cgst = TOTAL_GST/2;
          total_sgst = TOTAL_GST/2;

          total_cgst = (quantity*total_sgst);
          total_sgst = (quantity*total_sgst);
          document.getElementById("var-item-cgst-total-"+id).value = this.floating_point(total_cgst);
          document.getElementById("var-item-sgst-total-"+id).value = this.floating_point(total_sgst);
          document.getElementById("var-item-price-"+id).value = this.floating_point(gross_amount);
          price = (quantity*price)-discount;
          
          document.getElementById("var-item-total-txt-"+id).value =this.floating_point(price);
        }else if(igst!=="" && igst!=="0"){
          
          igst = parseFloat(igst);
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + igst ) ) );
          
          GST = price-GST;
          GST = parseFloat(this.floating_point(GST));
         
          let gross_amount = GST-(discount/quantity);
          let TOTAL_GST = price-GST;
          let total_igst = TOTAL_GST;
          total_igst = (quantity*total_igst);
          document.getElementById("var-item-igst-total-"+id).value = this.floating_point(total_igst);
          document.getElementById("var-item-price-"+id).value = this.floating_point(gross_amount);
          price = (quantity*price)-discount;
          
          document.getElementById("var-item-total-txt-"+id).value =this.floating_point(price);
        }else{
          let price = document.getElementById("var-item-price-hidden-"+id).value;
          if(price===""){
            price = document.getElementById("var-item-price-"+id).value;
            document.getElementById("var-item-price-hidden-"+id).value = price;
          }
          price = price.replace(/,/g , "");
          
          document.getElementById("var-item-price-"+id).value = this.floating_point(price);
          
          price = parseFloat(price);

          let disc = 0;
          let total_discount=0;
          if(document.getElementById("var-item-pp-disc-"+id)){
            disc = document.getElementById("var-item-pp-disc-"+id).value;
            if(disc===""){
              disc = 0;
            }else{
              disc = parseFloat(disc);
            }
            total_discount = (quantity*price)*disc/100;
            //document.getElementById("discount-hidden-"+id).value = total_discount;
          }

          price = (quantity*price)-total_discount;
          let cgst = document.getElementById("var-item-cgst-"+id).value;
          let sgst = document.getElementById("var-item-sgst-"+id).value;
          let igst = document.getElementById("var-item-igst-"+id).value;

          let t_cgst = (price)*cgst/100;
          let t_sgst = (price)*sgst/100;
          let t_igst = (price)*igst/100;

          
          document.getElementById("var-item-cgst-total-"+id).value = t_cgst;
          document.getElementById("var-item-sgst-total-"+id).value = t_sgst;
          document.getElementById("var-item-igst-total-"+id).value = t_igst;

          //price = price+t_cgst+t_sgst+t_igst;

          
          document.getElementById("var-item-total-txt-"+id).value = this.floating_point(price);
        }
      }else{
          
          if(((cgst!=="" && cgst!=="0") && (sgst!=="" && sgst!=="0")) || (igst!=="" && igst!=="0")){
            
          let price = document.getElementById("var-item-price-hidden-"+id).value;
          if(price===""){
            price = document.getElementById("var-item-price-"+id).value;
            document.getElementById("var-item-price-hidden-"+id).value = price;
          }
          price = price.replace(/,/g , "");
          
          document.getElementById("var-item-price-"+id).value = this.floating_point(price);
          
          price = parseFloat(price);

          let disc = 0;
          let total_discount=0;
          if(document.getElementById("var-item-pp-disc-"+id)){
            disc = document.getElementById("var-item-pp-disc-"+id).value;
            if(disc===""){
              disc = 0;
            }else{
              disc = parseFloat(disc);
            }
            total_discount = (quantity*price)*disc/100;
            //document.getElementById("discount-hidden-"+id).value = total_discount;
          }

          price = (quantity*price)-total_discount;
          let cgst = document.getElementById("var-item-cgst-"+id).value;
          let sgst = document.getElementById("var-item-sgst-"+id).value;
          let igst = document.getElementById("var-item-igst-"+id).value;

          let t_cgst = (price)*cgst/100;
          let t_sgst = (price)*sgst/100;
          let t_igst = (price)*igst/100;

          
          document.getElementById("var-item-cgst-total-"+id).value = t_cgst;
          document.getElementById("var-item-sgst-total-"+id).value = t_sgst;
          document.getElementById("var-item-igst-total-"+id).value = t_igst;

          //price = price+t_cgst+t_sgst+t_igst;

          
          document.getElementById("var-item-total-txt-"+id).value = this.floating_point(price);
        }
      }
    }
    handleVariationFinalCalculation(e){
        let id = e.currentTarget.getAttribute('data-id');
        let is_checked = document.getElementById("var-item-pp-tax-type-"+id).value;

        let cgst = document.getElementById("var-item-cgst-"+id).value;
        let sgst = document.getElementById("var-item-sgst-"+id).value;
        let igst = document.getElementById("var-item-igst-"+id).value;
        let quantity = document.getElementById("var-item-quantity-"+id).value.replace(/,/g , "");
        if(quantity===""){
          quantity = 1;
        }else{
          quantity = parseInt(quantity);
        }
        
        let price = e.currentTarget.value;
        if(price===""){
          price = 0;
        }else{
          price = parseFloat(price);
          price = price/quantity;
        }
        if(is_checked==='including'){  
        //let price = document.getElementById("price-hidden").value;

       /* if(price===""){
          price = document.getElementById("price").value;
          document.getElementById("price-hidden").value = price;
        }*/
        let discount = 0;
        if(document.getElementById("var-pp-discount-hidden-"+id)){
          discount = document.getElementById("var-pp-discount-hidden-"+id).value;
          if(discount===""){
            discount=0;
          }
          discount = parseFloat(discount);
        }

        
        let tax = 0;
        if((cgst!=="" && cgst!=="0")  && (sgst!=="" && sgst!=="0")){
          
          cgst = parseFloat(cgst);
          sgst = parseFloat(sgst);
          let total_cgst = 0;
          let total_sgst =0;
        
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + (sgst+cgst) ) ) );

          GST = price-GST;

          GST = parseFloat(this.floating_point(GST));

          let gross_amount = GST-(discount/quantity);
          
          let TOTAL_GST = price-GST;

          total_cgst = TOTAL_GST/2;
          total_sgst = TOTAL_GST/2;

          total_cgst = (quantity*total_sgst);
          total_sgst = (quantity*total_sgst);
          document.getElementById("var-item-cgst-total-"+id).value = this.floating_point(total_cgst);
          document.getElementById("var-item-sgst-total-"+id).value = this.floating_point(total_sgst);
          document.getElementById("var-item-price-"+id).value = this.floating_point(gross_amount);
          //price = (quantity*price)-discount;
         
        }else if(igst!=="" && igst!=="0"){
          
          igst = parseFloat(igst);
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + igst ) ) );
          
          GST = price-GST;
          GST = parseFloat(this.floating_point(GST));
        
          let gross_amount = GST-(discount/quantity);
         

          let TOTAL_GST = price-GST;
          let total_igst = TOTAL_GST;
          total_igst = (quantity*total_igst);
          document.getElementById("var-item-igst-total-"+id).value = this.floating_point(total_igst);
          document.getElementById("var-item-price-"+id).value = this.floating_point(gross_amount);
        } 
      }
    }
    
  render() {
    if(localStorage.getItem('is_auto_generate_barcode')=="true"){
      auto_generate_barcode = 'Auto Generate';
    }
  return  <tr className="variation-item" id={"variation-item-"+this.props.index} data-id={this.props.index} style={{position:'relative'}}>
            <td id={"item-lbl-"+this.props.index} className={(this.props.item_data.inventory_id>0)?'hide':''}>
                {this.props.label}
            </td>
            <td style={{minWidth:'110px'}}>
              <input type="hidden" className="form-control" defaultValue={this.props.item_data.list_item_id} id={"var-item-id-"+this.props.index}/>
              <input type="hidden" className="form-control" defaultValue={this.props.item_data.item_id} id={"var-item-inv-id-"+this.props.index}/>
              <input id={"var-item-price-"+this.props.index} type="text" className="form-control"  onChange={this.handleVariationCalculation} data-id={this.props.index}  data-target="var-item-price" target="var-item-price" defaultValue={this.props.item_data.price}  tabindex={'111'+this.props.index}/>

              <input id={"var-item-price-hidden-"+this.props.index} type="text" className="form-control hide"  onChange={this.handleVariationCalculation} data-id={this.props.index}  data-target="var-item-price-hidden" target="var-item-price-hidden"  defaultValue={this.props.item_data.price_hidden}/>
              
            
              <input type="hidden" id={"item-option1-"+this.props.index} defaultValue={(this.props.item_data.value1)?this.props.item_data.value1:''} data-id={(this.props.item_data.option1)?this.props.item_data.option1:''} />
              <input type="hidden" id={"item-option2-"+this.props.index} defaultValue={(this.props.item_data.value2)?this.props.item_data.value2:''} data-id={(this.props.item_data.option2)?this.props.item_data.option2:''} />
              <input type="hidden" id={"item-option3-"+this.props.index} defaultValue={(this.props.item_data.value3)?this.props.item_data.value3:''} data-id={(this.props.item_data.option3)?this.props.item_data.option3:''}/ >
              
            </td>
            <td style={{minWidth:'110px'}}  className="hide-pur-opt-check" data-id="ppdisc">
             <input id={"var-item-pp-disc-"+this.props.index} type="number" className="form-control" defaultValue={(this.props.item_data.pp_discount)?this.props.item_data.pp_discount:this.props.item_data.discount} onChange={this.handleVariationCalculation} data-id={this.props.index}  data-target="var-item-pp-disc" target="var-item-pp-disc" tabindex={'112'+this.props.index}/>
            </td>
            <td style={{minWidth:'130px'}}  className="hide-pur-opt-check" data-id="ppgst">
              <input list={"var-item-pp-tax-dd-"+this.props.index} className="form-control" placeholder="%" id={"var-item-pp-tax-"+this.props.index} onChange={this.handleVariationCalculation} data-id={this.props.index}  data-target="var-item-pp-tax" target="var-item-pp-tax" defaultValue={this.props.item_data.tax}  tabindex={'113'+this.props.index}/>
              <datalist id={"var-item-pp-tax-dd-"+this.props.index}>
              
                {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                   return (
                    <option>{value.value}</option>
                  )
                }) : '' }

              </datalist>
            </td>
            <td style={{minWidth:'130px'}} className="hide-pur-opt-check" data-id="pptaxtype">
              <select className="form-control" id={"var-item-pp-tax-type-"+this.props.index} onChange={this.handleVariationCalculation} data-id={this.props.index}  data-target="var-item-pp-tax-type" target="var-item-pp-tax-type" defaultValue={this.props.item_data.pp_tax_type}  tabindex={'114'+this.props.index}>
                <option value="excluding">Exc GST</option>
                <option value="including">Inc GST</option>
              </select>

              <input type="text" className="hide form-control var-item-cgst" placeholder="%" id={"var-item-cgst-"+this.props.index} data-id={this.props.index}  data-target="var-item-cgst"  target="icgst" defaultValue={this.props.item_data.cgst} />

              <input type="text" id={"var-item-cgst-hidden-"+this.props.index} data-id={this.props.index} className="var-item-cgst-hidden hide" data-target="var-item-cgst-hidden" defaultValue={this.props.item_data.total_cgst}/>

              <input type="text" id={"var-item-cgst-total-"+this.props.index} data-id={this.props.index} className="var-item-cgst-total hide" data-target="var-item-cgst-total" defaultValue={this.props.item_data.total_cgst}/>

              <input type="text" className="hide form-control var-item-sgst" placeholder="%" id={"var-item-sgst-"+this.props.index} data-id={this.props.index}  data-target="var-item-sgst"  target="isgst" defaultValue={this.props.item_data.sgst}/>

              <input type="text" className="hide var-item-sgst-hidden" id={"var-item-sgst-hidden-"+this.props.index} data-id={this.props.index}  data-target="var-item-sgst-hidden" defaultValue={this.props.item_data.total_sgst}/>

              <input type="text" className="hide var-item-sgst-total" id={"var-item-sgst-total-"+this.props.index} data-id={this.props.index}  data-target="var-item-sgst-total" defaultValue={this.props.item_data.total_sgst}/>

              <input type="text" className="hide form-control var-item-igst" placeholder="%" id={"var-item-igst-"+this.props.index} data-id={this.props.index}  data-target="var-item-igst"   target="iigst"  defaultValue={this.props.item_data.igst}/>

              <input type="text" className="hide var-item-igst-hidden" id={"var-item-igst-hidden-"+this.props.index} data-id={this.props.index}  data-target="var-item-igst-hidden"  defaultValue={this.props.item_data.total_igst}/>

              <input type="text" className="hide var-item-igst-total" id={"var-item-igst-total-"+this.props.index} data-id={this.props.index}  data-target="var-item-igst-total"  defaultValue={this.props.item_data.total_igst}/>

              <input type="text" className="hide form-control var-item-lowest-selling-price" placeholder=""  id={"var-item-lowest-selling-price-"+this.props.index} data-id={this.props.index} data-target="var-item-lowest-selling-price"/>

              <input type="text" className="hide var-item-pp-discount-hidden" id={"var-pp-discount-hidden-"+this.props.index} defaultValue={this.props.item_data.total_discount}/>
            </td>
            <td style={{minWidth:'110px'}}>
             <input id={"var-item-selling-price-"+this.props.index} type="text" className="form-control" defaultValue={this.props.item_data.price2}  tabindex={'115'+this.props.index} data-target="var-item-selling-price" data-id={this.props.index}/>
            </td>
            <td style={{minWidth:'110px'}}  className="hide-pur-opt-check" data-id="sdisc">
             <input id={"var-item-sp-disc-"+this.props.index} type="number" className="form-control" defaultValue={this.props.item_data.sp_discount}  tabindex={'6'+this.props.index}  data-target="var-item-sp-disc" data-id={this.props.index}/>
            </td>
            <td style={{minWidth:'130px'}} className="hide-pur-opt-check" data-id="sgst">
              <input list={"var-item-sp-tax-dd-"+this.props.index} className="form-control" placeholder="%" id={"var-item-sp-tax-"+this.props.index}  defaultValue={this.props.item_data.sp_tax}  tabindex={'117'+this.props.index} data-target="var-item-sp-tax" data-id={this.props.index}/>
              <datalist id={"var-item-sp-tax-dd-"+this.props.index}>
              
                {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                   return (
                    <option>{value.value}</option>
                  )
                }) : '' }

              </datalist>
            </td>
            <td style={{minWidth:'130px'}} className="hide-pur-opt-check" data-id="staxtype">
              <select className="form-control" id={"var-item-sp-tax-type-"+this.props.index} defaultValue={this.props.item_data.sp_tax_type}  tabindex={'118'+this.props.index} data-target="var-item-sp-tax-type" data-id={this.props.index}>
                <option value="excluding">Exc GST</option>
                <option value="including">Inc GST</option>
              </select>
            </td>
            <td style={{minWidth:'110px'}}><input id={"var-item-quantity-"+this.props.index} type="text" className="form-control" onChange={this.handleVariationCalculation} data-id={this.props.index}  data-target="var-item-quantity" target="var-item-quantity" defaultValue={this.props.item_data.quantity}  tabindex={'119'+this.props.index}/>
              
            <input id={"var-item-sku-"+this.props.index} type="hidden" className="form-control" defaultValue={this.props.item_data.sku}/>
            </td>
           
            <td style={{minWidth:'110px'}} className="hide-pur-opt-check" data-id="pbarcode">
              <input id={"var-item-barcode-"+this.props.index} type="text" className="form-control" defaultValue={(this.props.item_data && this.props.item_data.barcode)?this.props.item_data.barcode:auto_generate_barcode}  tabindex={'120'+this.props.index}/>
            </td>
            <td style={{minWidth:'110px'}} className="hide-pur-opt-check" data-id="paliasbarcode">
              <input id={"var-item-alias-barcode-"+this.props.index} type="text" className="form-control" defaultValue={(this.props.item_data && this.props.item_data.alias_barcode)?this.props.item_data.alias_barcode:''}  tabindex={'121'+this.props.index}/>
            </td>
            <td style={{minWidth:'110px'}} className="hide-pur-opt-check" data-id="preorderquantity">
              <input id={"var-re-order-quantity-"+this.props.index} type="text" className="form-control" defaultValue={(this.props.item_data && this.props.item_data.re_order_quantity)?this.props.item_data.re_order_quantity:''}  tabindex={'122'+this.props.index}/>
            </td>
            <PurchaseCustomFields prefix="var" target='cf-fields' cls={this.props.index} tabindex={'123'+this.props.index} custom_fields_json={(this.props.item_data && this.props.item_data.custom_fields_json)?this.props.item_data.custom_fields_json:''}/>
            <td style={{minWidth:'130px'}} className="hide-pur-opt-check" data-id="stotal"><input type="text" className="form-control var-item-total-txt " id={"var-item-total-txt-"+this.props.index} data-id={this.props.index}  data-target="var-item-total-txt" onChange={this.handleVariationFinalCalculation} defaultValue={this.props.item_data.total}  tabindex={'124'+this.props.index}/></td>
          
            <td><FontAwesomeIcon icon={faTrash} id={this.props.index} data-id={this.props.index} onClick={this.handleRemoveItem}/></td>
            
          </tr>
  }
}

class VariationPreviewList extends Component {
  constructor(props) {
      super(props)
      this.state = {
          
      }
      this.handlePreviewEvent = this.handlePreviewEvent.bind(this);
      this.currency_format = utils.currency_format.bind(this);
      this.floating_point = utils.floating_point.bind(this);
  }

  handleCalculateMUPrice = (price) =>{
    let selling_price_margin = document.getElementById("selling_price_margin").value;
    let mu_price =0;
    if(selling_price_margin!==""){
      selling_price_margin = parseFloat(selling_price_margin);
      let calculate_sp = price/(1-(selling_price_margin/100))
      let new_sp = calculate_sp;
      mu_price = Math.round(new_sp);
    }
    return mu_price;
  }

  handlePreviewEvent(e){
      let id = e.currentTarget.id;
      let tval = e.currentTarget.value;

      let globa_pp = document.getElementById("prev-item-price").value;

      if(globa_pp===""){
        globa_pp = 0;
      }else{
        globa_pp = parseFloat(globa_pp);
      }

      let mu_price = this.handleCalculateMUPrice(globa_pp);

      if(id==='prev-item-price'){
        document.getElementById("prev-item-selling-price").value = mu_price;
      }
      

      let data_target = e.currentTarget.getAttribute('data-target');
      data_target = data_target.replace('prev','var');
      let dtarget = data_target;
      if(data_target==='var-item-price-increment'){
        dtarget = 'var-item-price';
      }else if(data_target==='var-item-sp-increment'){
        dtarget = 'var-item-selling-price';
      }
      localStorage.setItem('ctarget',dtarget);
      let dt = document.querySelectorAll('[data-target='+data_target+']');
      let pp_increment = document.getElementById('prev-item-price-increment').value;
      let sp_increment = document.getElementById('prev-item-sp-increment').value;
      if(pp_increment==='' || isNaN(pp_increment)){
        pp_increment = 0;
      }
      if(sp_increment==='' || isNaN(sp_increment)){
        sp_increment = 0;
      }
      if(data_target==='var-item-price-increment'){
        dt = document.querySelectorAll('[data-target=var-item-price]');
      }else if(data_target==='var-item-sp-increment'){
        dt = document.querySelectorAll('[data-target=var-item-selling-price]');
      }
      for(let i=0;i<dt.length;i++){
        let did = dt[i].getAttribute('data-id');

        if((data_target==='var-item-price' || data_target==='var-item-price-increment') && i===0){
          if(data_target==='var-item-price-increment'){
            tval = document.getElementById("var-item-price-"+did).value;
          }
          if(document.getElementById("var-item-price-"+did)){
            document.getElementById("var-item-price-"+did).value = tval;
            mu_price = this.handleCalculateMUPrice(tval);
            document.getElementById("var-item-selling-price-"+did).value = mu_price;
          }
          if(document.getElementById("var-item-price-hidden-"+did)){
            document.getElementById("var-item-price-hidden-"+did).value = tval;
          }
        }else if((data_target==='var-item-price' || data_target==='var-item-price-increment') && i>0){
          if(document.getElementById("var-item-price-"+did)){
            tval = (document.getElementById("var-item-price-"+(did-1)))?document.getElementById("var-item-price-"+(did-1)).value:"";
            if(tval!==""){
              if(document.getElementById("var-item-price-"+did)){
                let isp = this.floating_point(parseFloat(tval)+parseFloat(pp_increment));
               document.getElementById("var-item-price-"+did).value = isp;
               mu_price = this.handleCalculateMUPrice(isp);
               document.getElementById("var-item-selling-price-"+did).value = mu_price;
              }
            }else{
              if(document.getElementById("var-item-price-"+did)){
                document.getElementById("var-item-price-"+did).value = '';
              }
            }
          }
        }else if((data_target==='var-item-selling-price' || data_target==='var-item-sp-increment') && i===0){
          if(data_target==='var-item-sp-increment'){
            if(document.getElementById("var-item-selling-price-"+did)){
              tval = document.getElementById("var-item-selling-price-"+did).value;
            }
          }
          if(document.getElementById("var-item-selling-price-"+did)){
            document.getElementById("var-item-selling-price-"+did).value = tval;
          }

        }else if((data_target==='var-item-selling-price' || data_target==='var-item-sp-increment') && i>0){
          if(document.getElementById("var-item-selling-price-"+did)){

            tval = (document.getElementById("var-item-selling-price-"+(did-1)))?document.getElementById("var-item-selling-price-"+(did-1)).value:"";
            if(tval!==""){
               document.getElementById("var-item-selling-price-"+did).value = this.floating_point(parseFloat(tval)+parseFloat(sp_increment));
            }else{
               document.getElementById("var-item-selling-price-"+did).value = '';
            }
          }
        }else{
          dt[i].value = tval;
        }
      }
      document.getElementById("handleCalculationOnClick").click();
  }
  
  render() {
    return  <tr className="variation-item1">
            <td style={{minWidth:'110px'}}>
              <input id={"prev-item-price"} type="text" className="form-control"  onChange={this.handlePreviewEvent} data-id={1} data-target="prev-item-price" target="prev-item-price"  tabindex={'111'}/>
            </td>
            <td style={{minWidth:'110px'}}>
              <input id={"prev-item-price-increment"} type="text" className="form-control" data-id={1} data-target="prev-item-price-increment" target="prev-item-price-increment"  tabindex={'112'} onChange={this.handlePreviewEvent}/>
            </td>
            <td style={{minWidth:'110px'}} className="hide-pur-opt-check" data-id="ppdisc">
             <input id={"prev-item-pp-disc"} type="text" className="form-control" onChange={this.handlePreviewEvent} data-id={1}  data-target="prev-item-pp-disc" target="prev-item-pp-disc" tabindex={'113'}/>
            </td>
            <td style={{minWidth:'130px'}} className="hide-pur-opt-check" data-id="ppgst">
              <input list={"prev-item-pp-tax-dd"} className="form-control" placeholder="%" id={"prev-item-pp-tax"} onChange={this.handlePreviewEvent} data-id={1}  data-target="prev-item-pp-tax" target="prev-item-pp-tax" tabindex={'114'}/>
              <datalist id={"prev-item-pp-tax-dd"}>
                {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                   return (
                    <option>{value.value}</option>
                  )
                }) : '' }
              </datalist>
            </td>
            <td style={{minWidth:'130px'}} className="hide-pur-opt-check" data-id="pptaxtype">
              <select className="form-control" id={"prev-item-pp-tax-type"} onChange={this.handlePreviewEvent} data-id={1}  data-target="prev-item-pp-tax-type" target="prev-item-pp-tax-type"  tabindex={'115'}>
                <option value="excluding">Exc GST</option>
                <option value="including">Inc GST</option>
              </select>
            </td>
            <td style={{minWidth:'110px'}}>
             <input id={"prev-item-selling-price"} data-target={"prev-item-selling-price"} type="text" className="form-control" tabindex='116' onChange={this.handlePreviewEvent}/>
            </td>
             <td style={{minWidth:'110px'}}>
              <input id={"prev-item-sp-increment"} type="text" className="form-control" data-id={1} data-target="prev-item-sp-increment" target="prev-item-sp-increment"  tabindex={'117'} onChange={this.handlePreviewEvent}/>
            </td>
            <td style={{minWidth:'110px'}} className="hide-pur-opt-check" data-id="sdisc">
             <input id={"prev-item-sp-disc"}  data-target={"prev-item-sp-disc"} type="text" className="form-control" tabindex={'118'} onChange={this.handlePreviewEvent}/>
            </td>
            <td style={{minWidth:'130px'}} className="hide-pur-opt-check" data-id="sgst">
              <input list={"prev-item-sp-tax-dd"} className="form-control" placeholder="%" id={"prev-item-sp-tax"} tabindex={'119'} data-target={"prev-item-sp-tax"} onChange={this.handlePreviewEvent}/>
              <datalist id={"prev-item-sp-tax-dd"}>
                {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                   return (
                    <option>{value.value}</option>
                  )
                }) : '' }
              </datalist>
            </td>
            <td style={{minWidth:'130px'}} className="hide-pur-opt-check" data-id="staxtype">
              <select className="form-control" id={"prev-item-sp-tax-type"} tabindex={'120'} data-target={"prev-item-sp-tax-type"} onChange={this.handlePreviewEvent}>
                <option value="excluding">Exc GST</option>
                <option value="including">Inc GST</option>
              </select>
            </td>
            <td style={{minWidth:'110px'}}>
              <input id={"prev-item-quantity"} type="text" className="form-control" onChange={this.handlePreviewEvent} data-id={1}  data-target="prev-item-quantity" target="prev-item-quantity" tabindex={'121'} />
            </td>
          </tr>
  }
}