import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import ContactsDropdown from "./contacts-dropdown";
import PaymentModes from "./payment-modes";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch,faPlus,faSpinner,faCalculator,faShoppingCart, faPlusCircle,faCheckCircle, faTimesCircle, faLeaf, faTimes, faKey  } from '@fortawesome/free-solid-svg-icons';
import 'animate.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BillingItemList from './billing-item-list';
import ContactField from './contact-field';
import ClosedFYButton from "./closed-fy-button";
import ModalBox from "./modal-box";
import Autocomplete from "./autocomplete";
import AddBillingItem from "./add-billing-item";
import ShortcutHelp from "./shortcut-help.js";
import check_access from './access-level.js';
import encrypt_decrypt from './encrypt-decrypt.js';
import ServiceLoader from './service-loader.js';
import PrintBill from './print-bill.js';
import HoldBillPopup from './hold-bill-list-popup.js';
import utils from '../utils.js';
import { ToastContainer, toast } from 'react-toastify';
import BillPaymentPopup from "./bill-payment-popup";
import '../../node_modules/react-toastify/dist/ReactToastify.css';
import ContactSelect from "./contact-select-on-type";
import DrawerLink from "./open-drawer-link";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}
function redirectToSettings(){
  window.location.href = '/settings/billings-and-payments';
}
let _this;
export default class NewBilling extends Component {
    constructor() {
        super();
        this.state = {
            otp:'',
            otp_error:'',
            bill_hours : 0,
            is_store : false,
            is_asked_otp : false,
            is_validated_otp : false,
            ask_otp_while_update_sale : false,
            item_discount_on_mrp : false,
            transport_id : 0,
            encrypted_selling_price : '',
            enc_profit : '',
            total_profit : '',
            is_show_enc_profit : false,
            unlink_transports : [],
            unlink_transports_data : [],
            bill_popup_total : 0,
            module:'billing',
            is_global_commission:false,
            global_commission : 0,
            selected_staff_while_billing : false,
            tcs_percent : 0,
            other_charges : 0,
            contact_id : 0,
            contact_name : '',
            response_id : 0,
            save_button:'Create Sale',
            send_type : 'save',
            update_id : 0,
            more_rows : '',
            billing_type : 'New Sale',
            payment_mode : 'payment',
            currrent_sequence : '',
            currrent_sequence_no : '',
            billingDate:  new Date(),
            invoiceDate:  new Date(),
            contact_info : '',
            billing_data : '',
            target : 'sale',
            round_off_opt : true,
            round_off_opt2 : false,
            discount_opt : true,
            discount_opt2 : false,
            is_unsaved_list : false,
            round_off : 0,
            round_off_sym :'',
            global_discount : '',
            global_discount_amount : '',
            discount_sym :'',
            on_load : true,
            lowest_selling_price_percent : '',            
            referer : 1, 
            staff_options:[], 
            agent_options:[], 
            assisted_by : '',  
            agent_name : '',  
            add_item_area :'',  
            is_reedeem:false,   
            show_toast:false,  
            created_variations : [], 
            credit_bill_general_contact : false,
            default_inventory_status : 'Draft',
            payment_modes:[],
            isMobile : false,
            redeem_error : '',
            note_type : '',
            is_resume_cancelled : false,
            buffer_amount_for_credit_limit : '',
            is_item_return : false,
            show_hold_button : true,
            contact_error : '',
            on_account_error : '',
            is_on_account_return : false,
            pending_bills : [],
            return_goods_bills : [],
            pending_loader:'No record(s) found.',
            is_direct_pay : false,
            customer_advance_payment : 0,
            show_loader:true,
            whatsapp_phone:'',
            whatsapp_msg : '',
            advance_sale_data : false,
            dispatched_info : '',
            is_fy_closed : false,
            change_pay_to_save : false,	
            is_block_visible : false,	
            return_type : '',	
            return_option : '',	
            is_contact_selected : false,	
            is_return_bill_search : false,	
            returned_invoice_id : 0,	
            searched_invoice_items : [],	
            inv_active:0,	
            check_global_disc : 0,
            global_discount_rs : 0,
            global_discount_per : 0,
            barcode_name_on_bill_view : false,
            item_name_on_bill_view : false,
            is_convert_to_sale : 0,
            is_pay_popup_triggered : false,
            is_triggered_save : false,
            is_loaded_bill : false,
            enable_tcs : false,
            is_add_item_btn : false,
            default_payment_mode_for_sale : '',
            default_payment_mode_for_parchase : '',
            default_payment_mode : 1,
            business_type : '',
            is_remaining_amount : false,
            remaining_amount : 0,
            pending_ret_adj_amt : 0,
            transportation_charge : 0,
            pending_ret_adj_opt : '',
            print_data : {
              "data": {
                "schema": "",
                "payment_info": [],
                "pending_amount": "0",
                "payment_mode": '',
                "payment_mode_txt": "",
                "customer_advance_payment": 0,
                "target": "",
                "bundle_text": "",
                "total": "",
                "id": 0,
                "branch_id": 1,
                "contact_id": 0,
                "transport_id": 0,
                "note": "",
                "total_unit": "",
                "sub_total": "",
                "tax": "0",
                "total_sgst": "0",
                "total_igst": "0",
                "total_cgst": "0",
                "number": "#PO51",
                "number_sequence": "",
                "c_date": "",
                "created_date": "",
                "bill_date": "",
                "bill_time": "",
                "visible_created_date": "",
                "inv_date": "",
                "invoice_date": "",
                "visible_invoice_date": "",
                "discount": "0",
                "is_current": true,
                "total_before_tax": 0,
                "calculate_total": 0,
                "global_discount": "",
                "global_discount_sym": "",
                "round_off": 0,
                "round_off_sym": "-",
                "buyer_order_no": "",
                "dispatched_through": "",
                "dispatched_document": "",
                "supplier_ref_no": "",
                "delivery_note": "",
                "other_note": "",
                "other_refrence": "",
                "terms_of_delivery": "",
                "bill_status": "Unpaid",
                "bill_status_id": 1,
                "assisted_by": 0,
                "agent_name": "",
                "payment_note_number": "0",
                "before_payment_note_redeem_total": "",
                "payment_note_redeem_total": "",
                "pieces_in_parcel": "",
                "no_of_parcels": "",
                "packed_by": "",
                "checked_by": "",
                
                "note_info": {
                  "amount": 0
                },
                "user_info": [
                  
                ],
                "contact_info": [
                  
                ],
                "company_info": [
                ],
                "credit_data": {
                 
                },
                "list_items": [
                  
                ],
                "quantity": 0,
                "is_direct_pay": false,
                "payments_meta_data": [],
                "payments_comma_sep": "",
                "return_data": [],
                "return_meta_data": [],
                "custom_data" : [],
              },
              "barcode_print_data": [
                {
                  
                }
              ],
              "is_empty": false,
              "dispatched_info": ''
            },
            lr_error:'',
            lr_error_info : {},
            rg_deleted_record : [],
            selected_contact : 1,
            selected_contact_info : []
        };
        var today = new Date();
        this.today_date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
        _this = this;
        this.search_list_items = [];
        this.timeout = null;
        this.more_rows = [];
        this.add_item_area = [];
        this.variant_data = [];
        this.types = {sales:"New Sale",purchase:"New Purchase",sales_return:"New Sales Return",purchase_return:"New Purchase Return"};
        this.sequence = [];
        this.send_data_items = [];
        this.send_return_data_items = [];
        this.saveBilling = this.saveBilling.bind(this);
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        this.operateCheck = this.operateCheck.bind(this);
        this.calculateReturnTotal = this.calculateReturnTotal.bind(this);
        this.handleRoundOff = this.handleRoundOff.bind(this);
        this.handleRoundOffChange = this.handleRoundOffChange.bind(this);
        this.handleGlobalDiscount = this.handleGlobalDiscount.bind(this);
        this.handleGlobalDiscountChange = this.handleGlobalDiscountChange.bind(this);
        this.handleChangeStaff = this.handleChangeStaff.bind(this);
        this.handleChangeAgent = this.handleChangeAgent.bind(this);
        this.addMoreItem = this.addMoreItem.bind(this);
        
        this.handleRedeemNote = this.handleRedeemNote.bind(this);
        this.handleNoteTypeChange = this.handleNoteTypeChange.bind(this);
        this.handleEnableReturnInfo = this.handleEnableReturnInfo.bind(this);
        this.handleChangeDefaultInventory = this.handleChangeDefaultInventory.bind(this);
        this.IsJsonString = this.IsJsonString.bind(this);
        this.handleCreateVariation = this.handleCreateVariation.bind(this);
        this.handleCheckAdvancePayment = this.handleCheckAdvancePayment.bind(this);
        this.handleCheckOnAccount = this.handleCheckOnAccount.bind(this);
        this.handleLoadPendingInvoice = this.handleLoadPendingInvoice.bind(this);
        this.handleCheckPendingInvoice = this.handleCheckPendingInvoice.bind(this);
        this.getGoodsData = this.getGoodsData.bind(this);
        this.enableDirectPay = this.enableDirectPay.bind(this);
        this.disableDirectPay = this.disableDirectPay.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.moveToPayment = this.moveToPayment.bind(this);
        this.shareMessage = this.shareMessage.bind(this);
        this.is_access = check_access.is_access.bind(this);
        this.decrypt_sanitize = encrypt_decrypt.decrypt_sanitize.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.isMobile = utils.isMobile.bind(this);
        this.CHECK_CREDIT_LIMIT = utils.CHECK_CREDIT_LIMIT.bind(this);
        this.CHECK_INTERNET_CONNECTION = utils.CHECK_INTERNET_CONNECTION.bind(this);
        this.ADJUST_BILL_SNO = utils.ADJUST_BILL_SNO.bind(this);
        localStorage.removeItem('item_options');
        localStorage.removeItem('purchase_item_options');
    }
    handleDateFormat = (date) =>{
      var d = new Date(date);
      
      let month =(d.getMonth() + 1);
      let day =d.getDate();
      let year = d.getFullYear();
      let new_date = day+'/'+month+'/'+year;
      new_date = date.toLocaleDateString("en-GB", { // you can use undefined as first argument
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      return new_date;
    }
    handleSendOTP = ()=>{
      let billing_data = this.state.billing_data;
      let contact_info = billing_data.contact_info[0];
      let updated_data = {'bill_number':this.state.currrent_sequence,'old_customer':contact_info.name,'old_date':billing_data.bill_date,'customer':'','date':'',payment_mode:'',list_items:[]};
      
      let list_items = this.state.billing_data.list_items;
      let old_items = {};
      for (let index = 0; index < list_items.length; index++) {
        const element = list_items[index];
        let item_id = element.list_item_id;
        let price = element.price;
        let quantity = element.quantity;
        let discount = element.discount;
        old_items[item_id] = {item_name:element.item_name, price:price, quantity:quantity,discount:discount}
      }
     
      const new_date = this.handleDateFormat(this.state.billingDate);
      if(billing_data.bill_date!==new_date){
        let changed = {old_value:billing_data.bill_date,new_value:new_date};
        updated_data.date = changed;
      }
     
      let contact_name = document.getElementById("contact_box").value;
      let old_contact_name = contact_info.name.toLowerCase();
      old_contact_name = old_contact_name.trim();
      let new_contact_name = contact_name.toLowerCase();
      new_contact_name = new_contact_name.trim();
      if(old_contact_name!==new_contact_name){
        let changed = {old_value:contact_info.name,new_value:contact_name};
        updated_data.customer = changed;
      }
      if(parseFloat(this.state.default_payment_mode)!==parseFloat(this.state.billing_data.payment_mode)){
        let changed = {old_value:this.state.default_payment_mode,new_value:this.state.billing_data.payment_mode};
        updated_data.payment_mode = changed;
      }
      var otp_inputs = document.querySelectorAll('.items-row'); 
    
      let item_update = [];
      for (var i = 0; i < otp_inputs.length; i++) { 
          let i_data = [{},{},{},{}];
         
          let idx = otp_inputs[i].getAttribute('data-id');
          let list_item_id = 0;
          if(document.getElementById("item-list-id-"+idx) && document.getElementById("item-list-id-"+idx).value!==""){
            list_item_id = document.getElementById("item-list-id-"+idx).value;
          }
          let old_name = '';
          let old_price = '';
          let old_quantity = '';
          let old_discount = '';
          if(old_items[list_item_id]!==undefined){
            old_name = old_items[list_item_id].item_name;
            old_price = old_items[list_item_id].price;
            old_quantity = old_items[list_item_id].quantity;
            old_discount = old_items[list_item_id].discount;
          }

          let item_text = document.getElementById("item-box-"+idx).value;
         
          let quantity = parseFloat(document.getElementById("item-quantity-"+idx).value);
            
          let price = document.getElementById("price-"+idx).value;
          
          let discount = 0;
          if(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase'){
            discount = document.getElementById("discount-"+idx).value;
            if(discount===""){
              discount = 0;
            }
          }
          
          if(old_name!==item_text){
            let changed = {key:'Product Name',old_value:old_name,new_value:item_text};
            i_data[0] = changed;
          }
          if(parseFloat(old_quantity)!==parseFloat(quantity)){
            let changed = {key:'Quantity',old_value:old_quantity,new_value:quantity};
            i_data[1] = changed;
          }
          if(parseFloat(old_price)!==parseFloat(price)){
            let changed = {key:'Price',old_value:old_price,new_value:price};
            i_data[2] = changed;
          }
          if(old_discount!==discount){
            let changed = {key:'Discount',old_value:old_discount,new_value:discount};
            i_data[3] = changed;
          }  
          item_update.push(i_data); 
        }
        updated_data.list_items = item_update;
    
      let set_data = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        id : this.state.update_id,
        target:'billing',
        uuid : utils.uuid(),
        updated_data : updated_data,
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
      }
      let opt_api = app_api+'/send_otp';
      axios.post(opt_api, set_data)
        .then(function (response) {
          let resp = response.data;
      }.bind(this)).catch(function(res){
          
      })
    }
    formatDate = (value) =>{
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '/' + month + '/' + year;
    }
    formatAMPM = (date) =>{
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    }

    handleSetSelectedContact = () =>{
      let selected_contact = localStorage.getItem("selected_contact");
      this.setState({selected_contact:selected_contact});
    }
    handleSetSelectedContactInfo = () =>{
      let con_info = JSON.parse(localStorage.getItem("contact_info"));
      this.setState({selected_contact_info:con_info});
    }

    setPrintData = (key, value, type='data') =>{
        let print_data = this.state.print_data;
        if(type==='data'){
          let data = print_data.data;
          data[key] = value;
          print_data.data = data;
        }else if(type==='dispatched_info'){
          print_data.dispatched_info = value;
        }
        this.setState({print_data:print_data})
    }

    shareMessage(sdata){
      let data = {
                    target:'new',
                    title:this.state.billing_type,
                    contact_name:document.getElementById("contact_box").value,
                    contact_phone:(this.state.contact_info && this.state.contact_info.phone_1!==undefined)?this.state.contact_info.phone_1:localStorage.getItem("phone_1"),
                    currrent_sequence:this.state.currrent_sequence,
                    bill_date:sdata.bill_date,
                    bill_time:sdata.bill_time,
                   
                    bill_total:document.getElementById("final-total-text").value,
                    sharable_link:encodeURI(sdata.sharable_link),
                }
      
      let txt_message =  utils.shareBillMessage(data);
      
      this.setState({whatsapp_msg:txt_message});
      let mobile = (this.state.contact_info && this.state.contact_info.phone_1!==undefined)?this.state.contact_info.phone_1:localStorage.getItem("phone_1");
      mobile = utils.check_mobile_number(mobile);
      this.setState({whatsapp_phone:mobile});
    }
    handlePrint(){
      let id = localStorage.getItem('response_id');
      this.setState({response_id:parseInt(id)});
    }

    handleOpenBillPaymentPopup = () =>{
      let contact_id = document.getElementById("customer-dropdown").value;
      let contact_name = document.getElementById("contact_box").value;
      this.setState({contact_id:contact_id,contact_name:contact_name});
      if(document.getElementById("product-variation-add") && document.getElementById("product-variation-add").style && document.getElementById("product-variation-add").style.display==='block'){
        return false;
      }
      if(document.getElementById("create-contact-on-fly") && document.getElementById("create-contact-on-fly").style && document.getElementById("create-contact-on-fly").style.display==='block'){
        return false;
      }
      let g_total = document.getElementById("final-total-text").value;
      let billing_data = this.state.billing_data;
      if(billing_data.before_payment_note_redeem_total !== undefined && billing_data.before_payment_note_redeem_total!==""){
        g_total = billing_data.before_payment_note_redeem_total;
      }
      this.setState({bill_popup_total:g_total});
      this.handleSetBillPopupData(g_total);

      if(this.state.billing_type==='New Sales Return' || this.state.billing_type==='New Purchase Return'){
        let remaining_amount = 0;
        if(this.state.return_option==='old_bill'){
          let pen_check = document.querySelectorAll(".pending-chk:checked");	
          let total_bill_amount = document.getElementById("final-total-text").value;
          total_bill_amount = parseFloat(total_bill_amount);
          let total_sel_bill_amt = 0;
          /* if(this.state.pending_ret_adj_amt>0){
            total_sel_bill_amt = this.state.pending_ret_adj_amt;
          }else{ */
            let ret_amt_qs = document.querySelectorAll(".ret-good-amt");
            for (let ri = 0; ri < ret_amt_qs.length; ri++) {
              const element = ret_amt_qs[ri];
              let rid = element.getAttribute('data-id');
              let retamt = document.getElementById("return-amt-"+rid).value;
              total_sel_bill_amt = total_sel_bill_amt+parseFloat(retamt);
            }
          //}
          for(let i=0;i<pen_check.length;i++){
            let elem = pen_check[i];
            let did = elem.getAttribute('data-id');
            let ped_amt = document.getElementById("pending-amt-"+did).value;
            total_sel_bill_amt = total_sel_bill_amt + parseFloat(ped_amt);
          }
          if(total_sel_bill_amt < total_bill_amount){
            remaining_amount = total_bill_amount - total_sel_bill_amt;
          }
        }
        this.setState({remaining_amount:remaining_amount});
        if(remaining_amount>0){
          this.setState({is_remaining_amount:true});
        }
      }
      setTimeout(() => {
        if(document.getElementById("handleManageTCSTransaction")){
          document.getElementById("handleManageTCSTransaction").click();
        }
        localStorage.setItem("p_mode",this.state.default_payment_mode);
        this.setState({change_pay_to_save:true});	
        var element = document.getElementById('bill-payment-popup');
        element.classList.remove("fade");
        element.style.display="block";
      }, 50);
      setTimeout(() => {
        if(billing_data.before_payment_note_redeem_total !== undefined && billing_data.before_payment_note_redeem_total!==""){
          if(document.getElementById('handleRedeemNoteIcon')){
            document.getElementById('handleRedeemNoteIcon').click();
          }
        }
      }, 500);
    }

    moveToPayment(e){
      if(document.getElementById("ptype_note")){
        document.getElementById("ptype_note").scrollIntoView();
      }
      let qs = document.querySelectorAll(".total-bill-amount");

      //setTimeout(function(){
        //if(qs[0]!==undefined){
          this.handleOpenBillPaymentPopup()
          if(qs[0]!==undefined){
            qs[0].focus();
          }
          return false;
        //}
      //},1000);
    }
    handleCheckAdvancePayment(e){
       let adv_pay = localStorage.getItem('adv_pay');
       this.setState({customer_advance_payment:adv_pay});
       localStorage.removeItem('adv_pay');
    }

    enableDirectPay(){
      this.setState({is_direct_pay:true});
    }
    disableDirectPay(){
      this.setState({is_direct_pay:false});
    }
    handleCheckPendingInvoice(e){
      let is_checked = e.currentTarget.checked;
      let data_id = e.currentTarget.getAttribute("data-id");
      let item_row = document.querySelectorAll(".billing-item-row");
      if(item_row.length===0 && is_checked===true){
          e.currentTarget.checked = false;
          document.getElementById("iitem-box").focus();
      }else{
        document.getElementById("pi-list-"+data_id).classList.toggle('active');
      }
      let pen_check = document.querySelector(".pending-chk:checked");	
      	
      if(pen_check!==null){	
        let cls = this.state.billing_type.replaceAll(" ",'-');	
        let visibleCls = document.querySelectorAll(".top-btn-blk."+cls);	
        for (let index = 0; index < visibleCls.length; index++) {	
          visibleCls[index].style.display='block';	
        }	
        visibleCls = document.querySelectorAll(".content-head."+cls);	
        for (let index = 0; index < visibleCls.length; index++) {	
          //visibleCls[index].style.display='block';	
        }	
        this.setState({change_pay_to_save:true});	
      }else{	
        let cls = this.state.billing_type.replaceAll(" ",'-');	
        let visibleCls = document.querySelectorAll(".top-btn-blk."+cls);	
        for (let index = 0; index < visibleCls.length; index++) {	
          visibleCls[index].style.display='none';	
        }	
        visibleCls = document.querySelectorAll(".content-head."+cls);	
        for (let index = 0; index < visibleCls.length; index++) {	
          //visibleCls[index].style.display='none';	
        }	
        this.setState({change_pay_to_save:false});	
      }
    }
    handleCheckOnAccount(e){
        let is_on_account_return = e.currentTarget.checked;
        this.setState({is_on_account_return:is_on_account_return});
        let item_row = document.querySelectorAll(".billing-item-row");
        if(is_on_account_return===true){
          this.handleLoadPendingInvoice();
        }
       /* }else{
          this.setState({on_account_error:'Please add an items'});
        }*/
    }
    handleLoadPendingInvoice(){
      let contact_id = document.getElementById("customer-dropdown").value;
      this.setState({pending_bills:[]});
      this.setState({pending_loader:'Loading...'});
      let customer = contact_id;
      let not_in_id = 0;
      if(document.getElementById("returned_invoice_id")){
        not_in_id = document.getElementById("returned_invoice_id").value;
      }
      let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              id : customer,  
              active_tab : (this.state.billing_type==='New Sales Return')?'IN':'OUT',
              target : this.state.billing_type,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              not_in_id : not_in_id,
              screen : 'billing'
           }
        let api = app_api+'/get_contact_pending_bills';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              this.setState({pending_bills:resp.pending_bills});
              if(resp.pending_bills.length===0){
                this.setState({pending_loader:'No Pending Invoice Found.'});
              }
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
    }

    calculateReturnTotal(){
      var item_sub_total = document.querySelectorAll('.ret-item-sub-total'); 
      var item_quantity = document.querySelectorAll('.ret-item-quantity'); 
      if(item_sub_total.length>0){
        let sub_total = 0;
        for (var i = 0; i < item_sub_total.length; i++) { 
            if(item_sub_total[i].value!==''){
              let quan = 0;
              if(item_quantity[i].value!==''){
                quan = parseFloat(item_quantity[i].value);
              }
              let i_s_total = (item_sub_total[i].value!=''?(item_sub_total[i].value):0);
              sub_total = sub_total + (quan*parseFloat(i_s_total));
            }
        } 
        document.getElementById("return-subtotal").innerHTML = this.currency_format(sub_total); 

        let item_tax = 0;
        let cgst_total =0;
        let sgst_total =0;
        let igst_total =0;
        var item_total_tax = document.querySelectorAll('.ret-item-cgst-total'); 
        for (var i = 0; i < item_total_tax.length; i++) { 
             let did = item_total_tax[i].getAttribute('data-id');
             let cgst = item_total_tax[i].value;
             let sgst = document.getElementById("ret-item-sgst-total-"+did).value;
             let igst = document.getElementById("ret-item-igst-total-"+did).value;
            
             cgst = parseFloat((cgst!=''?(cgst):0));
             sgst = parseFloat((sgst!=''?(sgst):0));
             igst = parseFloat((igst!=''?(igst):0));
             let iigst = document.getElementById("ret-item-igst-"+did).value;
             let isgst = document.getElementById("ret-item-sgst-"+did).value;
             let icgst = document.getElementById("ret-item-cgst-"+did).value;

             if(iigst==="" || iigst===0 || iigst==='0'){
              igst = 0;
             }
             if(isgst==="" || isgst===0 || isgst==='0'){
              sgst = 0;
             }
             if(icgst==="" || icgst===0 || icgst==='0'){
              cgst = 0;
             }
             cgst_total = cgst_total+cgst;
             sgst_total = sgst_total+sgst;
             igst_total = igst_total+igst;

             item_tax = item_tax + (cgst+sgst+igst);
        } 

        var item_final_total = document.querySelectorAll('.ret-item-total-txt'); 
        let item_total = 0;
        let total_unit = 0;
        for (var i = 0; i < item_final_total.length; i++) { 
              if(item_final_total[i].value!=='' && item_final_total[i].value!=='0'){
                total_unit++;
                item_total = item_total+parseFloat(item_final_total[i].value);
              }
        } 
    
        var item_total_discount = document.querySelectorAll('.ret-item-discount-hidden'); 
        let item_discount = 0;
        let i_discount = 0;
        for (var i = 0; i < item_total_discount.length; i++) { 
             let did = item_total_discount[i].getAttribute('data-id');
          
             let cgst = document.getElementById("ret-item-cgst-total-"+did).value;
             let sgst = document.getElementById("ret-item-sgst-total-"+did).value;
             let igst = document.getElementById("ret-item-igst-total-"+did).value;
            
             cgst = parseFloat((cgst!=''?(cgst):0));
             sgst = parseFloat((sgst!=''?(sgst):0));
             igst = parseFloat((igst!=''?(igst):0));
             let iigst = document.getElementById("ret-item-igst-"+did).value;
             let isgst = document.getElementById("ret-item-sgst-"+did).value;
             let icgst = document.getElementById("ret-item-cgst-"+did).value;

             if(iigst==="" || iigst===0 || iigst==='0'){
              igst = 0;
             }
             if(isgst==="" || isgst===0 || isgst==='0'){
              sgst = 0;
             }
             if(icgst==="" || icgst===0 || icgst==='0'){
              cgst = 0;
             }
          
            let titax = (cgst+sgst+igst);
        } 

        let grand_total = document.getElementById("final-total-text").value;
        let final_total = grand_total;
        if(final_total!=="" && final_total!=="0"){
          final_total = parseFloat(final_total)-item_total;
          document.getElementById("total-before-return").innerHTML = this.currency_format(grand_total);
          document.getElementById("total-before-return-txt").value = this.floating_point(grand_total);
        }else{
          final_total = item_total;
          document.getElementById("total-before-return").innerHTML = 0;
          document.getElementById("total-before-return-txt").value = 0;
        }

        document.getElementById("return-total").innerHTML = this.currency_format(item_total);
        document.getElementById("final-return-total-text").value = this.floating_point(item_total);

        document.getElementById("top-final-total").innerHTML = this.currency_format(final_total);
        document.getElementById("final-total").innerHTML = this.currency_format(final_total);
        document.getElementById("final-total-text").value = this.floating_point(final_total);

        document.getElementById("return-total-unit").innerHTML = this.currency_format(total_unit);

        if(document.getElementById("total-bill-amount")){
          document.getElementById("total-bill-amount").innerHTML = this.currency_format(final_total);
        }
        if(document.getElementById("bill-pay-pop-title")){
          document.getElementById("bill-pay-pop-title").innerHTML = this.currency_format(final_total);
        }
        let quant_field = document.querySelectorAll(".ret-item-quantity");
        let total_quantity = 0;
        
        for(let i=0;i<quant_field.length;i++){
            if(quant_field[i].value!==''){
              total_quantity = total_quantity+parseInt(quant_field[i].value);
            }
        }
        document.getElementById("return-total-quantity").innerHTML = this.currency_format(total_quantity);
      }
    }
    handleNoteTypeChange(e){
      this.setState({note_type:e.currentTarget.value});
    }
    handleEnableReturnInfo(e){
      let return_row = document.querySelectorAll(".return-items-row");
      let is_item_return = false;
      if(return_row.length>0){
        is_item_return = true;
      }
      this.setState({is_item_return:is_item_return});
    }
   


    handleChangeDefaultInventory(e){
      this.setState({default_inventory_status:e.currentTarget.value});
    }
    handleCreateVariation(e){
      let iitem_type = document.getElementById("iitem-type").value;
      let selected_product = document.getElementById("selected_product").value;
      if(this.variant_data[selected_product]===undefined){
        this.variant_data[selected_product] = [];
      }else{
        this.variant_data[selected_product] = [];
      }
      let variations = document.querySelectorAll(".variation-item");
      let has_variation = false;
      if(variations.length>0){
        has_variation = true;
      }
      let parent_item_name = selected_product;
      let product_key = parent_item_name.replace(/\s+/g, "_");

      for(let i=0;i<variations.length;i++){
          let did = variations[i].getAttribute('data-id');

          let option1 = document.getElementById("item-option1-"+did);
          let opt1_name = option1.getAttribute('data-id');
          let opt1_val = option1.value;

          let option2 = document.getElementById("item-option2-"+did);
          let opt2_name = option2.getAttribute('data-id');
          let opt2_val = option2.value;

          let option3 = document.getElementById("item-option3-"+did);
          let opt3_name = option3.getAttribute('data-id');
          let opt3_val = option3.value;

          let var_options = {
                          option1:opt1_name,opt1_val:opt1_val,value1:opt1_val,
                          option2:opt2_name,opt2_val:opt2_val,value2:opt2_val,
                          option3:opt3_name,opt3_val:opt3_val,value3:opt3_val,
                        };


          let variant_name = document.getElementById("item-lbl-"+did).innerHTML;
         
          let purchase_price = document.getElementById("var-item-price-"+did).value;
          let pp_discount = document.getElementById("var-item-pp-disc-"+did).value;
          let pp_discount_hidden = document.getElementById("var-pp-discount-hidden-"+did).value;
          
          let pp_tax = document.getElementById("var-item-pp-tax-"+did).value;
          let pp_tax_type = document.getElementById("var-item-pp-tax-type-"+did).value;

          let pp_item_cgst = document.getElementById("var-item-cgst-"+did).value;
          let pp_item_cgst_hidden = document.getElementById("var-item-cgst-hidden-"+did).value;
          let pp_item_cgst_total = document.getElementById("var-item-cgst-total-"+did).value;


          let pp_item_sgst = document.getElementById("var-item-sgst-"+did).value;
          let pp_item_sgst_hidden = document.getElementById("var-item-sgst-hidden-"+did).value;
          let pp_item_sgst_total = document.getElementById("var-item-sgst-total-"+did).value;


          let pp_item_igst = document.getElementById("var-item-igst-"+did).value;
          let pp_item_igst_hidden = document.getElementById("var-item-igst-hidden-"+did).value;
          let pp_item_igst_total = document.getElementById("var-item-igst-total-"+did).value;

          let selling_price = document.getElementById("var-item-selling-price-"+did).value;
          let sp_discount = document.getElementById("var-item-sp-disc-"+did).value;
          let sp_tax = document.getElementById("var-item-sp-tax-"+did).value;
          let sp_tax_type = document.getElementById("var-item-sp-tax-type-"+did).value;
          let quantity = document.getElementById("var-item-quantity-"+did).value;
          let sku = document.getElementById("var-item-sku-"+did).value;
          let barcode = document.getElementById("var-item-barcode-"+did).value;
          let alias_barcode = document.getElementById("var-item-alias-barcode-"+did).value;

          let item_total_txt = document.getElementById("var-item-total-txt-"+did).value;

          let vd = {
              iitem_type : iitem_type,
              is_variation : true,
              product_key :product_key,
              has_variation : has_variation,
              availability: 0,
              bundle: "",
              bundle_quantity: 0,
              bundle_text: "",
              cgst: pp_item_cgst,
              desc: "",
              discount: pp_discount,
              hidden_cgst: pp_item_cgst_hidden,
              hidden_igst: pp_item_igst_hidden,
              hidden_sgst: pp_item_sgst_hidden,
              hsn: "",
              igst: pp_item_igst,
              is_tax_inc: pp_tax_type,
              pp_tax_type: pp_tax_type,
              item_id: 0,
              item_name: variant_name,
              list_item_id: 0,
              parent_id: 0,
              price: purchase_price,
              price2 : selling_price,
              price_hidden: purchase_price,
              product_name: parent_item_name,
              quantity: quantity,
              sgst: pp_item_sgst,
              tax: pp_tax,
              total: item_total_txt,
              total_cgst: pp_item_cgst_total,
              total_discount: pp_discount_hidden,
              total_igst: pp_item_igst_total,
              total_sgst: pp_item_sgst_total,
              total_tax: 0,
              unit_id: 1,
              unit_name: "Pcs",
              unitname: "pcs",
              sku:sku,
              barcode:barcode,
              alias_barcode:alias_barcode,
              sp_discount : sp_discount,
              sp_total_discount : sp_discount,
              sp_tax : sp_tax,
              sp_tax_type : sp_tax_type,
              var_options:var_options,
          };

         this.variant_data[selected_product].push(vd);
      }
    }
    
    handleRedeemNote(e){
      let thisval = document.getElementById("ptype_note").value;
      
      //if(thisval!==""){
        document.getElementById("ptype_note_lbl").innerHTML = thisval;

        let total_grand = 0;
      
          total_grand = document.getElementById("final-total-text").value;
          if(localStorage.getItem('bptotal')){
            total_grand = localStorage.getItem('bptotal');
          }
            if(total_grand===""){
              total_grand = 0;
          }
          
          total_grand = parseFloat(total_grand.toString().replace(/,/g , ""));
        //clearTimeout(this.timeout);
        let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              type : this.state.billing_type,
              key : thisval,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/redeem_credit_debit_note';
        
        //this.timeout = setTimeout(function () {
          axios.post(api, body)
            .then(function (response) {
              let data = response.data;;

              let cd_type = 'Credit';
              if(_this.state.billing_type==='New Purchase'){
                cd_type = 'Debit';
              }
              if(data.amount!==undefined && data.amount!==""){
                  _this.setState({is_reedeem:true});
                  let amount = data.amount;
                  let redeem_amount = data.amount;
                  if(document.getElementById("redeem_original_amount")){
                    document.getElementById("redeem_original_amount").value = amount;
                  }
                  amount = parseFloat(amount.toString().replace(/,/g , ""));
                  redeem_amount = parseFloat(redeem_amount.toString().replace(/,/g , ""));
                  if(amount>total_grand){
                    amount = total_grand;
                  }
                  let total = total_grand - amount;
                  if(_this.state.billing_type==="New Purchase"){
                    //total = total_grand+amount;
                  }
                  document.getElementById("before-redeem-total").innerHTML = _this.currency_format(total_grand);
                  document.getElementById("redeem-total").innerHTML = _this.currency_format(amount);
                  document.getElementById("top-final-total").innerHTML = _this.currency_format(total);
                  document.getElementById("final-total").innerHTML = _this.currency_format(total);
                  document.getElementById("final-total-text").value = total;
                 
                  _this.setState({redeem_error:cd_type+' note amount : ₹'+_this.currency_format(data.amount)});
                  
                  if(document.getElementById("handleCalculateReturnAmount")){
                    document.getElementById("handleCalculateReturnAmount").click();
                  }
                  
                  if(document.getElementById("total-bill-amount")){
                    document.getElementById("total-bill-amount").innerHTML = _this.currency_format(total_grand);
                  }
                  if(document.getElementById("bill-pay-pop-title")){
                    document.getElementById("bill-pay-pop-title").innerHTML = _this.currency_format(total_grand);
                  }
                  if(document.getElementById("top-final-total")){
                    document.getElementById("top-final-total").innerHTML = _this.currency_format(total_grand);
                  }
                  document.querySelector('input[name="global_discount"]:checked').click();
                  
                  
                  setTimeout(() => {
                    let amount_paid_desc = '(Redeemed:'+amount;

                    if(redeem_amount<total_grand){
                      let cash = total_grand - redeem_amount;
                      if(document.getElementById("total-cash-amount")){
                        //document.getElementById("total-cash-amount").value = cash;
                        amount_paid_desc += ' + Cash:'+cash;
                      }else if(document.getElementById("total-online-amount")){
                        //document.getElementById("total-online-amount").value = cash;
                        amount_paid_desc += ' + Online:'+cash;
                      }else if(document.getElementById("total-credit-amount")){
                        document.getElementById("total-credit-amount").value = cash;
                      }
                    }else{
                      if(document.getElementById("total-cash-amount")){
                        document.getElementById("total-cash-amount").value = 0;
                        amount_paid_desc += ' + Cash: 0';
                      }else if(document.getElementById("total-online-amount")){
                        document.getElementById("total-online-amount").value = 0;
                        amount_paid_desc += ' + Online: 0';
                      }else if(document.getElementById("total-credit-amount")){
                        document.getElementById("total-credit-amount").value = 0;
                      }
                    }
                    amount_paid_desc += ')';
                    if(document.getElementById("amount-paid-desc")){
                      document.getElementById("amount-paid-desc").innerHTML = amount_paid_desc;
                    }
                    if(document.getElementById("total-paid-amount")){
                      document.getElementById("total-paid-amount").innerHTML = total_grand;
                    }
                    if(document.getElementById("total-return-amount")){
                      document.getElementById("total-return-amount").innerHTML = 0;
                    }
                    
                    //document.querySelector('input[name="global_discount"]:checked').click();
                  }, 600);

              }else{
                _this.setState({is_reedeem:false});
                _this.setState({redeem_error:'No '+cd_type+' note found'});
                document.getElementById("top-final-total").innerHTML = _this.currency_format(total_grand);
                document.getElementById("final-total").innerHTML = _this.currency_format(total_grand);
                document.getElementById("final-total-text").value = total_grand;
                if(document.getElementById("total-bill-amount")){
                  document.getElementById("total-bill-amount").innerHTML = _this.currency_format(total_grand);
                }
                if(document.getElementById("bill-pay-pop-title")){
                  document.getElementById("bill-pay-pop-title").innerHTML = _this.currency_format(total_grand);
                }
                _this.setState({redeem_error:''});
                if(document.getElementById("amount-paid-desc")){
                  document.getElementById("amount-paid-desc").innerHTML = '';
                }
                if(document.getElementById("handleCalculateReturnAmount")){
                  document.getElementById("handleCalculateReturnAmount").click();
                }
                document.querySelector('input[name="global_discount"]:checked').click();
  
                if(document.getElementById("total-paid-amount")){
                //  document.getElementById("total-paid-amount").innerHTML = total_paid_amount;
                }
              }

              if(document.getElementById("handleResetPrefilledAmount")){
                //document.getElementById("handleResetPrefilledAmount").click();
              }
          }.bind(_this)).catch(function(res){
              
                  _this.setState({is_reedeem:false});
                  document.getElementById("top-final-total").innerHTML = _this.currency_format(total_grand);
                  document.getElementById("final-total").innerHTML = _this.currency_format(total_grand);
                  document.getElementById("final-total-text").value = total_grand;
                  if(document.getElementById("total-bill-amount")){
                    document.getElementById("total-bill-amount").innerHTML = _this.currency_format(total_grand);
                  }
                  if(document.getElementById("bill-pay-pop-title")){
                    document.getElementById("bill-pay-pop-title").innerHTML = _this.currency_format(total_grand);
                  }
                  _this.setState({redeem_error:''});
                  if(document.getElementById("amount-paid-desc")){
                    document.getElementById("amount-paid-desc").innerHTML = '';
                  }
                  if(document.getElementById("handleCalculateReturnAmount")){
                    document.getElementById("handleCalculateReturnAmount").click();
                  }
                  document.querySelector('input[name="global_discount"]:checked').click();
                  if(document.getElementById("handleResetPrefilledAmount")){
                    document.getElementById("handleResetPrefilledAmount").click();
                  }
                
          })
       // }, 400);
      /* }else{
        this.setState({redeem_error:''});
        //clearTimeout(this.timeout);
      } */
    }

    

    handleChangeStaff(e){
      let assisted_by = e.currentTarget.value;
      this.setState({assisted_by:assisted_by});
      if(assisted_by!=="" && this.state.is_global_commission===true){
          let global_commission = this.state.global_commission;
          let item_total = document.getElementById("total-before-tax").innerHTML;
          if(global_commission!=="" && item_total!==""){
            item_total = item_total.replaceAll(',','');
            item_total = parseFloat(item_total);
            global_commission = parseFloat(global_commission);
            let commission = (item_total*global_commission)/100;
            commission = utils.floating_point(commission);
            setTimeout(() => {
              if( document.getElementById("staff-global-comission")){
                document.getElementById("staff-global-comission").innerHTML = commission;
              }
            }, 500);
          }
      }else{

        setTimeout(() => {
          if( document.getElementById("staff-global-comission")){
            document.getElementById("staff-global-comission").innerHTML = 0;
          }
        }, 500);
      }
    }
    handleChangeAgent(e){
      this.setState({agent_name:e.currentTarget.value});
    }

    handleRoundOffChange(e){
      let round_off = document.getElementById("round_off").value;
      let round_off_temp = document.getElementById("round_off").value;
      let round_off_opt = e.currentTarget.value;

      if(round_off_opt==='-'){
        this.setState({round_off_opt:false});
        this.setState({round_off_opt2:true});
      }else{
        this.setState({round_off_opt:true});
        this.setState({round_off_opt2:false});
      }
      this.setState({round_off_sym:round_off_opt});
      let item_total = document.getElementById("s-total").innerHTML;
      item_total = parseFloat(item_total.toString().replace(/,/g , ""));
      let i_total = parseFloat(item_total.toString().replace(/,/g , ""));
     
      if(round_off_opt==='-'){
       
          item_total = Math.floor(item_total);
          round_off = parseFloat(i_total)-parseFloat(item_total);
          if(round_off===0){	
            item_total = parseFloat(i_total)-parseFloat(round_off_temp);	
            round_off = round_off_temp;	
          }
      }else if(round_off_opt==='+'){
        
          item_total = Math.ceil(item_total);
          round_off = parseFloat(item_total)-parseFloat(i_total);
          if(round_off===0){	
            item_total = parseFloat(i_total)+parseFloat(round_off_temp);	
            round_off = round_off_temp;	
          }
      }
      document.getElementById("round_off").value = this.floating_point(round_off);
      document.getElementById("round_off_lbl").innerHTML = this.floating_point(round_off);
      
      
      if( document.getElementById("before-redeem-total")){
        document.getElementById("before-redeem-total").innerHTML = this.currency_format(item_total);
      }

      let redeem_total = 0;
      if(document.getElementById("redeem-total")){
        redeem_total = document.getElementById("redeem-total").innerHTML;
        redeem_total = redeem_total.replace(/,/g , "");
        redeem_total = parseFloat(redeem_total);
        if(this.state.billing_type==="New Sale"){
          item_total = item_total - redeem_total;
        }else if(this.state.billing_type==="New Purchase"){
          item_total = item_total - redeem_total;
        }
      }

      document.getElementById("top-final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total-text").value = this.floating_point(item_total);
/*      if(document.getElementById("total-cash-amount")){
        document.getElementById("total-cash-amount").value = item_total;
      }*/

      if(document.getElementById("total-bill-amount")){
        document.getElementById("total-bill-amount").innerHTML = this.currency_format(item_total);
      }
      if(document.getElementById("bill-pay-pop-title")){
        document.getElementById("bill-pay-pop-title").innerHTML = this.currency_format(item_total);
      }

     /* if(document.getElementById("total-paid-amount")){
        document.getElementById("total-paid-amount").innerHTML = this.currency_format(item_total);
      }

      if(document.getElementById("total-return-amount")){
        document.getElementById("total-return-amount").innerHTML = 0;
      }*/
      this.calculateReturnTotal();
    }

    handleRoundOff(e){
      let round_off = e.currentTarget.value;
      let item_total = document.getElementById("s-total").innerHTML;
      item_total = parseFloat(item_total.toString().replace(/,/g , ""));
      let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;
      if(round_off!=="" && round_off>0){
          round_off = parseFloat(round_off);
          if(round_off_opt==='-'){
              item_total = item_total-round_off;
          }else if(round_off_opt==='+'){
              item_total = item_total+round_off;
          }
      }
      if( document.getElementById("before-redeem-total")){
        document.getElementById("before-redeem-total").innerHTML = this.currency_format(item_total);
      }
      let redeem_total = 0;
      if(document.getElementById("redeem-total")){
        redeem_total = document.getElementById("redeem-total").innerHTML;
        redeem_total = redeem_total.replace(/,/g , "");
        redeem_total = parseFloat(redeem_total);
        if(this.state.billing_type==="New Sale"){
          item_total = item_total - redeem_total;
        }else if(this.state.billing_type==="New Purchase"){
          item_total = item_total - redeem_total;
        }
      }
      document.getElementById("top-final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total-text").value = this.floating_point(item_total);
      /*if(document.getElementById("total-cash-amount")){
        document.getElementById("total-cash-amount").value = item_total;
      }*/

      if(document.getElementById("total-bill-amount")){
        document.getElementById("total-bill-amount").innerHTML = this.currency_format(item_total);
      }
      if(document.getElementById("bill-pay-pop-title")){
        document.getElementById("bill-pay-pop-title").innerHTML = this.currency_format(item_total);
      }
      /*if(document.getElementById("total-paid-amount")){
        document.getElementById("total-paid-amount").innerHTML = this.currency_format(item_total);
      }

      if(document.getElementById("total-return-amount")){
        document.getElementById("total-return-amount").innerHTML = 0;
      }*/
      this.calculateReturnTotal();
    }
    handleCheckGDiscountAmount = (e) =>{	
      let thisvalue = e.currentTarget.value;	
      if(thisvalue===""){	
        thisvalue = 0;	
      }	
      this.setState({check_global_disc:thisvalue});	
    }	

    handleGlobalDiscount(e){
      let thisid = e.currentTarget.id;
      let global_discount = document.getElementById("global_discount").value;
      
      let discount_opt = document.querySelector('input[name="global_discount"]:checked').value;
      global_discount = global_discount.trim();
      
      if(discount_opt==='per'){
        if(global_discount!==""){
          global_discount = parseFloat(global_discount);
          if(global_discount>100){
              e.currentTarget.value = 100;
              global_discount = 100;
          }
        }
      }
      if(global_discount===""){
        global_discount = 0;
      }else{
        global_discount = parseFloat(global_discount);
      }
      if(thisid!=='global_discount'){
        if(discount_opt==='per'){
          if(this.state.global_discount_per>0){
            global_discount = this.state.global_discount_per;
            this.setState({global_discount:global_discount});
            document.getElementById("global_discount").value = global_discount;
          }
        }else if(discount_opt==='rs'){
          if(this.state.global_discount_rs>0){
            global_discount = this.state.global_discount_rs;
            this.setState({global_discount:global_discount});
            document.getElementById("global_discount").value = global_discount;
          }
        }
      }
      
      var item_final_total = document.querySelectorAll('.item-sub-total'); 
      var item_quantity = document.querySelectorAll('.item-quantity'); 
      var items_discount = document.querySelectorAll('.item-discount-hidden'); 
      var item_gst = document.querySelectorAll('.item-gst'); 
      let item_total = 0;
      let total_unit = 0;
      let gst_arr = [];
      let total_gst = 0;
      if(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase'){
        if(item_final_total.length===0){
          if(document.getElementById("handleShowAddItemBtn")){
            document.getElementById("handleShowAddItemBtn").click();
          }
        }
        if(item_final_total.length>0){
          if(document.getElementById("handleHideAddItemBtn")){
            document.getElementById("handleHideAddItemBtn").click();
          }
        }
      }else{
       /*  if(document.getElementById("handleHideAddItemBtn")){
          document.getElementById("handleHideAddItemBtn").click();
        } */
      }
      let itotal_sub = 0;
      for (var i = 0; i < item_final_total.length; i++) { 
        if(item_final_total[i].value!=='' && item_final_total[i].value!=="0" && item_final_total[i].value!==0){
          let did = item_final_total[i].getAttribute('data-id');
          let is_t_inc = "";
           if(document.getElementById("item-tax-inc-"+did)){
             is_t_inc = document.getElementById("item-tax-inc-"+did).value;
           }else if(document.getElementById("sp-item-tax-inc-"+did)){
             is_t_inc = document.getElementById("sp-item-tax-inc-"+did).value;
           }
           let is_i_gst = "";
           if(document.getElementById("item-gst-"+did)){
             is_i_gst = document.getElementById("item-gst-"+did).value;
           }else if(document.getElementById("item-sp-gst-"+did)){
             is_i_gst = document.getElementById("item-sp-gst-"+did).value;
           }
          total_unit++;
          let disc = (items_discount[i]!==undefined && items_discount[i].value!=="")?parseFloat(items_discount[i].value):0;
          let quan = item_quantity[i].value;
          
          quan = (quan==='')?0:parseFloat(quan);
          let itot = parseFloat(item_final_total[i].value.replace(/,/g , ""));
           
          if(is_t_inc==='including' && is_i_gst!=="" && is_i_gst!=="0"){
            disc = 0;
          }
          itotal_sub = itotal_sub+(quan*itot);
          let total_amount = (quan*itot)-disc;
          
          item_total = item_total + (quan*itot)-disc;
          
          if(discount_opt==="per"){
            let i_gst = parseFloat(item_gst[i].value);
            if(i_gst>0){
              let dis = total_amount*global_discount/100;
              total_amount = total_amount-dis;
              total_gst = total_gst+(total_amount*i_gst)/100;
            }
          }else if(discount_opt==="rs"){
            let sub_total = parseFloat(document.getElementById("sub-total").innerHTML.replace(/,/g , ""));
           
            let gd = global_discount/sub_total*100;
            
            let i_gst = parseFloat(item_gst[i].value);
            if(i_gst>0){
              let dis = total_amount*gd/100;
              total_amount = total_amount-dis;
              total_gst = total_gst+(total_amount*i_gst)/100;
            }
          }
        }
      }
      document.getElementById("sub-total").innerHTML = itotal_sub;
      if(discount_opt==="rs"){
        let sub_total = parseFloat(document.getElementById("sub-total").innerHTML.replace(/,/g , ""));
         
          if(global_discount>sub_total){
            e.currentTarget.value = this.state.check_global_disc;	
            global_discount = this.state.check_global_disc;
          }
      }
    
      //if(discount_opt==="per"){
        

        let total_igst = document.getElementById("total-igst").innerHTML;
        let total_sgst = document.getElementById("total-sgst").innerHTML;
        let total_cgst = document.getElementById("total-cgst").innerHTML;
        let contact_state = localStorage.getItem('state');
        let com_state = user_info.state;
        if(com_state!==contact_state){
          document.getElementById("total-igst").innerHTML = this.currency_format(total_gst);
        }else{
          total_gst = this.floating_point(total_gst);
          let csgst = total_gst/2;
          csgst = this.floating_point(csgst);
          total_gst = csgst+csgst;
          document.getElementById("total-sgst").innerHTML = this.currency_format(csgst);
          document.getElementById("total-cgst").innerHTML = this.currency_format(csgst);
        }
        document.getElementById("total-after-tax").innerHTML = this.currency_format(total_gst);
        document.getElementById("total-tax").innerHTML = this.currency_format(total_gst);
      //}

      var item_total_discount = document.querySelectorAll('.item-discount-hidden'); 
     
      let cont_disc = localStorage.getItem('discount');

       if(cont_disc==="" || cont_disc===null || cont_disc==='null'){
         cont_disc = 0;
       }else{
         cont_disc = parseInt(cont_disc);
       }
       

       let gdisc = global_discount;
       
       if(discount_opt==="rs"){
          if(e.currentTarget.id==='global_discount'){
              this.setState({global_discount_rs:gdisc});
              let gper = (global_discount/item_total)*100;
              this.setState({global_discount_per:gper});
          }
          item_total = parseFloat(item_total)-global_discount;
          document.getElementById("disc_on_bill").innerHTML = '';
          document.getElementById("disc_on_bill_lbl").innerHTML = '';
        
       }else if(discount_opt==="per"){
         gdisc = (parseFloat(item_total)*global_discount)/100;
         gdisc = this.floating_point(gdisc);
         item_total = parseFloat(item_total)-gdisc;
         document.getElementById("disc_on_bill").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+gdisc+'</span>';
         document.getElementById("disc_on_bill_lbl").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+gdisc+'</span>';
         if(e.currentTarget.id==='global_discount'){
           this.setState({global_discount_per:global_discount});
           this.setState({global_discount_rs:gdisc});
         }
         
       }
       if(this.state.assisted_by!=="" && this.state.is_global_commission===true){
            let global_commission = this.state.global_commission;
            if(global_commission!==""){
              global_commission = parseFloat(global_commission);
              let commission = (item_total*global_commission)/100;
              commission = utils.floating_point(commission);
              setTimeout(() => {
                if( document.getElementById("staff-global-comission")){
                  document.getElementById("staff-global-comission").innerHTML = commission;
                }
              }, 500);
            }
       }


       document.getElementById("total-before-tax").innerHTML = this.currency_format(item_total);
       let item_tax=document.getElementById("total-tax").innerHTML;
       item_tax=parseFloat(item_tax.toString().replace(/,/g , ""));
       let total_after_tax = item_total+item_tax;
       item_total = item_total+item_tax;
       
       if(this.state.item_discount_on_mrp===true){
        let total_discount = 0;
        if(document.getElementById("total-discount")){
         total_discount = document.getElementById("total-discount").innerHTML;
         if(total_discount!==""){
          total_discount=parseFloat(total_discount.toString().replace(/,/g , ""));
         }
        }
        total_after_tax = total_after_tax - total_discount;
        item_total = item_total - total_discount;
      }
      
       document.getElementById("s-total").innerHTML = this.currency_format(total_after_tax);

       if(document.getElementById("tcs-total")){
          let tcs_percent = 0;
          if(document.getElementById("tcs-percent")){
            tcs_percent = document.getElementById("tcs-percent").value;
          }else{
            tcs_percent = this.state.tcs_percent;
          }
          if(tcs_percent===''){
            tcs_percent = 0;
          }
          tcs_percent = parseFloat(tcs_percent);
          let cal_tcs = (total_after_tax*tcs_percent)/100;
          document.getElementById("tcs-tot-per").innerHTML = tcs_percent;
          document.getElementById("tcs-total").innerHTML = utils.floating_point(cal_tcs);
          item_total = item_total+cal_tcs;
        
       }
       
       let after_round = Math.round(item_total)-item_total;
      
      if(after_round>=0){
        after_round = Math.ceil(item_total)-item_total;
        after_round = this.currency_format(after_round);
        document.getElementById("plus").click();
      }else{
        after_round = item_total-Math.floor(item_total);
        after_round = this.currency_format(after_round);
        document.getElementById("minus").click();
      }
      document.getElementById("round_off").value = after_round;
      document.getElementById("round_off_lbl").innerHTML = after_round;

      let round_off = document.getElementById("round_off").value;
      let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;

      
      
      if(round_off!=="" && round_off>0){
          item_total = parseFloat(item_total.toString().replace(/,/g , ""));
          if(round_off_opt==='-'){

              item_total = item_total-parseFloat(round_off);
          }else if(round_off_opt==='+'){
              item_total = item_total+parseFloat(round_off);
          }
      }

      if( document.getElementById("before-redeem-total")){
        document.getElementById("before-redeem-total").innerHTML = this.currency_format(item_total);
      }
      let redeem_total = 0;
      if(document.getElementById("redeem-total")){
        redeem_total = document.getElementById("redeem-total").innerHTML;
        redeem_total = redeem_total.replace(/,/g , "");
        redeem_total = parseFloat(redeem_total);
        if(this.state.billing_type==="New Sale"){
          item_total = item_total - redeem_total;
        }else if(this.state.billing_type==="New Purchase"){
          item_total = item_total - redeem_total;
        }
      }

      if(document.getElementById("other-charges")){
        let other_charges = document.getElementById("other-charges").value;
        if(other_charges!==""){
          other_charges = parseFloat(other_charges);
          this.setState({transportation_charge:other_charges});
          item_total = item_total + other_charges;
        }
      }
      
      if(document.getElementById("mode_of_payment")){
        let mode_of_payment = document.getElementById("mode_of_payment").value;
        if(mode_of_payment==='2' || mode_of_payment===2){
         if(document.getElementById("total-credit-amount")){
          document.getElementById("total-credit-amount").value = item_total;
         }
        }
        let reset_clicked = localStorage.getItem('reset_clicked');
        localStorage.removeItem('reset_clicked');
        if(reset_clicked==='yes'){
          this.setState({change_pay_to_save:false});
        }else{
          this.setState({change_pay_to_save:true});
        }
      }

      document.getElementById("top-final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total-text").value = this.floating_point(item_total);
      /*if(document.getElementById("total-cash-amount")){
        document.getElementById("total-cash-amount").value = item_total;
      }*/

      if(document.getElementById("total-bill-amount")){
        document.getElementById("total-bill-amount").innerHTML = this.currency_format(item_total);
      }
      if(document.getElementById("bill-pay-pop-title")){
        document.getElementById("bill-pay-pop-title").innerHTML = this.currency_format(item_total);
      }
      document.getElementById("global_discount_lbl").innerHTML = global_discount;
      this.calculateReturnTotal();

      /*if(document.getElementById("total-paid-amount")){
        document.getElementById("total-paid-amount").innerHTML = this.currency_format(item_total);
      }

      if(document.getElementById("total-return-amount")){
        document.getElementById("total-return-amount").innerHTML = 0;
      }*/
      this.handleCalculateEncProfit();
    }
    handleChangePayBtnStatus = (e) =>{	
      if(document.getElementById("total-paid-amount")){	
        let pamt = document.getElementById("total-paid-amount").innerHTML;	
        let ramt = pamt.replaceAll(",",'');	
        if(parseFloat(ramt)===0){	
          this.setState({change_pay_to_save:false});	
        }else{	
          this.setState({change_pay_to_save:true});	
        }	
      }	
    }
    handleGlobalDiscountChange(e){
      
      let global_discount = document.getElementById("global_discount").value;
      if(global_discount==='rs'){
        this.setState({discount_opt:false});
        this.setState({discount_opt2:true});
      }else{
        this.setState({discount_opt:true});
        this.setState({discount_opt2:false});
      }
      var item_final_total = document.querySelectorAll('.item-sub-total'); 
      var item_quantity =document.querySelectorAll('.item-quantity'); 
      let item_total = 0;
      let total_unit = 0;
      for (var i = 0; i < item_final_total.length; i++) { 
        if(item_final_total[i].value!=='' && item_final_total[i].value!=="0" && item_final_total[i].value!==0){
          let ftotal = item_final_total[i].value.replace(/,/g , "");
          let quan = item_quantity[i].value;
          quan = (quan==='')?0:parseFloat(quan);
          item_total = item_total + (quan*parseFloat(ftotal));
        }
      } 
      
      var item_total_discount = document.querySelectorAll('.item-discount-hidden'); 
     
      let item_discount = 0;
      for (var i = 0; i < item_total_discount.length; i++) { 
           item_discount = item_discount + parseFloat((item_total_discount[i].value!==''?(item_total_discount[i].value):0));
      } 

      item_total = (item_total)-item_discount;


       let cont_disc = localStorage.getItem('discount');

       if(cont_disc==="" || cont_disc===null || cont_disc==='null'){
         cont_disc = 0;
       }else{
         cont_disc = parseFloat(cont_disc);
       }
       /* //cont_disc = cont_disc.toFixed(2);  
       cont_disc = cont_disc.substring(0, cont_disc.indexOf('.') + 3);
       let discount_opt = document.querySelector('input[name="global_discount"]:checked').value;
       if(discount_opt==="rs"){
          let gdisc = (parseFloat(item_total)*cont_disc)/100;
          document.getElementById("global_discount").value = gdisc;
          document.getElementById("global_discount_lbl").innerHTML = gdisc;
          item_total = parseFloat(item_total)-gdisc;
          document.getElementById("disc_on_bill").innerHTML = '';
          document.getElementById("disc_on_bill_lbl").innerHTML = '';
       }else if(discount_opt==="per"){
          let gdisc = (parseFloat(item_total)*cont_disc)/100;
          item_total = parseFloat(item_total)-gdisc;
          gdisc = this.floating_point(gdisc);
          document.getElementById("disc_on_bill_lbl").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+gdisc+'</span>';
          document.getElementById("disc_on_bill").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+gdisc+'</span>';
          document.getElementById("global_discount").value = cont_disc;
          document.getElementById("global_discount_lbl").innerHTML = cont_disc;
       } */
       this.handleGlobalDiscount(e);
    }

    
   
      billingDate = date => {
        this.setState({
          billingDate: date
        });
      }; 
      invoiceDate = date => {
        this.setState({
          invoiceDate: date
        });
      };
      handleChangeEvent(e){
        let is_print = document.getElementById("print_bill").style.display;
        if(is_print==='block'){
          this.setState({on_load:true});
        }else{
          this.setState({on_load:false});
        }
        
        if(document.getElementById("product-variation-add") && document.getElementById("product-variation-add").style && document.getElementById("product-variation-add").style.display==='block'){
          
          if(e.ctrlKey && e.which===187){
            e.preventDefault();
            if(document.getElementById("handleMoreVariantOpt")){
              document.getElementById("handleMoreVariantOpt").click();
              return false;
            }
          }else if(e.ctrlKey && e.which===86){
            e.preventDefault();
            if(document.getElementById("create-variation-btn")){
              document.getElementById("create-variation-btn").click();
              return false;
            }
          }
        }

        if(e.ctrlKey && e.which===80){
          let is_bill_pay_popup = false;
          if(document.getElementById("bill-payment-popup").style){
            let is_print = document.getElementById("bill-payment-popup").style.display;
            if(is_print==='block'){
              is_bill_pay_popup = true;
            }
          }
          if(is_bill_pay_popup===false){
            e.preventDefault();
            this.handlePrintOnFly();
            return false;
          }else{
            if(document.getElementById("handleSavePrint")){
              document.getElementById("handleSavePrint").click();
            }
          }
        }
        
     
        let key_code = e.which;
        
        if(key_code===119){
          e.preventDefault();
          if(document.getElementById("save-print")){
            document.getElementById("save-print").click();
          }
        }

        if((key_code>=112 && key_code<=117)||(key_code>=119 && key_code<=121)){
          this.setState({response_id:0});
        }

        if(key_code===118){
            e.preventDefault();
            let dpmfp = {
              store_name:user_info.store_name,
              branch_id:user_info.login_id,
              user_id : user_info.user_id,
              active_tab:this.state.billing_type,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
          }
          let lb_api = app_api+'/get_last_bill';
          axios.post(lb_api, dpmfp)
            .then(function (response) {
              let resp = response.data;
              if(parseInt(resp)>0){
                this.setState({response_id:parseInt(resp)});
              }
          }.bind(this)).catch(function(res){
              
          })
        }
        
        if(e.ctrlKey && key_code===46){
            if(this.state.update_id===0){

              if(document.getElementById("contact_box")){
                document.getElementById("contact_box").value = '';
              }

              if(document.getElementById("customer-dropdown")){
                document.getElementById("customer-dropdown").value = '';
              }

              if(document.getElementById("old-customer-dropdown")){
                document.getElementById("old-customer-dropdown").value = '';
              }

              if(document.getElementById("staff-dropdown")){
                document.getElementById("staff-dropdown").value = '';
                this.setState({assisted_by:''});
              }

              if(document.getElementById("previous_balance_txt")){
                document.getElementById("cont-old-balance").classList.add('hide');
                document.getElementById("previous_balance_txt").innerHTML = 0;
              }

              if(document.getElementById("credit_limit_txt")){
                document.getElementById("cont-credit-limit").classList.remove('hide');
                document.getElementById("credit_limit_txt").innerHTML = 0;
              }

              if(document.getElementById("mode_of_payment")){
                localStorage.setItem("p_mode",2);
                document.getElementById("handleSetModeOfPayment").click();
              }
              
              let payment_mode = {payment_mode:2};
              this.setState({payment_mode:'credit'});
              this.setState({billing_data:payment_mode});
              this.setState({customer_advance_payment:false});
              let list_items = document.querySelectorAll(".rm-yes");
              for(let i=0;i<list_items.length;i++){
                list_items[i].click();
              }
            }
        }

        

        if(this.state.billing_type==='New Sales Return' || this.state.billing_type==='New Purchase Return'){
          if(key_code===40){
            var element = document.getElementById('return-invoices');
            var ret_invoice_list = document.querySelectorAll(".ret-inv-list");
            if(element.style.display==="block"){
              let inv_active = this.state.inv_active;
              inv_active++;
              if(ret_invoice_list.length>=(inv_active+1)){
                this.setState({inv_active:inv_active});
              }
            }
          }
          
          if(key_code===38){
            var element = document.getElementById('return-invoices');
            var ret_invoice_list = document.querySelectorAll(".ret-inv-list");
            if(element.style.display==="block"){
              let inv_active = this.state.inv_active;
              inv_active--;
              if(inv_active>=0){
                this.setState({inv_active:inv_active});
              }
            }
          }
          
          if(key_code===13){
            var element = document.getElementById('return-invoices');
            var ret_invoice_list = document.querySelectorAll(".ret-inv-list");
            if(element.style.display==="block"){
              let inv_active = this.state.inv_active;
              ret_invoice_list[inv_active].click();
            }
            var retinvitem = document.getElementById('returned-invoice-items');
            
            if(retinvitem.style.display==="block"){
                //document.getElementById("handleReturnBillList").click();
            }
          }
        }
      }

    handleTriggerOnPaymentModeChange=()=>{
      let payment_mode = localStorage.getItem("spm");
      this.setState({payment_mode:payment_mode});
      let bpm = localStorage.getItem("p_mode");
      if(bpm!==null){
        let billing_data = this.state.billing_data;
        if(billing_data.payment_mode!==undefined){
            billing_data.payment_mode = bpm;
        }else{
            billing_data = {payment_mode:bpm};
        }
        this.setState({billing_data:billing_data});
      }
    }

    handleChangePayBtnVisibility=(flag)=>{
      if(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase'){
        this.setState({change_pay_to_save:flag});	
      }else{
        this.setState({change_pay_to_save:true});	
      }
    }
    handleCreateBillFlag=()=>{
      this.setState({is_pay_popup_triggered:true});	
    }
    handleRefreshPaymentPopup=(data)=>{
     
      let reset_state ={
                          change_pay_to_save:data.change_pay_to_save,
                          customer_advance_payment:data.customer_advance_payment,
                          discount_opt:data.discount_opt,
                          discount_opt2:data.discount_opt2,
                          discount_sym:data.discount_sym,
                          global_commission:data.global_commission,
                          global_discount:data.global_discount,
                          global_discount_amount:data.global_discount_amount,
                          global_discount_per:data.global_discount_per,
                          global_discount_rs:data.global_discount_rs,
                          round_off:data.round_off,
                          round_off_opt:data.round_off_opt,
                          round_off_opt2:data.round_off_opt2,
                          round_off_sym:data.round_off_sym,
                          save_button:data.save_button,
                          is_reedeem:false,
                          redeem_error:'',
                          //billing_data : billing_data
                      };
      
      if(document.getElementById("ptype_note_lbl")){
        document.getElementById("ptype_note_lbl").innerHTML = '';
      }
      
      this.setState(reset_state);	
      this.setState({is_loaded_bill:false});	
      let _this = this;
      this.setState({change_pay_to_save:false});
      localStorage.setItem('reset_clicked','yes');
      setTimeout(() => {
        _this.setState({is_loaded_bill:true});
        document.querySelector('input[name="global_discount"]:checked').click();
      }, 100);
    }

    handleDefaultPaymentMode = () =>{
      let pm = localStorage.getItem('p_mode');
      if(pm){
        this.setState({default_payment_mode:pm});
      }
    }

    handleBusinessType = ()=>{
      let gst_number = localStorage.getItem('con_gst_number');
      let business_type = 'B2C';
      if(gst_number!==""){
      business_type = 'B2B';
      }
      this.setState({business_type:business_type});
    }

    handleEnableTCS = () =>{
      this.setState({enable_tcs:true})
    }
    handleDisableTCS = () =>{
      this.setState({enable_tcs:false})
    }

    componentDidMount(){

      localStorage.removeItem('bpmode');
      localStorage.removeItem('bptotal');
      let page_type = '';
      document.addEventListener('scroll', function(e) {
        let top = window.scrollY;
        if(top>=33){
          if(document.getElementById("another-fin-notice")){
            document.getElementById("another-fin-notice").classList.add("slide-up");
          }
        }else{
          if(document.getElementById("another-fin-notice")){
            document.getElementById("another-fin-notice").classList.remove("slide-up");
          }
        }
      });

      localStorage.removeItem('response_id');
      localStorage.removeItem('open_list_view');
      localStorage.removeItem('save_print');
      localStorage.removeItem('save_print_new');
      localStorage.removeItem('create_new_bill');
      localStorage.removeItem('open_new_bill');
      localStorage.removeItem('discount');
      var isMobile = this.isMobile();

      if(isMobile){
        this.setState({isMobile:true});
      }
      let curl = window.location.href;
      const add_billing_type = curl.match(/new-billing\/(\d+$)/i);
      const resume_cancelled = curl.match(/new-billing\/resume\/([a-z_A-Z]+)\/(\d+$)/i);
      const edit_billing = curl.match(/new-billing\/([edit]+)\/([a-z_A-Z]+)\/(\d+$)/i);
      const convert_to_sale = curl.match(/new-billing\/convert-to-sale\/(\d+$)/i);
      if(add_billing_type){
        this.setState({is_loaded_bill:true});
        if(add_billing_type[1]==="1"){
          page_type='New Sale';
        }else if(add_billing_type[1]==="2"){
          page_type='New Purchase';
          this.setState({
            invoiceDate: null
          });
        }else if(add_billing_type[1]==="3"){
          page_type='New Sales Return';
        }else if(add_billing_type[1]==="4"){
          page_type='New Purchase Return';
        }
        let target = page_type.replaceAll(' ','');
        if(localStorage.getItem('unsaved_list_item_'+target)){
          this.setState({is_unsaved_list:true});
        }
      }else if(edit_billing){
        const type = edit_billing[2];
        let t = this.types[type];
        page_type=t;
        let target = page_type.replaceAll(' ','');
        if(localStorage.getItem('unsaved_list_item_'+target)){
          this.setState({is_unsaved_list:true});
        }
      }

      if(add_billing_type && (add_billing_type[1]==="2" || add_billing_type[1]==="1")){
        this.setState({is_add_item_btn:true});
        this.setState({is_block_visible:true});
        let dpmfp = {
              store_name:user_info.store_name,
              branch_id:user_info.login_id,
              user_id : user_info.user_id,
              keys:"'default_payment_mode_for_sale','default_payment_mode_for_parchase','tcs_percent','global_discount_type'",
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
          }
          let dpmfp_api = app_api+'/get_settings';
          axios.post(dpmfp_api, dpmfp)
            .then(function (response) {
              let resp = response.data;
              if(resp.default_payment_mode_for_parchase){
                let pm = resp.default_payment_mode_for_parchase;
                this.setState({default_payment_mode_for_parchase:pm});
              }
              if(resp.default_payment_mode_for_sale){
                let pm = resp.default_payment_mode_for_sale;
                this.setState({default_payment_mode_for_sale:pm});
              }
              if(resp.tcs_percent){
                let pm = resp.tcs_percent;
                this.setState({tcs_percent:pm});
              }
              if(resp.global_discount_type){
                let pm = resp.global_discount_type;
                this.setState({discount_sym:pm});
                if(pm==='rs'){
                  if(document.getElementById('discount-rs')){
                    document.getElementById('discount-rs').checked = true;
                    document.querySelector('input[name="global_discount"]:checked').click();
                  }
                }
                if(pm==='per'){
                  if(document.getElementById('discount-per')){
                    document.getElementById('discount-per').checked = true;
                    document.querySelector('input[name="global_discount"]:checked').click();
                  }
                }
              }
              
              if(add_billing_type[1]==="2"){
                if(resp.default_payment_mode_for_parchase!==undefined){
                  let pm = resp.default_payment_mode_for_parchase;
                 this.setState({default_payment_mode:pm})
                  let billing_data = this.state.billing_data;
                  if(billing_data.payment_mode){
                    billing_data.payment_mode = pm;
                    this.setState({billing_data:billing_data});
                  }else{
                    let payment_mode = {payment_mode:pm};
                    this.setState({billing_data:payment_mode});
                  }
                  if(pm==='2'){
                    this.setState({payment_mode:'credit'});
                  }else if(pm==='1'){
                    this.setState({payment_mode:'payment'});
                  }
                }else{
                  this.setState({payment_mode:'payment'});
                }
              }else if(add_billing_type[1]==="1"){
                
                if(resp.default_payment_mode_for_sale!==undefined){
                  let pm = resp.default_payment_mode_for_sale;
                  
                  this.setState({default_payment_mode:pm})
                  if(pm==='2'){
                    this.setState({is_direct_pay:false});
                  }
                  let billing_data = this.state.billing_data;
                  if(billing_data.payment_mode){
                    billing_data.payment_mode = pm;
                    this.setState({billing_data:billing_data});
                  }else{
                    let payment_mode = {payment_mode:pm};
                    this.setState({billing_data:payment_mode});
                  }
                  if(pm==='2'){
                    this.setState({payment_mode:'credit'});
                  }else if(pm==='1'){
                    this.setState({payment_mode:'payment'});
                  }else{
                    this.setState({payment_mode:''});
                  }
                }else{
                  let payment_mode = {payment_mode:1};
                  this.setState({billing_data:payment_mode});
                  this.setState({payment_mode:'payment'});
                }
                
              }
              
          }.bind(this)).catch(function(res){
              
          })
        }
        
       
        let general_contact_bill_settings = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            keys:"'credit_bill_general_contact','default_inventory_status','relevant_contact_type_ssr','relevant_contact_type_ppr','buffer_amount_for_credit_limit','advance_sale_data','is_financial_year_closed','is_global_commission','global_commission','enable_tcs','encrypted_selling_price','selected_staff_while_billing','ask_otp_while_update_sale','item_discount_on_mrp'"
        }
        let gc_api = app_api+'/get_settings';
        axios.post(gc_api, general_contact_bill_settings)
          .then(function (response) {
            let resp = response.data;
            if(resp.ask_otp_while_update_sale!==undefined){
              let ps = resp.ask_otp_while_update_sale;
              this.setState({ask_otp_while_update_sale:ps});
            }
            if(resp.item_discount_on_mrp!==undefined){
              let ps = resp.item_discount_on_mrp;
              this.setState({item_discount_on_mrp:ps});
            }
            if(resp.encrypted_selling_price){
              let ps = resp.encrypted_selling_price;
              this.setState({encrypted_selling_price:ps});
            }
            if(resp.selected_staff_while_billing){
              let pm = resp.selected_staff_while_billing;
              this.setState({selected_staff_while_billing:pm});
            }
            if(resp.is_global_commission){
              let ps = resp.is_global_commission;
              this.setState({is_global_commission:ps});
            }
            if(resp.global_commission){
              let ps = resp.global_commission;
              this.setState({global_commission:ps});
            }
           
            if(resp.credit_bill_general_contact){
              let ps = resp.credit_bill_general_contact;
             
              this.setState({credit_bill_general_contact:ps});
            }
            if(resp.default_inventory_status){
              let ps = resp.default_inventory_status;
              this.setState({default_inventory_status:ps});
            }
            if(resp.barcode_name_on_bill_view){
              let ps = resp.barcode_name_on_bill_view;
              this.setState({barcode_name_on_bill_view:ps});
            }
            if(resp.item_name_on_bill_view){
              let ps = resp.item_name_on_bill_view;
              this.setState({item_name_on_bill_view:ps});
            }
            if(resp.advance_sale_data){
              let ps = resp.advance_sale_data;
              this.setState({advance_sale_data:ps});
            }
            if(resp.buffer_amount_for_credit_limit){
              let ps = resp.buffer_amount_for_credit_limit;
              this.setState({buffer_amount_for_credit_limit:ps});
            }
            if(resp.relevant_contact_type_ssr){
              let relevant_contact_type_ssr = resp.relevant_contact_type_ssr;
              localStorage.setItem("relevant_contact_type_ssr",relevant_contact_type_ssr);
            }else{
              localStorage.setItem("relevant_contact_type_ssr",'1');
            }
            if(resp.relevant_contact_type_ppr){
              let relevant_contact_type_ppr = resp.relevant_contact_type_ppr;
              localStorage.setItem("relevant_contact_type_ppr",relevant_contact_type_ppr);
            }else{
              localStorage.setItem("relevant_contact_type_ppr",'2');
            }
            if(resp.is_fy_closed){
              let is_fy_closed = resp.is_fy_closed;
              this.setState({is_fy_closed:is_fy_closed});
            }
        }.bind(this)).catch(function(res){
            
        }) 
      localStorage.removeItem('p_mode');
      if(user_info)  {      
        let version = localStorage.getItem('IdbVersion');
        version = parseInt(version);
        let dbReq = window.indexedDB.open('BaazarERP', version);
        dbReq.onupgradeneeded = function(event) {
          db = event.target.result;
        }
        
        dbReq.onsuccess = function(event) {
          db = event.target.result;
          try{
            let ContactList = [];  
            
            let AgentOptions = [];  
            let transaction = db.transaction("contacts", "readwrite");
            let contacts = transaction.objectStore("contacts");
            let request = contacts.getAll();
            request.onsuccess = function(e) {
              let cursor =  e.target.result;
              cursor.map( (value, key) => {
                  let id = value.id;
                  let type = value.type;
                  let contact_type = value.contact_type;
                  let data = value.data;
                  let bytes = CryptoJS.AES.decrypt(data, enc_key);
                  let cval = bytes.toString(CryptoJS.enc.Utf8);
                  let con =   JSON.parse(cval);
                  let mobile = con.phone_1.toString();
                  let name = con.name;
                  if(contact_type==="Staff"){
                    //ContactOptions[id]={id:value.id,name:name};
                  }
                  //if(contact_type==="Agent"){
                    AgentOptions[id]={id:value.id,name:name};
                  //}
              });
              db.close();
              //_this.setState({ 'staff_options': ContactOptions }); 
              _this.setState({ 'agent_options': AgentOptions }); 
              
            }; 
          }catch{}
        }
        
        if(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase'){
          let pmbody = {};
          pmbody = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                contact_type : 4,
                uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            }
          let pmapi = app_api+'/get_contact';
          axios.post(pmapi, pmbody)
            .then(function (response) {
              let cursor = response.data;
              let ContactOptions = [];  
              cursor.map( (value, key) => {
                let id = value.contact_id;
                ContactOptions[id]={id:id,name:value.name};
              });
              this.setState({ 'staff_options': ContactOptions }); 
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                      
                  }
              }
          })
        }

        
      }

      document.addEventListener('keydown', this.handleChangeEvent);
      window.onbeforeunload = function(e) {
        if(_this.state.on_load===false){
          return false;
        }
      };

      
      if(edit_billing){ // EDIT MODE
        if(document.getElementById("billing-notes")){
          document.getElementById("billing-notes").style.display='block';
        }
          
          const id = edit_billing[3];
          const type = edit_billing[2];
          let t = this.types[type];
          this.setState({update_id:id});
          this.setState({billing_type:t});
          this.setState({target:type});
          this.setState({send_type:'update'});
          if(type==="sales"){
            this.setState({is_block_visible:true});
            this.setState({save_button:'Update Sale',referer:1});
          } 
          if(type==="purchase"){
            this.setState({is_block_visible:true});
            this.setState({save_button:'Update Purchase',referer:2});
          } 
          if(type==="sales_return"){
            this.setState({save_button:'Update Sale Return',referer:3});
          } 
          if(type==="purchase_return"){
            this.setState({save_button:'Update Purchase Return',referer:4});
          }
          let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              store_state : user_info.state,
              type : t,
              id : parseInt(id),
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/billings/get_billing';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              
              let data = resp.data;
              if(data.length===0){
                window.location.href = '/404';
              }

              this.variant_data = resp.variation_data;
             
              let note_type = data.cd_sequence;
              if(data.other_charges){
                this.setState({other_charges:data.other_charges});
                this.setState({transportation_charge:data.other_charges});
              }
              if(data.tcs_percent){
                this.setState({tcs_percent:data.tcs_percent});
              }
              if(data.hours){
                this.setState({bill_hours:data.hours});
              }
              if(data.pending_ret_adj_opt){
                this.setState({pending_ret_adj_opt:data.pending_ret_adj_opt});
              }
              if(data.pending_ret_adj_amt){
                let pending_ret_adj_amt = data.pending_ret_adj_amt;
                if(pending_ret_adj_amt!==""){
                  pending_ret_adj_amt = parseFloat(pending_ret_adj_amt);
                }
                if(pending_ret_adj_amt>0){
                  this.setState({is_remaining_amount:true});
                }
                this.setState({pending_ret_adj_amt:pending_ret_adj_amt});
                this.setState({remaining_amount:pending_ret_adj_amt});
              }
              if(note_type===""){
                this.setState({note_type:'cash'});
              }
              this.setState({show_hold_button:false});
              if(data.bill_status==='On Hold'){
                this.setState({show_hold_button:true});
                if(type==="sales"){
                  this.setState({save_button:'Create Sale'});
                } 
                if(type==="purchase"){
                  this.setState({save_button:'Create Purchase'});
                } 

                if(type==="sales_return"){
                  this.setState({save_button:'Create Sale Return'});
                  
                } 
                if(type==="purchase_return"){
                  this.setState({save_button:'Create Purchase Return'});
                  
                }
              }
              if(type==="sales_return" || type==="purchase_return"){
                this.setState({is_on_account_return:data.is_on_account});
                if(data.return_option==='old_bill'){
                  this.setState({return_goods_bills:data.return_goods});
                  setTimeout(function(){
                    _this.handleLoadPendingInvoice();
                  },150);
                }
                this.setState({change_pay_to_save:true});	
                let barea = this.state.billing_type.replaceAll(" ",'-');	
                document.querySelector(".top-btn-blk."+barea).style.display ='block';	
                let butarea = document.querySelectorAll(".content-head."+barea);	
                for (let index = 0; index < butarea.length; index++) {	
                  const element = butarea[index];	
                  //element.style.display = 'block';	
                }	
                this.setState({returned_invoice_id:data.returned_invoice_id})
              } 

              if(data.customer_advance_payment!==undefined){
                  this.setState({customer_advance_payment:data.customer_advance_payment});
              }

              this.setState({billing_data:data,dispatched_info:resp.dispatched_info, assisted_by:data.assisted_by,agent_name:data.agent_name,payment_mode:data.payment_mode_txt});
              if(data.transport_id){
                this.setState({transport_id:data.transport_id});
              }
              this.setState({default_payment_mode:data.payment_mode});
              this.setState({is_loaded_bill:true});
              if(data.is_convert_to_sale===undefined){
                this.setState({is_convert_to_sale:data.is_convert_to_sale});
              }
              
              let is_direct_pay = data.is_direct_pay;
              if(data.return_type!==undefined){	
                this.setState({return_type:data.return_type});	
                this.setState({return_option:data.return_option});	
                this.setState({is_contact_selected:true});	
                if(data.return_option==='old_bill'){	
                  this.setState({is_on_account_return:true});	
                }	
              }	
              
              if(data.payments_meta_data!==undefined && data.payments_meta_data.return_amount!==undefined && data.payments_meta_data.return_amount!==""){	
                let ramt = data.payments_meta_data.return_amount.replaceAll(",",'');	
                if(parseFloat(ramt)>=0){	
                  this.setState({change_pay_to_save:true});	
                }	
              }	

              this.setState({is_direct_pay:is_direct_pay});
              this.setState({show_loader:false});

              let rooff = data.round_off;
              let rooff_sym = data.round_off_sym;
              let payment_note_number  = data.payment_note_number;
              let payment_note_redeem_total  = data.payment_note_redeem_total;
              if(payment_note_number!=="" && payment_note_number!==null && payment_note_redeem_total!==null && payment_note_redeem_total!==""){
                this.setState({is_reedeem:true});
              }
              
              setTimeout(() => {	
                this.setState({round_off:rooff});	
                if(document.getElementById("round_off")){	
                  document.getElementById("round_off").value = rooff;	
                }	
              }, 500);
              this.setState({round_off_sym:rooff_sym});
              
              if(rooff_sym==='-'){
                document.getElementById("minus").checked=true;
              }else{
                document.getElementById("plus").checked=true;
              }

              let gdisc = data.global_discount;
              let gdisc_sym = data.global_discount_sym;
              this.setState({global_discount:gdisc});
              this.setState({discount_sym:gdisc_sym});
              
                let disc = 0;
              if(gdisc_sym==='per'){
                let sub_total = parseFloat(data.sub_total.replace(/,/g , ""));
                if(data.global_discount!==undefined){
                  if(isNaN(data.global_discount)){
                    disc =parseFloat(data.global_discount.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(data.global_discount);
                  }
                }
                let stotal = sub_total-disc;
                let gamount = stotal*gdisc/100;
                this.setState({global_discount_amount:this.floating_point(gamount)});
                disc = gdisc;
                localStorage.setItem('discount',gdisc);
                this.setState({global_discount_per:disc});
              }else{
                let sub_total = parseFloat(data.sub_total.replace(/,/g , ""));
                if(data.global_discount!==undefined){
                  if(isNaN(data.global_discount)){
                    disc =parseFloat(data.global_discount.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(data.global_discount);
                  }
                  this.setState({global_discount_rs:disc});
                }
                let stotal = sub_total-disc;
                if(gdisc!==""){
                    disc = (gdisc/stotal)*100;
                    localStorage.setItem('discount',disc);
                }
              }

              if(gdisc_sym==='rs'){
                document.getElementById("discount-rs").checked=true;
              }else{
                document.getElementById("discount-per").checked=true;
              }

              let b_date = data.c_date;
              let billing_date = data.created_date;
              let inv_date = data.inv_date;
              let invoice_date = data.invoice_date;
              this.setState({billingDate:new Date(b_date)});
              this.setState({invoiceDate:new Date(inv_date)});

              let contact_info = data.contact_info;
              localStorage.setItem('contact_info',JSON.stringify(contact_info[0]));
              setTimeout(() => {
                if(document.getElementById("handleManageTCSTransaction")){
                  document.getElementById("handleManageTCSTransaction").click();
                }
                if(document.getElementById("handleSetSelectedContactInfo")){
                  document.getElementById("handleSetSelectedContactInfo").click();
                }
              }, 100);
              let credit_info = data.credit_data;
              let contact_name = '';
              let contact_id = '';
              let address_1 =  '';
              let address_2 = '';
              let city = '';
              let credit_limit = '';
              let previous_balance = '';
              let email = '';
              let phone_1 = '';
              let phone_2 = '';
              let state = '';
              
              
              if(Object.keys(contact_info).length > 0) {
                 contact_name = contact_info[0].name.trim();
                 contact_id = contact_info[0].contact_id;
                 localStorage.setItem("selected_contact",contact_id);
                 setTimeout(() => {
                  if(document.getElementById("handleSetSelectedContact")){
                    document.getElementById("handleSetSelectedContact").click();
                  }
                 }, 100);
                 address_1 = contact_info[0].address_1;
                 address_2 = contact_info[0].address_2;
                 city = contact_info[0].city;
                 /*
                 address_1 = this.decrypt_sanitize(contact_info[0].address_1);
                 address_2 = this.decrypt_sanitize(contact_info[0].address_2);
                 city = this.decrypt_sanitize(contact_info[0].city);*/
                 credit_limit = contact_info[0].credit_limit;
                 email = contact_info[0].email;
                 phone_1 = contact_info[0].phone_1;
                 phone_2 = contact_info[0].phone_2;
                 state = contact_info[0].state;
                 let gst_number = contact_info[0].gst_number;
                 let business_type = 'B2C';
                 if(gst_number!==""){
                  business_type = 'B2B';
                 }
                 this.setState({business_type:business_type});
                 localStorage.setItem('credit_days', contact_info[0].credit_days);
                 localStorage.setItem('state', state);
                 localStorage.setItem('payment_mode', contact_info[0].payment_mode);
                 if(disc==0){
                    localStorage.setItem('discount', contact_info[0].discount);
                  }
                 /*
                 email = this.decrypt_sanitize(contact_info[0].email);
                 phone_1 = this.decrypt_sanitize(contact_info[0].phone_1);
                 phone_2 = this.decrypt_sanitize(contact_info[0].phone_2);
                 state = this.decrypt_sanitize(contact_info[0].state);*/
              }
              previous_balance = credit_info.previous_balance
                credit_limit = credit_info.credit_limit
                let pending_amount = data.pending_amount
             
              let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,original_credit_limit:contact_info[0].credit_limit,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state,previous_balance:previous_balance};
              this.setState({contact_info:c_info});

              let sequence = data.number;
              let sequence_no = data.number_sequence;
              this.sequence[t] = sequence;
              this.sequence[t+" No"] = sequence_no;
              this.setState({currrent_sequence:sequence});
              this.setState({currrent_sequence_no:sequence_no});

              let list_items = data.list_items;
              let return_data = [];
              if(data.return_data!==undefined){
                return_data =data.return_data; 
                if(return_data.length>0){
                  this.setState({is_item_return:true});
                }
              }
              
              this.add_item_area.push(<AddBillingItem variations={this.state.created_variations} key={1} count='1' type="more" index="1" billing_type={this.state.billing_type} list_items={list_items} return_list_items={return_data} variation_data={resp.variation_data}/>);
              this.setState({add_item_area:this.add_item_area});

              
              setTimeout(function(){
                _this.ADJUST_BILL_SNO('regular');
                _this.ADJUST_BILL_SNO('return');
                document.querySelector('input[name="global_discount"]:checked').click();
              },100);
              if(type==='purchase'){
                this.handleLoadUnlinkTransports();
              }
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })


      }else{
        this.setState({show_loader:false});
        this.setState({round_off_opt:true});
        this.setState({round_off_opt2:false});
        let a_tab = "New Sale";
        let thisval ="New Sale";
        if(add_billing_type!==null && add_billing_type[1]){
            this.setState({referer:add_billing_type[1]});
            if(add_billing_type[1]==="1"){
              a_tab="New Sale";
              this.setState({save_button:'Create Sale'});
            }else if(add_billing_type[1]==="2"){
              a_tab="New Purchase";
              this.setState({save_button:'Create Purchase',payment_mode:''});
            }else if(add_billing_type[1]==="3"){
              a_tab="New Sales Return";
              this.setState({save_button:'Create Sales Return'});
            }else if(add_billing_type[1]==="4"){
              a_tab="New Purchase Return";
              this.setState({save_button:'Create Purchase Return'});
            }

            this.setState({billing_type:a_tab});
             thisval =a_tab;
            this.setState({billing_type:thisval});
        }
        //this.more_rows.push(<BillingItemList  key={1} count='1' type="more" index="1" billing_type={a_tab}/>);
        //this.setState({more_rows:this.more_rows});
        if(convert_to_sale===null && resume_cancelled===null){
          this.add_item_area.push(<AddBillingItem variations={this.state.created_variations} key={1} count='1' type="more" index="1" billing_type={a_tab}/>);
          this.setState({add_item_area:this.add_item_area});
        }
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_sequence_number';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              let sales_sequence_no = resp.sales_sequence;
              let purchase_sequence_no = resp.purchase_sequence;
              let sales_return_sequence_no = resp.sales_return_sequence;
              let purchase_return_sequence_no = resp.purchase_return_sequence;
              let lowest_selling_price_percent = resp.lowest_selling_price_percent;
              this.setState({lowest_selling_price_percent:lowest_selling_price_percent});

              let sales_sequence = "#SB"+sales_sequence_no;
              this.sequence["New Sale"] = sales_sequence;
              this.sequence["New Purchase"] = "#PB"+purchase_sequence_no;
              this.sequence["New Sales Return"] = "#SRB"+sales_return_sequence_no;
              this.sequence["New Purchase Return"] = "#PRB"+purchase_return_sequence_no;

              this.sequence["New Sale No"] = sales_sequence_no;
              this.sequence["New Purchase No"] = purchase_sequence_no;
              this.sequence["New Sales Return No"] = sales_return_sequence_no;
              this.sequence["New Purchase Return No"] = purchase_return_sequence_no;

              this.setState({currrent_sequence:sales_sequence});
              this.setState({currrent_sequence_no:sales_sequence_no});
              
         
              this.setState({currrent_sequence_no:this.sequence[thisval+" No"]});
              this.setState({currrent_sequence:_this.sequence[thisval]});
            
                  
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
        
        if(convert_to_sale){ // EDIT MODE
          const id = convert_to_sale[1];
          const type = 'sales';

          let t = this.types[type];
          this.setState({update_id:0});
          this.setState({billing_type:t});
          this.setState({target:type});
          this.setState({send_type:'save'});
         
          this.setState({is_block_visible:true});
          this.setState({is_convert_to_sale:id});
          this.setState({save_button:'Create Sale',referer:1});
          
        
          let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              store_state : user_info.state,
              type : t,
              id : parseInt(id),
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/billings/get_quotation';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              
              let data = resp.data;
              if(data.length===0){
                window.location.href = '/404';
              }

              this.variant_data = resp.variation_data;

              let note_type = data.cd_sequence;
              if(note_type===""){
                this.setState({note_type:'cash'});
              }
              this.setState({show_hold_button:false});
              this.setState({billing_data:data,assisted_by:data.assisted_by,agent_name:data.agent_name,payment_mode:2});
              this.setState({is_loaded_bill:true});
              let is_direct_pay = data.is_direct_pay;
           
              this.setState({is_direct_pay:is_direct_pay});
              this.setState({show_loader:false});

              let rooff = data.round_off;
              let rooff_sym = data.round_off_sym;
            
              setTimeout(() => {	
                this.setState({round_off:rooff});	
                if(document.getElementById("round_off")){	
                  document.getElementById("round_off").value = rooff;	
                }	
              }, 500);
              this.setState({round_off_sym:rooff_sym});
              
              if(rooff_sym==='-'){
                document.getElementById("minus").checked=true;
              }else{
                document.getElementById("plus").checked=true;
              }

              let gdisc = data.global_discount;
              let gdisc_sym = data.global_discount_sym;
              this.setState({global_discount:gdisc});
              this.setState({discount_sym:gdisc_sym});
              
                let disc = 0;
              if(gdisc_sym==='per'){
                let sub_total = parseFloat(data.sub_total.replace(/,/g , ""));
                if(data.discount!==undefined){
                  if(isNaN(data.discount)){
                    disc =parseFloat(data.discount.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(data.discount);
                  }
                }
                let stotal = sub_total-disc;
                let gamount = stotal*gdisc/100;
                this.setState({global_discount_amount:this.floating_point(gamount)});
                disc = gdisc;
                localStorage.setItem('discount',gdisc);
                this.setState({global_discount_per:disc});
              }else{
                let sub_total = parseFloat(data.sub_total.replace(/,/g , ""));
                if(data.discount!==undefined){
                  if(isNaN(data.discount)){
                    disc =parseFloat(data.discount.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(data.discount);
                  }
                  this.setState({global_discount_rs:disc});
                }
                let stotal = sub_total-disc;
                if(gdisc!==""){
                    disc = (gdisc/stotal)*100;
                    localStorage.setItem('discount',disc);
                }
              }

              if(gdisc_sym==='rs'){
                document.getElementById("discount-rs").checked=true;
              }else{
                document.getElementById("discount-per").checked=true;
              }

              let b_date = data.c_date;
              let billing_date = data.created_date;
              let inv_date = data.inv_date;
              let invoice_date = data.invoice_date;
              _this.setState({billingDate:new Date(b_date)});
              _this.setState({invoiceDate:new Date(inv_date)});

              let contact_info = data.contact_info;
              localStorage.setItem('contact_info',JSON.stringify(contact_info[0]));
              setTimeout(() => {
                if(document.getElementById("handleManageTCSTransaction")){
                  document.getElementById("handleManageTCSTransaction").click();
                }
                if(document.getElementById("handleSetSelectedContactInfo")){
                  document.getElementById("handleSetSelectedContactInfo").click();
                }
              }, 100);
              let credit_info = data.credit_data;
              let contact_name = '';
              let contact_id = '';
              let address_1 =  '';
              let address_2 = '';
              let city = '';
              let credit_limit = '';
              let previous_balance = '';
              let email = '';
              let phone_1 = '';
              let phone_2 = '';
              let state = '';
              if(Object.keys(contact_info).length > 0) {
                 contact_name = contact_info[0].name.trim();
                 contact_id = contact_info[0].contact_id;
                 localStorage.setItem("selected_contact",contact_id);
                 setTimeout(() => {
                  if(document.getElementById("handleSetSelectedContact")){
                    document.getElementById("handleSetSelectedContact").click();
                  }
                 }, 100);
                 address_1 = contact_info[0].address_1;
                 address_2 = contact_info[0].address_2;
                 city = contact_info[0].city;
                 /*
                 address_1 = this.decrypt_sanitize(contact_info[0].address_1);
                 address_2 = this.decrypt_sanitize(contact_info[0].address_2);
                 city = this.decrypt_sanitize(contact_info[0].city);*/
                 credit_limit = contact_info[0].credit_limit;
                 email = contact_info[0].email;
                 phone_1 = contact_info[0].phone_1;
                 phone_2 = contact_info[0].phone_2;
                 state = contact_info[0].state;

                 localStorage.setItem('credit_days', contact_info[0].credit_days);
                 localStorage.setItem('state', state);
                 localStorage.setItem('payment_mode', contact_info[0].payment_mode);
                 if(disc==0){
                    localStorage.setItem('discount', contact_info[0].discount);
                  }
                 /*
                 email = this.decrypt_sanitize(contact_info[0].email);
                 phone_1 = this.decrypt_sanitize(contact_info[0].phone_1);
                 phone_2 = this.decrypt_sanitize(contact_info[0].phone_2);
                 state = this.decrypt_sanitize(contact_info[0].state);*/
              }
              previous_balance = credit_info.previous_balance
                credit_limit = credit_info.credit_limit
                let pending_amount = data.pending_amount
             
              let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,original_credit_limit:contact_info[0].credit_limit,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state,previous_balance:previous_balance};
              this.setState({contact_info:c_info});

         
              let list_items = data.list_items;
              let return_data = [];
              

              this.add_item_area.push(<AddBillingItem variations={this.state.created_variations} key={1} count='1' type="more" index="1" billing_type={this.state.billing_type} list_items={list_items} return_list_items={return_data} variation_data={resp.variation_data}/>);
              this.setState({add_item_area:this.add_item_area});
              

              setTimeout(function(){
                _this.ADJUST_BILL_SNO('regular');
                _this.ADJUST_BILL_SNO('return');
                document.querySelector('input[name="global_discount"]:checked').click();
              },100);
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })


      }


        if(resume_cancelled){ // RESUME CANCELLED
            const id = resume_cancelled[2];
            const type = resume_cancelled[1];
            let t = this.types[type];
            //this.setState({update_id:id});
            this.setState({billing_type:t});
            this.setState({target:type});
            this.setState({send_type:'save'});
            this.setState({is_resume_cancelled:true});
            if(type==="sales"){
              this.setState({save_button:'Create Sale',referer:1});
            } 
            if(type==="purchase"){
              this.setState({save_button:'Create Purchase',referer:2});
            } 
            if(type==="sales_return"){
              this.setState({save_button:'Create Sale Return',referer:3});
            } 
            if(type==="purchase_return"){
              this.setState({save_button:'Create Purchase Return',referer:4});
            }
            let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                store_state : user_info.state,
                type : t,
                id : parseInt(id),
                action : 'resume_cancelled',
                uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
             }
          let api = app_api+'/billings/get_billing';
          axios.post(api, body)
            .then(function (response) {
              let resp = response.data;
              
              let data = resp.data;
              if(data.length===0){
                window.location.href = '/404';
              }

              this.variant_data = resp.variation_data;

              let note_type = data.cd_sequence;
              if(data.tcs_percent){
                this.setState({tcs_percent:data.tcs_percent});
              }
              if(note_type===""){
                this.setState({note_type:'cash'});
              }
              this.setState({show_hold_button:false});
              if(data.bill_status==='On Hold'){
                this.setState({show_hold_button:true});
                if(type==="sales"){
                  this.setState({save_button:'Create Sale'});
                } 
                if(type==="purchase"){
                  this.setState({save_button:'Create Purchase'});
                } 

                if(type==="sales_return"){
                  this.setState({save_button:'Create Sale Return'});
                  
                } 
                if(type==="purchase_return"){
                  this.setState({save_button:'Create Purchase Return'});
                  
                }
              }
              if(type==="sales_return" || type==="purchase_return"){
                this.setState({is_on_account_return:data.is_on_account});
                if(data.return_option==='old_bill'){
                  this.setState({return_goods_bills:data.return_goods});
                  setTimeout(function(){
                    _this.handleLoadPendingInvoice();
                  },150);
                }
                this.setState({change_pay_to_save:true});	
                let barea = this.state.billing_type.replaceAll(" ",'-');	
                document.querySelector(".top-btn-blk."+barea).style.display ='block';	
                let butarea = document.querySelectorAll(".content-head."+barea);	
                for (let index = 0; index < butarea.length; index++) {	
                  const element = butarea[index];	
                  //element.style.display = 'block';	
                }	
                this.setState({returned_invoice_id:data.returned_invoice_id})
              } 

              if(data.customer_advance_payment!==undefined){
                  this.setState({customer_advance_payment:data.customer_advance_payment});
              }

              this.setState({billing_data:data,dispatched_info:resp.dispatched_info, assisted_by:data.assisted_by,agent_name:data.agent_name,payment_mode:data.payment_mode_txt});
              this.setState({default_payment_mode:data.payment_mode});
              this.setState({is_loaded_bill:true});
              if(data.is_convert_to_sale===undefined){
                this.setState({is_convert_to_sale:data.is_convert_to_sale});
              }

              let is_direct_pay = data.is_direct_pay;
              if(data.return_type!==undefined){	
                this.setState({return_type:data.return_type});	
                this.setState({return_option:data.return_option});	
                this.setState({is_contact_selected:true});	
                if(data.return_option==='old_bill'){	
                  this.setState({is_on_account_return:true});	
                }	
              }	
              if(data.payments_meta_data!==undefined && data.payments_meta_data.return_amount!==undefined && data.payments_meta_data.return_amount!==""){	
                let ramt = data.payments_meta_data.return_amount.replaceAll(",",'');	
                if(parseFloat(ramt)>=0){	
                  this.setState({change_pay_to_save:true});	
                }	
              }	

              this.setState({is_direct_pay:is_direct_pay});
              this.setState({show_loader:false});

              let rooff = data.round_off;
              let rooff_sym = data.round_off_sym;
              let payment_note_number  = data.payment_note_number;
              let payment_note_redeem_total  = data.payment_note_redeem_total;
              if(payment_note_number!=="" && payment_note_number!==null && payment_note_redeem_total!==null && payment_note_redeem_total!==""){
                this.setState({is_reedeem:true});
              }
              setTimeout(() => {	
                this.setState({round_off:rooff});	
                if(document.getElementById("round_off")){	
                  document.getElementById("round_off").value = rooff;	
                }	
              }, 500);
              this.setState({round_off_sym:rooff_sym});
              
              if(rooff_sym==='-'){
                document.getElementById("minus").checked=true;
              }else{
                document.getElementById("plus").checked=true;
              }

              let gdisc = data.global_discount;
              let gdisc_sym = data.global_discount_sym;
              this.setState({global_discount:gdisc});
              this.setState({discount_sym:gdisc_sym});
              
                let disc = 0;
              if(gdisc_sym==='per'){
                let sub_total = parseFloat(data.sub_total.replace(/,/g , ""));
                if(data.global_discount!==undefined){
                  if(isNaN(data.global_discount)){
                    disc =parseFloat(data.global_discount.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(data.global_discount);
                  }
                }
                let stotal = sub_total-disc;
                let gamount = stotal*gdisc/100;
                this.setState({global_discount_amount:this.floating_point(gamount)});
                disc = gdisc;
                localStorage.setItem('discount',gdisc);
                this.setState({global_discount_per:disc});
              }else{
                let sub_total = parseFloat(data.sub_total.replace(/,/g , ""));
                if(data.global_discount!==undefined){
                  if(isNaN(data.global_discount)){
                    disc =parseFloat(data.global_discount.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(data.global_discount);
                  }
                  this.setState({global_discount_rs:disc});
                }
                let stotal = sub_total-disc;
                if(gdisc!==""){
                    disc = (gdisc/stotal)*100;
                    localStorage.setItem('discount',disc);
                }
              }

              if(gdisc_sym==='rs'){
                document.getElementById("discount-rs").checked=true;
              }else{
                document.getElementById("discount-per").checked=true;
              }

              let b_date = data.c_date;
              let billing_date = data.created_date;
              let inv_date = data.inv_date;
              let invoice_date = data.invoice_date;
              _this.setState({billingDate:new Date(b_date)});
              _this.setState({invoiceDate:new Date(inv_date)});

              let contact_info = data.contact_info;
              localStorage.setItem('contact_info',JSON.stringify(contact_info[0]));
              setTimeout(() => {
                if(document.getElementById("handleManageTCSTransaction")){
                  document.getElementById("handleManageTCSTransaction").click();
                }
                if(document.getElementById("handleSetSelectedContactInfo")){
                  document.getElementById("handleSetSelectedContactInfo").click();
                }
              }, 100);
              let credit_info = data.credit_data;
              let contact_name = '';
              let contact_id = '';
              let address_1 =  '';
              let address_2 = '';
              let city = '';
              let credit_limit = '';
              let previous_balance = '';
              let email = '';
              let phone_1 = '';
              let phone_2 = '';
              let state = '';
              if(Object.keys(contact_info).length > 0) {
                 contact_name = contact_info[0].name.trim();;
                 contact_id = contact_info[0].contact_id;
                 localStorage.setItem("selected_contact",contact_id);
                 setTimeout(() => {
                  if(document.getElementById("handleSetSelectedContact")){
                    document.getElementById("handleSetSelectedContact").click();
                  }
                 }, 100);
                 address_1 = contact_info[0].address_1;
                 address_2 = contact_info[0].address_2;
                 city = contact_info[0].city;
                 /*
                 address_1 = this.decrypt_sanitize(contact_info[0].address_1);
                 address_2 = this.decrypt_sanitize(contact_info[0].address_2);
                 city = this.decrypt_sanitize(contact_info[0].city);*/
                 credit_limit = contact_info[0].credit_limit;
                 email = contact_info[0].email;
                 phone_1 = contact_info[0].phone_1;
                 phone_2 = contact_info[0].phone_2;
                 state = contact_info[0].state;
                 let gst_number = contact_info[0].gst_number;
                 let business_type = 'B2C';
                 if(gst_number!==""){
                  business_type = 'B2B';
                 }
                 this.setState({business_type:business_type});
                 localStorage.setItem('credit_days', contact_info[0].credit_days);
                 localStorage.setItem('state', state);
                 localStorage.setItem('payment_mode', contact_info[0].payment_mode);
                 if(disc==0){
                    localStorage.setItem('discount', contact_info[0].discount);
                  }
                 /*
                 email = this.decrypt_sanitize(contact_info[0].email);
                 phone_1 = this.decrypt_sanitize(contact_info[0].phone_1);
                 phone_2 = this.decrypt_sanitize(contact_info[0].phone_2);
                 state = this.decrypt_sanitize(contact_info[0].state);*/
              }
              previous_balance = credit_info.previous_balance
                credit_limit = credit_info.credit_limit
                let pending_amount = data.pending_amount
             
              let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,original_credit_limit:contact_info[0].credit_limit,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state,previous_balance:previous_balance};
              this.setState({contact_info:c_info});

              let sequence = data.number;
              let sequence_no = data.number_sequence;
              _this.sequence[t] = sequence;
              _this.sequence[t+" No"] = sequence_no;
              _this.setState({currrent_sequence:sequence});
              _this.setState({currrent_sequence_no:sequence_no});

              let list_items = data.list_items;
              let return_data = [];
              if(data.return_data!==undefined){
                return_data =data.return_data; 
                if(return_data.length>0){
                  this.setState({is_item_return:true});
                }
              }

              this.add_item_area.push(<AddBillingItem variations={this.state.created_variations} key={1} count='1' type="more" index="1" billing_type={this.state.billing_type} list_items={list_items} return_list_items={return_data} variation_data={resp.variation_data}/>);
              this.setState({add_item_area:this.add_item_area});

              
              setTimeout(function(){
                _this.ADJUST_BILL_SNO('regular');
                _this.ADJUST_BILL_SNO('return');
                document.querySelector('input[name="global_discount"]:checked').click();
              },100);
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
        }




        setTimeout(function(){
        if(_this.state.billing_type==='New Sale' || _this.state.billing_type==='New Purchase'){
          if(document.getElementById("mode_of_payment")){
              let pm_element = document.getElementById("mode_of_payment");
              if(pm_element.selectedIndex>=0){
                let ptxt = pm_element.options[pm_element.selectedIndex].text.toLowerCase();
                if(document.getElementById("paid")){
                  if(ptxt==='payment' || ptxt==='cash'){
                    document.getElementById("paid").classList.add('hide');
                  }else{
                    document.getElementById("paid").classList.remove('hide');
                  }
                }
              }
          }
        }
      },1500);

      }
      let PaymentMode = [];
      let pmbody = {};
        pmbody = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let pmapi = app_api+'/get_payment_mode';
        axios.post(pmapi, pmbody)
          .then(function (response) {
            let cursor = response.data;
            
            cursor.map( (value, key) => {
                let id = value.payment_mode_id;
                let payment_mode = value.payment_mode;
                PaymentMode[id] = {id:id,payment_mode:payment_mode,status:value.status,direct_pay:value.direct_pay,is_cheque:value.is_cheque,account_type:value.account_type};
            });
           
            this.setState({ 'payment_modes': PaymentMode }); 
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }

    addMoreItem(event){
      let type = event.currentTarget.id;
      let item_len = document.getElementsByClassName("item-name").length;
      let key = item_len+1;
      this.more_rows.push(<BillingItemList count={key} type={type} key={key} index={key} billing_type={this.state.billing_type}/>);
      this.setState({more_rows:this.more_rows});
      let rm = document.querySelectorAll(".item-remove");
      for(let i=0;i<rm.length;i++){
        rm[i].style.display = 'block';
      }
    }
    
    operateCheck(event){
        let thisval = event.target.value;
        let referer = "";
        if(thisval==='New Sale'){
          window.history.pushState({},'','/new-billing/1');
          referer = 1;
          this.setState({save_button:'Create Sale',payment_mode:'payment'});
          setTimeout(function(){
            if(document.getElementById("mode_of_payment")){
              document.getElementById("mode_of_payment").value = 1;
            }
          },200);
          if(document.getElementById("total-bill-amount")){
            document.getElementById("total-bill-amount").innerHTML = 0;
          }
          if(document.getElementById("total-paid-amount")){
            document.getElementById("total-paid-amount").innerHTML = 0;
          }
          if(document.getElementById("total-return-amount")){
            document.getElementById("total-return-amount").innerHTML = 0;
          }
           if(this.state.send_type==="save"){
              let dpmfp = {
                    store_name:user_info.store_name,
                    branch_id:user_info.login_id,
                    user_id : user_info.user_id,
                    key:'default_payment_mode_for_sale',
                    uuid : utils.uuid(),
                    role_id : user_info.access_level,
                    token : user_info.token,
                    _schema : user_info._schema,
                }
                let dpmfp_api = app_api+'/get_settings';
                axios.post(dpmfp_api, dpmfp)
                  .then(function (response) {
                    let resp = response.data;

                    if(resp.default_payment_mode_for_sale){
                      let pm = resp.default_payment_mode_for_sale;
                      let billing_data = this.state.billing_data;
                      if(billing_data.payment_mode){
                        billing_data.payment_mode = pm;
                        this.setState({billing_data:billing_data});
                      }else{
                        let payment_mode = {payment_mode:pm};
                        this.setState({billing_data:payment_mode});
                      }

                      setTimeout(function(){
                        if(document.getElementById("mode_of_payment")){
                          document.getElementById("mode_of_payment").value=pm;
                          let pm_element = document.getElementById("mode_of_payment");
                          let mode_of_payment_txt = pm_element.options[pm_element.selectedIndex].text;
                          mode_of_payment_txt = mode_of_payment_txt.toLowerCase();
                          _this.setState({payment_mode:mode_of_payment_txt});
                        }
                      },300);
                    }else{
                      let billing_data = this.state.billing_data;
                      if(billing_data.payment_mode){
                        billing_data.payment_mode = 1;
                        this.setState({billing_data:billing_data});
                      }else{
                        let payment_mode = {payment_mode:1};
                        this.setState({billing_data:payment_mode});
                      }
                      _this.setState({payment_mode:'payment'});
                    }
                }.bind(this)).catch(function(res){
                    
                })
              }
        }else if(thisval==='New Purchase'){
          window.history.pushState({},'','/new-billing/2');
          referer = 2;
          this.setState({save_button:'Create Purchase',payment_mode:''});
           setTimeout(function(){
            if(document.getElementById("mode_of_payment")){
              document.getElementById("mode_of_payment").value = '';
            }
          },200);
          if(document.getElementById("total-bill-amount")){
            document.getElementById("total-bill-amount").innerHTML = 0;
          }
          if(document.getElementById("total-paid-amount")){
            document.getElementById("total-paid-amount").innerHTML = 0;
          }
          if(document.getElementById("total-return-amount")){
            document.getElementById("total-return-amount").innerHTML = 0;
          }

          if(this.state.send_type==="save"){
            let dpmfp = {
                  store_name:user_info.store_name,
                  branch_id:user_info.login_id,
                  user_id : user_info.user_id,
                  key:'default_payment_mode_for_parchase',
                  uuid : utils.uuid(),
                  role_id : user_info.access_level,
                  token : user_info.token,
                  _schema : user_info._schema,
              }
              let dpmfp_api = app_api+'/get_settings';
              axios.post(dpmfp_api, dpmfp)
                .then(function (response) {
                  let resp = response.data;

                  if(resp.default_payment_mode_for_parchase){
                    let pm = resp.default_payment_mode_for_parchase;
                    let billing_data = this.state.billing_data;
                    if(billing_data.payment_mode){
                      billing_data.payment_mode = pm;
                      this.setState({billing_data:billing_data});
                    }else{
                      let payment_mode = {payment_mode:pm};
                      this.setState({billing_data:payment_mode});
                    }
                    setTimeout(function(){
                      if(document.getElementById("mode_of_payment")){
                        document.getElementById("mode_of_payment").value=pm;
                        let pm_element = document.getElementById("mode_of_payment");
                        let mode_of_payment_txt = pm_element.options[pm_element.selectedIndex].text;
                        mode_of_payment_txt = mode_of_payment_txt.toLowerCase();
                        _this.setState({payment_mode:mode_of_payment_txt});
                      }
                    },300);
                  }
              }.bind(this)).catch(function(res){
                  
              })
            }

        }else if(thisval==='New Sales Return'){
          window.history.pushState({},'','/new-billing/3');
          referer = 3;
          this.setState({save_button:'Create Sales Return'});
        }else if(thisval==='New Purchase Return'){
          referer = 4;
          this.setState({save_button:'Create Purchase Return'});
          window.history.pushState({},'','/new-billing/4');
        }
        if(document.getElementById("resetMenuState")){
          document.getElementById("resetMenuState").click();
        }
        
        _this.setState({billing_type:thisval,referer:referer});
        _this.setState({currrent_sequence_no:_this.sequence[thisval+" No"]});
        _this.setState({currrent_sequence:_this.sequence[thisval]});
        document.getElementById("breadcrumbs_subtitle").innerHTML = thisval;
        let button = thisval.replace("New",'Create');
        /*this.more_rows = [];
        this.setState({more_rows:this.more_rows,save_button:button});
*/
        let input = document.querySelectorAll('input[type=text]');
        for(let i=0;i<input.length;i++){
            input[i].value = '';
        }
        let iTotal = document.querySelectorAll('.item-total');
        for(let i=0;i<iTotal.length;i++){
            iTotal[i].innerHTML = 0;
        }
        let allOut = document.querySelectorAll('.all-out');
        for(let i=0;i<allOut.length;i++){
            allOut[i].innerHTML = 0;
        }
        if(thisval==="New Sale" || thisval==="New Purchase"){
          setTimeout(function(){
            let pm_element = document.getElementById("mode_of_payment");

            if(pm_element){
              try{
                let ptxt = pm_element.options[pm_element.selectedIndex].text.toLowerCase();
                
                if(ptxt==='payment' || ptxt==='cash'){
                  document.getElementById("paid").classList.add('hide');
                }else{
                  document.getElementById("paid").classList.remove('hide');
                }
              }catch(e){}
            }
          },2000);
        }else{
          if(document.getElementById("paid")){
            document.getElementById("paid").classList.remove('hide');
          }
        }
        /*localStorage.removeItem('item_options');
        localStorage.removeItem('purchase_item_options');*/
        
        this.add_item_area=[];
        this.setState({add_item_area:this.add_item_area});
        setTimeout(function(){
          _this.add_item_area.push(<AddBillingItem variations={_this.state.created_variations} key={1} count='1' type="more" index="1" billing_type={thisval}/>);
          _this.setState({add_item_area:_this.add_item_area});
          if(document.getElementById("handleRefreshContactList")){
            document.getElementById("handleRefreshContactList").click();
          }
         },100);

    }
    IsJsonString(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }

    getGoodsData(pamt){
      let return_goods = [];
      let pending_check = document.querySelectorAll('.pending-chk');
      let total_bill_amount = document.getElementById("final-total-text").value;
      if(pending_check.length>0){
        let return_amt = parseFloat(pamt);
        let good_amt = 0;
        for(let i=0;i<pending_check.length;i++){
          let gval = pending_check[i].value; 
          let is_checked = pending_check[i].checked; 
          if(is_checked===true){
            let gid = pending_check[i].getAttribute('data-id'); 
            let g_amt = document.getElementById("pending-amt-"+gid).value;
            g_amt = parseFloat(g_amt);
            if(return_amt<=g_amt){
              let g_data = {invoice_id:gval,invoice_type:this.state.billing_type,amount:return_amt,invoice_amount:g_amt,total_bill_amount:total_bill_amount};
              return_goods.push(g_data);
              return return_goods;
            }else{
              let ret_amt = return_amt-g_amt;
              let p_amt = return_amt - ret_amt;
              return_amt = ret_amt;
              let g_data = {invoice_id:gval,invoice_type:this.state.billing_type,amount:p_amt,invoice_amount:g_amt,total_bill_amount:total_bill_amount};
              return_goods.push(g_data);
            }
          }
        }
      }
      return return_goods;
    }
    handleResetPrintWindow = (e) =>{	
      this.setState({response_id:0});	
    }

    processSetPrintData = (print_data) =>{
      let date = print_data.date;
      let invoice_date = print_data.invoice_date;
      let mode_of_payment = print_data.payment_mode;
      let mode_of_payment_txt = print_data.payment_mode_txt;
      let global_bundle_txt = print_data.global_bundle_txt;
      let total_grand = print_data.total_grand;
      let customer = print_data.customer;
      let notes = print_data.notes;
      let total_unit = print_data.total_unit;
      let total_sub = print_data.total_sub;
      let total_tax = print_data.total_tax;
      let total_sgst = print_data.total_sgst;
      let total_igst = print_data.total_igst;
      let total_cgst = print_data.total_cgst;
      let total_discount = print_data.total_discount;
      let global_discount = print_data.global_discount;
      let global_discount_opt = print_data.global_discount_opt;
      let round_off = print_data.round_off;
      let round_off_opt = print_data.round_off_opt;
      let staff = print_data.staff;
      let agent_name = print_data.agent_name;
      let ptype_note = print_data.payment_cr_dr_note;
      let before_redeem_total = print_data.before_cr_dr_redeem_total;
      let redeem_total = print_data.cr_dr_redeem_total;
      let print_list_items = print_data.print_list_items;
      let payments_data = print_data.payments_data;
      let send_return_data_items = print_data.return_items;
      let return_meta = print_data.return_meta;
      let dispatched_details = print_data.dispatched_details;
      
      let pcreated_date  = this.formatDate(date);
      let inv_date  = this.formatDate(invoice_date);
      let bill_time  = this.formatAMPM(date);
      this.setPrintData('c_date',pcreated_date+' '+bill_time);
      this.setPrintData('created_date',pcreated_date+' '+bill_time);
      this.setPrintData('bill_date',pcreated_date);
      this.setPrintData('bill_time',bill_time);
      this.setPrintData('visible_created_date',pcreated_date);
      this.setPrintData('inv_date',inv_date);
      this.setPrintData('invoice_date',inv_date+' '+bill_time);
      this.setPrintData('visible_invoice_date',inv_date);
      this.setPrintData('other_charges',this.state.transportation_charge);

      let total_before_tax = document.getElementById("total-before-tax").innerHTML;
      total_before_tax = total_before_tax.replaceAll(',','');
      let calculate_total = document.getElementById("s-total").innerHTML;

      let paid_status = '';
      if(mode_of_payment===2 || mode_of_payment===''){
          paid_status = 'Unpaid';
      }
      if(mode_of_payment===1){
          if(document.getElementById("total-return-amount")){
              let tra = document.getElementById("total-return-amount").innerHTML.replaceAll(',','');
              tra = parseFloat(tra);
              if(tra>=0){
                  paid_status = 'Paid';
              }
          }
      }

      let con_info = this.state.selected_contact_info;


      //let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,original_credit_limit:contact_info[0].credit_limit,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state,previous_balance:previous_balance};
     // this.setState({contact_info:c_info});
      
      this.setPrintData('contact_info',[con_info]);
      

      this.setPrintData('schema',localStorage.getItem("_current"));
      this.setPrintData('pending_amount',0);
      this.setPrintData('payment_mode',mode_of_payment);
      this.setPrintData('payment_mode_txt',mode_of_payment_txt);
      this.setPrintData('target','');
      this.setPrintData('bundle_text',global_bundle_txt);
      this.setPrintData('total',total_grand);
      this.setPrintData('id',1);
      this.setPrintData('branch_id',user_info.login_id);
      this.setPrintData('contact_id',customer);
      this.setPrintData('note',notes);
      this.setPrintData('total_unit',total_unit);
      this.setPrintData('sub_total',total_sub);
      this.setPrintData('tax',total_tax);
      this.setPrintData('total_sgst',total_sgst);
      this.setPrintData('total_igst',total_igst);
      this.setPrintData('total_cgst',total_cgst);

      let tcs_percent = print_data.tcs_percent;
      
      let tcs_total = 0;
      if(total_tax!==""){
        total_tax = 0;
      }
      if(tcs_percent!=="" && tcs_percent!==undefined){
        tcs_percent = parseFloat(tcs_percent);
        if(tcs_percent>0){
          total_tax = parseFloat(total_tax);
          total_before_tax = parseFloat(total_before_tax);
          tcs_total = (total_before_tax * tcs_percent)/100;
        }
      }
      print_data.tcs_total = tcs_total;

      this.setPrintData('tcs_percent',tcs_percent);
      this.setPrintData('tcs_total',tcs_total);
      this.setPrintData('number',this.state.currrent_sequence);
      this.setPrintData('number_sequence',this.state.currrent_sequence_no);

      this.setPrintData('calculate_total',calculate_total);
      this.setPrintData('discount',total_discount);
      this.setPrintData('total_before_tax',total_before_tax);
      this.setPrintData('global_discount',global_discount);
      this.setPrintData('global_discount_sym',global_discount_opt);
      this.setPrintData('round_off',round_off);
      this.setPrintData('round_off_sym',round_off_opt);
      let staff_txt = '';
      if(document.getElementById("staff-dropdown")){
        let staff_dropdown = document.getElementById("staff-dropdown");
         staff_txt = staff_dropdown.options[staff_dropdown.selectedIndex].text;
      }

      this.setPrintData('bill_status',paid_status);
      this.setPrintData('bill_status_id',mode_of_payment);
      this.setPrintData('assisted_by',staff);
      this.setPrintData('assisted_by_txt',staff_txt);
      this.setPrintData('agent_name',agent_name);
      this.setPrintData('payment_note_number',ptype_note);
      this.setPrintData('before_payment_note_redeem_total',before_redeem_total);
      this.setPrintData('payment_note_redeem_total',redeem_total);

      
      this.setPrintData('list_items',print_list_items);
//payments_comma_sep
      
      if(payments_data.bill_types!==undefined){
        let bptyps = payments_data.bill_types;
        let ptyps_arr= [];
        for (let index = 0; index < bptyps.length; index++) {
          const element = bptyps[index];
          let ptid = element.id;
          let ptvalue = element.value;
          let ptype_rep = ptid.replace('total-','');
          ptype_rep = ptype_rep.replace('-amount','');
          ptype_rep = ptype_rep.replace('-',' ');
          if(ptvalue!=="" && parseFloat(ptvalue)>0){
            let build_type = ptype_rep+':'+ptvalue;
            ptyps_arr.push(build_type);
          }
        }
        let payments_comma_sep = ptyps_arr.toString();
        this.setPrintData('payments_comma_sep',payments_comma_sep);
      }

      this.setPrintData('payments_meta_data',payments_data);
      this.setPrintData('return_data',this.send_return_data_items);
      this.setPrintData('return_meta_data',return_meta);

      this.setPrintData('dispatched_info',dispatched_details,'dispatched_info');
      
      let username = [{'username':user_info.name}];
      this.setPrintData('user_info',username);

      let company_info = [{
          "store_name": user_info.store_name,
          "email": user_info.email,
          "username":  user_info.name,
          "phone": user_info.phone,
          "phone_2": user_info.phone_2,
          "address":  user_info.address,
          "address_2": user_info.address_2,
          "pin_code": user_info.pin_code,
          "city": user_info.city,
          "state": user_info.state,
          "gst_type": user_info.gst_type,
          "gst_number": user_info.gst_number,
          "company_name": user_info.company_name,
          "company_sub_title": user_info.company_sub_title,
        }];
       
      this.setPrintData('company_info',company_info);

      let custom_fields = document.querySelectorAll('.iitem-custom-field');

      let custom_fields_json = [];
      for (let index = 0; index < custom_fields.length; index++) {
        const cf_element = custom_fields[index];
        const data_id = cf_element.getAttribute('data-id');
        let thisval = cf_element.value;
        let cf_id = document.getElementById('iitem-cf-id-'+data_id).value;
        let cf_name = document.getElementById('iitem-cf-name-'+data_id).value;
        let cf_is_sale_active = document.getElementById('iitem-cf-is-sale-active-'+data_id).value;
        let cf_is_purchase_active = document.getElementById('iitem-cf-is-purchase-active-'+data_id).value;


        let cf_fields = {'id':cf_id,'is_purchase_active':cf_is_purchase_active,'is_sale_active':cf_is_sale_active,'name':cf_name,'value':thisval};
        custom_fields_json.push(cf_fields);
      }
      this.setPrintData('custom_data',custom_fields_json);

      let pieces_in_parcel = '';
      if(document.getElementById("pieces_in_parcel")){
        pieces_in_parcel = document.getElementById("pieces_in_parcel").value;
      }

      let no_of_parcels = '';
      if(document.getElementById("no_of_parcels")){
        no_of_parcels = document.getElementById("no_of_parcels").value;
      }
      let packed_by = '';
      if(document.getElementById("packed_by")){
        packed_by = document.getElementById("packed_by").value;
      }


      let checked_by = '';
      if(document.getElementById("checked_by")){
        checked_by = document.getElementById("checked_by").value;
      }
      this.setPrintData('pieces_in_parcel',pieces_in_parcel);
      this.setPrintData('no_of_parcels',no_of_parcels);
      this.setPrintData('packed_by',packed_by);
      this.setPrintData('checked_by',checked_by);
    }

    handleSetBillPopupData =(g_total)=>{
      if(document.getElementById("handleResetDataOnFly")){
        document.getElementById("handleResetDataOnFly").click();
      }
      let _this = this;
      setTimeout(() => {
        localStorage.setItem('bptotal',g_total);
      if(document.getElementById("bill-pay-pop-title")){
        document.getElementById("bill-pay-pop-title").innerHTML = g_total;
      }
      if(document.getElementById("total-bill-amount")){
        let camt = document.getElementById("total-bill-amount").innerHTML;
        if(camt==="" || camt==="0"){
          document.getElementById("total-bill-amount").innerHTML = g_total;
        }
      }
      if(document.getElementById("total-paid-amount")){
        let camt = document.getElementById("total-paid-amount").innerHTML;
        if(camt==="" || camt==="0"){
          document.getElementById("total-paid-amount").innerHTML = g_total;
        }
      }
      if(this.state.update_id>0){
      }else{
        if(document.getElementById("total-cash-amount")){
          let camt = document.getElementById("total-cash-amount").value;
          if(camt===""){
            //document.getElementById("total-cash-amount").value = g_total;
          }
        }else if(document.getElementById("total-online-payment-amount")){
          let camt = document.getElementById("total-online-payment-amount").value;
          if(camt===""){
            //document.getElementById("total-online-payment-amount").value = g_total;
          }
        }else if(document.getElementById("total-credit-amount")){
          let camt = document.getElementById("total-credit-amount").value;
          if(camt===""){
            document.getElementById("total-credit-amount").value = g_total;
          }
        }
      }
      _this.handleChangePayBtnStatus();
      }, 100);
    }

    handlePrintOnFly = () =>{
      localStorage.setItem("response_id",1);
      let bill_status = 'save' ;
      let sel_contact = document.getElementById("contact_box").value;
      let total_paid_amount = 0;
      let total_return_amount = 0;
    
      let payments_data = [];
      if(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase'){
          if(bill_status==='save' || bill_status==='paid' || bill_status==='save-print'){
            let tba = '';
            if(document.getElementById("total-bill-amount")){
              tba = document.getElementById("total-bill-amount").innerHTML.replaceAll(',','');
            }
            let tpa = '';
            if(document.getElementById("total-paid-amount")){
              tpa = document.getElementById("total-paid-amount").innerHTML.replaceAll(',','');
            }
            let tra = '';
            if(document.getElementById("total-return-amount")){
              tra = document.getElementById("total-return-amount").innerHTML.replaceAll(',','');
            }
            total_paid_amount = tpa;
            total_return_amount = tra;
           
            let t_p_amts = document.querySelectorAll(".total-bill-amount");
  
            let pay_data = [];
            for(let i=0;i<t_p_amts.length;i++){
              let amt = t_p_amts[i].value;
              let id = t_p_amts[i].id;
              let data_id = t_p_amts[i].getAttribute('data-id');
              if(data_id){
                let chq_bank_name = 'chq-bank-name-'+data_id.replaceAll(' ','-').toLowerCase();
                let chq_no = 'chq-no-'+data_id.replaceAll(' ','-').toLowerCase();
                let chq_date = 'chq-date-'+data_id.replaceAll(' ','-').toLowerCase();
                let send_for_recon = 'send-for-recon-'+data_id.replaceAll(' ','-').toLowerCase();
                let pay_chq_no = '';
                let pay_chq_date = '';
                let pay_chq_bank_name = '';
                let pay_is_recon = '';
                if(document.getElementById(chq_no)){
                  pay_chq_bank_name = document.getElementById(chq_bank_name).value;
                  pay_chq_no = document.getElementById(chq_no).value;
                  pay_chq_date = document.getElementById(chq_date).value;
                }
                if(document.getElementById(send_for_recon)){
                  pay_is_recon = document.getElementById(send_for_recon).checked;
                }
                let pdata = {'id':id,value:amt,'cheque_bank_name':pay_chq_bank_name,'cheque_no':pay_chq_no,'cheque_date':pay_chq_date,'is_reconciliation':pay_is_recon};
                pay_data.push(pdata);
              }
            }
            if(tba!=="" && tpa!==""){
              let pd = {'bill_amount':tba,'paid_amount':tpa,'return_amount':tra};
              payments_data = {bill_types:pay_data,data:pd};
            }
            
          }
        //}
      }
      
        let total_grand = document.getElementById("final-total").innerHTML;
        let return_goods = [];
        if(this.state.billing_type==='New Sales Return' || this.state.billing_type==='New Purchase Return'){
          let pamt = total_grand.replace(/,/g , "");
          if(this.state.return_option==='cash'){
            let pd = {'bill_amount':pamt,'paid_amount':pamt,'return_amount':0};
            let pay_data = [];
            let pdata = {'id':'total-cash-amount',value:pamt};
            pay_data.push(pdata);
            payments_data = {bill_types:pay_data,data:pd};
          }
          return_goods = this.getGoodsData(pamt);
        }
  
        let inventory_status = this.state.default_inventory_status;
        
        if(bill_status==='save' || bill_status==='save-print' || bill_status==='paid'){
          bill_status=1;
        }else{
          bill_status=2;
        }
        let send_data = {};
        let customer = parseInt(document.getElementById("customer-dropdown").value);
        let selected_contact = this.state.selected_contact;
  
        if((customer===null || customer==="" || customer===0 || isNaN(customer)) && (sel_contact!=="")){
            customer = selected_contact;
        }
  
  
        let staff = 0;
        if(document.getElementById("staff-dropdown")){
          staff = parseInt(document.getElementById("staff-dropdown").value);
        }
        let agent_name = '';
        if(document.getElementById("agent-dropdown")){
          agent_name = document.getElementById("agent-dropdown").value;
        }
        let date = this.state.billingDate;
        let invoice_date = this.state.invoiceDate;
        let mode_of_payment = '';
        let mode_of_payment_txt = '';
        let buyer_order_number = '';
        let dispatched_through = '';
        let dispatched_doc = '';
        let supplier_ref_no = '';
        let delivery_note = '';
        let other_note = '';
        let other_refrence = '';
        let terms_of_delivery = '';
        if(this.state.billing_type==='New Sale'){
          let pm_element = document.getElementById("mode_of_payment");
          mode_of_payment =pm_element.value;
          mode_of_payment_txt = pm_element.options[pm_element.selectedIndex].text;
        }
        
        //if(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase'){
          dispatched_through = (document.getElementById("dispatched-through"))?document.getElementById("dispatched-through").value:'';
          dispatched_doc = (document.getElementById("dispatched-doc"))?document.getElementById("dispatched-doc").value:'';
          supplier_ref_no = (document.getElementById("supplier-ref-no"))?document.getElementById("supplier-ref-no").value:'';
          delivery_note = (document.getElementById("delivery-note"))?document.getElementById("delivery-note").value:'';
          other_note = (document.getElementById("other-note"))?document.getElementById("other-note").value:'';
          other_refrence = (document.getElementById("other-refrence"))?document.getElementById("other-refrence").value:'';
          terms_of_delivery = (document.getElementById("terms-of-delivery"))?document.getElementById("terms-of-delivery").value:'';
        //}
  
  
        let credit_days = localStorage.getItem("credit_days");
        let notes = document.getElementById("billing-notes").value;
  
        let total_unit = document.getElementById("total-unit").innerHTML;
        let total_sub = document.getElementById("sub-total").innerHTML;
        
        let total_discount = 0;
        if(document.getElementById("total-discount")){
         total_discount = document.getElementById("total-discount").innerHTML;
        }
        let round_off = document.getElementById("round_off").value;
        let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;
  
        let global_discount = document.getElementById("global_discount").value;
        let global_discount_opt = document.querySelector('input[name="global_discount"]:checked').value;
  
        let total_tax = document.getElementById("total-tax").innerHTML;
        let total_igst = document.getElementById("total-igst").innerHTML;
        let total_sgst = document.getElementById("total-sgst").innerHTML;
        let total_cgst = document.getElementById("total-cgst").innerHTML;
        
       
        var return_row = document.querySelectorAll('.return-items-row'); 
        let send_return_data_items = [];
        let send_data_items = [];
        for (var i = 0; i < return_row.length; i++) { 
            let idx = return_row[i].getAttribute('data-id');
            let list_item_id = document.getElementById("ret-item-list-id-"+idx).value;
            let item_text = document.getElementById("ret-item-box-"+idx).value;
            let item_name = document.getElementById("ret-item-name-"+idx).value;
            let old_item_name = document.getElementById("ret-old-item-name-"+idx).value;
            let item_desc = document.getElementById("ret-item-desc-"+idx).value;
            let item_hsn = document.getElementById("ret-item-hsn-"+idx).value;
            let item_unit = document.getElementById("ret-item-unit-"+idx).value;
            let quantity = document.getElementById("ret-item-quantity-"+idx).value;
            let discount = (document.getElementById("ret-item-discount-"+idx))?document.getElementById("ret-item-discount-"+idx).value:0;
           
            let barcode = '';
           
            let category = '';
         
            let is_tax_inc = 'false';
            let price_hidden = 0;
            if(document.getElementById("ret-price-hidden-"+idx)){
              price_hidden = document.getElementById("ret-price-hidden-"+idx).value;
            }
  
            let return_price = document.getElementById("ret-price-"+idx).value;
           
            if(quantity===""){
              quantity = 0;
            }
        
            let gst = document.getElementById("ret-item-gst-"+idx).value;
            let igst = document.getElementById("ret-item-igst-"+idx).value;
            let cgst = document.getElementById("ret-item-cgst-"+idx).value;
            let sgst = document.getElementById("ret-item-sgst-"+idx).value;
            let total = document.getElementById("ret-item-total-txt-"+idx).value;
           
            send_return_data_items[i] = {
                                        list_item_id:list_item_id,
                                        bill_status:bill_status,
                                        item_text:item_text,
                                        item_name:item_name,
                                        old_item_name:old_item_name,
                                        item_desc:item_desc,
                                        hsn:item_hsn,
                                        quantity:quantity,
                                        discount:discount,
                                        product_unit_id:item_unit,
                                        price:return_price,
                                        igst:igst,
                                        cgst:cgst,
                                        sgst:sgst,
                                        tax:gst,
                                        total:total,
                                        price_hidden:price_hidden,
                                        target:this.state.billing_type,
                                      };
        }
  
       
        let return_meta = {};
        if(send_return_data_items.length>0){
          return_meta = {
            total_unit : (document.getElementById("return-total-unit"))?document.getElementById("return-total-unit").innerHTML:0,
            total_quantity : (document.getElementById("return-total-quantity"))?document.getElementById("return-total-quantity").innerHTML:0,
            subtotal : (document.getElementById("return-subtotal"))?document.getElementById("return-subtotal").innerHTML:0,
            total : (document.getElementById("final-return-total-text"))?document.getElementById("final-return-total-text").value:0,
            total_before_return : (document.getElementById("total-before-return-txt"))?document.getElementById("total-before-return-txt").value:0,
          }
        }
  
        var inputs = document.querySelectorAll('.items-row'); 
        let print_list_items = [];
        let global_bundle_txt = '';
        for (var i = 0; i < inputs.length; i++) { 
            let idx = inputs[i].getAttribute('data-id');
            let list_item_id = 0;
            if(document.getElementById("item-list-id-"+idx)){
              list_item_id = document.getElementById("item-list-id-"+idx).value;
            }
            let item_text = document.getElementById("item-box-"+idx).value;
            let item_name = document.getElementById("item-name-"+idx).value;
            let old_item_name = document.getElementById("old-item-name-"+idx).value;
            let item_desc = document.getElementById("item-desc-"+idx).value;
            let item_hsn = document.getElementById("item-hsn-"+idx).value;
            let item_unit = document.getElementById("item-unit-"+idx).value;
            let item_unit_name = document.getElementById("item-unit-name-"+idx).value;
            let quantity = parseFloat(document.getElementById("item-quantity-"+idx).value);
            let barcode = '';
            if(document.getElementById("vitem-barcode-name-"+idx)){
              barcode = document.getElementById("vitem-barcode-name-"+idx).value;
            }
            let alias_barcode = '';
            if(document.getElementById("vitem-alias-barcode-name-"+idx)){
              alias_barcode = document.getElementById("vitem-alias-barcode-name-"+idx).value;
            }
             let category = '';
            if(document.getElementById("item-category-"+idx)){
              category = document.getElementById("item-category-"+idx).value;
            }
            
  
            let is_tax_inc = 'false';
            if(document.getElementById("item-tax-inc-"+idx)){
              is_tax_inc = document.getElementById("item-tax-inc-"+idx).value;
            }
            let price_hidden = 0;
            if(document.getElementById("price-hidden-"+idx)){
              price_hidden = document.getElementById("price-hidden-"+idx).value;
            }
  
            let purchase_price = 0;
            //if(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase' || this.state.billing_type==='New Purchase Return'){
              purchase_price = document.getElementById("price-"+idx).value;
            //}
  
            let selling_price = 0;
            let sp_discount = 0 ;
            let sp_tax = 0 ;
            let sp_tax_type = 0 ;
            if(this.state.billing_type==='New Purchase'){
              selling_price = document.getElementById("item-selling-price-"+idx).value;
              sp_discount = document.getElementById("sp-discount-"+idx).value;
              if(sp_discount===""){
                sp_discount = 0;
              }
              sp_tax = document.getElementById("item-sp-gst-"+idx).value; ;
              sp_tax_type = document.getElementById("sp-item-tax-inc-"+idx).value; ;
            }
            if(this.state.billing_type==='New Sales Return'){
              selling_price = document.getElementById("price-"+idx).value;
            }
            if(quantity===""){
              quantity = 0;
            }
            let availability = quantity;
            if(this.state.billing_type==='New Sale'){
              availability = document.getElementById("item-availability-"+idx).innerHTML;
            }
  
            let discount = 0;
            if(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase'){
              discount = document.getElementById("discount-"+idx).value;
              if(discount===""){
                discount = 0;
              }
            }
  
  
            let gst = document.getElementById("item-gst-"+idx).value;
            let igst = document.getElementById("item-igst-"+idx).value;
            let cgst = document.getElementById("item-cgst-"+idx).value;
            let sgst = document.getElementById("item-sgst-"+idx).value;
            let total = document.getElementById("item-total-txt-"+idx).value;
            let item_commission = 0;
            if(document.getElementById("item-total-commission-"+idx)){
              item_commission = document.getElementById("item-total-commission-"+idx).value;
            }
            
            let list_item_type = '';
            if(document.getElementById("item-type-"+idx)){
              list_item_type = document.getElementById("item-type-"+idx).value;
            }
           
            let has_variant = '';
            if(document.getElementById("item-has-variant-"+idx)){
              has_variant = document.getElementById("item-has-variant-"+idx).value;
            }
  
            let is_variant = '';
            if(document.getElementById("item-is-variant-"+idx)){
              is_variant = document.getElementById("item-is-variant-"+idx).value;
            }
            let custom_fields_json = '[]';
            if(document.getElementById("item-cf-"+idx)){
              custom_fields_json = document.getElementById("item-cf-"+idx).value;
            }
            let reorder_quantity = '';
            if(document.getElementById("item-re-order-qty-"+idx)){
              reorder_quantity = document.getElementById("item-re-order-qty-"+idx).value;
            }
            let option1 = '';
            let value1 = '';
            
            if(document.getElementById("vitem-option1-"+idx)){
              let v_option1 = document.getElementById("vitem-option1-"+idx);
               option1 = v_option1.getAttribute('data-id');
               value1 = v_option1.value;
            }
  
            let option2 = '';
            let value2 = '';
            
            if(document.getElementById("vitem-option2-"+idx)){
              let v_option2 = document.getElementById("vitem-option2-"+idx);
               option2 = v_option2.getAttribute('data-id');
               value2 = v_option2.value;
            }
  
            let option3 = '';let value3 = '';
            if(document.getElementById("vitem-option3-"+idx)){
              let v_option3 = document.getElementById("vitem-option3-"+idx);
               option3 = v_option3.getAttribute('data-id');
               value3 = v_option3.value;
            }
            
  
            
            send_data_items[i] = {
                                        is_variant:is_variant,
                                        has_variant:has_variant,
                                        item_type:list_item_type,
                                        list_item_id:list_item_id,
                                        bill_status:bill_status,
                                        item_text:item_text,
                                        item_name:item_name,
                                        old_item_name:old_item_name,
                                        item_desc:item_desc,
                                        hsn:item_hsn,
                                        quantity:quantity,
                                        product_unit_id:item_unit,
                                        item_unit_name:item_unit_name,
                                        availability:availability,
                                        purchase_price:purchase_price,
                                        selling_price:selling_price,
                                        discount:discount,
                                        igst:igst,
                                        cgst:cgst,
                                        sgst:sgst,
                                        tax:gst,
                                        item_commission:item_commission,
                                        total:total,
                                        price_hidden:price_hidden,
                                        is_tax_inc:is_tax_inc,
                                        sp_discount:sp_discount,
                                        sp_tax : sp_tax,
                                        sp_tax_type : sp_tax_type,
                                        inventory_status:inventory_status,
                                        option1:option1,
                                        option2:option2,
                                        option3:option3,
                                        value1:value1,
                                        value2:value2,
                                        value3:value3,
                                        barcode_name:barcode,
                                        alias_barcode:alias_barcode,
                                        category_id:category,
                                        custom_fields_json:custom_fields_json,
                                        reorder_quantity:reorder_quantity,
                                      };
                let iitem_category_name = '';
                if(document.getElementById("item-category-name-"+idx)){
                  iitem_category_name = document.getElementById("item-category-name-"+idx).innerHTML;
                }
                let bundle_quantity = '';
                if(document.getElementById("item-bundle-quantity-"+idx)){
                  bundle_quantity = document.getElementById("item-bundle-quantity-"+idx).value;
                }
                let bundle_txt = '';
                if(document.getElementById("item-bundle-text-"+idx)){
                  bundle_txt = document.getElementById("item-bundle-text-"+idx).innerHTML;
                  global_bundle_txt = bundle_txt;
                }
                let bundle_desc = '';
                if(document.getElementById("bundle_quantity-"+idx)){
                  bundle_desc = document.getElementById("bundle_quantity-"+idx).innerHTML;
                }
                print_list_items[i] = {
                  "is_variation": is_variant,
                  "has_variation": has_variant,
                  "iitem_category_name": iitem_category_name,
                  "iitem_category": category,
                  "product_key": "",
                  "barcode": barcode,
                  "alias_barcode":alias_barcode,
                  "barcode_name_on_bill_view": this.state.barcode_name_on_bill_view,
                  "item_name_on_bill_view": this.state.item_name_on_bill_view,
                  "bundle_desc": bundle_desc,
                  "bundle": bundle_desc,
                  "bundle_quantity": bundle_quantity,
                  "bundle_text": bundle_txt,
                  "item_id": item_name,
                  "list_item_id": list_item_id,
                  "item_name": item_text,
                  "quantity": quantity,
                  "price": purchase_price,
                  "price2": selling_price,
                  "discount": discount,
                  "price_hidden": price_hidden,
                  "is_tax_inc":is_tax_inc,
                  "pp_tax_type": is_tax_inc,
                  "sp_discount": sp_discount,
                  "sp_total_discount": "0",
                  "sp_tax": sp_tax,
                  "sp_tax_type": sp_tax_type,
                  "availability": availability,
                  "total_discount": 0,
                  "tax": gst,
                  "igst":igst,
                  "cgst": cgst,
                  "sgst": sgst,
                  "hidden_igst": "0",
                  "hidden_sgst": "0",
                  "hidden_cgst": "0",
                  "total_tax": 0,
                  "total_igst": 0,
                  "total_sgst": 0,
                  "total_cgst": 0,
                  "total": total,
                  "desc": item_desc,
                  "hsn": item_hsn,
                  "unit_id": item_unit,
                  "unit_name": item_unit_name,
                  "total_avl": 0,
                  "product_name": item_name,
                  "parent_id": 0,
                  "p_rand_key": 0,
                  "iitem_type": list_item_type,
                  "custom_fields_json":JSON.parse(custom_fields_json),
                  "reorder_quantity":reorder_quantity,
              }                                      
                
        }
     
        let items_to_delete = [];
        let return_items_to_delete = [];
        
        let payment_type_note = '';
        let b_target = "sales";
        if(this.state.billing_type==="New Purchase"){
          b_target = "purchase";
        }else if(this.state.billing_type==="New Purchase Return"){
          b_target = "purchase_return";
        }else if(this.state.billing_type==="New Sales Return"){
          b_target = "sales_return";
        }
        if(document.getElementById("payemet-type-note")){
          payment_type_note = document.getElementById("payemet-type-note").value;
        }
        this.setState({target:b_target});
        let ptype_note = 0;
        if(document.getElementById("ptype_note")){
          ptype_note = document.getElementById("ptype_note").value;
          if(ptype_note===""){
            ptype_note = 0;
          }
        }
        let before_redeem_total = '';
        if(document.getElementById("before-redeem-total")){
          before_redeem_total = document.getElementById("before-redeem-total").innerHTML;
        }
        let redeem_total = '';
        if(document.getElementById("redeem-total")){
          redeem_total = document.getElementById("redeem-total").innerHTML;
        }
  
        let dispatched_details ={
            agent_name : agent_name,
            dispatched_through : dispatched_through,
            dispatched_document : dispatched_doc,
            supplier_ref_no : supplier_ref_no,
            delivery_note : delivery_note,
            other_note : other_note,
            other_refrence : other_refrence,
            terms_of_delivery : terms_of_delivery,
          }
  
          let returned_invoice_id = 0;	
          if(document.getElementById("returned_invoice_id")){	
            returned_invoice_id = document.getElementById("returned_invoice_id").value;	
          }
  
          let commission = 0;	
          if(document.getElementById("staff-global-comission")){	
            commission = document.getElementById("staff-global-comission").innerHTML;	
          }
  
          let pieces_in_parcel = '';
          if(document.getElementById("pieces_in_parcel")){
            pieces_in_parcel = document.getElementById("pieces_in_parcel").value;
          }
  
          let no_of_parcels = '';
          if(document.getElementById("no_of_parcels")){
            no_of_parcels = document.getElementById("no_of_parcels").value;
          }
          let packed_by = '';
          if(document.getElementById("packed_by")){
            packed_by = document.getElementById("packed_by").value;
          }
  
  
          let checked_by = '';
          if(document.getElementById("checked_by")){
            checked_by = document.getElementById("checked_by").value;
          }
  
          let selling_price_margin = '';
          if(document.getElementById("selling_price_margin")){
            selling_price_margin = document.getElementById("selling_price_margin").value;
          }
          let tcs_percent = 0;	
          if(document.getElementById("tcs-percent")){	
            tcs_percent = document.getElementById("tcs-percent").value;	
          }
        send_data = {
            pieces_in_parcel:pieces_in_parcel,
            no_of_parcels:no_of_parcels,
            packed_by:packed_by,
            checked_by:checked_by,
            selling_price_margin:selling_price_margin,
            dispatched_details : dispatched_details,
            payment_cr_dr_note :ptype_note,
            before_cr_dr_redeem_total :before_redeem_total,
            cr_dr_redeem_total :redeem_total,
            update_id : this.state.update_id,
            send_type : this.state.send_type,
            bill_status : bill_status,
            type : this.state.billing_type,
            customer : customer,
            contact_name : sel_contact,
            selected_contact : selected_contact,
            staff : staff,
            credit_days : credit_days,
            date : date,
            invoice_date : invoice_date,
            payment_mode : mode_of_payment,
            payment_mode_txt : mode_of_payment_txt,
            buyer_order_no : buyer_order_number,
            dispatched_through : dispatched_through,
            dispatched_document : dispatched_doc,
            supplier_ref_no : supplier_ref_no,
            delivery_note : delivery_note,
            other_note : other_note,
            other_refrence : other_refrence,
            terms_of_delivery : terms_of_delivery,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            sequence_no : this.state.currrent_sequence_no,
            sequence : this.state.currrent_sequence,
            notes : notes,
            total_unit:total_unit,
            global_discount:global_discount,
            global_discount_opt:global_discount_opt,
            round_off:round_off,
            round_off_opt:round_off_opt,
            total_sub:total_sub,
            total_discount:total_discount,
            total_igst:total_igst,
            total_cgst:total_cgst,
            total_sgst:total_sgst,
         
            total_tax:total_tax,
            total_grand:total_grand,
            commission : commission,
            items : send_data_items,
            return_items : send_return_data_items,
            return_meta : return_meta,
            items_to_delete : items_to_delete,
            return_items_to_delete : return_items_to_delete,
            payment_type_note : payment_type_note,
            agent_name : agent_name,
            inventory_status : inventory_status,
            current_bill_status : (this.state.is_resume_cancelled===false && this.state.billing_data && this.state.billing_data.bill_status!==undefined)?this.state.billing_data.bill_status:'',
            payments_data:payments_data,
            return_goods:return_goods,
            is_on_account:this.state.is_on_account_return,	
            return_type : this.state.return_type,	
            return_option : this.state.return_option,	
            returned_invoice_id : returned_invoice_id,
            tcs_percent:tcs_percent,
            is_convert_to_sale : this.state.is_convert_to_sale,
        }
        let pro_print_data = send_data;
        pro_print_data.print_list_items = print_list_items;
        pro_print_data.global_bundle_txt = global_bundle_txt;
        this.processSetPrintData(pro_print_data);
        
        document.getElementById("handlePrint").click();
    }

    handleHoldCurrentBill = () =>{
      if(document.getElementById("hold")){
        document.getElementById('hold-bill-new').classList.add("hide");	
        document.getElementById('hold-bill-new-btn-spinner').classList.remove("hide");
        document.getElementById("hold").click(); 
      }
    }
    handleChangeOTP = (otp) =>{
      this.setState({otp:otp});
    }
    handleCloseOTP = () =>{
      this.setState({is_pay_popup_triggered:false});
      this.setState({is_asked_otp:false});
    }
    handleSubmitOTP = ()=>{
      if(this.state.otp==="" || this.state.otp.length<3){
        document.getElementById('otp').focus();
        return false;
      }
      this.setState({is_triggered_save:false});
      document.getElementById('validate-billing-otp').classList.add("hide");	
      document.getElementById('validate-billing-otp-btn-spinner').classList.remove("hide");
      let cc_data = {
          type:'billing',
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          otp:this.state.otp,
          id:this.state.update_id,
          _schema : user_info._schema,
      }
      let npcapi = app_api+'/validate_otp';
      axios.post(npcapi, cc_data)
        .then(function (response) {
          let resp = response.data;
          let message = resp.message;
          document.getElementById('validate-billing-otp').classList.remove("hide");	
          document.getElementById('validate-billing-otp-btn-spinner').classList.add("hide");
          if(resp.success===1){
            this.setState({is_validated_otp:true,is_asked_otp:false});
            if(document.getElementById('ts-save')){
              document.getElementById('ts-save').click();
            }
          }else{
            this.setState({otp_error:message});
            return false;
          }
      }.bind(this)).catch(function(res){
      })
    }
    saveBilling(event) {
      let is_online = this.CHECK_INTERNET_CONNECTION();
      if(!is_online){
        return false;
      }
      if(this.state.selected_staff_while_billing===true){
        if(document.getElementById("staff-dropdown")){
          let staffdd = document.getElementById("staff-dropdown").value;
          if(staffdd===''){
            document.getElementById("staff-dropdown").focus();
            return false;
          }
        }
      }
      if(document.getElementById("dispatched-doc")){
        if(this.state.lr_error!==''){
          document.getElementById("dispatched-doc").focus();
          return false;
        }
      }
      let invoice_date = this.state.invoiceDate;
        let buyer_order_number = '';
        let dispatched_through = '';
        let dispatched_doc = '';
        let supplier_ref_no = '';
        let delivery_note = '';
        let other_note = '';
        let other_refrence = '';
        let terms_of_delivery = '';
        
          dispatched_through = (document.getElementById("dispatched-through"))?document.getElementById("dispatched-through").value:'';
          dispatched_doc = (document.getElementById("dispatched-doc"))?document.getElementById("dispatched-doc").value:'';
          supplier_ref_no = (document.getElementById("supplier-ref-no"))?document.getElementById("supplier-ref-no").value:'';
          delivery_note = (document.getElementById("delivery-note"))?document.getElementById("delivery-note").value:'';
          other_note = (document.getElementById("other-note"))?document.getElementById("other-note").value:'';
          other_refrence = (document.getElementById("other-refrence"))?document.getElementById("other-refrence").value:'';
          terms_of_delivery = (document.getElementById("terms-of-delivery"))?document.getElementById("terms-of-delivery").value:'';
        
        if(this.state.billing_type==='New Purchase'){
          if(document.getElementById("invoiceDate")){
            if(invoice_date===null){
              document.getElementById("invoiceDate").focus();
              return false;
            }
          }
          if(document.getElementById("supplier-ref-no")){
            if(supplier_ref_no===""){
              document.getElementById("supplier-ref-no").focus();
              return false;
            }
          }
        }
          
      let clicked_on = event.currentTarget.id;	
      let bill_status = clicked_on;	
      if(clicked_on==='ts-save'){	
        bill_status = 'save';	
      }
      let sel_contact = document.getElementById("contact_box").value;
      let total_paid_amount = 0;
      let total_return_amount = 0;
    
      let payments_data = [];
      if(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase'){
        


        let is_credit_limit = this.CHECK_CREDIT_LIMIT('save','none');
        if(is_credit_limit===false){
          return false;
        }
        
        let contact_payment_mode = localStorage.getItem("payment_mode");
        let pmode_element = document.getElementById("mode_of_payment");
        let pmode_txt = pmode_element.options[pmode_element.selectedIndex].text;
        //if(this.state.billing_type==="New Sale"){
        
          
        //}
        //if(this.state.is_direct_pay===true){
          pmode_txt = pmode_txt.toLowerCase();

          this.setState({payment_mode:pmode_txt});

          if(bill_status==='save' || bill_status==='paid' || bill_status==='save-print'){
            let tba = '';
            if(document.getElementById("total-bill-amount")){
              tba = document.getElementById("total-bill-amount").innerHTML.replaceAll(',','');
            }
            let tpa = '';
            if(document.getElementById("total-paid-amount")){
              tpa = document.getElementById("total-paid-amount").innerHTML.replaceAll(',','');
            }
            let tra = '';
            if(document.getElementById("total-return-amount")){
              tra = document.getElementById("total-return-amount").innerHTML.replaceAll(',','');
            }
            total_paid_amount = tpa;
            total_return_amount = tra;
            let g_total = document.getElementById("final-total-text").value;
            if(tpa==="" || (tpa==="0" && g_total!=="0")){	
              if(document.getElementById("total-cash-amount")){	
                if(document.getElementById("total-cash-amount")){	
                  document.getElementById("total-cash-amount").focus();	
                  this.handleOpenBillPaymentPopup();
                  return false
                }else if(document.getElementById("total-online-payment-amount")){	
                  document.getElementById("total-online-payment-amount").focus();	
                }	
                if(document.getElementById("bill-save-error")){	
                  document.getElementById("bill-save-error").innerHTML = 'Please enter amount to pay.';	
                  return false;	
                }	
              }	
            }
            
            if(tra!=="" && parseFloat(tra)<0){
              if(document.getElementById("bill-save-error")){
                document.getElementById("bill-save-error").innerHTML = 'Please adjust balance amount.';
                return false;
              }
            }

            let t_p_amts = document.querySelectorAll(".total-bill-amount");

            let pay_data = [];
            for(let i=0;i<t_p_amts.length;i++){
              let amt = t_p_amts[i].value;
              let id = t_p_amts[i].id;
              let data_id = t_p_amts[i].getAttribute('data-id');
              let chq_bank_name = '';
              let chq_no = '';
              let chq_date = '';
              let chq_is_recon = '';
              if(data_id){
                chq_bank_name = 'chq-bank-name-'+data_id.replaceAll(' ','-').toLowerCase();
                chq_no = 'chq-no-'+data_id.replaceAll(' ','-').toLowerCase();
                chq_date = 'chq-date-'+data_id.replaceAll(' ','-').toLowerCase();
                chq_is_recon = 'send-for-recon-'+data_id.replaceAll(' ','-').toLowerCase();
              }
              let pay_chq_no = '';
              let pay_chq_bank_name = '';
              let pay_chq_date = '';
              let pay_is_recon = '';
              if(chq_no!=='' && document.getElementById(chq_no)){
                pay_chq_bank_name = document.getElementById(chq_bank_name).value;
                pay_chq_no = document.getElementById(chq_no).value;
                pay_chq_date = document.getElementById(chq_date).value;
              }
              if(chq_is_recon!=='' && document.getElementById(chq_is_recon)){
                pay_is_recon = document.getElementById(chq_is_recon).checked;
              }
              let pdata = {'id':id,value:amt,'cheque_bank_name':pay_chq_bank_name,'cheque_no':pay_chq_no,'cheque_date':pay_chq_date,'is_reconciliation':pay_is_recon};
              pay_data.push(pdata);
            }
            if(tba!=="" && tpa!==""){
              let pd = {'bill_amount':tba,'paid_amount':tpa,'return_amount':tra};
              payments_data = {bill_types:pay_data,data:pd};
            }
            if(this.state.is_pay_popup_triggered===false){
              this.handleOpenBillPaymentPopup();
              setTimeout(() => {
                if(document.getElementById('mode_of_payment')){
                  let mop = document.getElementById('mode_of_payment').value;
                  if(mop!==1){
                    document.getElementById('mode_of_payment').focus();
                  }
                }
              }, 100);
              return false;
            }
          }
        //}
      }else{
        if(this.state.is_pay_popup_triggered===false){
          this.handleOpenBillPaymentPopup();
          return false;
        }
      }
      
      if(this.state.is_triggered_save===true){
        return false;
      }
      
      this.setState({is_triggered_save:true});
        let total_grand = document.getElementById("final-total").innerHTML;
        let return_goods = [];
        if(this.state.billing_type==='New Sales Return' || this.state.billing_type==='New Purchase Return'){
          let pamt = total_grand.replace(/,/g , "");
          if(this.state.return_option==='cash'){
            let pd = {'bill_amount':pamt,'paid_amount':pamt,'return_amount':0};
            let pay_data = [];
            let pdata = {'id':'total-cash-amount',value:pamt};
            pay_data.push(pdata);
            payments_data = {bill_types:pay_data,data:pd};
          }
          return_goods = this.getGoodsData(pamt);
          if(this.state.return_type==='invoice' && this.state.return_option==='old_bill' && return_goods.length===0 && this.state.update_id===0){	
            toast("Sorry! Old invoice is not selected.");	
            return false;	
          }
        }

        let inventory_status = this.state.default_inventory_status;
        this.setState({on_load:true});
        
        let click_id = bill_status;
        if(bill_status==='save' || bill_status==='save-print' || bill_status==='paid'){
          bill_status=1;
        }else{
          bill_status=2;
        }
        if(this.state.is_store===true){
          bill_status=2;
        }
        let send_data = {};
        let customer = parseInt(document.getElementById("customer-dropdown").value);
        let selected_contact = this.state.selected_contact;

        if((customer===null || customer==="" || customer===0 || isNaN(customer)) && (sel_contact!=="")){
            customer = selected_contact;
        }

        if(sel_contact!=="" && (customer==="" || customer==="0" || customer===null || isNaN(customer))){

            let cc_data = {
                store_name:user_info.store_name,
                branch_id:user_info.login_id,
                user_id : user_info.user_id,
                name : sel_contact,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
            }
            let npcapi = app_api+'/check_contact_status';
            axios.post(npcapi, cc_data)
              .then(function (response) {
                let resp = response.data;
                let message = resp.message;
                document.getElementById("contact_box").focus();
                setTimeout(function(){
                  _this.setState({contact_error:''});
                },10000);
                this.setState({contact_error:message});
            }.bind(this)).catch(function(res){
            })

            document.getElementById("page-content-wrapper").scrollIntoView(true);
            
            return false;
        }

        let staff = 0;
        if(document.getElementById("staff-dropdown")){
          staff = parseInt(document.getElementById("staff-dropdown").value);
        }
        let agent_name = '';
        if(document.getElementById("agent-dropdown")){
          agent_name = document.getElementById("agent-dropdown").value;
        }
        let date = this.state.billingDate;
        let mode_of_payment = '';
        let mode_of_payment_txt = '';
                
        if(document.getElementById("mode_of_payment")){
          let pm_element = document.getElementById("mode_of_payment");
          mode_of_payment =pm_element.value;
          mode_of_payment_txt = pm_element.options[pm_element.selectedIndex].text;
        }
        
       
       


        let credit_days = localStorage.getItem("credit_days");
        let notes = document.getElementById("billing-notes").value;

        let total_unit = document.getElementById("total-unit").innerHTML;
        let total_sub = document.getElementById("sub-total").innerHTML;
        
        let total_discount = 0;
        if(document.getElementById("total-discount")){
         total_discount = document.getElementById("total-discount").innerHTML;
        }
        let round_off = document.getElementById("round_off").value;
        let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;

        let global_discount = document.getElementById("global_discount").value;
        let global_discount_opt = document.querySelector('input[name="global_discount"]:checked').value;
        global_discount = global_discount.trim();
        let total_tax = document.getElementById("total-tax").innerHTML;
        let total_igst = document.getElementById("total-igst").innerHTML;
        let total_sgst = document.getElementById("total-sgst").innerHTML;
        let total_cgst = document.getElementById("total-cgst").innerHTML;
        
       
        var return_row = document.querySelectorAll('.return-items-row'); 
        
        for (var i = 0; i < return_row.length; i++) { 
            let idx = return_row[i].getAttribute('data-id');
            let list_item_id = document.getElementById("ret-item-list-id-"+idx).value;
            let item_text = document.getElementById("ret-item-box-"+idx).value;
            let item_name = document.getElementById("ret-item-name-"+idx).value;
            let old_item_name = document.getElementById("ret-old-item-name-"+idx).value;
            let item_desc = document.getElementById("ret-item-desc-"+idx).value;
            let item_hsn = document.getElementById("ret-item-hsn-"+idx).value;
            let item_unit = document.getElementById("ret-item-unit-"+idx).value;
            let quantity = document.getElementById("ret-item-quantity-"+idx).value;
            let discount = (document.getElementById("ret-item-discount-"+idx))?document.getElementById("ret-item-discount-"+idx).value:0;
           
            let barcode = '';
           
            let category = '';
         
            let is_tax_inc = 'false';
            let price_hidden = 0;
            if(document.getElementById("ret-price-hidden-"+idx)){
              price_hidden = document.getElementById("ret-price-hidden-"+idx).value;
            }

            let return_price = document.getElementById("ret-price-"+idx).value;
           
            if(quantity===""){
              quantity = 0;
            }
        
            let gst = document.getElementById("ret-item-gst-"+idx).value;
            let igst = document.getElementById("ret-item-igst-"+idx).value;
            let cgst = document.getElementById("ret-item-cgst-"+idx).value;
            let sgst = document.getElementById("ret-item-sgst-"+idx).value;
            let total = document.getElementById("ret-item-total-txt-"+idx).value;
           
            this.send_return_data_items[i] = {
                                        list_item_id:list_item_id,
                                        bill_status:bill_status,
                                        item_text:item_text,
                                        item_name:item_name,
                                        old_item_name:old_item_name,
                                        item_desc:item_desc,
                                        hsn:item_hsn,
                                        quantity:quantity,
                                        discount:discount,
                                        product_unit_id:item_unit,
                                        price:return_price,
                                        igst:igst,
                                        cgst:cgst,
                                        sgst:sgst,
                                        tax:gst,
                                        total:total,
                                        price_hidden:price_hidden,
                                        target:this.state.billing_type,
                                      };
        }

       
        let return_meta = {};
        if(this.send_return_data_items.length>0){
          return_meta = {
            total_unit : (document.getElementById("return-total-unit"))?document.getElementById("return-total-unit").innerHTML:0,
            total_quantity : (document.getElementById("return-total-quantity"))?document.getElementById("return-total-quantity").innerHTML:0,
            subtotal : (document.getElementById("return-subtotal"))?document.getElementById("return-subtotal").innerHTML:0,
            total : (document.getElementById("final-return-total-text"))?document.getElementById("final-return-total-text").value:0,
            total_before_return : (document.getElementById("total-before-return-txt"))?document.getElementById("total-before-return-txt").value:0,
          }
        }

        var inputs = document.querySelectorAll('.items-row'); 
        let print_list_items = [];
        let global_bundle_txt = '';
        for (var i = 0; i < inputs.length; i++) { 
            let idx = inputs[i].getAttribute('data-id');
            let list_item_id = 0;
            if(document.getElementById("item-list-id-"+idx)){
              list_item_id = document.getElementById("item-list-id-"+idx).value;
            }
            let item_text = document.getElementById("item-box-"+idx).value;
            let item_name = document.getElementById("item-name-"+idx).value;
            let old_item_name = document.getElementById("old-item-name-"+idx).value;
            let item_desc = document.getElementById("item-desc-"+idx).value;
            let item_hsn = document.getElementById("item-hsn-"+idx).value;
            let item_unit = document.getElementById("item-unit-"+idx).value;
            let item_unit_name = document.getElementById("item-unit-name-"+idx).value;
            let quantity = parseFloat(document.getElementById("item-quantity-"+idx).value);
            let barcode = '';
            if(document.getElementById("vitem-barcode-name-"+idx)){
              barcode = document.getElementById("vitem-barcode-name-"+idx).value;
            }
            let alias_barcode = '';
            if(document.getElementById("vitem-alias-barcode-name-"+idx)){
              alias_barcode = document.getElementById("vitem-alias-barcode-name-"+idx).value;
            }
          let category = '';                            
            if(document.getElementById("item-category-"+idx)){
              category = document.getElementById("item-category-"+idx).value;
            }
            

            let is_tax_inc = 'false';
            if(document.getElementById("item-tax-inc-"+idx)){
              is_tax_inc = document.getElementById("item-tax-inc-"+idx).value;
            }
            let price_hidden = 0;
            if(document.getElementById("price-hidden-"+idx)){
              price_hidden = document.getElementById("price-hidden-"+idx).value;
            }

            let item_pp = 0;
            if(document.getElementById('item-purchase-price-'+idx)){
                item_pp = document.getElementById('item-purchase-price-'+idx).value;
            }

            let purchase_price = 0;
            //if(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase' || this.state.billing_type==='New Purchase Return'){
              purchase_price = document.getElementById("price-"+idx).value;
            //}

            let selling_price = 0;
            let sp_discount = 0 ;
            let sp_tax = 0 ;
            let sp_tax_type = 0 ;
            if(this.state.billing_type==='New Purchase'){
              selling_price = document.getElementById("item-selling-price-"+idx).value;
              sp_discount = document.getElementById("sp-discount-"+idx).value;
              if(sp_discount===""){
                sp_discount = 0;
              }
              sp_tax = document.getElementById("item-sp-gst-"+idx).value; ;
              sp_tax_type = document.getElementById("sp-item-tax-inc-"+idx).value; ;
            }
            if(this.state.billing_type==='New Sales Return'){
              selling_price = document.getElementById("price-"+idx).value;
            }
            if(quantity===""){
              quantity = 0;
            }
            let availability = quantity;
            if(this.state.billing_type==='New Sale'){
              availability = document.getElementById("item-availability-"+idx).innerHTML;
            }

            let discount = 0;
            if(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase'){
              discount = document.getElementById("discount-"+idx).value;
              if(discount===""){
                discount = 0;
              }
            }

            let sale_sp_discount_type = 'per';
            if(document.getElementById("sale-sp-discount-type-"+idx)){
              sale_sp_discount_type = document.getElementById("sale-sp-discount-type-"+idx).value;
            }
            let purchase_sp_discount_type = 'per';
            if(document.getElementById("purchase-sp-discount-type-"+idx)){
              purchase_sp_discount_type = document.getElementById("purchase-sp-discount-type-"+idx).value;
            }
            let purchase_pp_discount_type = 'per';
            if(document.getElementById("purchase-pp-discount-type-"+idx)){
              purchase_pp_discount_type = document.getElementById("purchase-pp-discount-type-"+idx).value;
            }

            let gst = document.getElementById("item-gst-"+idx).value;
            let igst = document.getElementById("item-igst-"+idx).value;
            let cgst = document.getElementById("item-cgst-"+idx).value;
            let sgst = document.getElementById("item-sgst-"+idx).value;
            let total = document.getElementById("item-total-txt-"+idx).value;
            let item_commission = 0;
            if(document.getElementById("item-total-commission-"+idx)){
              item_commission = document.getElementById("item-total-commission-"+idx).value;
            }
            let list_item_type = '';
            if(document.getElementById("item-type-"+idx)){
              list_item_type = document.getElementById("item-type-"+idx).value;
            }
           
            let has_variant = '';
            if(document.getElementById("item-has-variant-"+idx)){
              has_variant = document.getElementById("item-has-variant-"+idx).value;
            }

            let is_variant = '';
            if(document.getElementById("item-is-variant-"+idx)){
              is_variant = document.getElementById("item-is-variant-"+idx).value;
            }
            let custom_fields_json = '[]';
            if(document.getElementById("item-cf-"+idx)){
              custom_fields_json = document.getElementById("item-cf-"+idx).value;
            }
            let reorder_quantity = '';
            if(document.getElementById("item-re-order-qty-"+idx)){
              reorder_quantity = document.getElementById("item-re-order-qty-"+idx).value;
            }
            

         

            let option1 = '';
            let value1 = '';
            
            if(document.getElementById("vitem-option1-"+idx)){
              let v_option1 = document.getElementById("vitem-option1-"+idx);
               option1 = v_option1.getAttribute('data-id');
               value1 = v_option1.value;
            }

            let option2 = '';
            let value2 = '';
            
            if(document.getElementById("vitem-option2-"+idx)){
              let v_option2 = document.getElementById("vitem-option2-"+idx);
               option2 = v_option2.getAttribute('data-id');
               value2 = v_option2.value;
            }

            let option3 = '';let value3 = '';
            if(document.getElementById("vitem-option3-"+idx)){
              let v_option3 = document.getElementById("vitem-option3-"+idx);
               option3 = v_option3.getAttribute('data-id');
               value3 = v_option3.value;
            }
            

            
            this.send_data_items[i] = {
                                        is_variant:is_variant,
                                        has_variant:has_variant,
                                        item_type:list_item_type,
                                        list_item_id:list_item_id,
                                        bill_status:bill_status,
                                        item_text:item_text,
                                        item_name:item_name,
                                        old_item_name:old_item_name,
                                        item_desc:item_desc,
                                        hsn:item_hsn,
                                        quantity:quantity,
                                        product_unit_id:item_unit,
                                        item_unit_name:item_unit_name,
                                        availability:availability,
                                        purchase_price:purchase_price,
                                        selling_price:selling_price,
                                        item_pp:item_pp,
                                        discount:discount,
                                        igst:igst,
                                        cgst:cgst,
                                        sgst:sgst,
                                        tax:gst,
                                        total:total,
                                        item_commission:item_commission,
                                        price_hidden:price_hidden,
                                        is_tax_inc:is_tax_inc,
                                        sp_discount:sp_discount,
                                        sp_tax : sp_tax,
                                        sp_tax_type : sp_tax_type,
                                        inventory_status:inventory_status,
                                        option1:option1,
                                        option2:option2,
                                        option3:option3,
                                        value1:value1,
                                        value2:value2,
                                        value3:value3,
                                        barcode_name:barcode,
                                        alias_barcode:alias_barcode,
                                        category_id:category,
                                        custom_fields_json:custom_fields_json,
                                        reorder_quantity:reorder_quantity,
                                        sale_sp_discount_type:sale_sp_discount_type,
                                        purchase_sp_discount_type:purchase_sp_discount_type,
                                        purchase_pp_discount_type:purchase_pp_discount_type,
                                      };
                let iitem_category_name = '';
                if(document.getElementById("item-category-name-"+idx)){
                  iitem_category_name = document.getElementById("item-category-name-"+idx).innerHTML;
                }
                let bundle_quantity = '';
                if(document.getElementById("item-bundle-quantity-"+idx)){
                  bundle_quantity = document.getElementById("item-bundle-quantity-"+idx).value;
                }
                let bundle_txt = '';
                if(document.getElementById("item-bundle-text-"+idx)){
                  bundle_txt = document.getElementById("item-bundle-text-"+idx).innerHTML;
                  global_bundle_txt = bundle_txt;
                }
                let bundle_desc = '';
                if(document.getElementById("bundle_quantity-"+idx)){
                  bundle_desc = document.getElementById("bundle_quantity-"+idx).innerHTML;
                }
                
                print_list_items[i] = {
                  item_commission:item_commission,
                  "is_variation": is_variant,
                  "has_variation": has_variant,
                  "iitem_category_name": iitem_category_name,
                  "iitem_category": category,
                  "product_key": "",
                  "barcode": barcode,
                  "alias_barcode":alias_barcode,
                  "barcode_name_on_bill_view": this.state.barcode_name_on_bill_view,
                  "item_name_on_bill_view": this.state.item_name_on_bill_view,
                  "bundle_desc": bundle_desc,
                  "bundle": bundle_desc,
                  "bundle_quantity": bundle_quantity,
                  "bundle_text": bundle_txt,
                  "item_id": item_name,
                  "list_item_id": list_item_id,
                  "item_name": item_text,
                  "quantity": quantity,
                  "price": purchase_price,
                  "price2": selling_price,
                  "discount": discount,
                  "sale_sp_discount_type": sale_sp_discount_type,
                  "purchase_sp_discount_type": purchase_sp_discount_type,
                  "purchase_pp_discount_type": purchase_pp_discount_type,
                  "price_hidden": price_hidden,
                  "is_tax_inc":is_tax_inc,
                  "pp_tax_type": is_tax_inc,
                  "sp_discount": sp_discount,
                  "sp_total_discount": "0",
                  "sp_tax": sp_tax,
                  "sp_tax_type": sp_tax_type,
                  "availability": availability,
                  "total_discount": 0,
                  "tax": gst,
                  "igst":igst,
                  "cgst": cgst,
                  "sgst": sgst,
                  "hidden_igst": "0",
                  "hidden_sgst": "0",
                  "hidden_cgst": "0",
                  "total_tax": 0,
                  "total_igst": 0,
                  "total_sgst": 0,
                  "total_cgst": 0,
                  "total": total,
                  "desc": item_desc,
                  "hsn": item_hsn,
                  "unit_id": item_unit,
                  "unit_name": item_unit_name,
                  "total_avl": 0,
                  "product_name": item_name,
                  "parent_id": 0,
                  "p_rand_key": 0,
                  "iitem_type": list_item_type,
                  "custom_fields_json":JSON.parse(custom_fields_json),
                  "reorder_quantity":reorder_quantity,
              }                                      
                
        }
     
        
        
        let items_to_delete = document.getElementById("delete-row").value;
        if(this.IsJsonString(items_to_delete)){
          items_to_delete = JSON.parse(items_to_delete);
        }else{
          items_to_delete = [];
        }

        let return_items_to_delete = document.getElementById("delete-return-row").value;
        if(this.IsJsonString(return_items_to_delete)){
          return_items_to_delete = JSON.parse(return_items_to_delete);
        }else{
          return_items_to_delete = [];
        }
        let payment_type_note = '';
        let b_target = "sales";
        if(this.state.billing_type==="New Purchase"){
          b_target = "purchase";
        }else if(this.state.billing_type==="New Purchase Return"){
          b_target = "purchase_return";
        }else if(this.state.billing_type==="New Sales Return"){
          b_target = "sales_return";
        }
        if(document.getElementById("payemet-type-note")){
          payment_type_note = document.getElementById("payemet-type-note").value;
        }
        this.setState({target:b_target});
        let ptype_note = 0;
        if(document.getElementById("ptype_note")){
          ptype_note = document.getElementById("ptype_note").value;
          if(ptype_note===""){
            ptype_note = 0;
          }
        }
        let before_redeem_total = '';
        if(document.getElementById("before-redeem-total")){
          before_redeem_total = document.getElementById("before-redeem-total").innerHTML;
        }
        let redeem_total = '';
        if(document.getElementById("redeem-total")){
          redeem_total = document.getElementById("redeem-total").innerHTML;
        }

        if(this.send_data_items.length===0 && this.send_return_data_items.length===0){
          toast("Sorry! You can not create a bill without the items.");
          return false;
        }
        
        let pieces_in_parcel = '';
        if(document.getElementById("pieces_in_parcel")){
          pieces_in_parcel = document.getElementById("pieces_in_parcel").value;
        }

        let no_of_parcels = '';
        if(document.getElementById("no_of_parcels")){
          no_of_parcels = document.getElementById("no_of_parcels").value;
        }
        let packed_by = '';
        if(document.getElementById("packed_by")){
          packed_by = document.getElementById("packed_by").value;
        }


        let checked_by = '';
        if(document.getElementById("checked_by")){
          checked_by = document.getElementById("checked_by").value;
        }
        let dispatched_details ={
            agent_name : agent_name,
            dispatched_through : dispatched_through,
            dispatched_document : dispatched_doc,
            supplier_ref_no : supplier_ref_no,
            delivery_note : delivery_note,
            other_note : other_note,
            other_refrence : other_refrence,
            terms_of_delivery : terms_of_delivery,
            pieces_in_parcel : pieces_in_parcel,
            no_of_parcels : no_of_parcels,
            packed_by : packed_by,
            checked_by : checked_by,
          }

          let returned_invoice_id = 0;	
          if(document.getElementById("returned_invoice_id")){	
            returned_invoice_id = document.getElementById("returned_invoice_id").value;	
          }

          let commission = 0;	
          if(document.getElementById("staff-global-comission")){	
            commission = document.getElementById("staff-global-comission").innerHTML;	
          }

          let tcs_percent = 0;	
          if(document.getElementById("tcs-percent")){	
            tcs_percent = document.getElementById("tcs-percent").value;	
          }
          let other_charges = 0;
          if(document.getElementById("other-charges")){
            other_charges = document.getElementById("other-charges").value;
          }
         

          let selling_price_margin = '';
          if(document.getElementById("selling_price_margin")){
            selling_price_margin = document.getElementById("selling_price_margin").value;
          }

          let pending_ret_adj_opt = '';
          if(document.getElementById("re-adjust-pending-return-opt")){
            pending_ret_adj_opt = document.getElementById("re-adjust-pending-return-opt").value;
          }
          let transport_link_list = '';
          if(document.getElementById("transport-link-list")){
            transport_link_list = document.getElementById("transport-link-list").value;
          }
            
        send_data = {
            otp:this.state.otp,
            is_store : this.state.is_store,
            transport_link_list:transport_link_list,
            pieces_in_parcel:pieces_in_parcel,
            no_of_parcels:no_of_parcels,
            packed_by:packed_by,
            checked_by:checked_by,
            selling_price_margin:selling_price_margin,
            dispatched_details : dispatched_details,
            payment_cr_dr_note :ptype_note,
            before_cr_dr_redeem_total :before_redeem_total,
            cr_dr_redeem_total :redeem_total,
            update_id : this.state.update_id,
            send_type : this.state.send_type,
            bill_status : bill_status,
            type : this.state.billing_type,
            customer : customer,
            contact_name : sel_contact,
            selected_contact : selected_contact,
            staff : staff,
            credit_days : credit_days,
            date : date,
            invoice_date : invoice_date,
            payment_mode : mode_of_payment,
            payment_mode_txt : mode_of_payment_txt,
            buyer_order_no : buyer_order_number,
            dispatched_through : dispatched_through,
            dispatched_document : dispatched_doc,
            supplier_ref_no : supplier_ref_no,
            delivery_note : delivery_note,
            other_note : other_note,
            other_refrence : other_refrence,
            terms_of_delivery : terms_of_delivery,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            sequence_no : this.state.currrent_sequence_no,
            sequence : this.state.currrent_sequence,
            notes : notes,
            total_unit:total_unit,
            global_discount:global_discount,
            global_discount_opt:global_discount_opt,
            round_off:round_off,
            round_off_opt:round_off_opt,
            total_sub:total_sub,
            total_discount:total_discount,
            total_igst:total_igst,
            total_cgst:total_cgst,
            total_sgst:total_sgst,
         
            total_tax:total_tax,
            total_grand:total_grand,
            commission : commission,
            tcs_percent : tcs_percent,
            other_charges : other_charges,
            items : this.send_data_items,
            return_items : this.send_return_data_items,
            return_meta : return_meta,
            items_to_delete : items_to_delete,
            return_items_to_delete : return_items_to_delete,
            payment_type_note : payment_type_note,
            agent_name : agent_name,
            inventory_status : inventory_status,
            current_bill_status : (this.state.is_resume_cancelled===false && this.state.billing_data && this.state.billing_data.bill_status!==undefined)?this.state.billing_data.bill_status:'',
            payments_data:payments_data,
            return_goods:return_goods,
            is_on_account:this.state.is_on_account_return,	
            return_type : this.state.return_type,	
            return_option : this.state.return_option,	
            returned_invoice_id : returned_invoice_id,
            is_convert_to_sale : this.state.is_convert_to_sale,
            pending_ret_adj_opt : pending_ret_adj_opt,
            pending_ret_adj_amt : this.state.remaining_amount,
            rg_deleted_record:this.state.rg_deleted_record
        }
        let pro_print_data = send_data;
        pro_print_data.print_list_items = print_list_items;
        pro_print_data.global_bundle_txt = global_bundle_txt;
        this.processSetPrintData(pro_print_data);

        let pcreated_date  = this.formatDate(date);
        let bill_time  = this.formatAMPM(date);
        console.log(this.state.update_id);
        console.log(this.state.ask_otp_while_update_sale);
        console.log(this.state.bill_hours);
        console.log(this.state.is_asked_otp);
        console.log(this.state.is_validated_otp);
        
        if(this.state.update_id>0 && this.state.ask_otp_while_update_sale===true && this.state.bill_hours>=48 && this.state.is_asked_otp===false && this.state.is_validated_otp===false){
            this.setState({is_asked_otp:true});
            this.handleSendOTP();
            return false;
        }
        document.getElementById(clicked_on).classList.add("hide");	
        document.getElementById(clicked_on+'-btn-spinner').classList.remove("hide");
        if(click_id==='save-print'){
          if(this.isMobile()!==true){
            localStorage.setItem('save_print_new','yes');
            localStorage.setItem('save_print','yes');
            localStorage.setItem("response_id",1);
            this.setState({response_id:1});
          }
        }
       
        setTimeout(() => {
          if(((this.state.billing_type==="New Sale" || this.state.billing_type==="New Sales Return" ) && bill_status===1) && click_id==='save'){  
            if(this.isMobile()===true){
              setTimeout(function(){
                //document.getElementById('yes-print').setAttribute('href','/view-billing/sales/print/'+id);
                //document.getElementById('no-print').setAttribute('href','/billings');
              },500);
            }else{
                var element = document.getElementById('print_bill');
                element.classList.remove("fade");
                element.style.display="block";
                document.getElementById("yes-print").focus();
                localStorage.setItem('save_print','yes');
                localStorage.setItem("response_id",1);
            }
            
          }
        }, 100);
       
        let api = app_api+'/create_billing';
        axios.post(api, send_data)
          .then(function (response) {
             let resp = response.data;
             let id = resp.id;
             this.hanldeCreateFrieshListItems();
             localStorage.removeItem('item_options');
             localStorage.removeItem('purchase_item_options');
             if(this.state.billing_type==="New Sale" && bill_status===1){
               localStorage.setItem('sharable_link',resp.sharable_link);
               localStorage.setItem('share_id',parseInt(id));
               if(document.getElementById("share-email-block")){
                var share_em_blk = document.getElementById("share-email-block");
                share_em_blk.classList.remove("hide"); 
               }
             }
             if(click_id==='paid'){
                window.location.href = '/new-payment/'+this.state.target+"/"+id;
             }else if(click_id==='save-print'){
              if(this.isMobile()===true){
                 localStorage.setItem('save_print','yes');
                 this.setState({response_id:parseInt(id)});
              }
             }else{
              if(resp.success===1){
                  if(resp.payment_type_note!==undefined && resp.payment_type_note==='credit'){
                    let note_sequence = resp.note_sequence;
                    let print_data = this.state.print_data;
                    let pdata = print_data.data;
                    pdata['cd_sequence'] = note_sequence;
                    print_data.data = pdata;
                    this.setState({print_data:print_data})
                  }

                  document.getElementById(clicked_on).classList.remove("hide");	
                  document.getElementById(clicked_on+'-btn-spinner').classList.add("hide");
                 if((this.state.billing_type==="New Sale" || this.state.billing_type==="New Sales Return" ) && bill_status===1){
                   if(this.isMobile()===true){
                    //this.setState({response_id:parseInt(id)});
                    var element = document.getElementById('print_bill');
                    element.classList.remove("fade");
                    element.style.display="block";
                    setTimeout(function(){
                      document.getElementById('yes-print').setAttribute('href','/view-billing/sales/print/'+id);
                      document.getElementById('no-print').setAttribute('href','/billings');
                    },500);
                    
                  }else{
                    //localStorage.setItem('save_print','yes');
                    //localStorage.setItem("response_id",id);
                  }
                  this.shareMessage(resp);
                }
                if(this.state.billing_type==="New Sale" && bill_status===2){
                  window.location.href = '/billings';
                }else if(this.state.billing_type==="New Purchase"){
                  let expires = resp.expire;
                  localStorage.setItem('fetch_inv',expires);
                  window.location.href = '/billings/purchase';
                }else if(this.state.billing_type==="New Purchase Return"){
                  window.location.href = '/billings/purchase_return';
                }else if(this.state.billing_type==="New Sales Return" && bill_status===2){
                  window.location.href = '/billings/sales_return';
                }
              }
             }
             
        }.bind(this)).catch(function(res){
          if(res.response){
            if(res.response.data){
              document.getElementById(click_id).classList.remove("hide");
              document.getElementById(click_id+'-btn-spinner').classList.add("hide");
              _this.setState({show_toast:true});
              toast("Oops ! something went wrong. an error occured with status code "+res.response.status);
              setTimeout(() => {
                //window.location.href = '/billings';
              }, 1000);
            }
          }
        })

        
    }
    handleShowHoldBill = (e) =>{
      if(document.getElementById("handleShowHoldBill")){
        document.getElementById("handleShowHoldBill").click();
      }
  }

  handleHideShowBlocks = (type)=>{
    let cls = this.state.billing_type.replaceAll(" ",'-');
    if(type==='show'){
      if(document.querySelector(".sticky-add-item-panel."+cls)){
        document.querySelector(".sticky-add-item-panel."+cls).style.display = "block";
      }
      if(document.querySelector(".list-item-add-blk."+cls)){
        document.querySelector(".list-item-add-blk."+cls).style.display = "block";
      }
      if(document.querySelector(".add-item-mob."+cls)){
        document.querySelector(".add-item-mob."+cls).style.display = "block";
      }
    }else if(type==='hide'){
      if(document.querySelector(".sticky-add-item-panel."+cls)){
        document.querySelector(".sticky-add-item-panel."+cls).style.display = "none";
      }
      if(document.querySelector(".list-item-add-blk."+cls)){
        document.querySelector(".list-item-add-blk."+cls).style.display = "none";
      }
      if(document.querySelector(".add-item-mob."+cls)){
        document.querySelector(".add-item-mob."+cls).style.display = "none";
      }
    }
  }

  handleSelectReturnOpt = (e) =>{
    let tid = e.currentTarget.id;
    this.setState({return_type:tid});
    this.setState({return_option:''});
    this.setState({is_on_account_return:false});
    if(tid==='invoice'){
      var element = document.getElementById('return-invoices');
      element.classList.remove("fade");
      element.style.display="block"; 
      document.getElementById("return-invoice-search").value="";
      setTimeout(() => {
          if(document.getElementById("return-invoice-search")){
            document.getElementById("return-invoice-search").focus();
          }
      }, 50);
      this.handleHideShowBlocks('hide');
      this.handleInvoiceSearch();
    }else if(tid==='inv-items'){
      this.handleHideShowBlocks('show');
    }
    if(tid===""){
      /* this.setState({is_on_account_return:false});
      if(document.getElementById("handleResetField")){
        document.getElementById("handleResetField").click();
      }
      this.add_item_area = [];
      this.add_item_area.push(<AddBillingItem variations={this.state.created_variations} key={1} count='1' type="more" index="1" billing_type={this.state.billing_type}/>);
      this.setState({add_item_area:this.add_item_area}); */
    }
  }
  
  handleReturnOptChange = (e) =>{
    let tid = e.currentTarget.value;
    
    if(this.state.return_type==='invoice'){
      this.handleHideShowBlocks('hide');

      let item_row = document.querySelectorAll(".billing-item-row");
      if(item_row.length===0){
          localStorage.setItem("return_option",tid);
          document.getElementById("invoice").click();
          return false;
      }

    }else{
      this.handleHideShowBlocks('show');
    }

    if(document.getElementById("billing-notes")){
      document.getElementById("billing-notes").style.display='block';
    }

    if(tid==='old_bill' && this.state.return_type==='invoice'){
      this.setState({return_option:tid});
      var inputs = document.querySelectorAll('.items-row'); 
      if(inputs.length===0){
        var element = document.getElementById('return-invoices');
        element.classList.remove("fade");
        element.style.display="block"; 
        setTimeout(() => {
            if(document.getElementById("return-invoice-search")){
              document.getElementById("return-invoice-search").focus();
            }
        }, 50);
        return false;
      }

      this.setState({is_on_account_return:true});
      this.handleLoadPendingInvoice();
      let cls = this.state.billing_type.replaceAll(" ",'-');
      let visibleCls = document.querySelectorAll(".top-btn-blk."+cls);
      for (let index = 0; index < visibleCls.length; index++) {
        visibleCls[index].style.display='none';
      }
    }else{
      
      if(this.state.return_type==='inv-items'){
        let item_row = document.querySelectorAll(".billing-item-row");
        if(item_row.length===0){
          localStorage.setItem("return_option",tid);
            document.getElementById("iitem-box").focus();
            return false;
        }
      }
      this.setState({return_option:tid});
      if(tid==='old_bill'){
        this.setState({is_on_account_return:true});
        this.handleLoadPendingInvoice();
        let cls = this.state.billing_type.replaceAll(" ",'-');
        let visibleCls = document.querySelectorAll(".top-btn-blk."+cls);
        for (let index = 0; index < visibleCls.length; index++) {
          visibleCls[index].style.display='none';
        }
      }else{
        this.setState({is_on_account_return:false});
        if(tid!==""){
          let cls = this.state.billing_type.replaceAll(" ",'-');
          let visibleCls = document.querySelectorAll(".top-btn-blk."+cls);
          for (let index = 0; index < visibleCls.length; index++) {
            visibleCls[index].style.display='block';
          }
          this.setState({change_pay_to_save:true});
          if(tid==='credit'){
            let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
            }
            let api = app_api+'/get_current_cn_sequence';
            axios.post(api, body)
              .then(function (response) {
                  let resp = response.data;
                  let note_number = resp.data;
                  let note_sequence = resp.note_sequence;
                  let print_data = this.state.print_data;
                  let pdata = print_data.data;
                  pdata['cd_sequence'] = note_number;
                  print_data.data = pdata;
                  this.setState({print_data:print_data})
            }.bind(this)).catch(function(res){
                if(res.response){
                    if(res.response.data){
                    }
                }
            })
          }
        }else{
          let cls = this.state.billing_type.replaceAll(" ",'-');
          let visibleCls = document.querySelectorAll(".top-btn-blk."+cls);
          for (let index = 0; index < visibleCls.length; index++) {
            visibleCls[index].style.display='none';
          }
          this.setState({change_pay_to_save:false});
        }
      }
    }
  }

  hanldeContactSelection = () =>{
    if(this.state.billing_type=='New Sales Return' || this.state.billing_type==='New Purchase Return'){
      let contact = document.getElementById("contact_box").value;
      if(contact!==""){
        this.setState({is_contact_selected:true});
        if(document.getElementById("inv-items")){
          document.getElementById("inv-items").focus();
        }
      }else{
        this.setState({is_contact_selected:false});
        this.setState({return_type:''});
        this.setState({is_on_account_return:false});
        if(document.getElementById("handleResetField")){
          document.getElementById("handleResetField").click();
        }
        this.add_item_area = [];
        this.add_item_area.push(<AddBillingItem variations={this.state.created_variations} key={1} count='1' type="more" index="1" billing_type={this.state.billing_type}/>);
        this.setState({add_item_area:this.add_item_area});
      }
    }
  }

  handleCloseReturnPop = ()=>{
      var element = document.getElementById('return-invoices');
      element.classList.add("fade");
      element.style.display="none"; 
      this.setState({searched_invoice_items:[]});
  }
  
  handleReturnBillStartSearch = ()=>{
      this.setState({is_return_bill_search:true});
  }
  
  handleReturnBillStopSearch = ()=>{
      this.setState({is_return_bill_search:false});
  }

  /* handleInvoiceSearch = () =>{
    if(document.getElementById("handleInvoiceSearchOnClick")){
      document.getElementById("handleInvoiceSearchOnClick").click();
    }
  } */


  handleInvoiceSearch = () =>{
      let search = document.getElementById("return-invoice-search").value;
      this.setState({is_return_bill_search:true});
      this.setState({loading_process:'Loading...'});
      let body = {};
      body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          search : search,
          contact_id : (document.getElementById("customer-dropdown"))?document.getElementById("customer-dropdown").value:0,
          target : this.state.billing_type,
          screen : 'billing'
      }
      let api = app_api+'/search_invoice_number';
      axios.post(api, body)
        .then(function (response) {
            this.setState({is_return_bill_search:false});
            let resp = response.data;
            let data = resp.data;
            if(data.length===0){
              this.setState({loading_process:'No record found.'})
            }
            let options = data; 
            this.setState({searched_invoice_items:options});
      }.bind(this)).catch(function(res){
          if(res.response){
              if(res.response.data){
              }
          }
      })
  }

  handleSelectReturnedInvoice = (e) =>{
        let id = e.currentTarget.getAttribute('data-id');
        let index = id;
        if(document.getElementById('return-invoices')){
          var element = document.getElementById('return-invoices');
          element.classList.add("fade");
          element.style.display="none"; 
        }
        let invoices = this.state.searched_invoice_items[id];
      
        let item_id = invoices.id;
        
        document.getElementById("returned_invoice_id").value = item_id;
  
        let t = invoices.target;
        let search_list_items = invoices.list_items;
        let invoice_number = (invoices.sales_number!==undefined)?invoices.sales_number:invoices.purchase_number;
        let pop_title = invoice_number+ " ITEMS";
        var element = document.getElementById('returned-invoice-items');
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("ret-list-item-title").innerHTML = pop_title;
       
        let str = '';
        
        for(let i=0;i<search_list_items.length;i++){
            let iid = search_list_items[i].item_id;
          
            if(this.search_list_items[i]===undefined){
              this.search_list_items[i] = [];
            }
            this.search_list_items[i] = search_list_items[i];
          
            let product_name = search_list_items[i].product_name;
            let item_name = search_list_items[i].item_name;
           
            str += '<tr>'+
                      '<td>'+
                        '<div class="form-check form-check-inline">'+
                          '<input class="form-check-input prev-item-cb" id="pitem-'+i+'" type="checkbox" value="'+iid+'" data-id="'+i+'" checked> '+
                          '<label class="form-check-label" for="pitem-'+i+'"><b>'+
                            item_name+
                          '</b></label>'+
                      '</td>'+
                    '</tr>';
        }
        
        if(str===''){
          str = '<tr><td class="text-center">No item found to return</td></tr>';
        }
  
        document.getElementById("returned-list-item-block").innerHTML = str;
        setTimeout(function(){
          document.getElementById("handleReturnBillList").focus();
        },500);
  }


  handleReturnBillList = (e)=>{
    let qs = document.querySelectorAll(".prev-item-cb");
    let contact_id = '';
    let contact_name = '';
    let contact_discount = '';
    let contact_state = '';
    let contact_payment_mode = '';
    
    if(document.getElementById("handleClearItemList")){
      document.getElementById("handleClearItemList").click();
    }
    
    for(let i=0;i<qs.length;i++){
      let is_checked = qs[i].checked;
      localStorage.setItem('return_bill_count',i);
      if(is_checked){

        let data_id = qs[i].getAttribute('data-id');
        let item_id = qs[i].value;
        let item_data = this.search_list_items[data_id];
        let availability = item_data.availability;
        let lowest_selling_price = item_data.lowest_selling_price;
        let bundle = item_data.bundle;
        let bundle_quantity = item_data.bundle_quantity;
        let bundle_text = item_data.bundle_text;
        let cgst = item_data.cgst;
        let desc = item_data.desc;
        let discount = item_data.discount;
        let hidden_cgst = item_data.hidden_cgst;
        let hidden_igst = item_data.hidden_igst;
        let hidden_sgst = item_data.hidden_sgst;
        let hsn = item_data.hsn;
        let igst = item_data.igst;
        let is_tax_inc = item_data.is_tax_inc;

        let price = item_data.price;
        let price_hidden = item_data.price_hidden;
        let product_name = item_data.product_name;
        let quantity = item_data.quantity;
        let sgst = item_data.sgst;
        let tax = item_data.tax;
        let total = item_data.total;
        let total_cgst = item_data.total_cgst;
        let total_discount = item_data.total_discount;
        let total_igst = item_data.total_igst;
        let total_sgst = item_data.total_sgst;
        let total_tax = item_data.total_tax;
        let product_unit_id = item_data.unit_id;
        let unit_name = item_data.unit_name;
        let unitname = item_data.unitname;
         contact_id = item_data.contact_id;
         contact_name = item_data.contact_name;
         contact_discount = item_data.contact_discount;
         contact_state = item_data.contact_state;
         contact_payment_mode = item_data.contact_payment_mode;

        localStorage.setItem('discount',contact_discount);
        localStorage.setItem('state',contact_state);
        localStorage.setItem('payment_mode',contact_payment_mode);

        let item_name = item_data.item_name;
        document.getElementById("iitem-box").value = item_name;
        document.getElementById("iitem-name").value = item_id;
        document.getElementById("old-iitem-name").value = item_id;

        if( document.getElementById("iitem-lowest-selling-price")){
          document.getElementById("iitem-lowest-selling-price").value = lowest_selling_price;
        }
        if( document.getElementById("iitem-barcode")){
         document.getElementById("iitem-barcode").value = item_data.barcode;
        }
        if( document.getElementById("iitem-bundle-quantity")){
          document.getElementById("iitem-bundle-quantity").value = bundle_quantity;
        }
        if( document.getElementById("iitem-bundle-text")){
          document.getElementById("iitem-bundle-text").value = bundle_text;
        }

        if(document.getElementById("iitem-tax-inc")){
          document.getElementById("iitem-tax-inc").value = is_tax_inc;
        }
        if(document.getElementById("iitem-gst")){
          document.getElementById("iitem-gst").value = tax;
        }

        if(document.getElementById("iitem-sgst")){
          document.getElementById("iitem-sgst").value = sgst;
        }
        if(document.getElementById("iitem-cgst")){
          document.getElementById("iitem-cgst").value = cgst;
        }
        if(document.getElementById("iitem-igst")){
          document.getElementById("iitem-igst").value = igst;
        }
        if(document.getElementById("discount")){
          document.getElementById("discount").value = discount;
        }
        if(document.getElementById("discount-hidden")){
          document.getElementById("discount-hidden").value = total_discount;
        }

        if(discount==='' || discount===null){
          discount = 0;
        }else{
          discount = parseFloat(discount);
        }

        let cal_discount = ((price_hidden*quantity)*discount)/100;
        let item_grand_total = (price*quantity)-cal_discount;
        let cal_tax = (item_grand_total*tax)/100;
        hidden_sgst = (item_grand_total*cgst)/100;
        hidden_cgst = (item_grand_total*sgst)/100;
        hidden_igst = (item_grand_total*igst)/100;
        document.getElementById("iitem-igst-total").value = this.floating_point(hidden_igst);
        document.getElementById("iitem-sgst-total").value = this.floating_point(hidden_sgst);
        document.getElementById("iitem-cgst-total").value = this.floating_point(hidden_cgst);

        let final_amount = item_grand_total+cal_tax;
        document.getElementById("iitem-total").innerHTML =final_amount;
        document.getElementById("iitem-total-txt").value = final_amount;

        if(document.getElementById("iitem-sgst-hidden")){
          document.getElementById("iitem-sgst-hidden").value = hidden_sgst;
        }
        if(document.getElementById("iitem-cgst-hidden")){
          document.getElementById("iitem-cgst-hidden").value = hidden_cgst;
        }
        if(document.getElementById("iitem-igst-hidden")){
          document.getElementById("iitem-igst-hidden").value = hidden_igst;
        }
        document.getElementById("iitem-desc").value = desc;
        document.getElementById("iitem-desc-txt").innerHTML = desc;
        document.getElementById("iitem-hsn").value = (hsn==0)?'':hsn;
        document.getElementById("iitem-quantity").value = quantity;
        document.getElementById("iitem-unit").value = product_unit_id;
        document.getElementById("current-available").value = availability;

        if(document.getElementById("iitem-availability")){
          document.getElementById("iitem-availability").innerHTML = (availability);
        }
        if(document.getElementById("price")){
          document.getElementById("price").value = price;
        }
        if(document.getElementById("price-hidden")){
          document.getElementById("price-hidden").value = price;
        }

        if(document.getElementById("iitem-unit-name")){
          document.getElementById("iitem-unit-name").value = unit_name;
        }
       
        document.getElementById("add-item-to-list").click();
      }
    }
    this.closeReturnBillList();
  }
  closeReturnBillList = () =>{
    var element = document.getElementById('returned-invoice-items');
    element.classList.add("fade");
    element.style.display="none";
    document.getElementById("ret-list-item-title").innerHTML = '';
  }

  moveToAddNewItem = () =>{
    if(document.getElementById("iitem-box")){
      document.getElementById("iitem-box").focus();
    }
  }
  handleHideAddItemBtn = () =>{
    this.setState({is_add_item_btn:false});
    if(document.getElementById("mode_of_payment")){
      let mode_of_payment = document.getElementById("mode_of_payment").value;
      if(mode_of_payment==='2' || mode_of_payment===2){
       // this.setState({change_pay_to_save:true});
      }
    }

  }
  handleShowAddItemBtn = () =>{
    this.setState({is_add_item_btn:true});
    //this.setState({change_pay_to_save:false});
  }

  handleOpenStaff = (e) =>{
    let key_code = e.which;
    if(key_code===38 || key_code===40){
      let assisted_by = this.state.assisted_by;
      let elem = document.getElementById("staff-dropdown");
      let options = elem.options;
      let len = options.length;
      for (let index = 0; index < len; index++) {
        const element = options[index];
        const ev = element.value;
        if(key_code===40){
          if(assisted_by===ev){
            let as_key = index+1;
            if(options[as_key]!==undefined){
              let new_v = options[as_key].value;
              this.setState({assisted_by:new_v});
            }
          }
        }
        if(key_code===38){
          if(assisted_by===ev){
            let as_key = index-1;
            if(options[as_key]!==undefined){
              let new_v = options[as_key].value;
              this.setState({assisted_by:new_v});
            }
          }
        }
      }
    }
  }
  hanldeContinueWithExistingListItems = () =>{
    if(document.getElementById("hanldeContinueWithExistingListItems")){
      document.getElementById("hanldeContinueWithExistingListItems").click();
      this.setState({is_unsaved_list:false});
    }
  }
  hanldeCreateFrieshListItems = () =>{
    let target = this.state.billing_type.replaceAll(' ','');
    localStorage.removeItem('unsaved_list_item_'+target);
    this.setState({is_unsaved_list:false});
  }
  handleCheckLRNo = (e) =>{
      let thisval = e.currentTarget.value;
      this.setState({lr_error:''});
      this.setState({lr_error_info:''});
      /* if(thisval!==""){
          let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              search : thisval,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
          }
          let api = app_api+'/search_lr_no';
          axios.post(api, body)
              .then(function (response) {
                  let resp = response.data;
                  if(resp.is_exist===true){
                      let transport_info = resp.transport;
                      this.setState({lr_error_info:transport_info});
                      this.setState({lr_error:thisval+' is already taken, it is associated with transport '});
                  }else{
                      this.setState({lr_error:''});
                      this.setState({lr_error_info:''});
                  }
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
      }else{
          this.setState({lr_error:''});
      } */
    }

    handleSelectTransportFromList = (e)=>{
      let thisvalue = e.currentTarget.value;
      this.setState({transport_id:thisvalue});
      let transport_data = this.state.unlink_transports_data[thisvalue];
      
      let transport_name = transport_data.transport_name;
      let b_no = transport_data.b_no;
      let bale = transport_data.bale;
      let lr_no = transport_data.lr_no;
      let no_of_parcel_received = transport_data.no_of_parcel_received;
      if(document.getElementById("dispatched-doc")){
        document.getElementById("dispatched-doc").value = lr_no; 
      }
      if(document.getElementById("supplier-ref-no")){
        document.getElementById("supplier-ref-no").value = b_no; 
      }
      if(document.getElementById("dispatched-through")){
        document.getElementById("dispatched-through").value = transport_name; 
      }
    }
    handleLoadUnlinkTransports = ()=>{
      let body = {};
      body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            update_id : this.state.update_id,
            contact_id : this.state.selected_contact,
        }
      let api = app_api+'/get_unlink_transports';
      axios.post(api, body)
        .then(function (response) {
            let resp = response.data;
            this.setState({unlink_transports: resp.data});
            this.setState({unlink_transports_data: resp.trans_data});
      }.bind(this)).catch(function(res){
          if(res.response){
              if(res.response.data){
              }
          }
      })
    }
    handleOpenDeleteRGItemConfirm = (e)=>{
      let data_id = e.currentTarget.getAttribute('data-id');
      document.getElementById("rm-rg-litem-"+data_id).classList.remove('hide');
    }
    handleCloseDeleteConfirm =(e)=>{
      let data_id = e.currentTarget.getAttribute('data-id');
      document.getElementById("rm-rg-litem-"+data_id).classList.add('hide');
    }
    handleDeleteReturnGoods = (e) =>{
      let data_id = e.currentTarget.getAttribute('data-id');
      let return_goods_bills = this.state.return_goods_bills;
      let _del_rg = return_goods_bills[data_id];
      return_goods_bills.splice(data_id,1);
      this.setState({return_goods_bills:return_goods_bills});
      if(this.state.update_id>0){
        let rg_deleted_record = this.state.rg_deleted_record;
        rg_deleted_record.push(_del_rg);
        this.setState({rg_deleted_record:rg_deleted_record})
      }
    }

    handleCheckIsStore = () =>{
        let is_store = localStorage.getItem('is_store');
        if(is_store==='yes'){
          this.setState({is_store:true});
        }else{
          this.setState({is_store:false});
        }
    }

    handleCalculateEncProfit = () =>{
      if(this.state.billing_type!=='New Sale'){
        return false;
      }
      let total_pp = 0;
      let item_total = document.getElementById("total-before-tax").innerHTML;
      item_total = item_total.replaceAll(',','');
      item_total = parseFloat(item_total);
      let qs = document.querySelectorAll('.item-purchase-price');
      if(qs.length>0){
        for (let index = 0; index < qs.length; index++) {
          const element = qs[index];
          const data_id = element.getAttribute('data-id');
          let quantity = document.getElementById('item-quantity-'+data_id).value;
          let itotal = document.getElementById('item-total-txt-'+data_id).value;
          itotal = parseFloat(itotal);
          
          quantity = parseFloat(quantity);
          let pp = parseFloat(element.value);
          total_pp = total_pp + (pp*quantity);
        }
        let total_profit = item_total - total_pp;
        let encrypted_selling_price = this.state.encrypted_selling_price;
        if(encrypted_selling_price!=='' && encrypted_selling_price.value!==undefined){
          let price = utils.CALCULATE_ENCRYPTED_PRICE(total_profit,encrypted_selling_price.value);
          this.setState({enc_profit:price,total_profit:total_profit});
        }
      }
    }
    handleShowEncryptedPrice = () =>{
      this.setState({is_show_enc_profit:true});
    }
    handleHideEncryptedPrice = () =>{
      this.setState({is_show_enc_profit:false});
    }
    render() {

        let is_payment = false;
        let is_cd_note = false;
        let cd_type = 'Credit';
        if(this.state.billing_type==='New Purchase'){
          is_cd_note = true;
          cd_type = 'Debit';
          if(this.is_access('payments-out-create')){
            is_payment = true;
          }
        }
        if(this.state.billing_type==='New Sale'){
          is_cd_note = true;
          if(this.is_access('payments-in-create')){
            is_payment = true;
          }
        }
        let bstatus = this.state.billing_data.bill_status;
        if(bstatus==='Paid'){
          is_payment = false;
        }

        let round_off_sym = '-';
        if(this.state.round_off_sym==='+'){
          round_off_sym = '+';
        }else if(this.state.round_off_sym==='-'){
          round_off_sym = '-';
        }
        let global_discount_sym = 'per';
        if(this.state.discount_sym==='rs'){
          global_discount_sym = 'rs';
        }else if(this.state.discount_sym==='per'){
          global_discount_sym = 'per';
        }
        let new_purchase_info = 'new-purchase-info';
        if(this.isMobile()===true){
          new_purchase_info = '';
        }
        let is_update_date = true;
        if(this.is_access('billing-stop-editing-date')){
            is_update_date = false;
        }
        return (
            <div className="d-flex new-bill" id="wrapper" onClick={this.handleDivClick}>
              <Leftnav />
              
              <span id="handleCheckIsStore" onClick={this.handleCheckIsStore}></span>
              <span id="handleHideAddItemBtn" onClick={this.handleHideAddItemBtn}></span>
              <span id="handleBusinessType" onClick={this.handleBusinessType}></span>
              <span id="handleShowAddItemBtn" onClick={this.handleShowAddItemBtn}></span>
              <span id="handleEnableReturnInfo" onClick={this.handleEnableReturnInfo}></span>
              <span id="handleResetPrintWindow" onClick={this.handleResetPrintWindow}></span>	
              <span id="handleEnableTCS" onClick={this.handleEnableTCS}></span>	
              <span id="handleDisableTCS" onClick={this.handleDisableTCS}></span>	
              <span id="handleSetSelectedContact" onClick={this.handleSetSelectedContact}></span>	
              <span id="handleSetSelectedContactInfo" onClick={this.handleSetSelectedContactInfo}></span>	
              	
              {(this.state.billing_type!=='New Purchase' && this.state.billing_type!=='New Sale') &&	
                <span id="hanldeContactSelection" onClick={this.hanldeContactSelection}></span>	
              }
              <span id="handlePrint" onClick={this.handlePrint}></span>
              <span id="handleChangePayBtnStatus" onClick={this.handleChangePayBtnStatus}></span>
              <div id="page-content-wrapper">
                
                
                 <nav className="nb navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">	
                        <div className="mod-title"><a href="/billings"><span>Billing</span></a> / <b id="breadcrumbs_subtitle">{this.state.billing_type}</b>
                        {(this.state.business_type!=='') &&
                          <b> / {this.state.business_type}</b>
                        }
                        {(this.state.billing_type==='New Sale' && this.is_access('encprofit')) &&
                          <span style={{fontSize:'14px',cursor:'pointer'}} onMouseDown={this.handleShowEncryptedPrice}  onMouseUp={this.handleHideEncryptedPrice}>
                            {(this.state.is_show_enc_profit===false) &&
                              <>
                              {(this.state.enc_profit!=="")?' / '+this.state.enc_profit:''}
                              </>
                            }
                            {(this.state.is_show_enc_profit===true) &&
                              <>
                              {(this.state.total_profit!=="")?' / '+this.state.total_profit:''}
                              </>
                            }
                            

                          </span>
                          
                        }
                      </div>
                    </div>	
                    <div className={"col-md-8"} style={{textAlign:'right', paddingRight:'34px'}}>	
                       	
                        <div className={"top-mod-title"}>	
                        {(utils.isMobile()===true) &&	
                          <a onClick={this.handleShowHoldBill} className="bcart mrright-5">	
                            <FontAwesomeIcon  icon={faShoppingCart} className=""/>	
                          </a>	
                        }	
                        {(utils.isMobile()===false && this.state.billing_type==='New Sale') &&	
                        <>
                        {(this.state.is_add_item_btn===false && this.state.update_id===0) &&
                        <>
                          <button type="button" onClick={this.handleHoldCurrentBill} className="btn btn-success" style={{marginRight:'5px',padding:'2px 10px',marginTop:'-2px'}} id="hold-bill-new">Hold Bill</button>
                          <button type="button" id="hold-bill-new-btn-spinner" className="hide btn btn-success" style={{marginRight:'5px',padding:'2px 10px',marginTop:'-2px'}}><FontAwesomeIcon icon={faSpinner} pulse/></button>
                        </>
                        }
                          <button type="button" onClick={this.handleShowHoldBill} className="btn btn-success" style={{marginRight:'5px',padding:'2px 10px',marginTop:'-2px'}}>Hold Bills List</button>
                          <span id="handlePrintOnFlyClick" onClick={this.handlePrintOnFly}></span>
                       
                        </>
                        }
                          <label>
                            {(this.state.isMobile===true) &&
                            <> Qty : </>
                            }
                            {(this.state.isMobile===false) &&
                            <> Quantity : </>
                            }
                            <span id="top-total-quantity">{(this.state.billing_data && this.state.billing_data.quantity)?this.state.billing_data.quantity:0}</span>
                          </label>	

                          <label className="mrleft-15">Total : <span className="rs-sym">₹</span><span id="top-final-total">{(this.state.billing_data && this.state.billing_data.total)?this.state.billing_data.total:0}</span></label>	
                         
                          <span className={"top-btn-blk "+this.state.billing_type.replaceAll(" ",'-')}>	
                          {(this.state.payment_mode==='payment' || this.state.payment_mode==='cash') &&	
                            <>
                              {(this.state.is_add_item_btn===true) &&
                                <button type="button" className="mrleft-10 btn btn-success" style={{padding:'2px 10px',marginTop:'-2px'}} onClick={this.moveToAddNewItem}>Add Item</button>	
                              }
                              {(this.state.is_fy_closed===false && this.state.change_pay_to_save===false  && this.state.is_add_item_btn===false) &&
                                <button id="moveToPayment" type="button" className="mrleft-10 btn btn-success" style={{padding:'2px 10px',marginTop:'-2px'}} onClick={this.moveToPayment}>Pay</button>	
                              }
                              {(this.state.is_fy_closed===true && this.state.change_pay_to_save===false && this.state.is_add_item_btn===false) &&
                                <ClosedFYButton className="mrleft-10" style={{padding:'2px 10px',marginTop:'-2px'}} label="Pay" type="button"/>
                              }
                              {(this.state.is_fy_closed===false && this.state.change_pay_to_save===true && this.state.is_add_item_btn===false) &&
                              <>
                                <button  style={{padding:'2px 10px',marginTop:'-2px'}} type="button" onClick={this.saveBilling} id="ts-save" className="mrleft-10 btn btn-success">{this.state.save_button}	
                                <ShortcutHelp arrow="bottom" target="ts-save-bill" shortcuts={['Ctrl+Enter']}/></button>	
                                <button  style={{padding:'2px 10px'}} type="button" id="ts-save-btn-spinner" className="hide mrleft-10 btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>	
                                </>
                              }
                              {(this.state.is_fy_closed===true && this.state.change_pay_to_save===true) &&
                                <ClosedFYButton className="mrleft-10" style={{padding:'2px 10px',marginTop:'-2px'}} label={this.state.save_button} type="button"/>
                              }
                            </>
                          }	
                          {(this.state.payment_mode!=='payment' && this.state.payment_mode!=='cash') &&	
                          <>	
                            {(this.state.is_add_item_btn===true) &&
                              <button type="button" className="mrleft-10 btn btn-success" style={{padding:'2px 10px',marginTop:'-2px'}} onClick={this.moveToAddNewItem}>Add Item</button>	
                            }
                            {(this.state.is_fy_closed===false && this.state.is_add_item_btn===false) &&
                              <>
                              <button  style={{padding:'2px 10px',marginTop:'-2px'}} type="button" onClick={this.saveBilling} id="ts-save" className="mrleft-10 btn btn-success">{this.state.save_button}	
                              <ShortcutHelp arrow="bottom" target="ts-save-bill" shortcuts={['Ctrl+Enter']}/></button>	
                              <button  style={{padding:'2px 10px'}} type="button" id="ts-save-btn-spinner" className="hide mrleft-10 btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>	
                              </>
                            }
                            {(this.state.is_fy_closed===true && this.state.is_add_item_btn===false) &&
                              <ClosedFYButton className="mrleft-10" style={{padding:'2px 10px',marginTop:'-2px'}} label={this.state.save_button} type="button"/>
                            }
                          </>	
                          }	
                          </span>	
                          
                        </div>	
                      	
                    </div>
                    {this.state.send_type==='save' &&
                    <div className="mrtop-15 new-billing-opt hide">
                          {this.is_access('billing-sale-create') &&
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" onChange={this.operateCheck} id="newsale" value="New Sale" checked={this.state.billing_type==='New Sale'?true:false}/>
                            <label className="form-check-label" htmlFor="newsale">New Sale</label>
                            <ShortcutHelp  arrow="top" target="new_bill_cb" shortcuts={['Ctrl+Alt+1']}/>
                          </div>
                          }
                          {this.is_access('billing-purchase-create') &&
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" onChange={this.operateCheck} id="newpurchase" value="New Purchase"  checked={this.state.billing_type==='New Purchase'?true:false}/>
                            <label className="form-check-label" htmlFor="newpurchase">New Purchase</label>
                            <ShortcutHelp arrow="top" target="new_bill_cb" shortcuts={['Ctrl+Alt+2']}/>
                          </div>
                          }
                          {this.is_access('billing-sales-return-create') &&
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" onChange={this.operateCheck} id="newsalesreturn" value="New Sales Return"  checked={this.state.billing_type==='New Sales Return'?true:false}/>
                            <label className="form-check-label" htmlFor="newsalesreturn">New Sales Return</label>
                            <ShortcutHelp arrow="top" target="new_bill_cb" shortcuts={['Ctrl+Alt+3']}/>
                          </div>
                          }
                          {this.is_access('billing-purchase-return-create') &&
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" onChange={this.operateCheck} id="newpurchasereturn" value="New Purchase Return"  checked={this.state.billing_type==='New Purchase Return'?true:false}/>
                            <label className="form-check-label" htmlFor="newpurchasereturn">New Purchase Return</label>
                            <ShortcutHelp arrow="top" target="new_bill_cb" shortcuts={['Ctrl+Alt+4']}/>
                          </div>
                          }
                    </div>
                        }
                  </div>
                </nav>
                <div className="new-billing container-fluid mobile-view">
                {(this.state.is_unsaved_list) &&
                  <div className="alert alert-warning text-center" role="alert" style={{paddingTop: '0px',paddingBottom: '3px'}}>
                    <p style={{margin:'0px'}}>We have detected that you were creating a bill and left in the middle. What you wish to do <button className="btn btn-warning btn-sm" onClick={this.hanldeContinueWithExistingListItems} style={{paddingBottom:'0px',paddingTop:'0px'}}>Continue with existing list item</button> or <button className="btn btn-warning btn-sm" onClick={this.hanldeCreateFrieshListItems} style={{paddingBottom:'0px',paddingTop:'0px'}}>Create a friesh list</button>?</p>
                  </div>
                }
                <ServiceLoader show={this.state.show_loader}/>
                <input type="text" className="hide" id="returned_invoice_id" defaultValue={this.state.returned_invoice_id}/>
                <input type="hidden" id="payment_mode" value=""/>
                <input type="hidden" id="contact_state" value=""/>
                <input type="hidden" id="credit_days" value=""/>
                <input type="hidden" id="lsp-percent" defaultValue={this.state.lowest_selling_price_percent} />
                <input type="hidden" id="contact_discount" value="" />
                {/*<div className="add-new-bill-btn" onClick={this.handleAddNewBillItemBtn}>
                                  <FontAwesomeIcon icon={faPlus} />
                                </div>*/}
                  <div className="content-area new-billing-area">
                     <form id="contact-info-form">
                     <div className="content-head border-bottom">
                       <div className="row">
                        <div className="col-md-3 mrtop-5">
                         <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box" contact_info={this.state.contact_info}  target={this.state.billing_type}/>
                         
                         <input type="hidden" id="contact_add_type" />
                         <input className="hide" type="text" id="original_credit_limit_box" defaultValue={this.state.contact_info.original_credit_limit}/>
                         <input className="hide" type="text" id="buffer_amount_for_credit_limit" defaultValue={this.state.buffer_amount_for_credit_limit}/>
                         <input className="hide" type="text" id="credit_limit_box" defaultValue={this.state.contact_info.credit_limit}/>
                       
                        </div>
                        
                        {(this.state.billing_type==='New Sale') &&
                    
                        <div className="col-md-2 mrtop-5">
                          <p className="cinfo hide" id="cont-credit-limit">Credit Limit: <span id="credit_limit_txt">{this.state.contact_info!=''?this.currency_format(this.state.contact_info.credit_limit): 0}</span></p>
                          <p className="cinfo hide" id="cont-old-balance">Old Balance: <span id="previous_balance_txt">{this.state.contact_info!=''?this.currency_format(this.state.contact_info.previous_balance): 0}</span></p>
                        </div>
                       
                        }
                        {(this.state.billing_type==='New Purchase') &&
                          <div className="col-md-2 mrtop-5"></div>
                        }
                        
                        {(this.state.billing_type==='New Sale') &&
                          <div className="col-md-2 mrtop-5">
                             
                          </div>
                        }
                        {(this.state.staff_options.length>0 && (this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase')) &&
                        <div className="col-md-2  mrtop-5">
                           <select className="form-control" tabindex={2} id="staff-dropdown" value={this.state.assisted_by} onChange={this.handleChangeStaff} target="staff" onKeyDown={this.handleOpenStaff}>
                              <option value="">Select Staff</option>
                              {(this.state.staff_options.length > 0) ?this.state.staff_options.map( (value, index) => {
                               return (
                                  <option value={value.id} key={index}>{value.name}</option>
                                )
                              }) : ''}
                          </select>
                        </div>
                        }
                        {(this.state.staff_options.length===0 && (this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase')) &&
                          <div className="col-md-2  mrtop-5"></div>
                        }
                        {(this.state.billing_type==='New Purchase') &&
                          <div className="col-md-2  mrtop-5">
                            <div className="input-group">
                              <input id="selling_price_margin" type="number" className="form-control no-border-right" placeholder="S. Price Margin" tabindex="3" defaultValue={((this.state.billing_data && this.state.billing_data.selling_price_margin)?this.state.billing_data.selling_price_margin:'')}/>
                              <div className="input-group-append">
                                <span className="input-group-text pad-left-none">%</span>
                              </div>
                            </div>
                            
                          </div>
                        }
                        
                        {(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase') &&
                          <div className="col-md-2 text-right  mrtop-5">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text no-border-right">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                {(is_update_date===true) &&
                                  <DatePicker className="form-control  no-border-left" selected={this.state.billingDate} onChange={this.billingDate} dateFormat="dd/MM/yyyy"/>
                                }
                                {(is_update_date===false) &&
                                  <input type="text" className="form-control  no-border-left" defaultValue={(this.state.billing_data && this.state.billing_data.visible_created_date)?this.state.billing_data.visible_created_date:this.today_date} readOnly  style={{background:'white'}}/>
                                }
                              </div>
                            </div>
                          </div>
                          }
                        {(this.state.billing_type==='New Purchase Return' || this.state.billing_type==='New Sales Return') &&	
                         <div className="col-md-8"></div>	
                        }
                        <div className="col-md-1 text-right mrtop-5 bill-view">
                            <label className="bil_no">{this.state.currrent_sequence}</label>
                        </div>
                       </div>
                       {(this.state.contact_error!=="") &&
                         <div className="row">
                          <div className="col-md-12">
                            <p className="error" dangerouslySetInnerHTML={{ __html: this.state.contact_error }} />
                          </div>
                         </div>
                       }
                     </div>
                     {(this.state.is_contact_selected===true) &&	
                     <>	
                     {(this.state.billing_type==='New Purchase Return' || this.state.billing_type==='New Sales Return') &&	
                     <div className="content-head border-bottom">	
                       <div className="row">	
                        <div className="col-md-3 mrtop-5 mrbtm-10 opt-ret">	
                            {(this.state.is_contact_selected===true) &&	
                            <>	
                            <label>Choose Return Type</label>	
                            <div className="row ret-opt">	
                               <div className="col-md-6">	
                                 {(this.state.return_type!=='inv-items') &&	
                                    <button type="button" className="btn btn-light" style={{width:'100%'}} id="inv-items" onClick={this.handleSelectReturnOpt}>Items</button>	
                                  }	
                                  {(this.state.return_type==='inv-items') &&	
                                    <button type="button" className="btn btn-success bill-ret-type" style={{width:'100%'}} id="" data-id="inv-items">	
                                      <FontAwesomeIcon icon={faCheckCircle} /> Items	
                                    </button>	
                                  }	
                               </div>	
                                <div className="col-md-6">	
                                {(this.state.return_type!=='invoice') &&	
                                  <button type="button" onClick={this.handleSelectReturnOpt} className="btn btn-light" style={{width:'100%'}}  id="invoice">Invoice</button>	
                                }	
                                {(this.state.return_type==='invoice') &&	
                                    <button type="button" className="btn btn-success bill-ret-type" style={{width:'100%'}} id="invoice" data-id="invoice" onClick={this.handleSelectReturnOpt}>	
                                      <FontAwesomeIcon icon={faCheckCircle} /> Invoice	
                                    </button>	
                                  }	
                                </div>	
                            </div>	
                            </>	
                            }	
                        </div>	
                        	
                          {(this.state.return_type!=='') &&	
                          <>	
                        <div className="col-md-6 mrtop-5 border-left"></div>
                        <div className="col-md-3 mrtop-5">	
                            <label>Choose Return Option</label>	
                            <select className="form-control" onChange={this.handleReturnOptChange} value={this.state.return_option}>
                                <option value="">Select Option</option>
                                <option value="old_bill">Against Old Bill</option>
                                {(this.state.is_store===false) &&
                                <>
                                <option value="on_account">On Account</option>
                                <option value="cash">Cash Return</option>
                                </>
                            }
                                {(this.state.billing_type==='New Sales Return') &&	
                                  <>
                                    <option value="credit">Credit Note</option>	
                                  </>
                                }
                                {/* {(this.state.billing_type==='New Purchase Return') &&	
                                  <>	
                                  <option value="debit">Debit Note</option>	
                                  </>
                                } */}
                            </select>
                        </div> 	
                          </>	
                          }	
                       </div>	
                     </div>	
                    }	
                    </>	
                  }
                   {((this.state.billing_type==='New Purchase Return' || this.state.billing_type==='New Sales Return') && this.state.return_option!=='') &&
                     <div className="main-content border-bottom">
                        <div className="inner-content">
                          
                            <div className={"row "+new_purchase_info}>
                            <div className="col-md-3 mrtop-5">
                              <span className="fe-title">INVOICE DATE</span>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text no-border-right">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </span>
                                  {(is_update_date===true) &&
                                    <DatePicker className="form-control  no-border-left" selected={this.state.invoiceDate} onChange={this.invoiceDate} dateFormat="dd/MM/yyyy" id="invoiceDate"/>
                                  }
                                  {(is_update_date===false) &&
                                    <input type="text" className="form-control  no-border-left" defaultValue={(this.state.billing_data && this.state.billing_data.visible_invoice_date)?this.state.billing_data.visible_invoice_date:this.today_date} readOnly style={{background:'white'}}/>
                                  }
                                </div>
                              </div>
                            </div>
                            {(this.state.advance_sale_data===true) &&
                            <>
                            <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">AGENT NAME</span>
                                   {/*  <input type="text" className="form-control f-field" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.agent_name)?this.state.dispatched_info.agent_name:''} id="agent-dropdown"/>
 */}
                                    <ContactSelect className="form-control f-field" name="agent_name" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.agent_name)?this.state.dispatched_info.agent_name:''} label="Select Agent" type="5"  id="agent-dropdown"/>
                                    
                                  </div> 
                                 
                                  <div className="col-md-3 mrtop-5">
                                     <span className="fe-title">TRANSPORT</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="dispatched-through" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.dispatched_through)?this.state.dispatched_info.dispatched_through:''}/>
                                  </div> 
                           
                                  <div className="col-md-3 mrtop-5">
                                     <span className="fe-title">LR NO DISPATCHED DOCUMENT</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="dispatched-doc" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.dispatched_document)?this.state.dispatched_info.dispatched_document:''} onChange={this.handleCheckLRNo}/>
                                    <p className="error" style={{margin:'0px',fontSize:'12px'}}>{this.state.lr_error}
                                    {(this.state.lr_error!=="") &&
                                        <DrawerLink  target="transport" url={''} name={this.state.lr_error_info.transport_number} info={this.state.lr_error_info}/>
                                    }
                                    </p>
                                  </div> 
                                
                                  <div className="col-md-3 mrtop-5">
                                    <span className="fe-title">SUPPLIER'S REFRENCE NO</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="supplier-ref-no" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.supplier_ref_no)?this.state.dispatched_info.supplier_ref_no:''}/>
                                  </div> 
                                  <div className="col-md-3 mrtop-5">
                                    <span className="fe-title">DELIVERY NOTE</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="delivery-note" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.delivery_note)?this.state.dispatched_info.delivery_note:''}/>
                                  </div> 
                                  <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">OTHER NOTE FROM SUPPLIER</span>
                                  <input type="text" className="form-control  f-field" placeholder="" id="other-note"  defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.other_note)?this.state.dispatched_info.other_note:''}/>
                                    
                                </div>
                            
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">OTHER REFERENCE</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="other-refrence" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.other_refrence)?this.state.dispatched_info.other_refrence:''}/>
                                </div>
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">TERMS OF DELIVERY</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="terms-of-delivery"  defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.terms_of_delivery)?this.state.dispatched_info.terms_of_delivery:''}/>
                                </div>
                                
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">PIECES IN PARCEL</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="pieces_in_parcel"  defaultValue={(this.state.dispatched_info && this.state.dispatched_info.pieces_in_parcel)?this.state.dispatched_info.pieces_in_parcel:''}/>
                                </div>
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">NO. OF PARCELS/BALES</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="no_of_parcels"  defaultValue={(this.state.dispatched_info && this.state.dispatched_info.no_of_parcels)?this.state.dispatched_info.no_of_parcels:''}/>
                                </div>
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">PACKED BY</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="packed_by"  defaultValue={(this.state.dispatched_info && this.state.dispatched_info.packed_by)?this.state.dispatched_info.packed_by:''}/>
                                </div>
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">CHECKED BY</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="checked_by"  defaultValue={(this.state.dispatched_info && this.state.dispatched_info.checked_by)?this.state.dispatched_info.checked_by:''}/>
                                </div>
                                </>
                            }
                            </div>
                          
                        </div>
                     </div>
                   }
                    {(this.state.billing_type==='New Purchase') &&
                     <div className="main-content border-bottom">
                        <div className="inner-content">

                         
                          {this.state.billing_type==='New Purchase' &&
                            <>
                               <div className={"row "+new_purchase_info}>
                                
                                  <div className="col-md-3 mrtop-5">
                                    <span className="fe-title">INVOICE DATE</span>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text no-border-right">
                                          <FontAwesomeIcon icon={faCalendarAlt} />
                                        </span>
                                        {(is_update_date===true) &&
                                          <DatePicker className="form-control  no-border-left" selected={this.state.invoiceDate} onChange={this.invoiceDate} dateFormat="dd/MM/yyyy" id="invoiceDate"/>
                                        }
                                        {(is_update_date===false) &&
                                          <input type="text" className="form-control  no-border-left" defaultValue={(this.state.billing_data && this.state.billing_data.visible_invoice_date)?this.state.billing_data.visible_invoice_date:this.today_date} readOnly style={{background:'white'}}/>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3 mrtop-5">
                                    <span className="fe-title">AGENT NAME</span>
                                    {/* <input type="text" className="form-control" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.agent_name)?this.state.dispatched_info.agent_name:''} id="agent-dropdown"/> */}

                                    <ContactSelect className="form-control" name="agent_name" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.agent_name)?this.state.dispatched_info.agent_name:''} label="Select Agent" type="5"  id="agent-dropdown"/>
                                  </div> 
                                 
                                  <div className="col-md-3 mrtop-5">
                                    <span id="handleLoadUnlinkTransports" onClick={this.handleLoadUnlinkTransports}></span>
                                    <span className="fe-title">TRANSPORT LIST</span>
                                    <select className="form-control" onChange={this.handleSelectTransportFromList} id="transport-link-list" value={this.state.transport_id}>
                                      <option value="">SELECT TRASPORT TO LINK</option>
                                      {(this.state.unlink_transports.map((value,index)=>{
                                        return (
                                          <option value={value.transport_id}>{value.transport_number}({value.lr_no})</option>
                                        )
                                      }))}
                                    </select>
                                  </div> 
                                  <div className="col-md-3 mrtop-5">
                                     <span className="fe-title">TRANSPORT</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="dispatched-through" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.dispatched_through)?this.state.dispatched_info.dispatched_through:''}/>
                                  </div> 
                           
                                  <div className="col-md-3 mrtop-5">
                                     <span className="fe-title">LR NO DISPATCHED DOCUMENT</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="dispatched-doc" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.dispatched_document)?this.state.dispatched_info.dispatched_document:''} onChange={this.handleCheckLRNo}/>
                                    <p className="error" style={{margin:'0px',fontSize:'12px'}}>{this.state.lr_error}
                                    {(this.state.lr_error!=="") &&
                                        <DrawerLink  target="transport" url={''} name={this.state.lr_error_info.transport_number} info={this.state.lr_error_info}/>
                                    }
                                    </p>
                                  </div> 
                                
                                  <div className="col-md-3 mrtop-5">
                                    <span className="fe-title">SUPPLIER'S REFRENCE NO</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="supplier-ref-no" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.supplier_ref_no)?this.state.dispatched_info.supplier_ref_no:''}/>
                                  </div> 
                                  <div className="col-md-3 mrtop-5">
                                    <span className="fe-title">DELIVERY NOTE</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="delivery-note" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.delivery_note)?this.state.dispatched_info.delivery_note:''}/>
                                  </div> 
                                  <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">OTHER NOTE FROM SUPPLIER</span>
                                  <input type="text" className="form-control  f-field" placeholder="" id="other-note"  defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.other_note)?this.state.dispatched_info.other_note:''}/>
                                    
                                </div>
                            
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">OTHER REFERENCE</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="other-refrence" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.other_refrence)?this.state.dispatched_info.other_refrence:''}/>
                                </div>
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">TERMS OF DELIVERY</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="terms-of-delivery"  defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.terms_of_delivery)?this.state.dispatched_info.terms_of_delivery:''}/>
                                </div>
                                <div className="col-md-3 mrtop-5">
                                    <span className="fe-title">INVENTORY AFTER ADDING</span>
                                    <select className="form-control" value={this.state.default_inventory_status} id="default_inventory_status" onChange={this.handleChangeDefaultInventory}>
                                    <option value="Draft">Inactive</option>
                                    <option value="Active">Active</option>
                                  </select>
                                </div> 
                              </div>
                            </>
                          }
                        </div>
                     </div>
                   }


                   {(this.state.billing_type==='New Sale' && this.state.advance_sale_data===true) &&
                     <div className="main-content border-bottom">
                        <div className="inner-content">
                          
                            <div className={"row "+new_purchase_info}>
                                  <div className="col-md-3 mrtop-5">
                                    <span className="fe-title">AGENT NAME</span>
                                    {/* <input type="text" className="form-control" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.agent_name)?this.state.dispatched_info.agent_name:''} id="agent-dropdown"/> */}

                                    <ContactSelect className="form-control" name="agent_name" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.agent_name)?this.state.dispatched_info.agent_name:''} label="Select Agent" type="5"  id="agent-dropdown"/>
                                  </div> 
                                 
                                  <div className="col-md-3 mrtop-5">
                                     <span className="fe-title">TRANSPORT</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="dispatched-through" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.dispatched_through)?this.state.dispatched_info.dispatched_through:''}/>
                                  </div> 
                           
                                  <div className="col-md-3 mrtop-5">
                                     <span className="fe-title">LR NO DISPATCHED DOCUMENT</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="dispatched-doc" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.dispatched_document)?this.state.dispatched_info.dispatched_document:''} onChange={this.handleCheckLRNo}/>
                                    <p className="error" style={{margin:'0px',fontSize:'12px'}}>{this.state.lr_error}
                                    {(this.state.lr_error!=="") &&
                                        <DrawerLink  target="transport" url={''} name={this.state.lr_error_info.transport_number} info={this.state.lr_error_info}/>
                                    }
                                    </p>
                                  </div> 
                                
                                  <div className="col-md-3 mrtop-5">
                                    <span className="fe-title">SUPPLIER'S REFRENCE NO</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="supplier-ref-no" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.supplier_ref_no)?this.state.dispatched_info.supplier_ref_no:''}/>
                                  </div> 
                                  <div className="col-md-3 mrtop-5">
                                    <span className="fe-title">DELIVERY NOTE</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="delivery-note" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.delivery_note)?this.state.dispatched_info.delivery_note:''}/>
                                  </div> 
                                  <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">OTHER NOTE FROM SUPPLIER</span>
                                  <input type="text" className="form-control  f-field" placeholder="" id="other-note"  defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.other_note)?this.state.dispatched_info.other_note:''}/>
                                    
                                </div>
                            
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">OTHER REFERENCE</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="other-refrence" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.other_refrence)?this.state.dispatched_info.other_refrence:''}/>
                                </div>
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">TERMS OF DELIVERY</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="terms-of-delivery"  defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.terms_of_delivery)?this.state.dispatched_info.terms_of_delivery:''}/>
                                </div>
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">PIECES IN PARCEL</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="pieces_in_parcel"  defaultValue={(this.state.billing_data && this.state.billing_data.pieces_in_parcel)?this.state.billing_data.pieces_in_parcel:''}/>
                                </div>
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">NO. OF PARCELS/BALES</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="no_of_parcels"  defaultValue={(this.state.billing_data && this.state.billing_data.no_of_parcels)?this.state.billing_data.no_of_parcels:''}/>
                                </div>
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">PACKED BY</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="packed_by"  defaultValue={(this.state.billing_data && this.state.billing_data.packed_by)?this.state.billing_data.packed_by:''}/>
                                </div>
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">CHECKED BY</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="checked_by"  defaultValue={(this.state.billing_data && this.state.billing_data.checked_by)?this.state.billing_data.checked_by:''}/>
                                </div>
                            </div>
                          
                        </div>
                     </div>
                   }
                  
                     </form> 
                    <span onClick={this.handleCreateVariation} id="handleCreateVariation"></span>
                    {(this.state.isMobile===true) &&
                      <div className={"content-head border-bottom add-item-mob "+this.state.billing_type.replaceAll(" ",'-')} style={{padding:'10px 14px 3px 12px', background:'#f8f8ff'}}>
                         <div className="row">
                          <div className="col-md-12">
                            <h5>Add Items</h5>
                          </div>
                        </div> 
                      </div> 
                    }
                 
                      <div className={(this.state.is_on_account_return===true)?'row':''}>
                        <div className={(this.state.is_on_account_return===true)?'col-md-8':''}>
                          {this.state.add_item_area}
                        </div>
                        {(this.state.is_on_account_return===true) &&
                            <div className="col-md-4">
                            {(this.state.update_id>0 && this.state.return_goods_bills.length > 0)&&
                              <table className="table  pen-in-li no-block">
                                <thead className="thead-light">
                                  <tr>
                                    <th colSpan="3" className="text-center">
                                     RETURN GOODS INVOICE(S)
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(this.state.return_goods_bills.length > 0) ?this.state.return_goods_bills.map( (value, index) => {
                                     return (
                                      <tr key={ index } index={index} className="pb-list">
                                        <td>
                                          <div class="form-check form-check-inline">
                                            <label class="form-check-label" for={"return-chk-"+index}>{value.value2}</label>
                                         
                                          </div>
                                          <input type="hidden" className="ret-good-amt" data-id={index} id={"return-amt-"+index} value={value.grand_total} />
                                        </td>
                                        <td>
                                          ₹{this.currency_format(value.grand_total)} 
                                        </td>
                                        <td className="text-right">
                                          <div className="item-mod-options" style={{display:'flex'}}>
                                            <span className='item-remove' data-id={index} onClick={this.handleOpenDeleteRGItemConfirm}><FontAwesomeIcon icon={faTimes} />
                                            </span>
                                            <span className="rm-rg-litem hide" id={"rm-rg-litem-"+index}>
                                              <span className="rm-yes" data-id={index} onClick={this.handleDeleteReturnGoods}>
                                                Yes
                                              </span>
                                              <span className="rm-no" data-id={index} onClick={this.handleCloseDeleteConfirm}>
                                                No
                                              </span>
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                   }) : <tr><td style={{borderTop:'none'}} className="text-center" colSpan="3">{this.state.pending_loader}</td></tr> }
                                </tbody>
                              </table>
                            }
                              <table className="table pen-in-li  no-block" id="list-item-table">
                                <thead className="thead-light">
                                  <tr>
                                    <th colSpan="4" className="text-center">
                                     OLD INVOICES
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(this.state.pending_bills.length > 0) ?this.state.pending_bills.map( (value, index) => {
                                     return (
                                      <tr key={ index } index={index} className="pb-list" id={"pi-list-"+index}>
                                        <td>
                                          <div class="form-check form-check-inline">
                                          
                                            <input class="form-check-input pending-chk" type="checkbox" id={"pending-chk-"+index} value={value.id} data-id={index} onChange={this.handleCheckPendingInvoice}/>
                                            
                                            <label class="form-check-label" for={"pending-chk-"+index}>
                                              {value.value2}
                                              {(value.fin_year!==undefined) &&
                                                <span className="fyinstrip">{value.fin_year}</span>
                                              }
                                            </label>
                                            <span id={"pchk-error-"+index}></span>
                                          </div>
                                          <input type="hidden" id={"pending-amt-"+index} value={value.grand_total} />
                                        </td>
                                        <td>
                                          ₹{this.currency_format(value.grand_total)} 
                                        </td>
                                      </tr>
                                    )
                                   }) : <tr><td style={{borderTop:'none'}} className="text-center" colSpan="3">{this.state.pending_loader}</td></tr> }
                                </tbody>
                              </table>
                              
                            </div>
                          }
                      </div>
                      <div className={"content-head nb-detailed-area "+this.state.billing_type.replaceAll(" ",'-')}>
                       <div className="row">
                          <div className="col-md-6">
                              <textarea className="form-control text-notes" id="billing-notes" placeholder="Note" defaultValue={(this.state.billing_data && this.state.billing_data.note)?this.state.billing_data.note:''}></textarea>
                              <ShortcutHelp arrow="top" target="msg-to-customer" shortcuts={['Ctrl+M']}/>
                          </div>
                         <div className="col-md-2"></div>
                          <div className="col-md-4">
                           
                              <table className="table billing-total">
                                <tbody>
                                    <tr>
                                        <td><span className="sp-normal">Total Units</span></td>
                                        <td className="text-right"><span className="sp-normal all-out" id="total-unit">{(this.state.billing_data && this.state.billing_data.total_unit)?this.state.billing_data.total_unit:0}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span className="sp-normal">Total Quantity</span></td>
                                        <td className="text-right"><span className="sp-normal all-out" id="total-quantity">{(this.state.billing_data && this.state.billing_data.quantity)?this.state.billing_data.quantity:0}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span className="sp-normal">Subtotal</span></td>
                                        <td className="text-right"><span style={{fontWeight:'bold'}}  className="rs-sym">₹</span><span className="sp-normal all-out" id="sub-total">{(this.state.billing_data && this.state.billing_data.sub_total)?this.state.billing_data.sub_total:0}</span></td>
                                    </tr>
                                    {(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase' ) &&
                                      <>
                                        <tr>
                                            <td><span className="sp-normal">Discount</span></td>
                                            <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-discount">{(this.state.billing_data && this.state.billing_data.discount)?this.state.billing_data.discount:0}</span></td>
                                        </tr> 
                                      </>
                                    }
                                    {(this.state.billing_type==='New Sales Return' || this.state.billing_type==='New Purchase Return') &&
                                      <>
                                        <tr style={{display:(this.state.billing_data && this.state.billing_data.discount && this.state.billing_data.discount>0)?'':'none'}} id="nsr-npr-discount">
                                            <td><span className="sp-normal">Discount</span></td>
                                            <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-discount">{(this.state.billing_data && this.state.billing_data.discount)?this.state.billing_data.discount:0}</span></td>
                                        </tr> 
                                      </>
                                    }
                                    <tr>
                                        <td><span className="sp-normal">Discount on Bill <span id="disc_on_bill_lbl">{(this.state.discount_sym==='per') &&
                                            <>
                                               <span className="rs-sym" style={{fontWeight:'bold'}}> : ₹{this.floating_point(this.state.global_discount_amount)}
                                               </span>
                                            </>
                                          }</span></span></td>
                                        <td className="text-right">
                                          <span className="sp-normal all-out" id="global_discount_lbl"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                      <td><span className="sp-normal">Total Before Tax</span></td>
                                      <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-before-tax">{(this.state.billing_data && this.state.billing_data.total_before_tax)?this.currency_format(this.state.billing_data.total_before_tax):0}</span></td>
                                    </tr>
                                    {(this.state.assisted_by!=="" && this.state.is_global_commission===true) &&
                                      <tr>
                                        <td><span className="sp-normal">Staff Commission</span></td>
                                        <td className="text-right"><span className="rs-sym">₹</span>
                                          <span className="sp-normal all-out" id="staff-global-comission">{(this.state.billing_data && this.state.billing_data.comission)?this.state.billing_data.comission:0}</span>
                                          </td>
                                      </tr>
                                    }
                                    
                                    <tr>
                                      <td><span className="sp-normal">Tax</span></td>
                                      <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-after-tax">{(this.state.billing_data && this.state.billing_data.tax)?this.state.billing_data.tax:0}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span className="sp-normal">Total</span></td>
                                        <td className="text-right"><span style={{fontWeight:'bold'}}  className="rs-sym">₹</span><span className="sp-normal" id="s-total">{(this.state.billing_data && this.state.billing_data.calculate_total)?this.currency_format(this.state.billing_data.calculate_total):0}</span></td>
                                    </tr>
                                    {(this.state.enable_tcs===true) &&
                                      <tr>
                                          <td><span className="sp-normal">TCS <span id="tcs-tot-per">0</span>%</span></td>
                                          <td className="text-right"><span style={{fontWeight:'bold'}}  className="rs-sym">₹</span><span className="sp-normal" id="tcs-total">0</span></td>
                                      </tr>
                                    }
                                    
                                    <tr>
                                        <td><span className="sp-normal">Round Off</span></td>
                                        <td className="text-right">{this.state.round_off_sym}<span className="sp-normal" id="round_off_lbl">{this.state.round_off}</span></td>
                                    </tr>
                                    {(is_cd_note) &&
                                      <>
                                    <tr>
                                      <td>
                                        <span className="sp-normal">{cd_type} Note (if any)</span>
                                      </td>
                                      <td className="text-right tbl-in-bx">
                                        <span className="sp-normal" id="ptype_note_lbl">{(this.state.billing_data && this.state.billing_data.payment_note_number  )?this.state.billing_data.payment_note_number:''}</span>
                                      </td>
                                    </tr>
                                    </>
                                    }

                                    {(this.state.is_reedeem && is_cd_note)&&
                                      <>
                                      <tr>
                                          <td><span className="sp-normal">Total Before {cd_type} Note Redeem</span></td>
                                          <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="before-redeem-total">{(this.state.billing_data && this.state.billing_data.before_payment_note_redeem_total)?this.currency_format(this.state.billing_data.before_payment_note_redeem_total):''}</span>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td><span className="sp-normal">Redeem Amount</span></td>
                                          <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="redeem-total">{(this.state.billing_data && this.state.billing_data.payment_note_redeem_total)?this.currency_format(this.state.billing_data.payment_note_redeem_total):0}</span>
                                          </td>
                                      </tr>
                                      </>
                                    }

                                    {(this.state.is_item_return) &&
                                      <>
                                      <tr>
                                          <td><span className="sp-normal">Total Before Return</span></td>
                                          <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-before-return">{(this.state.billing_data && this.state.billing_data.return_meta_data && this.state.billing_data.return_meta_data.total_before_return)?this.state.billing_data.return_meta_data.total_before_return:0}</span>
                                            <input type="text" className="hide" id="total-before-return-txt" defaultValue={(this.state.billing_data && this.state.billing_data.return_meta_data && this.state.billing_data.return_meta_data.total_before_return)?this.state.billing_data.return_meta_data.total_before_return:0}/>
                                          </td>
                                      </tr>
                                      <tr style={{borderBottom:'1px solid #d7d7d7',background:'rgb(252 252 255)'}}>
                                        <th colSpan="2">Return Info</th>
                                      </tr>
                                      <tr style={{background:'rgb(252 252 255)'}}>
                                        <td><span className="sp-normal">Total Units</span></td>
                                        <td className="text-right">
                                            <span className="sp-normal all-out" id="return-total-unit">{(this.state.billing_data && this.state.billing_data.return_meta_data && this.state.billing_data.return_meta_data.total_unit)?this.state.billing_data.return_meta_data.total_unit:0}</span>
                                        </td>
                                      </tr>
                                      <tr style={{background:'rgb(252 252 255)'}}>
                                        <td><span className="sp-normal">Total Quantity</span></td>
                                        <td className="text-right">
                                            <span className="sp-normal all-out" id="return-total-quantity">{(this.state.billing_data && this.state.billing_data.return_meta_data && this.state.billing_data.return_meta_data.total_quantity)?this.state.billing_data.return_meta_data.total_quantity:0}</span>
                                        </td>
                                      </tr>
                                      <tr style={{background:'rgb(252 252 255)'}}>
                                        <td><span className="sp-normal">Subtotal</span></td>
                                        <td className="text-right">
                                            <span className="rs-sym">₹</span><span className="sp-normal all-out" id="return-subtotal">{(this.state.billing_data && this.state.billing_data.return_meta_data && this.state.billing_data.return_meta_data.subtotal)?this.state.billing_data.return_meta_data.subtotal:0}</span>
                                        </td>
                                      </tr>
                                      <tr style={{background:'rgb(252 252 255)'}}>
                                        <td><span className="sp-normal">Total</span></td>
                                        <td className="text-right">
                                            <span className="rs-sym">₹</span><span className="sp-normal all-out" id="return-total">{(this.state.billing_data && this.state.billing_data.return_meta_data && this.state.billing_data.return_meta_data.total)?this.currency_format(this.state.billing_data.return_meta_data.total):0}</span>
                                            <input type="text" className="hide" id="final-return-total-text" defaultValue={(this.state.billing_data && this.state.billing_data.return_meta_data && this.state.billing_data.return_meta_data.total)?this.state.billing_data.return_meta_data.total:0}/>
                                        </td>
                                      </tr>
                                      </>
                                    }

                                    <tr>
                                        <td><span className="sp-normal">Transportation/Other Charges</span></td>
                                        <td className="text-right"><span style={{fontWeight:'bold'}}  className="rs-sym">₹</span><span className="sp-normal" id="transportation-charge">{this.state.transportation_charge}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span className="sp-normal" style={{fontWeight:'bold'}}>Grand Total</span></td>
                                        <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" id="final-total"  style={{fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.total)?this.state.billing_data.total:0}</span>
                                          <input type="text" className="hide" id="final-total-text" defaultValue={this.state.billing_data.total}/>
                                        </td>
                                    </tr>
                                </tbody>
                              </table>
                              
                              <table width="100%" className="table inline-table tax-table">
                                      <tbody>
                                  <tr>
                                      <td>
                                        <span className="sp-normal">CGST</span>
                                      </td>
                                      <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-cgst">{(this.state.billing_data && this.state.billing_data.total_cgst)?this.state.billing_data.total_cgst:0}
                                        </span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                        <span className="sp-normal">SGST</span>
                                      </td>
                                      <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-sgst">{(this.state.billing_data && this.state.billing_data.total_sgst)?this.state.billing_data.total_sgst:0}
                                        </span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                        <span className="sp-normal">IGST</span>
                                      </td>
                                      <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-igst">{(this.state.billing_data && this.state.billing_data.total_igst)?this.state.billing_data.total_igst:0}
                                        </span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th><span className="sp-normal" style={{fontWeight:'bold'}}>TOTAL TAX</span></th>
                                      <td className="text-right"><span className="rs-sym" style={{fontWeight:'bold'}}>₹</span><span className="sp-normal all-out" id="total-tax"  style={{fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.tax)?this.state.billing_data.tax:0}</span></td>
                                  </tr> 
                                </tbody>
                              </table>
                          </div>
                       </div>
                     </div>
                     <div className={"content-head border-bottom border-top new-bill-btn-action hide "+this.state.billing_type.replaceAll(" ",'-')}>
                    <>
                      {(this.state.is_fy_closed===false) &&
                      <>
                        <button type="button" onClick={this.saveBilling} id="save" className="btn btn-success btn-save-action">{this.state.save_button}
                        <ShortcutHelp arrow="bottom" target="save-bill" shortcuts={['Ctrl+Enter']}/></button>
                        <button type="button" id="save-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                      </>
                      }
                      {(this.state.is_fy_closed===true) &&
                        <>
                          <ClosedFYButton className="btn-save-action" id="save" label={this.state.save_button} type="button"/>
                        </>
                      }
                        {(this.state.billing_type==='New Sale' && this.state.send_type==='save' && this.state.isMobile===false) &&
                        <>
                         {(this.state.is_fy_closed===false) &&
                          <>
                            <button type="button" onClick={this.saveBilling} id="save-print" className="mrleft-5 btn btn-success btn-save-action">Save + Print + New</button>
                            <button type="button" id="save-print-btn-spinner" className="mrleft-5 hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </>
                         }
                          {(this.state.is_fy_closed===true) &&
                            <>
                              <ClosedFYButton className="mrleft-5 btn-save-action" id="save-print" label="Save + Print + New" type="button"/>
                            </>
                          }
                        </>
                        }
                    </>
                      {(this.state.show_hold_button) &&
                      <>
                        {(this.state.is_fy_closed===false) &&
                          <>
                          <button type="button" onClick={this.saveBilling} id="hold" className="btn btn-success btn-save-action pull-right">Hold this Bill <ShortcutHelp  arrow="bottom" target="hold-bill" shortcuts={['Ctrl+Alt+H']}/></button>
                          <button type="button" id="hold-btn-spinner" className="hide btn btn-success btn-save-action pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </>
                        }
                        {(this.state.is_fy_closed===true) &&
                            <>
                              <ClosedFYButton className="btn-save-action pull-right" id="hold" label="Hold this Bill" type="button"/>
                            </>
                        }
                      </>
                      }
                      <>
                        <input className="hide" type="text" id="delete-row" defaultValue='[]'/>
                        <input className="hide" type="text" id="delete-return-row" defaultValue='[]'/>
                        {(this.state.send_type==='update' && is_payment) &&
                        <>
                          {(this.state.is_fy_closed===false) &&
                            <>
                              <button type="button" onClick={this.saveBilling} className="btn btn-success btn-save-action pull-right mrright-20" id="paid">Make Payment <ShortcutHelp  arrow="bottom" target="pay-bill" shortcuts={['Ctrl+Alt+P']}/></button>
                            </>
                          }
                          {(this.state.is_fy_closed===true) &&
                            <>
                              <ClosedFYButton className="btn-save-action pull-right mrright-20" id="paid" label="Make Payment" type="button"/>
                            </>
                          }
                        </>
                        }
                        {(this.state.send_type==='save' && is_payment) &&
                        <>
                         {(this.state.is_fy_closed===false) &&
                            <>
                              <button type="button" onClick={this.saveBilling} className="btn btn-success btn-save-action pull-right mrright-20" id="paid">Make Payment <ShortcutHelp  arrow="bottom" target="pay-bill" shortcuts={['Ctrl+Alt+P']}/></button>
                            </>
                          }
                          {(this.state.is_fy_closed===true) &&
                            <>
                              <ClosedFYButton className="btn-save-action pull-right mrright-20" id="paid" label="Make Payment" type="button"/>
                            </>
                          }
                        </>
                      }
                       {(this.state.send_type==='save' && is_payment) &&
                        <>
                          <button type="button" id="paid-btn-spinner" className="hide btn btn-success btn-save-action pull-right mrright-20"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                        </>
                        }
                      </>
                    </div>
                  </div>
                </div>
              </div>
              {(this.state.is_loaded_bill===true) &&
                  <BillPaymentPopup handleTriggerOnPaymentModeChange={this.handleTriggerOnPaymentModeChange.bind(this)} handleChangePayBtnVisibility={this.handleChangePayBtnVisibility.bind(this)} handleCreateBillFlag={this.handleCreateBillFlag.bind(this)} handleRefreshPaymentPopup={this.handleRefreshPaymentPopup.bind(this)} handleGlobalDiscount={this.handleGlobalDiscount.bind(this)} handleCheckGDiscountAmount={this.handleCheckGDiscountAmount.bind(this)} handleGlobalDiscountChange={this.handleGlobalDiscountChange.bind(this)} handleRoundOff={this.handleRoundOff.bind(this)} handleRoundOffChange={this.handleRoundOffChange.bind(this)} handleRedeemNote={this.handleRedeemNote.bind(this)} handleCheckAdvancePayment={this.handleCheckAdvancePayment.bind(this)} handleDefaultPaymentMode={this.handleDefaultPaymentMode.bind(this)} data={this.state}/>
              }
              <ModalBox id="add_contact" title="CREATE CONTACT" data_id="Create Contact"/>
              <ModalBox id="notice_box" title="Notice" data_id="Notice"/>
              <ModalBox id="ok_cancel_poup" title="Notice" data_id="Notice"/>
              <ModalBox id="add_category" title="ADD CATEGORY"  data_id="billing"/>
              <ModalBox id="print_bill" title="Print Bill" data_id="PrintBill" whatsapp_data={{whatsapp_phone:utils.check_mobile_number(this.state.whatsapp_phone),whatsapp_msg:this.state.whatsapp_msg}}/>
              <ModalBox id="buffer_credit_limit" title="Credit Limit Bill" data_id="CreditLimitBill"/>
              <PrintBill type={(this.state.billing_type==='New Sales Return')?3:1} id={this.state.response_id} target="print" bill_view_response={this.state.print_data} screen="new_billing" module="billing"/>
              <HoldBillPopup data={{screen:'billing',billing_type:this.state.billing_type,update_id:this.state.update_id}}/>	
              {(this.state.billing_type==='New Purchase Return' || this.state.billing_type==='New Sales Return') &&	
              <>	
                <div className="modal fade" id="return-invoices" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">	
                  <div className="modal-dialog" role="document">	
                    <div className="modal-content">	
                      <div className="modal-body">	
                        <div className="ret-inv-close" onClick={this.handleCloseReturnPop}><FontAwesomeIcon icon={faTimesCircle}/></div>	
                        <div className="row">	
                          <div className="col-md-12">	
                            <div className="input-group">	
                              <div className="input-group-append">	
                                <input type="text" className="form-control" placeholder="Search invoice to return" id="return-invoice-search" onChange={this.handleInvoiceSearch}/>	
                                <span className="input-group-text no-border-left" style={{position:'absolute',right:'0',height:'100%',cursor:'pointer'}} onClick={this.handleInvoiceSearch}>	
                                  {(this.state.is_return_bill_search===false) &&	
                                    <FontAwesomeIcon icon={faSearch} />	
                                  }	
                                  {(this.state.is_return_bill_search===true) &&	
                                    <FontAwesomeIcon icon={faSpinner} pulse/>	
                                  }	
                                </span>	
                              </div>	
                            </div>	
                          </div>	
                          <div className="col-md-12">	
                            <table className="table no-block">	
                                  <tbody>	
                                  {(this.state.searched_invoice_items && this.state.searched_invoice_items.length > 0) ?this.state.searched_invoice_items.map( (value, index) => {	
                                    return (	
                                      <>	
                                      <tr key={ index } className={(this.state.inv_active===index)?"active ret-inv-list":'ret-inv-list'} onClick={this.handleSelectReturnedInvoice} id={value.id} data-id={index}>	
                                        <td>	
                                          {value.value2}	
                                        </td>	
                                        <td>	
                                          {value.date}	
                                        </td>	
                                        <td>	
                                          {value.grand_total}	
                                        </td>	
                                      </tr>	
                                    </>	
                                    )	
                                  }) : <tr><td colSpan={3} className="text-center">{this.state.loading_process}</td></tr> }	
                                  </tbody>	
                            </table>	
                          </div>	
                        </div>	
                      </div>	
                    </div>	
                  </div>	
                </div> 	
                <div className="modal fade" id="returned-invoice-items" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">	
                <div className="modal-dialog" role="document">	
                  <div className="modal-content">	
                    <div className="modal-header">	
                      <h5 className="modal-title" id="ret-list-item-title"></h5>	
                    </div>	
                    <div className="modal-body">	
                      <div className="row">	
                        <div className="col-md-12">	
                          <table className="table no-block">	
                              <tbody id="returned-list-item-block">	
                              	
                              </tbody>	
                          </table>	
                        </div>	
                      </div>	
                    </div>	
                    <div className="modal-footer">	
                      <button type="button" className="btn btn-secondary" id="closeReturnBillList" onClick={this.closeReturnBillList}>Close</button>	
                      <button type="button" className="btn btn-success pull-right" onClick={this.handleReturnBillList} id="handleReturnBillList">Done</button>	
                    	
                    </div>	
                  </div>	
                </div>	
              </div> 	
              
              </>	
            }
            {(this.state.is_asked_otp===true) &&
                <div id="validate-otp" className="modal" style={{display:'block'}}>
                  <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                      <div className="modal-header flex-column">
                        <div className="icon-box icon-print">
                          <span className="material-icons"><FontAwesomeIcon icon={faKey}/></span>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12">
                            <label>Enter OTP</label>
                            <input type="text" className="form-control" placeholder="Enter OTP to Validate" onChange={(e)=>this.handleChangeOTP(e.target.value)} id="otp"/>
                          </div>
                          {(this.state.otp_error!=="") &&
                          <div className="col-md-12">
                            <p className="error">{this.state.otp_error}</p>
                          </div>
                          }
                        </div>
                      </div>
                      <div className="modal-footer" style={{padding:'0px'}}>
                        <div className="row">
                          <div className="col-md-6 mrtop-10">
                            <button type="button" className="btn btn-secondary" onClick={this.handleCloseOTP}>Close</button>
                          </div>
                          
                          <div className="col-md-6 mrtop-10">
                            <button type="button" id="validate-billing-otp" onClick={this.handleSubmitOTP} className="btn btn-primary">Validate</button>
                            <button type="button" id="validate-billing-otp-btn-spinner" className="hide btn btn-success btn-save-action pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
        );
    }
}